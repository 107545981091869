import { FC, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import { setUser, logout } from "store/actions/auth";

export interface SignInProps {
  user: any;
}

const SignIn: FC<SignInProps> = ({ user }) => {
  const dispatch = useDispatch();

  const currentUserState = (state: any) => state.auth;
  const auth = useSelector(currentUserState);

  useEffect(() => {
    const updateUser = async () => {
      try {
        if (!auth.isAuthenticated) {
          const user = await Auth.currentAuthenticatedUser();
          dispatch(setUser(user));
        } else {
          dispatch(logout());
        }
      } catch (error) {
    
        dispatch(logout());
      }
    };
    updateUser();
  }, [dispatch, user, auth]);

  return <div></div>;
};

export default SignIn;

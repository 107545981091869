import { on } from "events";
import useNcId from "hooks/useNcId";
import moment, { Moment } from "moment";
import { useEffect, useRef, useState } from "react";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "react-use";
import { addDate } from "store/actions/search";
import { Dates } from "store/reducers/search";

interface TravelDatesInputProps {
  className?: string;
  onBlur?: () => void;
  error?: string;
}

const DateIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="nc-icon-field"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 2 0 002 2z"
    />
  </svg>
);

const TravelDatesInput = ({
  className,
  onBlur,
  error,
}: TravelDatesInputProps) => {
  const dispatch = useDispatch();
  const travelingDate: Dates = useSelector((state: any) => state.search.dates);

  const [dates, setDates] = useState<Dates>({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
    null
  );
  const windowSize = useWindowSize();
  const startDateId = useNcId();
  const endDateId = useNcId();
  const inputStartDateRef = useRef<HTMLInputElement>(null);
  const inputEndDateRef = useRef<HTMLInputElement>(null);
  const [isStartDateFocused, setIsStartDateFocused] = useState(false);
  const [isEndDateFocused, setIsEndDateFocused] = useState(false);

  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  useEffect(() => {
    inputStartDateRef.current?.click();
  }, []);

  const toggleFocus = () => {
    console.log("isFocused");
    if (onBlur) onBlur();
    setIsEndDateFocused((prev) => !prev);
    setIsStartDateFocused((prev) => !prev);
  };

  const isDayBlocked = (day: Moment) =>
    moment().add(7, "days").isAfter(day, "day");

  const handleValueChange = (newValue: Dates) => {
    setDates(newValue);
    dispatch(addDate(newValue));
  };

  function handleDateFocusChange(
    nextFocusInput: FocusedInputShape | null
  ): void {
    onBlur && onBlur();
    console.log(nextFocusInput);
    setFocusedInput(nextFocusInput);
  }
  const handleFocusing = () => {
    onBlur && onBlur();
  };

  return (
    <div className="relative w-full h-full flex-1 bg-transparent">
      <div
        onClick={handleFocusing}
        className="relative w-full h-full flex flex-row gap-3 items-center justify-start bg-transparent"
      >
        <SingleDateWrapper
          name="Traveling from"
          date={dates.startDate}
          selectedInputRef={inputStartDateRef}
          isFocused={focusedInput === "startDate"}
          setShowCalendar={setShowCalendar}
          toggleFocus={toggleFocus}
        />
        <SingleDateWrapper
          name="Traveling until"
          date={dates.endDate}
          selectedInputRef={inputEndDateRef}
          isFocused={focusedInput === "endDate"}
          setShowCalendar={setShowCalendar}
          toggleFocus={toggleFocus}
        />
      </div>
      <div
        className={` absolute inset-1 flex ${showCalendar ? "" : "hidden"} `}
      >
        <div className="w-full h-full">
          <DateRangePicker
            required={true}
            disabled={false}
            startDate={dates.startDate}
            endDate={dates.endDate}
            onDatesChange={handleValueChange}
            onFocusChange={handleDateFocusChange}
            focusedInput={focusedInput}
            startDateId={startDateId}
            endDateId={endDateId}
            daySize={56}
            orientation={"horizontal"}
            showClearDates
            noBorder
            hideKeyboardShortcutsPanel
            anchorDirection={"left"}
            customArrowIcon={<div />}
            reopenPickerOnClearDates
            isDayBlocked={isDayBlocked}
          />
        </div>
      </div>
    </div>
  );
};

const SingleDateWrapper = ({
  date,
  isFocused,
  name,
  selectedInputRef,
  setShowCalendar,
  toggleFocus,
}: {
  name: string;
  date: Moment | null;
  selectedInputRef: React.RefObject<HTMLInputElement>;
  isFocused: boolean;
  setShowCalendar: React.Dispatch<React.SetStateAction<boolean>>;
  toggleFocus: () => void;
}) => {
  return (
    <div
      onClick={() => {
        setShowCalendar(true);
        toggleFocus();
      }}
      className={`h-full w-6/12 pl-4 bg-transparent flex flex-row justify-start items-center gap-x-2 rounded-full shadow-md  ${
        isFocused ? "!bg-[#fca15bc2] dark:bg-neutral-600" : ""
      }`}
    >
      <div
        className=""
        onClick={() => {
          setShowCalendar(true);
          toggleFocus();
        }}
      >
        <DateIcon />
      </div>
      {/* <div
        className=""
      > */}
      <div className="">
        {date && <label className="text-sm ">{name}</label>}
        <input
          type="text"
          ref={selectedInputRef}
          required
          placeholder={name}
          className={`m-0 p-0  h-full bg-transparent dark:bg-transparent border-none text-base
           text-white font-bold placeholder:bg-transparent placeholder:text-white placeholder:cursor-none focus:outline-none focus:ring-0 ${
             date ? "" : "text-transparent dark:text-transparent h-24"
           } `}
          value={date?.format("DD MMM") || ""}
        />
      </div>
      {/* </div> */}
    </div>
  );
};

export default TravelDatesInput;

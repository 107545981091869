import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { FC, Fragment, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { useSelector, useDispatch } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import convertNumbThousand from "utils/convertNumbThousand";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import CheckboxAsRadio from "shared/Checkbox/CheckboxAsRadio";
import Slider from "rc-slider";
import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import { RoundShape } from "react-placeholder/lib/placeholders";
import { getFlights } from "services/api";
import { getAirlines } from "services/api";
export interface HowToGetTherePageProps {
  className?: string;
}

const HowToGetTherePage: FC<HowToGetTherePageProps> = ({ className = "" }) => {
  const destination = useSelector((state: any) => state.search.travelingTo);
  const destinationString = destination?.city + ", " + destination?.country;
  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;

  const [airlineFilters, setAirlineFilters] = useState<any[]>([
    {
      name: "none",
      code: "none",
      logo: "none",
      description: "none",
      value: "none",
      checked: true,
    },
  ]);
  const [isLoading, setIsLoading] = useState(true);

  const [flights, setFlights] = useState<any>([]);
  const [airlines, setAirlines] = useState<any[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const [originalResults, setOriginalResults] = useState<any[]>([]);
  const [originalDurations, setOriginalDurations] = useState<any[]>([]);
  const [originalInbounds, setOriginalInbounds] = useState<any[]>([]);
  const [originalOutbounds, setOriginalOutbounds] = useState<any[]>([]);

  const [rangeFlightPrice, setRangeFlightPrice] = useState<{
    min: number;
    max: number;
  }>({ min: 0, max: 5000 });
  const [minFlightPrice, setMinFlightPrice] = useState<any>(0);
  const [maxFlightPrice, setMaxFlightPrice] = useState<any>(0);

  const [rangePrices, setRangePrices] = useState<any>([
    minFlightPrice,
    maxFlightPrice,
  ]);

  const [durationFilters, setDurationFilters] = useState<any[]>([]);
  const [inPoundTakeoffFilters, setInboundTakeoffFilters] = useState<any[]>([]);
  const [flightClassFilters, setFlightClassFilters] = useState<any[]>([]);

  const [outPoundTakeoffFilters, setOutboundTakeoffFilters] = useState<any[]>(
    []
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedSort, setSelectedSort] = useState("price");

  const [typeOfSorts, setTypeOfSorts] = useState([
    {
      id: "sort-1",
      name: "Price",
      description: "",
      sort: "price",
      checked: true,
    },
    {
      id: "sort-2",
      name: "Total duration",
      description: "",
      sort: "duration",
      checked: false,
    },
    {
      id: "sort-3",
      name: "Emissions",
      description: "",
      sort: "emissions",
      checked: false,
    },
  ]);

  const dispatch = useDispatch<any>();

  const search = useSelector((state: any) => state.search);

  const minPrice = () => {
    let minPrice = Number.MAX_VALUE; // Set the initial minimum price to the maximum possible value
    if (Array.isArray(originalResults)) {
      for (const hotel of originalResults) {
        if (hotel.price < minPrice) {
          // Check if the current hotel's price is less than the current minimum price
          minPrice = hotel.price; // Update the minimum price if a lower price is found
        }
      }
    }
    return minPrice;
  };

  const maxPrice = () => {
    let maxPrice = Number.MIN_VALUE;
    if (Array.isArray(originalResults)) {
      for (const hotel of originalResults) {
        if (hotel.price > maxPrice) {
          maxPrice = hotel.price;
        }
      }
    }
    return maxPrice;
  };

  // Function to generate dynamic duration filters
  const generateDurationFilters = (durations: any[]) => {
    const predefinedDurations = [
      {
        id: 1,
        name: "up to 2 hours",
        from: 0,
        to: 120,
        description: "",
        checked: true,
      },
      {
        id: 2,
        name: "2 to 4 hours",
        from: 120,
        to: 240,
        description: "",
        checked: true,
      },
      {
        id: 3,
        name: "4 to 6 hours",
        from: 240,
        to: 360,
        description: "",
        checked: true,
      },
      {
        id: 4,
        name: "6 to 8 hours",
        from: 360,
        to: 480,
        description: "",
        checked: true,
      },
      {
        id: 5,
        name: "8 to 10 hours",
        from: 480,
        to: 600,
        description: "",
        checked: true,
      },
      {
        id: 6,
        name: "10 to 12 hours",
        from: 600,
        to: 720,
        description: "",
        checked: true,
      },
      {
        id: 7,
        name: "12 to 14 hours",
        from: 720,
        to: 840,
        description: "",
        checked: true,
      },
      {
        id: 8,
        name: "14 to 16 hours",
        from: 840,
        to: 960,
        description: "",
        checked: true,
      },
      {
        id: 9,
        name: "16 to 18 hours",
        from: 960,
        to: 1080,
        description: "",
        checked: true,
      },
      {
        id: 10,
        name: "18 to 20 hours",
        from: 1080,
        to: 1200,
        description: "",
        checked: true,
      },
      {
        id: 11,
        name: "more than 20 hours",
        from: 1200,
        to: 99999,
        description: "",
        checked: true,
      },
    ];

    return predefinedDurations.filter((duration: any) => {
      return durations.some((d: any) => d >= duration.from && d <= duration.to);
    });
  };

  // Function to generate dynamic time filters
  const generateTimeFilters = (times: any[]) => {
    const predefinedTimeRanges = [
      {
        id: 1,
        name: "00:00am : 02:00am",
        from: "00:00",
        to: "02:00",
        description: "",
        checked: true,
      },
      {
        id: 2,
        name: "02:00am : 04:00am",
        from: "02:00",
        to: "04:00",
        description: "",
        checked: true,
      },
      {
        id: 3,
        name: "04:00am : 06:00am",
        from: "04:00",
        to: "06:00",
        description: "",
        checked: true,
      },
      {
        id: 4,
        name: "06:00am : 08:00am",
        from: "06:00",
        to: "08:00",
        description: "",
        checked: true,
      },
      {
        id: 5,
        name: "08:00am : 10:00am",
        from: "08:00",
        to: "10:00",
        description: "",
        checked: true,
      },
      {
        id: 6,
        name: "10:00am : 12:00pm",
        from: "10:00",
        to: "12:00",
        description: "",
        checked: true,
      },
      {
        id: 7,
        name: "12:00pm : 02:00pm",
        from: "12:00",
        to: "14:00",
        description: "",
        checked: true,
      },
      {
        id: 8,
        name: "02:00pm : 04:00pm",
        from: "14:00",
        to: "16:00",
        description: "",
        checked: true,
      },
      {
        id: 9,
        name: "04:00pm : 06:00pm",
        from: "16:00",
        to: "18:00",
        description: "",
        checked: true,
      },
      {
        id: 10,
        name: "06:00pm : 08:00pm",
        from: "18:00",
        to: "20:00",
        description: "",
        checked: true,
      },
      {
        id: 11,
        name: "08:00pm : 10:00pm",
        from: "20:00",
        to: "22:00",
        description: "",
        checked: true,
      },
      {
        id: 12,
        name: "10:00pm : 00:00am",
        from: "22:00",
        to: "00:00",
        description: "",
        checked: true,
      },
    ];

    return predefinedTimeRanges.filter((timeRange) => {
      const fromMinutes = timeToMinutes(timeRange.from);
      const toMinutes = timeToMinutes(timeRange.to);
      return times.some(
        (time: any) => time >= fromMinutes && time <= toMinutes
      );
    });
  };

  // Helper function to convert time to minutes
  const timeToMinutes = (time: any) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  useEffect(() => {
    getFlights(search)
      .then((res: any) => {
        setFlights(res);
        setOriginalResults(res);

        if (res.length === 0) setIsEmpty(true);

        setMinFlightPrice(minPrice() > 9999 ? 0 : minPrice());
        setMaxFlightPrice(maxPrice() > 9999 ? "-" : maxPrice());
        setRangeFlightPrice((prev) => ({ min: minPrice(), max: maxPrice() }));
        setRangePrices([
          minPrice() > 9999 ? 0 : minPrice(),
          maxPrice() > 9999 ? "-" : maxPrice(),
        ]);

        const uniqueAirlineNames: any = [];
        var flightClasses: any = [];
        res.forEach((flight: any) => {
          if (!flightClasses.includes(flight.flightClass)) {
            flightClasses.push(flight.flightClass);
          }
          flight.route.forEach((route: any) => {
            if (
              !uniqueAirlineNames.find(
                (airline: any) => airline.code === route.airlineCode
              )
            ) {
              uniqueAirlineNames.push({
                name: route.airlineName,
                code: route.airlineCode,
                logo: "",
                description: "",
                value: null,
                checked: true,
              });
            }
          });
        });

        uniqueAirlineNames.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );
        setAirlineFilters(uniqueAirlineNames);

        const allDurations: any = [];
        const allTimes: any = [];

        res.forEach((flight: any) => {
          allDurations.push(flight.duration.total / 60);

          flight.route.slice(0, 1).forEach((route: any) => {
            const localDepartureTime = route.local_departure
              .split("T")[1]
              .substring(0, 5);
            allTimes.push(timeToMinutes(localDepartureTime));
          });
        });

        const durationFilters = generateDurationFilters(allDurations);
        setDurationFilters(durationFilters);
        setOriginalDurations(durationFilters);

        const inboundTakeoffFilters = generateTimeFilters(allTimes);
        const outboundTakeoffFilters = generateTimeFilters(allTimes);
        const flightClassFilters = flightClasses.map((flightClass: any) => {
          return {
            name: flightClass === "Econom" ? "Economy" : flightClass,
            description: "",
            value: null,
            checked: true,
          };
        });

        setInboundTakeoffFilters(inboundTakeoffFilters);
        setOutboundTakeoffFilters(outboundTakeoffFilters);
        setOriginalInbounds(inboundTakeoffFilters);
        setOriginalOutbounds(outboundTakeoffFilters);
        setFlightClassFilters(flightClassFilters);
      })
      .then(() => {
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [isLoading, search]);

  useEffect(() => {
    const getAirlinesData = async () => {
      try {
        const airlinesData = await getAirlines();
        setAirlines(airlinesData);
      } catch (error) {
        console.error("Failed to fetch airlines:", error);
      }
    };
    getAirlinesData();
  }, []);

  const travelAirports = {
    depFrom: search.travelingFrom.name,
    arrTo: search.travelingTo.name,
    retDepFrom: search.travelingTo.name,
    retArrTo: search.travelingFrom.name,
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setAirlineFilters((prevState) => {
      const updatedData = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedData;
    });
  };

  const handleDurationsCheckboxChange = (name: string, checked: boolean) => {
    setDurationFilters((prevState) => {
      const updatedDurations = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedDurations;
    });
  };

  const handleInboundCheckboxChange = (name: string, checked: boolean) => {
    setInboundTakeoffFilters((prevState) => {
      const updatedDurations = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedDurations;
    });
  };

  const handleFlightClassdCheckboxChange = (name: string, checked: boolean) => {
    setFlightClassFilters((prevState) => {
      const updatedDurations = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedDurations;
    });
  };

  const handleOutboundCheckboxChange = (name: string, checked: boolean) => {
    setOutboundTakeoffFilters((prevState) => {
      const updatedDurations = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedDurations;
    });
  };

  const handleSortChange = (value: string) => {
    setSelectedSort(value);

    // Get the index of the selected value
    const selectedIndex = typeOfSorts.findIndex((item) => item.sort === value);

    // Set the checked property of the selected value to true
    typeOfSorts[selectedIndex].checked = true;

    // Set the checked property of all other values to false
    for (let i = 0; i < typeOfSorts.length; i++) {
      if (i !== selectedIndex) {
        typeOfSorts[i].checked = false;
      }
    }

    setTypeOfSorts(typeOfSorts);
  };

  const checkIfAtLeastOneAirlineFilterChecked = () => {
    const hasCheckedObject = airlineFilters.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfAtLeastOneDurationChecked = () => {
    const hasCheckedObject = durationFilters.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfAtLeastOneInboundChecked = () => {
    const hasCheckedObject = inPoundTakeoffFilters.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfAtLeastOneFlightClassChecked = () => {
    const hasCheckedObject = flightClassFilters.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfAtLeastOneOutboundChecked = () => {
    const hasCheckedObject = outPoundTakeoffFilters.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const updateRangePrice = (numbers: any[]) => {
    setRangePrices(numbers);
  };

  const resetAirlineFilters = () => {
    const uniqueAirlineNames: any = [];

    originalResults.forEach((flight: any) => {
      flight.route.forEach((route: any) => {
        if (
          !uniqueAirlineNames.find(
            (airline: any) => airline.code === route.airlineCode
          )
        ) {
          uniqueAirlineNames.push({
            name: route.airlineName,
            code: route.airlineCode,
            logo: "",
            description: "",
            value: null,
            checked: checkIfAtLeastOneAirlineFilterChecked() ? false : true,
          });
        }
      });
    });

    uniqueAirlineNames.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setAirlineFilters(uniqueAirlineNames);
  };

  const resetPriceRange = async () => {
    const minPrice = rangeFlightPrice?.min;
    const maxPrice = rangeFlightPrice?.max;
    await setMinFlightPrice(minPrice > 9999 ? 0 : minPrice);
    await setMaxFlightPrice(maxPrice > 9999 ? "-" : maxPrice);
    await setRangePrices([
      minPrice > 9999 ? 0 : minPrice,
      maxPrice > 9999 ? "-" : maxPrice,
    ]);
    await updateRangePrice([minPrice, maxPrice]);
    await filterFlights();
  };

  const updateDurationFilters = (durationFilters: any) => {
    const updatedDurationFilters = durationFilters.map((filter: any) => {
      return {
        ...filter,
        checked: checkIfAtLeastOneDurationChecked() ? false : true,
      };
    });

    return updatedDurationFilters;
  };

  const updateInboundTakeoffFilters = (inboundTakeoffFilters: any) => {
    const updatedInboundTakeoffFilters = inboundTakeoffFilters.map(
      (filter: any) => {
        return {
          ...filter,
          checked: checkIfAtLeastOneInboundChecked() ? false : true,
        };
      }
    );

    return updatedInboundTakeoffFilters;
  };

  const updateFlightClassFilters = (flightClassFilters: any) => {
    const updatedFlightClassFilters = flightClassFilters.map((filter: any) => {
      return {
        ...filter,
        checked: checkIfAtLeastOneFlightClassChecked() ? false : true,
      };
    });

    return updatedFlightClassFilters;
  };

  const updateOutboundTakeoffFilters = (outboundTakeoffFilters: any) => {
    const updatedOutboundTakeoffFilters = outboundTakeoffFilters.map(
      (filter: any) => {
        return {
          ...filter,
          checked: checkIfAtLeastOneOutboundChecked() ? false : true,
        };
      }
    );

    return updatedOutboundTakeoffFilters;
  };

  const resetDurations = () => {
    const updatedDurationsFilters = updateDurationFilters(originalDurations);
    setDurationFilters(updatedDurationsFilters);
  };

  const resetInboundFilters = () => {
    const updatedInboundTakeoffFilters =
      updateInboundTakeoffFilters(originalInbounds);
    setInboundTakeoffFilters(updatedInboundTakeoffFilters);
  };

  const resetFlightClassFilters = () => {
    const updatedFlightClassFilters =
      updateFlightClassFilters(originalInbounds);
    setFlightClassFilters(updatedFlightClassFilters);
  };

  const resetOutboundFilters = () => {
    const updatedOutboundTakeoffFilters =
      updateOutboundTakeoffFilters(originalOutbounds);
    setOutboundTakeoffFilters(updatedOutboundTakeoffFilters);
  };

  const resetSortType = async () => {
    const sortedFlights = flights.sort((a: any, b: any) =>
      a.name > b.name ? 1 : -1
    );
    await dispatch(setFlights(sortedFlights));
    await setTypeOfSorts([
      {
        id: "sort-1",
        name: "Price",
        description: "",
        sort: "price",
        checked: true,
      },
      {
        id: "sort-2",
        name: "Total duration",
        description: "",
        sort: "duration",
        checked: false,
      },
      {
        id: "sort-3",
        name: "Emissions",
        description: "",
        sort: "emissions",
        checked: false,
      },
    ]);
    await setSelectedSort("price");
    await handleSortChange("price");
    await filterFlights();
  };

  const resetAllFilters = async () => {
    await setIsLoading(true);
    await resetAirlineFilters();
    await setMinFlightPrice(minPrice() > 9999 ? 0 : minPrice());
    await setMaxFlightPrice(maxPrice() > 9999 ? "-" : maxPrice());
    await setRangePrices([
      minPrice() > 9999 ? 0 : minPrice(),
      maxPrice() > 9999 ? "-" : maxPrice(),
    ]);
    await updateRangePrice([minPrice(), maxPrice()]);
    await resetDurations();
    await resetInboundFilters();
    await resetFlightClassFilters();
    await resetOutboundFilters();
    await setTypeOfSorts([
      {
        id: "sort-1",
        name: "Price",
        description: "",
        sort: "price",
        checked: true,
      },
      {
        id: "sort-2",
        name: "Total duration",
        description: "",
        sort: "duration",
        checked: false,
      },
      {
        id: "sort-3",
        name: "Emissions",
        description: "",
        sort: "emissions",
        checked: false,
      },
    ]);
    await setSelectedSort("price");
    await handleSortChange("price");
    await setFlights(originalResults);
    await setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  // placeholders...
  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";
  const filterPlaceholder = (
    <div className="Btn-placeholder">
      <RoundShape style={{ width: 150, height: 40 }} color={placeholderColor} />
    </div>
  );

  const renderAirlineFilters = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Airline</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {airlineFilters.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          image={""}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={(checked) =>
                            handleCheckboxChange(item.name, checked)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetAirlineFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneAirlineFilterChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterFlights();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderPriceRangeFilters = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center min-w-[160px] px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>
                {`€${convertNumbThousand(
                  rangePrices[0]
                )} - €${convertNumbThousand(rangePrices[1])}`}{" "}
              </span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Price Range</span>
                      <Slider
                        range
                        className="text-red-400"
                        min={minFlightPrice}
                        max={maxFlightPrice}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        value={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={(e) => updateRangePrice(e as number[])}
                      />
                    </div>

                    <div className="flex justify-between">
                      <div className="text-left">
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md text-left top-3">
                          <span className="text-neutral-500 sm:text-sm">
                            €{convertNumbThousand(rangePrices[0])}
                          </span>
                          <input
                            type="text"
                            disabled
                            name="minPrice"
                            id="minPrice"
                            className="opacity-0 pl-7 pr-3 sm:text-sm text-neutral-900 border-none font-bold"
                            value={convertNumbThousand(rangePrices[0])}
                          />
                        </div>
                      </div>

                      <div className="text-right">
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md text-right top-3">
                          <span className="text-neutral-500 sm:text-sm">
                            €{convertNumbThousand(rangePrices[1])}
                          </span>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="opacity-0 pl-7 pr-3 sm:text-sm text-neutral-900 border-none font-bold"
                            value={convertNumbThousand(rangePrices[1])}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        resetPriceRange();
                        // updateRangePrice(rangePrices as number[]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterFlights();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderDurationFilters = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Duration</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <span className="font-medium">Total Duration</span>
                    {durationFilters.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={(checked) =>
                            handleDurationsCheckboxChange(item.name, checked)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetDurations();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneDurationChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterFlights();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderInboundTakeoffFilters = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Outbound Takeoff Time</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-auto min-w-[300px] max-w-[660px] px-4 mt-3 left-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <div className="grid gap-4">
                      {inPoundTakeoffFilters.map((item, index) => (
                        <div key={`${item.name}-${index}`} className="">
                          <Checkbox
                            name={item.name}
                            label={item.name}
                            subLabel={item.description}
                            checked={item.checked}
                            onChange={(checked) =>
                              handleInboundCheckboxChange(item.name, checked)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetInboundFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneInboundChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterFlights();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderFlightClassFilters = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Flight Class</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-auto min-w-[300px] max-w-[660px] px-4 mt-3 left-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <div className="grid gap-4">
                      {flightClassFilters.map((item, index) => (
                        <div key={`${item.name}-${index}`} className="">
                          <Checkbox
                            name={item.name}
                            label={item.name}
                            subLabel={item.description}
                            checked={item.checked}
                            onChange={(checked) =>
                              handleFlightClassdCheckboxChange(
                                item.name,
                                checked
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetFlightClassFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneFlightClassChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterFlights();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderOutboundTakeoffFilters = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Return Takeoff Time</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-auto min-w-[300px] max-w-[660px] px-4 mt-3 left-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <div className="grid gap-4">
                      {outPoundTakeoffFilters.map((item, index) => (
                        <div key={`${item.name}-${index}`} className="">
                          <Checkbox
                            name={item.name}
                            label={item.name}
                            subLabel={item.description}
                            checked={item.checked}
                            onChange={(checked) =>
                              handleOutboundCheckboxChange(item.name, checked)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetOutboundFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneOutboundChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterFlights();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfSort = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Sort By</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 right-1 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfSorts.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <CheckboxAsRadio
                          name={item.name}
                          label={item.name}
                          value={String(item.sort)}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={() => handleSortChange(item.sort)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        resetAllFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset All
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterFlights();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const filterFlights = () => {
    // Check if all airlineFilters are checked
    const allAirlineFiltersChecked = airlineFilters.every(
      (filter: any) => filter.checked
    );

    const filteredFlights = originalResults.filter((flight) => {
      // 1. Airline Filter
      const isAirlineIncluded =
        allAirlineFiltersChecked ||
        airlineFilters.some((filter: any) => {
          if (!filter.checked) return false;
          return (
            filter.code === flight.route[0]?.airlineCode &&
            filter.code === flight.route[1]?.airlineCode
          );
        });

      // 2. Price Filter
      const isPriceInRange =
        flight.price >= rangePrices[0] && flight.price <= rangePrices[1];

      // 3. Duration Filter
      const isDurationInRange = durationFilters.some((filter: any) => {
        return (
          filter.checked &&
          flight.duration.total / 60 >= filter.from &&
          flight.duration.total / 60 <= filter.to
        );
      });

      // 4. Inbound Takeoff Time Filter
      const takeoffTimeInbound = flight.route[0].local_departure.substring(
        11,
        16
      );

      const isInboundTakeoffInRange = inPoundTakeoffFilters.some((filter) => {
        const fromTime = filter.from;
        const toTime = filter.to;

        return (
          filter.checked &&
          takeoffTimeInbound >= fromTime &&
          takeoffTimeInbound < toTime
        );
      });

      // 5. Outbound Takeoff Time Filter
      const takeoffTimeOutbound = flight.route[1].local_departure.substring(
        11,
        16
      );
      const isOutboundTakeoffInRange = outPoundTakeoffFilters.some((filter) => {
        const fromTime = filter.from;
        const toTime = filter.to;
        return (
          filter.checked &&
          takeoffTimeOutbound >= fromTime &&
          takeoffTimeOutbound < toTime
        );
      });

      return (
        isAirlineIncluded &&
        isPriceInRange &&
        isDurationInRange &&
        isInboundTakeoffInRange &&
        isOutboundTakeoffInRange
      );
    });

    // Sorting logic
    const selectedSortType = typeOfSorts.find((type) => type.checked)?.sort;
    switch (selectedSortType) {
      case "price":
        filteredFlights.sort((a, b) => a.price - b.price);
        break;
      case "duration":
        filteredFlights.sort((a, b) => a.duration.total - b.duration.total);
        break;
      case "emissions":
        filteredFlights.sort((a, b) => {
          const aEmissions = a.route.reduce(
            (acc: any, curr: any) => acc + curr.emissionsKgEconomy,
            0
          );
          const bEmissions = b.route.reduce(
            (acc: any, curr: any) => acc + curr.emissionsKgEconomy,
            0
          );
          return aEmissions - bEmissions;
        });
        break;
      default:
        // Default sort logic (if any)
        break;
    }

    dispatch(setFlights(filteredFlights));
  };

  useEffect(() => {}, [
    airlineFilters,
    durationFilters,
    inPoundTakeoffFilters,
    outPoundTakeoffFilters,
    minPrice,
    maxPrice,
    maxFlightPrice,
    minFlightPrice,
    resetSortType,
    originalResults,
    originalDurations,
    originalInbounds,
    originalOutbounds,
    flights,
    airlines,
  ]);

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="HowToGetTherePage"
    >
      {/* <Helmet>
        <title>Chisfis || Booking React Template</title>
      </Helmet> */}
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        {/* <SectionHeroArchivePage
          currentPage="Flights"
          currentTab="Flights"
          title="How to get to"
          subTitle={destinationString}
          rightImage={
            "https://orbito.s3.eu-central-1.amazonaws.com/images/wizard/01.png"
          }
          listingType={
            <>
              <i className="text-2xl las la-plane-departure"></i>
              <span className="ml-2.5">1599 flights</span>
            </>
          }
          className="pt-10 lg:pt-16 "
        /> */}

        <div className="grid md:grid-cols-1 gap-6 lg:grid-cols-1 xl:gap-8 mb-4">
          <div className="flex flex-row p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <i
              style={{ margin: "auto 20px" }}
              className="las la-exclamation-circle text-3xl"
            ></i>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              Please note that only direct flights are displayed for your
              convenience. The orbito travel team will get in touch with the
              selected airports and airlines to ensure a seamless travel
              experience.
            </span>
          </div>
        </div>

        {/* SECTION */}

        <div>
          <div>
            <div className="lg:space-x-4 pb-8 flex justify-between">
              <div className="hidden lg:flex space-x-4">
                <ReactPlaceholder
                  ready={!isLoading}
                  customPlaceholder={filterPlaceholder}
                  showLoadingAnimation
                >
                  {renderAirlineFilters()}
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={!isLoading}
                  customPlaceholder={filterPlaceholder}
                  showLoadingAnimation
                >
                  {renderPriceRangeFilters()}
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={!isLoading}
                  customPlaceholder={filterPlaceholder}
                  showLoadingAnimation
                >
                  {renderDurationFilters()}
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={!isLoading}
                  customPlaceholder={filterPlaceholder}
                  showLoadingAnimation
                >
                  {renderInboundTakeoffFilters()}
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={!isLoading}
                  customPlaceholder={filterPlaceholder}
                  showLoadingAnimation
                >
                  {renderOutboundTakeoffFilters()}
                </ReactPlaceholder>
                <ReactPlaceholder
                  ready={!isLoading}
                  customPlaceholder={filterPlaceholder}
                  showLoadingAnimation
                >
                  {renderFlightClassFilters()}
                </ReactPlaceholder>
              </div>
              <div>
                <ReactPlaceholder
                  ready={!isLoading}
                  customPlaceholder={filterPlaceholder}
                  showLoadingAnimation
                >
                  {renderTabsTypeOfSort()}
                </ReactPlaceholder>
              </div>
            </div>
          </div>
        </div>

        <div className="min-h-[400px]">
          <SectionGridFilterCard
            className="pb-24 lg:pb-28"
            isEmpty={isEmpty}
            flights={flights}
            isLoading={isLoading}
            airlines={airlines}
            travelAirports={travelAirports}
          />
        </div>
      </div>
    </div>
  );
};

export default HowToGetTherePage;

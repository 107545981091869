import ReactDOM from "react-dom/client";
//
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "rc-slider/assets/index.css";
// STYLE
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "./styles/react_dates_overrides.css"
//
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { inject } from "@vercel/analytics";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { Provider } from "react-redux";
import store from "./store";
import { REACT_APP_SITE_KEY } from "config/environment-local";
import 'react-day-picker/dist/style.css';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  // <React.StrictMode>
  <GoogleReCaptchaProvider
    reCaptchaKey={REACT_APP_SITE_KEY}
    useRecaptchaNet={true}
    language="en"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: "head", // optional, default to "head", can be "head" or "body",
      nonce: undefined, // optional, default undefined
    }}
    container={{
      parameters: {
        badge: "bottomright", // optional, default undefined
        theme: "dark", // optional, default undefined
      },
    }}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleReCaptchaProvider>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
inject();

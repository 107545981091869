export enum RestaurantsActionTypes {
  SET_RESTAURANTS = 'SET_RESTAURANTS',
}

export interface SetRestaurantsAction {
  type: RestaurantsActionTypes.SET_RESTAURANTS;
  payload: any; 
}


export function setRestaurants(WTeat: any): SetRestaurantsAction {
  return {
    type: RestaurantsActionTypes.SET_RESTAURANTS,
    payload: WTeat,
  };
}


export type RestaurantsAction = SetRestaurantsAction;

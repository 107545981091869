export enum DoActionTypes {
  SET_DO = 'SET_DO',
}

export interface SetDoAction {
  type: DoActionTypes.SET_DO;
  payload: any; 
}


export function setDo(WTdo: any): SetDoAction {
  return {
    type: DoActionTypes.SET_DO,
    payload: WTdo,
  };
}


export type DoAction = SetDoAction;

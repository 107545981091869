// import { StarIcon } from "@heroicons/react/24/solid";
import { FC } from "react";

export interface EurosSignsProps {
  className?: string;
  EurosSigns?: number;
}

const EurosSigns: FC<EurosSignsProps> = ({
  className = "",
  EurosSigns = 0,
}) => {
  const renderEurosSigns = (EurosSigns: number) => {
    return [...Array(EurosSigns)].map((e, i) => (
      <i
        key={i}
        className="las la-money-bill text-3xl font-bold text-black"
      ></i>
    ));
  };

  return (
    <div
      className={`nc-StartRating flex flex-row justify-start space-x-1 text-sm  ${className}`}
      data-nc-id="StartRating"
    >
      {renderEurosSigns(EurosSigns)}
    </div>
  );
};

export default EurosSigns;

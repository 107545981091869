import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonSecondaryProps extends ButtonProps {}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  className = " ",
  ...args
}) => {
  return (
    <Button
      className={`ttnc-ButtonSecondary font-medium border bg-secondary-400 border-secondary-200 hover:border-primary-200 text-neutral-50 dark:bg-secondary-900 dark:text-neutral-50 dark:border-primary-700 hover:bg-primary-400 dark:hover:bg-secondary-800 ${className}`}
      {...args}
    />
  );
};

export default ButtonSecondary;

import { PaymentElement } from "@stripe/react-stripe-js";
import { FormEvent, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Order } from "./CheckOutPage";
import { authenticateCaptchaToken, finalizeOrder } from "services/payment";
import ReCAPTCHA from "react-google-recaptcha";
import { useCustomSearchParams } from "context/SearchParamsContext";

type CheckoutFormProps = {
    order: Order;
}

export default function CheckoutForm({ order }: CheckoutFormProps) {
    const stripe = useStripe();
    const elements = useElements();
    const {getStringParams} = useCustomSearchParams()
    const [message, setMessage] = useState<string | null>("");
    const [isProcessing, setIsProcessing] = useState(false);

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // const captchToken = captchaRef.current?.getValue()
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }



        setIsProcessing(true);
        const isCaptchaAuthenticated: boolean = await authenticateCaptchaToken("");
        if (true) {
            const { error } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    // Make sure to change this to your payment completion page
                    return_url: `${window.location.origin}/pay-done?${getStringParams()}`,
                },
            });


            if (error.type === "card_error" || error.type === "validation_error") {
                if (error.message) setMessage(error.message);
            } else {
                setMessage("An unexpected error occured.");
            }
        } else {
            setMessage("Bots not allowed")
        }
        setIsProcessing(false);
    };

    return (
        <form id="payment-form" className="w-full flex flex-col gap-3" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <button className="nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors text-sm sm:text-base font-medium px-4 py-3 sm:px-6  ttnc-ButtonPrimary 
            disabled:bg-opacity-70 bg-[#F47D20] hover:bg-[#2097F4] text-neutral-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-6000 dark:focus:ring-offset-0" disabled={isProcessing || !stripe || !elements} id="submit">
                <span id="button-text">
                    {isProcessing ? "Processing ... " : "Pay now"}
                </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}
import { FC } from "react";
// import ButtonPrimary from "shared/Button/ButtonPrimary";
// import homepagePng from "images/orbito/main/homepage.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import HorizontalImages from "./HorizontalImages";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-center gap-4">
        <div className=" lg:w-[45%] flex flex-col items-start space-y-8 ">
          <h2 className="font-medium text-4xl md:text-5xl xl:text-7xl !leading-[114%] ">
            redefining accessible travel
          </h2>
          <span className="text-base md:text-lg text-neutral-500 dark:text-neutral-400">
            <em>
              Our mission is to enable an accessible and actively inclusive
              travel community, in which travelers with reduced mobility have
              equal opportunities to participate in a socially sustainable
              tourism sector.
            </em>
          </span>
          {/* <ButtonPrimary>Start your search</ButtonPrimary> */}
        </div>
        <div className="flex-1 self-center">
          <HorizontalImages />
        </div>
      </div>
    </div>
  );
};

export default SectionHero;

export enum DarkModeActionTypes {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export interface setDarkModeAction {
  type: DarkModeActionTypes.DARK;
}

export interface setLightModeAction {
  type: DarkModeActionTypes.LIGHT;
}

export function setDarkMode(): setDarkModeAction {
  return {
    type: DarkModeActionTypes.DARK,
  };
}

export function setLightMode(): setLightModeAction {
  return {
    type: DarkModeActionTypes.LIGHT,
  };
}

export type DarkModeAction = setDarkModeAction | setLightModeAction;

import { User } from "store/reducers/auth"

export enum AuthActionTypes {
  SET_USER = 'SET_USER',
  LOGOUT = 'LOGOUT',
}

export interface SetUserAction {
  type: AuthActionTypes.SET_USER;
  payload: User;
}

export interface LogoutAction {
  type: AuthActionTypes.LOGOUT;
}

export function setUser(user: User): SetUserAction {
  return {
    type: AuthActionTypes.SET_USER,
    payload: user,
  };
}

export function logout(): LogoutAction {
  return {
    type: AuthActionTypes.LOGOUT,
  };
}

export type AuthAction = SetUserAction | LogoutAction;
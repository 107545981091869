import { useEffect, FC } from "react";
import { MoonIcon } from "@heroicons/react/24/solid";
import { SunIcon } from "@heroicons/react/24/outline";
import { useSelector, useDispatch } from "react-redux";
import { setDarkMode, setLightMode } from "store/actions/darkMode";

interface SwitchDarkModeProps {
  className?: string;
}

const SwitchDarkMode: FC<SwitchDarkModeProps> = ({ className = "" }) => {
  const darkModeState = (state: any) => state.auth;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;

      if (localStorage.theme === "dark" || (!("theme" in localStorage) && prefersDarkMode)) {
        toDark();
      } else {
        toLight();
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const toDark = () => {
    dispatch(setDarkMode());
    toggleTheme("dark");
  };

  const toLight = () => {
    dispatch(setLightMode());
    toggleTheme("light");
  };

  const toggleTheme = (theme: string) => {
    const root = document.querySelector("html");
    if (root) {
      root.classList.toggle("dark", theme === "dark");
      localStorage.theme = theme;
    }
  };

  const toggleDarkMode = () => {
    try {
      if (localStorage.theme === "light") {
        toDark();
      } else {
        toLight();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <button
      onClick={toggleDarkMode}
      className={`text-2xl md:text-3xl w-8 h-8 rounded-full text-neutral-700 dark:text-neutral-300 hover:bg-neutral-100 dark:hover:bg-neutral-800 focus:outline-none flex items-center justify-center ${className}`}
    >
      <span className="sr-only">Toggle dark mode</span>
      {isDarkMode ? <MoonIcon className="w-5 h-5" aria-hidden="true" /> : <SunIcon className="w-5 h-5" aria-hidden="true" />}
    </button>
  );
};

export default SwitchDarkMode;

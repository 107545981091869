import { Theme } from "@aws-amplify/ui-react";

function customColors(cssVar: string) {
  return `rgb(var(${cssVar}))`;
}


export const AuthFormThemeLight: Theme = {
  name: "Orbito Light",
  tokens: {
    colors: {
      border: {
        primary: {
          value: "rgb(255, 255, 255)",
        },
      },
      brand: {
        primary: {
          "10": customColors("--c-primary-100"),
          "20": customColors("--c-primary-200"),
          "40": customColors("--c-primary-300"),
          "60": customColors("--c-primary-400"),
          "80": customColors("--c-primary-500"),
          "90": customColors("--c-primary-600"),
          "100": customColors("--c-primary-700"),
        },
      },
    },
  },
};

export const AuthFormThemeDark: Theme = {
  name: "Orbito Dark",
  tokens: {
    colors: {
      border: {
        primary: {
          value: "rgb(0, 0, 0)",
        },
      },
      background: {
        primary: {
          value: customColors("--c-neutral-800"),
        },
        secondary: {
          value: customColors("--c-secondary-700"),
        },
      },
      brand: {
        primary: {
          "10": customColors("--c-primary-100"),
          "20": customColors("--c-primary-200"),
          "40": customColors("--c-primary-300"),
          "60": customColors("--c-primary-400"),
          "80": customColors("--c-primary-500"),
          "90": customColors("--c-primary-600"),
          "100": customColors("--c-primary-700"),
        },
      },
    },
  },
};

import {StayCancellationTypesActionTypes, stayCancellationTypesAction} from './../actions/stayCancellationTypes'


export interface StayCancellationTypesState {
  stayCancellationTypes: any[];
}

const initialState: StayCancellationTypesState = {
  stayCancellationTypes: [],
};

const stayCancellationTypesReducer = (state = initialState, action: stayCancellationTypesAction) => {
  switch (action.type) {
    case StayCancellationTypesActionTypes.SET_STAY_CANCELLATION_TYPES:
      return {
        ...state,
        stayCancellationTypes: action.payload,
      };
    default:
      return state;
  }
};

export default stayCancellationTypesReducer;
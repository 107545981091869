import { StarIcon } from "@heroicons/react/24/solid";
import { FC } from "react";

export interface StarsProps {
  className?: string;
  stars?: number;
}

const Stars: FC<StarsProps> = ({ className = "", stars = 0 }) => {
  const renderStars = (stars: number) => {
    return [...Array(stars)].map((e, i) => (
      <StarIcon key={i} className="w-[18px] h-[18px] text-orange-500" />
    ));
  };

  return (
    <div
      className={`nc-StartRating flex flex-row justify-start space-x-1 text-sm  ${className}`}
      data-nc-id="StartRating"
    >
      {renderStars(stars)}
    </div>
  );
};

export default Stars;

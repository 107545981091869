import { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import GoogleMapReact from "google-map-react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import { useParams } from "react-router-dom";
import { DataDetailType } from "data/types";
import Stars from "components/Stars/Stars";
import { getPlaceData } from "services/api";
import { setDo } from "../../store/actions/do";
import { useDispatch, useSelector } from "react-redux";
import { StateNames } from "services/stateNameService";
import { GOOGLE_MAPS_KEY } from "config/environment";
import {
  // addToCart,
  // removeFromCart,
  addWhatToDo,
  removeWhatToDo,
} from "store/actions/cart";
import { euro } from "utils/currency";
import { formatDuration, getLangLongDescText } from "../../helpers/methods";
import PlaceGuestsInput from "components/HeroSearchForm/PlaceGuestsInput";
// import GuestsInput from "components/HeroSearchForm/GuestsInput";
import PlaceTimeSlotsInput from "components/HeroSearchForm/PlaceTimeSlotsInput";
import PlaceDateSingleInput from "components/HeroSearchForm/PlaceDateSingleInput";
// import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import TripadvisorStars from "components/Stars/TripadvisorStars";
import moment from "moment";
// import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import "react-placeholder/lib/reactPlaceholder.css";
import {
  fetchHowToMoveAround,
  fetchWhatsExtra,
  fetchWhatToDo,
  fetchWhereToEat,
} from "store/actions/data";
import ReactPlaceholder from "react-placeholder";
import {
  TextBlock,
  RectShape,
  RoundShape,
} from "react-placeholder/lib/placeholders";
import TermsAndConditionsSection from "components/CancelationPolicy/TermsAndConditionsSection";
import { PlaceGuestsObject } from "components/HeroSearchForm2Mobile/PlaceGuestsInput";
import isPricePerPerson from "utils/isPricePerPerson";
import MinusIcon from "svg/MinusIcon";
import PlusIcon from "svg/PlusIcon";
import AddToCartModal from "components/AddToCartModal/AddToCartModal";

export interface DetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
  data?: DataDetailType[];
}

const DetailPage: FC<DetailPageProps> = ({
  className = "",
  isPreviewMode,
  // data = [],
}) => {
  let params = useParams();
  const dispatch = useDispatch();

  // const WTdo = useSelector((state: any) => state.do.do);
  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  const TravelDates = useSelector((state: any) => state.search.dates);
  const travelers = useSelector((state: any) => state.search.guests);
  const startDate = TravelDates.startDate;
  // const endDate = TravelDates.endDate;
  const guestsCount = travelers.guestAdults;
  const guestWheelChairCount = travelers.guestWheelchair;
  const [activityGuests, setActivityGuests] = useState<PlaceGuestsObject>({
    guestAdults: guestsCount !== null ? guestsCount : 0,
    guestChildren: 0,
    guestInfants: 0,
    guestWheelchair: guestWheelChairCount !== null ? guestWheelChairCount : 0,
  });
  const totalActivityGuests =
    (activityGuests?.guestAdults || 0) + (activityGuests?.guestWheelchair || 0);
  const currentPage = window.location.pathname.split("/")[1];

  const [isLoading, setIsLoading] = useState(true);
  const [place, setPlace] = useState<DataDetailType>();

  const currentUserState = (state: any) => state.auth;
  const auth = useSelector(currentUserState);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<string | undefined>("");
  let [isOpenModalVirtualRoom, setIsOpenModalVirtualRoom] = useState(false);

  const options = place?.options;
  const isOptionsEnabled = options?.isEnabled || false;
  const isOptionsIncludeInPrice = options?.includeInPrice || false;
  const [selectedOptions, setSelectedOptions] = useState<
    {
      title: string;
      price?: number;
    }[]
  >([]);
  const [showAddToCartModal, setShowAddToCartModal] = useState(false);

  // const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(
  //   moment().add(2, "days")
  // );

  // const [selectedDate, setSelectedDate] = useState<DateRage>({
  //   startDate: startDate,
  //   endDate: endDate,
  // });

  // const isLoading = useSelector(
  //   (state: any) =>
  //     state.data[StateNames[currentPage as keyof typeof StateNames] + "Loading"]
  // );

  // const selectedIds: number[] = useSelector((state: any) =>
  //   state.cart[StateNames[currentPage as keyof typeof StateNames]].map(
  //     (item: any) => item.id
  //   )
  // );

  const selectedIds: any = useSelector((state: any) =>
    (state.cart.whatToDo ?? []).map((item: any) => item._id)
  );

  const details = async () => {
    const data = await getPlaceData(params.id);
    if (data.place) {
      setPlace(data.place);
      dispatch(setDo(data.place));
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
    return data;
  };

  useEffect(() => {
    details();
  }, [isLoading, dispatch]);

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const useSmoothScroll = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scrollPercentage =
          (window.pageYOffset / window.innerHeight) * 100;
        setIsScrolled(scrollPercentage > 65);

        // Scroll to a specific element or position
        const targetElement = document.getElementById("targetElementId");
        targetElement?.scrollIntoView({
          behavior: "smooth",
        });
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return isScrolled;
  };

  const isScrolled = useSmoothScroll();

  let {
    id,
    _id,
    type,
    stars,
    name,
    // city,
    // country,
    // address,
    fullAddress,
    pricePer,
    href,
    lhref,
    overview,
    accessibilityFeatures,
    accessibilityIcons,
    detailsImgs,
    map,
    cancelationPolicy,
    notes,
    price,
    languages,
    max_occupancy,
    duration,
    availableTimeSlots,
    whatIsIncludes,
    whatNotIncludes,
    thingsToKnow,
    rating,
    bank_holidays,
  } = place || {};
  const priceItem = price || 0;
  price = (price || 40) * isPricePerPerson(place, totalActivityGuests);
  price = isOptionsIncludeInPrice
    ? price +
      selectedOptions.reduce((total, option) => {
        // Check if the price property exists for the current option
        if (option.price) {
          // If it exists, add the price to the total
          return total + option.price;
        } else {
          // If it doesn't exist, return the current total as is
          return total;
        }
      }, 0)
    : price;

  useEffect(() => {
    switch (StateNames[currentPage as keyof typeof StateNames]) {
      case StateNames["what-to-do-detail"]:
        dispatch(fetchWhatToDo());
        break;
      case StateNames["where-to-eat-and-drink-detail"]:
        dispatch(fetchWhereToEat());
        break;
      case StateNames["how-to-move-around-detail"]:
        dispatch(fetchHowToMoveAround());
        break;
      case StateNames["what-is-extra-detail"]:
        dispatch(fetchWhatsExtra());
        break;
    }
  }, []);

  // const isOnCart = selectedIds.includes(id);

  // const addToCartHandler = () => {
  //   if (isOnCart) {
  //     dispatch(
  //       removeFromCart({
  //         item: place!,
  //         storeKey: StateNames[currentPage as keyof typeof StateNames],
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       addToCart({
  //         item: place!,
  //         storeKey: StateNames[currentPage as keyof typeof StateNames],
  //       })
  //     );
  //   }
  // };

  // const isOnCart = selectedIds[0] === id;

  const addToCartHandler = () => {
    if (place) {
      if (isOnCart) {
        dispatch(removeWhatToDo(place._id!));
      } else {
        dispatch(
          addWhatToDo({
            _id: place._id!,
            cart_type: "WHAT_TO_DO",
            inputs: {
              date: selectedDate!,
              timeslot: selectedTimeSlot,
              guests: totalActivityGuests,
              options: selectedOptions,
            },
            value: {
              ...place,
              price:
                (place.price || 40) *
                isPricePerPerson(place, totalActivityGuests),
            },
          })
        );
        if (localStorage.getItem("disableNotification") !== "true") {
          setShowAddToCartModal(true);
        }
      }
    }
  };

  // const isOnCart = selectedIds[0] === id;
  const isOnCart = selectedIds.includes(_id);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  function closeModalVirtualRoom() {
    setIsOpenModalVirtualRoom(false);
  }

  function openModalVirtualRoom() {
    setIsOpenModalVirtualRoom(true);
  }

  const handleCloseModal = () => setIsOpen(false);

  // placeholders...
  // let placeholderColor = isDarkMode ? "#CCCCCC" : "#E0E0E0";
  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";
  const pricePlaceholder = (
    <div className="price-placeholder">
      <TextBlock color={placeholderColor} rows={2} />
    </div>
  );

  const btnPlaceholder = (
    <div className="Btn-placeholder">
      <RoundShape style={{ width: 380, height: 50 }} color={placeholderColor} />
    </div>
  );

  const sideboxesPlaceholder = (
    <div className="sidebars-placeholder">
      <RoundShape
        style={{ width: 378, height: 178 }}
        color={placeholderColor}
      />
    </div>
  );

  const textFourRowsPlaceholder = (
    <div className="text-4-placeholder">
      <TextBlock color={placeholderColor} rows={4} />
    </div>
  );

  const galleryPlaceholder = (
    <div className="gallery-placeholder">
      <RectShape
        style={{ width: 1400, height: 590 }}
        color={placeholderColor}
      />
    </div>
  );

  const subGalleryPlaceholder = (
    <div className="subGallery-placeholder">
      <RectShape style={{ width: 500, height: 290 }} color={placeholderColor} />
    </div>
  );

  const mapPlaceholder = (
    <div className="map-placeholder">
      <RectShape style={{ width: 725, height: 400 }} color={placeholderColor} />
    </div>
  );

  const showAllPhotosPlaceholder = (
    <div className="showAllPhotos-placeholder">
      <RectShape style={{ width: 50, height: 20 }} color={placeholderColor} />
    </div>
  );

  const textOneRowPlaceholder = (
    <div className="price-placeholder">
      <TextBlock color={placeholderColor} rows={1} />
    </div>
  );

  const textTwoRowsPlaceholder = (
    <div className="text-2-placeholder">
      <TextBlock color={placeholderColor} rows={2} />
    </div>
  );

  const textFiveRowsPlaceholder = (
    <div className="text-5-placeholder">
      <TextBlock color={placeholderColor} rows={5} />
    </div>
  );

  const textTenRowsPlaceholder = (
    <div className="text-10-placeholder">
      <TextBlock color={placeholderColor} rows={10} />
    </div>
  );

  const ratePlaceholder = (
    <div className="rate-placeholder">
      <RectShape style={{ width: 150, height: 30 }} color={placeholderColor} />
    </div>
  );

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <ReactPlaceholder
            ready={!isLoading}
            customPlaceholder={textOneRowPlaceholder}
            showLoadingAnimation
          >
            <div className="flex flex-row">
              <span className="mr-1">{type} </span>
              {!!stars && <Stars stars={stars} />}
            </div>
          </ReactPlaceholder>
          <div className="flex items-center justify-end">
            <ReactPlaceholder
              ready={!isLoading}
              customPlaceholder={ratePlaceholder}
              showLoadingAnimation
            >
              <div>
                <div className="flex items-center justify-end">
                  <div className="bg-[#35e0a1] rounded-full h-8 w-8 flex items-center justify-center mr-4">
                    <i className="lab la-tripadvisor text-black text-2xl"></i>
                  </div>
                  <div>
                    {rating ? (
                      <TripadvisorStars stars={rating} key={`hotel-stars`} />
                    ) : null}
                  </div>
                </div>
              </div>{" "}
            </ReactPlaceholder>
          </div>
        </div>

        {/* 2 */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={textTwoRowsPlaceholder}
          showLoadingAnimation
        >
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {name}
          </h2>
        </ReactPlaceholder>
        {/* 3 */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={textOneRowPlaceholder}
          showLoadingAnimation
        >
          <div className="flex items-center space-x-4">
            <span>
              <i className="las la-map-marker-alt"></i>
              <span className="ml-1"> {fullAddress} </span>
            </span>
          </div>
        </ReactPlaceholder>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* Duration, max people & languages for the place */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={textTwoRowsPlaceholder}
          showLoadingAnimation
        >
          <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
            <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
              <i className="las la-clock text-2xl"></i>
              <span className="">{formatDuration(duration ?? 0)}</span>
            </div>
            <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
              <i className="las la-user-friends text-2xl"></i>
              <span className="">Up to {max_occupancy} people</span>
            </div>
            <div className="flex flex-col sm:flex-row items-center space-y-3 sm:space-y-0 text-center sm:text-left sm:space-x-3 ">
              <i className="las la-language text-2xl"></i>
              <span className="">{getLangLongDescText(languages ?? [])}</span>
            </div>
          </div>
        </ReactPlaceholder>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Overview</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={textTenRowsPlaceholder}
          showLoadingAnimation
        >
          <div className="text-neutral-6000 dark:text-neutral-300 prose">
            <div dangerouslySetInnerHTML={{ __html: overview }} />
          </div>
        </ReactPlaceholder>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Accessibility Features </h2>
          <br />
          <ReactPlaceholder
            ready={!isLoading}
            customPlaceholder={textTenRowsPlaceholder}
            showLoadingAnimation
          >
            <div className="text-neutral-6000 dark:text-neutral-300 prose">
              <div
                dangerouslySetInnerHTML={{ __html: accessibilityFeatures }}
              />
            </div>
          </ReactPlaceholder>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={textFiveRowsPlaceholder}
          showLoadingAnimation
        >
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            {accessibilityIcons &&
              accessibilityIcons
                ?.filter((_, i) => i < 12)
                .map((item) => (
                  <div key={item.name} className="flex items-center space-x-3">
                    <i className={`text-3xl las ${item.icon}`}></i>
                    <span className=" ">{item.name}</span>
                  </div>
                ))}
          </div>
        </ReactPlaceholder>

        {/* ----- */}
        {accessibilityIcons && accessibilityIcons?.length >= 13 && (
          <>
            <div className="w-14 border-b border-neutral-200"></div>
            <div>
              <ButtonSecondary onClick={openModalAmenities}>
                View more
              </ButtonSecondary>
            </div>
            {renderMotalAmenities()}
          </>
        )}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Accessibility Features
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {accessibilityIcons
                      ?.filter((_, i) => i < 1212)
                      .map((item) => (
                        <div
                          key={item.name}
                          className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                        >
                          <i
                            className={`text-4xl text-neutral-6000 las ${item.icon}`}
                          ></i>
                          <span>{item.name}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection7 = () => {
    if (typeof map === "undefined") {
      return false;
    }

    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <ReactPlaceholder
            ready={!isLoading}
            customPlaceholder={textOneRowPlaceholder}
            showLoadingAnimation
          >
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              {fullAddress}
            </span>
          </ReactPlaceholder>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <ReactPlaceholder
            ready={!isLoading}
            customPlaceholder={mapPlaceholder}
            showLoadingAnimation
          >
            <div className="rounded-xl overflow-hidden">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: GOOGLE_MAPS_KEY,
                }}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={15}
                defaultCenter={{
                  lat: map.lat,
                  lng: map.lng,
                }}
              >
                <LocationMarker lat={map.lat} lng={map.lng} />
              </GoogleMapReact>
            </div>
          </ReactPlaceholder>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <TermsAndConditionsSection
        isLoading={isLoading}
        cancelationPolicy={cancelationPolicy}
        notes={notes}
        thingsToKnow={thingsToKnow}
      />
    );
  };

  const renderMotalVirtualRoom = () => {
    return (
      <Transition appear show={isOpenModalVirtualRoom} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-4 h-screen w-full">
                <div className="inline-flex  flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-6 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      {" "}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalVirtualRoom} />
                    </span>
                  </div>
                  <div className="h-full overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <iframe
                      data-hj-allow-iframe
                      title="VR_Rom"
                      src={auth.isAuthenticated ? lhref : href}
                      className={"w-full h-full"}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSideBoxes = () => {
    return (
      <div>
        {place && (
          <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
            <div className="flex flex-row divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2">
              {availableTimeSlots !== undefined && (
                <PlaceGuestsInput
                  fieldClassName="p-4 w-1/2"
                  setGuests={setActivityGuests}
                  guests={activityGuests}
                  hasButtonSubmit={false}
                />
              )}
              <PlaceTimeSlotsInput
                anchorDirection={"right"}
                fieldClassName="p-4 w-[179px]"
                className="h-full"
                setSelectedTimeSlot={setSelectedTimeSlot}
                data={availableTimeSlots}
              />
            </div>
            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex flex-row divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2">
              <div className="flex-1">
                <PlaceDateSingleInput
                  defaultValue={moment(startDate).add(0, "days")}
                  onChange={(date) => {
                    setSelectedDate(date?.format("l"));
                  }}
                  setSelectedDate={setSelectedDate}
                  bankHolidays={bank_holidays}
                  anchorDirection={"left"}
                  fieldClassName="p-4"
                  className="h-full"
                />
              </div>
              {(isOptionsEnabled || (options && options.values.length > 0)) && (
                <div className="flex flex-1">
                  <Popover className={`flex relative h-full`}>
                    {({ open, close }) => (
                      <>
                        <div
                          className={`flex-1 flex items-center focus:outline-none cursor-pointer ${
                            open ? "nc-hero-field-focused" : ""
                          }`}
                        >
                          <Popover.Button
                            className={`flex-1 flex text-left items-center p-4 w-[179px] space-x-3 `}
                            onClick={() =>
                              document.querySelector("html")?.click()
                            }
                          >
                            <div className="text-neutral-300 dark:text-neutral-400">
                              <div className="text-4xl">
                                <i className="las la-check-circle"></i>
                              </div>
                            </div>
                            <div className="flex-grow">
                              <span className="block xl:text-lg font-semibold">
                                {options?.label}
                              </span>
                              <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                                {options?.subLabel}
                              </span>
                            </div>
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute right-0 z-10 w-[350px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
                            <div className="overflow-hidden">
                              <div className="relative flex flex-col px-5 py-6 space-y-5">
                                {options?.values.map(
                                  (option, index: number) => (
                                    <div
                                      key={`option-${option.title}-${index}`}
                                      className="flex gap-3 justify-between w-full"
                                    >
                                      <div className="flex gap-3 justify-between w-full">
                                        <h4 className="flex-1">
                                          {option.title}
                                        </h4>
                                        {option.price > 0 && (
                                          <p className="">
                                            {euro.format(option.price)}
                                          </p>
                                        )}
                                      </div>
                                      <div>
                                        <input
                                          type="checkbox"
                                          className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary 
                                          rounded border-neutral-500 bg-white dark:bg-neutral-700  
                                          dark:checked:bg-primary-500 focus:ring-primary-500"
                                          checked={
                                            selectedOptions.findIndex(
                                              (opt) =>
                                                opt.title === option.title
                                            ) !== -1
                                          }
                                          onChange={() =>
                                            setSelectedOptions((prev) => {
                                              const index = prev.findIndex(
                                                (opt) =>
                                                  opt.title === option.title
                                              );
                                              if (index === -1) {
                                                return options.multiSelect
                                                  ? [...prev, option]
                                                  : [option];
                                              }
                                              return selectedOptions.filter(
                                                (opt) =>
                                                  opt.title !== option.title
                                              );
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              )}
            </div>
          </form>
        )}
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}

        {/* <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={pricePlaceholder}
          showLoadingAnimation
        >
          {price && (
            <div className="flex justify-between">
              <span className="text-3xl font-semibold">
                {euro.format(price)}
                <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                  {pricePer}
                </span>
              </span>
            </div>
          )}
        </ReactPlaceholder> */}

        {/* ROOM Virtual Tour */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={sideboxesPlaceholder}
          showLoadingAnimation
        >
          {href && (
            <ButtonPrimary
              className="text-2xl virtual-tour"
              onClick={() => openModalVirtualRoom()}
            >
              <i className={`las la-photo-video text-4xl pr-2`}></i>
              Virtual Tour
            </ButtonPrimary>
          )}
          {renderMotalVirtualRoom()}
        </ReactPlaceholder>

        {/* SIDE BOXES */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={sideboxesPlaceholder}
          showLoadingAnimation
        >
          {renderSideBoxes()}
        </ReactPlaceholder>

        {/* SUM */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={textFourRowsPlaceholder}
          showLoadingAnimation
        >
          <div className="flex flex-col space-y-4">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>
                {" "}
                €
                {priceItem &&
                  priceItem.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                x {place?.isPricePerPerson ? totalActivityGuests : 1}
              </span>
              <span>
                €
                {price &&
                  price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Taxes and other charges</span>
              <span>€0.00</span>
            </div>
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex justify-between font-semibold text-[18px]">
              <span>Total</span>
              <span>
                €
                {price &&
                  price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
              </span>
            </div>
          </div>
        </ReactPlaceholder>

        {/* SUBMIT */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={btnPlaceholder}
          showLoadingAnimation
        >
          {price && (
            <ButtonPrimary
              onClick={() => addToCartHandler()}
              className={`
              h-[83px]
              ring-0
               
            ${isOnCart ? "custom-green-button-cart" : ""}`}
            >
              <p className="flex space-x-2 justify-center items-center">
                {isOnCart ? (
                  <>
                    <span> Added To cart</span>
                  </>
                ) : (
                  <>
                    <span>Add to cart</span>
                  </>
                )}
              </p>
            </ButtonPrimary>
          )}
        </ReactPlaceholder>
      </div>
    );
  };

  const renderIncludes = () => {
    return (
      <div className="listingSection__wrap spacer-y-4">
        <div>
          <h2 className="text-2xl font-semibold">Included </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {whatIsIncludes
            ?.filter((_item: any, i: number) => i < 12)
            .map((item: any, index: number) => (
              <div
                key={`includes-${index}`}
                className="flex items-center space-x-3"
              >
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.text}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderNonIncludes = () => {
    return (
      <div className="listingSection__wrap spacer-y-4">
        <div>
          <h2 className="text-2xl font-semibold">Not Included </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {whatNotIncludes
            ?.filter((_item: any, i: number) => i < 12)
            .map((item: any, index: number) => (
              <div
                key={`includes-${index}`}
                className="flex items-center space-x-3"
              >
                <i className="las la-minus-circle text-2xl"></i>
                <span>{item.text}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      {/* SINGLE HEADER */}
      <AddToCartModal
        title={place ? place.name : "Experience"}
        showModal={showAddToCartModal}
        onClose={() => setShowAddToCartModal(false)}
      />
      <>
        <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            {detailsImgs && detailsImgs.length > 1 ? (
              <>
                <div
                  className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                  onClick={() => handleOpenModal(0)}
                >
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={galleryPlaceholder}
                    showLoadingAnimation
                  >
                    <NcImage
                      containerClassName="absolute inset-1"
                      className="object-cover w-full h-full rounded-md sm:rounded-xl"
                      src={
                        detailsImgs && detailsImgs.length > 0
                          ? detailsImgs[0].url
                          : ""
                      }
                    />
                  </ReactPlaceholder>
                </div>
                {detailsImgs
                  ?.filter((_item: any, i: number) => i >= 1 && i < 5)
                  .map((item: any, index: number) => (
                    <div
                      key={`imgBox-${index}`}
                      className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                        index >= 3 ? "hidden sm:block" : ""
                      }`}
                    >
                      <ReactPlaceholder
                        ready={!isLoading}
                        customPlaceholder={subGalleryPlaceholder}
                        showLoadingAnimation
                      >
                        <NcImage
                          key={`imgContainer-${index}`}
                          containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                          className="object-cover w-full h-full rounded-md sm:rounded-xl "
                          src={item.url || ""}
                        />
                      </ReactPlaceholder>

                      {/* OVERLAY */}
                      <div
                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                        onClick={() => handleOpenModal(index + 1)}
                      />
                    </div>
                  ))}
              </>
            ) : (
              <div
                className="col-span-4 row-span-2 w-full"
                // onClick={() => handleOpenModal(0)}
              >
                <ReactPlaceholder
                  ready={!isLoading}
                  customPlaceholder={galleryPlaceholder}
                  showLoadingAnimation
                >
                  <NcImage
                    containerClassName="w-full"
                    className="object-cover rounded-md sm:rounded-xl w-[1420px] h-[35rem]"
                    src={
                      detailsImgs && detailsImgs.length > 0
                        ? detailsImgs[0].url
                        : ""
                    }
                  />
                </ReactPlaceholder>
                {/* */}
              </div>
            )}

            {/* <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <ReactPlaceholder
                ready={!isLoading}
                customPlaceholder={showAllPhotosPlaceholder}
                showLoadingAnimation
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                  />
                </svg>
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </ReactPlaceholder>
            </div> */}
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={detailsImgs || []}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {renderSection2()}
          {renderSection3()}
          {renderIncludes()}
          {renderNonIncludes()}
          {/* {renderSection4()} */}
          {/* {renderSectionCheckIndate()} */}
          {/* {renderSection5()} */}
          {/* {renderSection6()} */}
          {renderSection7()}
          {renderSection8()}
          <div className="pt-8"></div>
        </div>

        {/* SIDEBAR */}
        {/* <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div
            className={`sticky ${isScrolled ? "smooth-scroll top-28 pt-20 pb-12" : ""
              }`}
          >
            {place && <div>{renderSidebar()}</div>}
          </div>
        </div> */}
        <div className="md:block md:flex-grow md:mt-0">
          <div className={"sticky smooth-scroll top-48 pb-12 "}>
            <div>{renderSidebar()}</div>
          </div>
        </div>
      </main>

      {/* OTHER SECTION */}
    </div>
  );
};

export default DetailPage;

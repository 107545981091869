import { FC } from "react";
import starEmpty from "images/orbito/stars/starEmpty.svg";
import starHalf from "images/orbito/stars/starHalf.svg";
import starFull from "images/orbito/stars/starFull.svg";

export interface TripadvisorStarsProps {
  className?: string;
  stars?: number;
}

const TripadvisorStars: FC<TripadvisorStarsProps> = ({
  className = "",
  stars = 0,
}) => {
  const renderStars = (stars: number) => {
    const starElems = [];
    let i;
    for (i = 0; i < Math.floor(stars); i++) {
      starElems.push(
        <img
          draggable="false"
          key={i}
          src={starFull}
          className="w-[18px] h-[18px]"
          alt="star"
        />
      );
    }
    if (stars % 1 !== 0) {
      starElems.push(
        <img
          draggable="false"
          key={i}
          src={starHalf}
          className="w-[18px] h-[18px]"
          alt="half-star"
        />
      );
      i++;
    }
    for (; i < 5; i++) {
      starElems.push(
        <img
          draggable="false"
          key={i}
          src={starEmpty}
          className="w-[18px] h-[18px]"
          alt="empty-star"
        />
      );
    }
    return starElems;
  };

  return (
    <div
      className={`nc-StartRating flex flex-row justify-start space-x-1 text-sm  ${className}`}
      data-nc-id="StartRating"
    >
      {renderStars(stars)}
    </div>
  );
};

export default TripadvisorStars;

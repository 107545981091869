export enum StayCurrentPriceActionTypes {
  SET_STAY_CURRENT_PRICE = 'SET_STAY_CURRENT_PRICE',
}

export interface SetStayCurrentPriceAction {
  type: StayCurrentPriceActionTypes.SET_STAY_CURRENT_PRICE;
  payload: any; 
}


export function setStayCurrentPrice(stayCurrentPrice: any): SetStayCurrentPriceAction {
  return {
    type: StayCurrentPriceActionTypes.SET_STAY_CURRENT_PRICE,
    payload: stayCurrentPrice,
  };
}


export type stayCurrentPriceAction = SetStayCurrentPriceAction;

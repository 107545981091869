import {StayCurrentTimeslotsActionTypes, stayCurrentTimeslotsAction} from './../actions/stayCurrentTimeslots'


export interface StayCurrentTimeslotsState {
  stayCurrentTimeslots: string[];
}

const initialState: StayCurrentTimeslotsState = {
  stayCurrentTimeslots: [],
};

const stayCurrentTimeslotsReducer = (state = initialState, action: stayCurrentTimeslotsAction) => {
  switch (action.type) {
    case StayCurrentTimeslotsActionTypes.SET_STAY_CURRENT_TIMESLOTS:
      return {
        ...state,
        stayCurrentTimeslots: action.payload,
      };
    default:
      return state;
  }
};

export default stayCurrentTimeslotsReducer;
import { API_URL } from "config/environment";

type saveNewEmailProp = {
  email: string;
};

export const saveNewEmail = ({ email }: saveNewEmailProp): Promise<any> => {
  const body = {
    email,
  };

  return fetch(API_URL + "/v1/newsletter/new", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return res;
  });
};

export enum StayMealTypesActionTypes {
  SET_STAY_MEAL_TYPES = 'SET_STAY_MEAL_TYPES',
}

export interface SetStayMealTypesAction {
  type: StayMealTypesActionTypes.SET_STAY_MEAL_TYPES;
  payload: any; 
}


export function setStayMealTypes(stayMealTypes: any): SetStayMealTypesAction {
  return {
    type: StayMealTypesActionTypes.SET_STAY_MEAL_TYPES,
    payload: stayMealTypes,
  };
}


export type stayMealTypesAction = SetStayMealTypesAction;

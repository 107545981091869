import { AnchorDirectionShape } from "react-dates";
import { FC } from "react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { PathName } from "routers/types";
import CheckboxAsRadio from "shared/Checkbox/CheckboxAsRadio";
import { useSelector, useDispatch } from "react-redux";
import { setStay } from "../../store/actions/stay";
import { setStayCurrentPrice } from "../../store/actions/stayCurrentPrice";
import { setStayCurrentCharges } from "../../store/actions/stayCurrentCharges";
import { setStayCurrentNON } from "../../store/actions/stayCurrentNON";
import { setStayCurrentTotalPrice } from "../../store/actions/stayCurrentTotalPrice";
import { setStayCurrentTotalPriceWithCharges } from "../../store/actions/stayCurrentTotalPriceWithCharges";
import { setStayMealTypes } from "../../store/actions/stayMealTypes";

export interface ExperiencesMealInputProps {
  onChange?: (date: moment.Moment | null) => void;
  defaultFocus?: boolean;
  fieldClassName?: string;
  onFocusChange?: (focused: boolean) => void;
  className?: string;
  anchorDirection?: AnchorDirectionShape;
  isDisabled?: boolean;
  hasButtonSubmit?: boolean;
  buttonSubmitHref?: PathName;
  setSelectedMeals: React.Dispatch<React.SetStateAction<string>>,
  data?: any[];
}

const ExperiencesMealInput: FC<ExperiencesMealInputProps> = ({
  hasButtonSubmit = true,
  buttonSubmitHref = "/listing-stay-map",
  isDisabled = false,
  onChange,
  defaultFocus = false,
  onFocusChange,
  anchorDirection,
  setSelectedMeals,
  className = "",

  fieldClassName = "[ nc-hero-field-padding ]",
  data = [],
}) => {
  const dispatch = useDispatch();

  // const [focusedInput, setFocusedInput] = useState(defaultFocus);

  const [selectedValue, setSelectedValue] = useState("");

  const stay = useSelector((state: any) => state.stay.stay);

  const stayCancellationTypes = useSelector(
    (state: any) => state.stayCancellationTypes.stayCancellationTypes
  );

  const stayMealTypes = useSelector(
    (state: any) => state.stayMealTypes.stayMealTypes
  );

  const webHotelierBoards: { [index: number]: string } = {
    0: "No meals",
    1: "All inclusive",
    2: "American",
    3: "Bed & breakfast",
    4: "Breakfast",
    5: "Caribbean breakfast",
    6: "Continental breakfast",
    7: "English breakfast",
    8: "European plan",
    9: "Family plan",
    10: "Full board",
    11: "Full breakfast",
    12: "Half board",
    13: "As brochured",
    14: "Room only",
    15: "Self catering",
    16: "Bermuda",
    17: "Dinner bed and breakfast plan",
    18: "Family American",
    19: "Breakfast",
    20: "Modified",
    21: "Lunch",
    22: "Dinner",
    23: "Breakfast & lunch",
  };

  const rateHawkBoards: { [index: string]: string } = {
    0: "No meals",
    unspecified: "Unspecified",
    "all-inclusive": "All Inclusive",
    breakfast: "Breakfast",
    "breakfast-buffet": "Breakfast Buffet",
    "continental-breakfast": "Continental Breakfast",
    dinner: "Dinner",
    "full-board": "Full Board",
    "half-board": "Half Board",
    lunch: "Lunch",
    nomeal: "No Meals",
    "some-meal": "Some Meal",
    "english-breakfast": "English Breakfast",
    "american-breakfast": "American Breakfast",
    "asian-breakfast": "Asian Breakfast",
    "chinese-breakfast": "Chinese Breakfast",
    "israeli-breakfast": "Israeli Breakfast",
    "japanese-breakfast": "Japanese Breakfast",
    "scandinavian-breakfast": "Scandinavian Breakfast",
    "scottish-breakfast": "Scottish Breakfast",
    "breakfast-for-1": "Breakfast for 1",
    "breakfast-for-2": "Breakfast for 2",
    "super-all-inclusive": "Super All Inclusive",
    "soft-all-inclusive": "Soft All Inclusive",
    "ultra-all-inclusive": "Ultra All Inclusive",
    "half-board-lunch": "Half Board with Lunch",
    "half-board-dinner": "Half Board with Dinner",
  };

  const boardGetter = (source: any, boardId: number) => {
    if (source === "wh") {
      return webHotelierBoards[boardId];
    } else if (source === "rh") {
      return rateHawkBoards[boardId];
    } else {
      return "No meals";
    }
  };

  useEffect(() => {
    let boardTypes: any = [];

    if (stay && !stay[0]?.no_availability) {
      stay?.forEach((stayDetails: any) => {
        const name = boardGetter(stayDetails.src, stayDetails.board);
        const isUnique = !boardTypes.some(
          (boardType: any) => boardType.name === name
        );

        if (isUnique) {
          boardTypes.push({
            id: stayDetails.id,
            name: name,
            description: "",
            checked: false,
            boardId: stayDetails.board,
          });
        }
      });

      if (boardTypes.length === 0) {
        boardTypes.push({
          id: 1,
          name: "No meals",
          description: "",
          checked: true,
          boardId: 0,
        });
      } else {
        boardTypes[0].checked = true;
      }

      const checkedObject = stay?.find((item: any) => item.checked);
      const checkedObjectPrice = checkedObject?.price;
      const checkedObjectCharges = checkedObject?.charges;
      const checkedObjectNON = checkedObject?.numberOfNights;
      const checkedObjectTotalPrice = checkedObject?.totalPrice;
      const checkedObjectTotalPriceWithCharges =
        checkedObject?.totalPriceWithCharges;

      setSelectedValue(boardTypes[0].name);
      setSelectedMeals(prev => boardTypes[0].name)
      dispatch(setStayCurrentPrice(checkedObjectPrice));
      dispatch(setStayCurrentCharges(checkedObjectCharges));
      dispatch(setStayCurrentNON(checkedObjectNON));
      dispatch(setStayCurrentTotalPrice(checkedObjectTotalPrice));
      dispatch(
        setStayCurrentTotalPriceWithCharges(checkedObjectTotalPriceWithCharges)
      );
      dispatch(setStayMealTypes(boardTypes));
    } else {
      boardTypes.push({
        id: 1,
        name: "No meals",
        description: "",
        checked: true,
        boardId: 0,
      });

      setSelectedValue(boardTypes[0].name);
      dispatch(setStayMealTypes(boardTypes));
    }
  }, [dispatch]);

  // const handleChange = (value: string) => {
  //   setSelectedValue(value);

  //   const selectedIndex = stayMealTypes.findIndex(
  //     (item: any) => item.name === value
  //   );

  //   const updatedStayMealTypes = stayMealTypes.map((mealType: any) =>
  //     mealType.name === value
  //       ? { ...mealType, checked: true }
  //       : { ...mealType, checked: false }
  //   );

  //   dispatch(setStayMealTypes(updatedStayMealTypes));

  //   const selectedBoardId = stayMealTypes[selectedIndex].boardId;
  //   const selectedCancellationId = stayCancellationTypes.find(
  //     (item: any) => item.checked
  //   )?.cancellationId;
  //   const updatedStay = stay.map((item: any) => {
  //     if (selectedCancellationId === null) {
  //       // Find the lowest price where item.cancellation_expiry is null
  //       const lowestPrice = stay.reduce(
  //         (lowest: number | null, stayItem: any) => {
  //           if (
  //             stayItem.board === selectedBoardId &&
  //             stayItem.cancellation_expiry === null &&
  //             (lowest === null || parseFloat(stayItem.price) < lowest)
  //           ) {
  //             return parseFloat(stayItem.price);
  //           }
  //           return lowest;
  //         },
  //         null
  //       );

  //       if (parseFloat(item.price) === lowestPrice) {
  //         return { ...item, checked: true };
  //       } else {
  //         return { ...item, checked: false };
  //       }
  //     } else if (typeof selectedCancellationId === "string") {
  //       // Find the lowest price where item.board === selectedBoardId and cancellation_expiry matches selectedCancellationId
  //       const lowestPrice = stay.reduce(
  //         (lowest: number | null, stayItem: any) => {
  //           if (
  //             stayItem.board === selectedBoardId &&
  //             stayItem.cancellation_expiry !== null &&
  //             (lowest === null || parseFloat(stayItem.price) < lowest)
  //           ) {
  //             return parseFloat(stayItem.price);
  //           }
  //           return lowest;
  //         },
  //         null
  //       );

  //       if (parseFloat(item.price) === lowestPrice) {
  //         return { ...item, checked: true };
  //       } else {
  //         return { ...item, checked: false };
  //       }
  //     } else {
  //       // No match with the given conditions, uncheck all stays
  //       return { ...item, checked: false };
  //     }
  //   });

  //   // dispatch(setStay([...updatedStay]));
  //   setStay([...updatedStay]);
  //   const checkedObject = updatedStay.find((item: any) => item.checked);
  //   const checkedObjectPrice = checkedObject?.price;
  //   const checkedObjectCharges = checkedObject?.charges;
  //   const checkedObjectNON = checkedObject?.numberOfNights;
  //   const checkedObjectTotalPrice = checkedObject?.totalPrice;
  //   const checkedObjectTotalPriceWithCharges =
  //     checkedObject?.totalPriceWithCharges;
  //   dispatch(setStayCurrentPrice(checkedObjectPrice));
  //   dispatch(setStayCurrentCharges(checkedObjectCharges));
  //   dispatch(setStayCurrentNON(checkedObjectNON));
  //   dispatch(setStayCurrentTotalPrice(checkedObjectTotalPrice));
  //   dispatch(
  //     setStayCurrentTotalPriceWithCharges(checkedObjectTotalPriceWithCharges)
  //   );
  // };

  // const handleChange = (value: string) => {
  //   setSelectedValue(value);

  //   const selectedIndex = stayMealTypes.findIndex(
  //     (item: any) => item.name === value
  //   );

  //   const updatedStayMealTypes = stayMealTypes.map((mealType: any) =>
  //     mealType.name === value
  //       ? { ...mealType, checked: true }
  //       : { ...mealType, checked: false }
  //   );

  //   dispatch(setStayMealTypes(updatedStayMealTypes));

  //   const selectedBoardId = stayMealTypes[selectedIndex].boardId;
  //   const selectedCancellationId = stayCancellationTypes.find(
  //     (item: any) => item.checked
  //   )?.cancellationId;

  //   const updatedStay = stay.map((item: any) => {
  //     if (selectedCancellationId === null) {
  //       // Find the lowest price where item.cancellation_expiry is null and item.board === selectedBoardId
  //       const lowestPrice = stay.reduce(
  //         (lowest: number | null, stayItem: any) => {
  //           if (
  //             stayItem.board === selectedBoardId &&
  //             stayItem.cancellation_expiry === null &&
  //             (lowest === null || parseFloat(stayItem.price) < lowest)
  //           ) {
  //             return parseFloat(stayItem.price);
  //           }
  //           return lowest;
  //         },
  //         null
  //       );

  //       if (parseFloat(item.price) === lowestPrice) {
  //         return { ...item, checked: true };
  //       } else {
  //         return { ...item, checked: false };
  //       }
  //     } else if (typeof selectedCancellationId === "string") {
  //       // Find the lowest price where item.board === selectedBoardId and cancellation_expiry matches selectedCancellationId
  //       const lowestPrice = stay.reduce(
  //         (lowest: number | null, stayItem: any) => {
  //           if (
  //             stayItem.board === selectedBoardId &&
  //             stayItem.cancellation_expiry === selectedCancellationId &&
  //             (lowest === null || parseFloat(stayItem.price) < lowest)
  //           ) {
  //             return parseFloat(stayItem.price);
  //           }
  //           return lowest;
  //         },
  //         null
  //       );

  //       if (parseFloat(item.price) === lowestPrice) {
  //         return { ...item, checked: true };
  //       } else {
  //         return { ...item, checked: false };
  //       }
  //     } else {
  //       // No match with the given conditions, uncheck all stays
  //       return { ...item, checked: false };
  //     }
  //   });

  //   // Update the state with the modified stay array
  //   setStay(updatedStay);
  // };

  const handleChange = (value: string) => {
    setSelectedValue(value);
    setSelectedMeals(prev => value)
    // Get the index of the selected value
    const selectedIndex = stayMealTypes.findIndex(
      (item: any) => item.name === value
    );

    // Create a new array with updated checked property
    const updatedStayMealTypes = stayMealTypes.map(
      (mealType: any, index: number) =>
        index === selectedIndex
          ? { ...mealType, checked: true }
          : { ...mealType, checked: false }
    );

    dispatch(setStayMealTypes(updatedStayMealTypes));

    const selectedBoardId = updatedStayMealTypes[selectedIndex]?.boardId;
    const selectedCancellationId = stayCancellationTypes.find(
      (item: any) => item.checked
    )?.cancellationId;

    const updatedStay = stay.map((item: any) => {
      if (selectedCancellationId === null) {
        // Find the lowest price where item.cancellation_expiry is null and item.board === selectedBoardId
        const lowestPrice = stay.reduce(
          (lowest: number | null, stayItem: any) => {
            if (
              stayItem.board === selectedBoardId &&
              stayItem.cancellation_expiry === null &&
              (lowest === null || parseFloat(stayItem.price) < lowest)
            ) {
              return parseFloat(stayItem.price);
            }
            return lowest;
          },
          null
        );

        if (parseFloat(item.price) === lowestPrice) {
          return { ...item, checked: true };
        } else {
          return { ...item, checked: false };
        }
      } else if (typeof selectedCancellationId === "string") {
        // Find the lowest price where item.board === selectedBoardId and cancellation_expiry matches selectedCancellationId
        const lowestPrice = stay.reduce(
          (lowest: number | null, stayItem: any) => {
            if (
              stayItem.board === selectedBoardId &&
              stayItem.cancellation_expiry === selectedCancellationId &&
              (lowest === null || parseFloat(stayItem.price) < lowest)
            ) {
              return parseFloat(stayItem.price);
            }
            return lowest;
          },
          null
        );

        if (parseFloat(item.price) === lowestPrice) {
          return { ...item, checked: true };
        } else {
          return { ...item, checked: false };
        }
      } else {
        // No match with the given conditions, uncheck all stays
        return { ...item, checked: false };
      }
    });

    dispatch(setStay(updatedStay));

    const checkedObject = updatedStay.find((item: any) => item.checked);
    // Get the price attribute value of the checked object
    const checkedObjectPrice = checkedObject.price;
    const checkedObjectCharges = checkedObject.charges;
    const checkedObjectNON = checkedObject?.numberOfNights;
    const checkedObjectTotalPrice = checkedObject?.totalPrice;
    const checkedObjectTotalPriceWithCharges =
      checkedObject?.totalPriceWithCharges;
    dispatch(setStayCurrentPrice(checkedObjectPrice));
    dispatch(setStayCurrentCharges(checkedObjectCharges));
    dispatch(setStayCurrentNON(checkedObjectNON));
    dispatch(setStayCurrentTotalPrice(checkedObjectTotalPrice));
    dispatch(
      setStayCurrentTotalPriceWithCharges(checkedObjectTotalPriceWithCharges)
    );
  };

  return (
    <div>
      <Popover className={`flex relative ${className}`}>
        {({ open, close }) => (
          <>
            <div
              className={`flex-1 flex items-center focus:outline-none cursor-pointer ${open ? "nc-hero-field-focused" : ""
                }`}
            >
              <Popover.Button
                className={`flex-1 flex text-left items-center ${fieldClassName} space-x-3 `}
                onClick={() => document.querySelector("html")?.click()}
              >
                <div className="text-neutral-300 dark:text-neutral-400">
                  <div className="text-4xl">
                    <i className="la la-utensils"></i>
                  </div>
                </div>
                <div className="flex-grow">
                  <span className="block xl:text-lg font-semibold">
                    {selectedValue || "No meals"}
                  </span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    Meals
                  </span>
                </div>
              </Popover.Button>
            </div>
            {!isDisabled && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 z-10 w-max max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
                  <div className="overflow-hidden">
                    <div className="relative flex flex-col px-5 py-6 space-y-5">
                      {stayMealTypes.map((item: any) => (
                        <div key={item.id} className="">
                          <CheckboxAsRadio
                            name={item.name}
                            label={item.name}
                            value={String(item.id)}
                            subLabel={item.description}
                            checked={item.checked}
                            onChange={() => handleChange(item.name)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            )}
          </>
        )}
      </Popover>
    </div>
  );
};

export default ExperiencesMealInput;

import { FC } from "react";
import AccreditationItems from "components/Accreditation/AccreditationItems";
import Seo from "components/Seo/Seo";

const AccreditationPage: FC<any> = () => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative`}
      data-nc-id="PageAbout"
    >
      <Seo
        name="Accreditations | Orbito Travel"
        description="Explore our accreditation process at Orbito Travel. Learn about our commitment to accessibility and inclusion in travel. Discover how we certify destinations for travelers with reduced mobility. Start your journey towards accessible tourism with us."
        title="Accreditations | Orbito Travel"
        type="page"
      />
      <div className="container py-16 space-y-16 lg:space-y-28">
        <div className="w-screen max-w-full">
          <div className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            <AccreditationItems />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccreditationPage;

import { FC, Fragment, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { Transition } from "@headlessui/react";

interface ModalProps {
    isOpen: boolean;
    closeModal: () => void;
    content: React.ReactNode;
}

const Modal: FC<ModalProps> = ({ isOpen, closeModal, content }) => {
    const modalRef = useRef<HTMLDivElement>(null);

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (modalRef.current === e.target) {
            closeModal();
        }
    };

    return createPortal(
        <Transition show={isOpen} as={Fragment}>
            <div className="fixed inset-0 my-28" onClick={handleOverlayClick} ref={modalRef}>
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                    </Transition.Child>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  sm:p-6">
                            {content}
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Transition>,
        document.getElementById("modal-root") as HTMLDivElement // Make sure you have a div with the id "modal-root" in your HTML file
    );
};

export default Modal;

export enum StayCurrentTotalPriceActionTypes {
  SET_STAY_CURRENT_TOTAL_PRICE = 'SET_STAY_CURRENT_TOTAL_PRICE',
}

export interface SetStayCurrentTotalPriceAction {
  type: StayCurrentTotalPriceActionTypes.SET_STAY_CURRENT_TOTAL_PRICE;
  payload: any; 
}


export function setStayCurrentTotalPrice(stayCurrentTotalPrice: any): SetStayCurrentTotalPriceAction {
  return {
    type: StayCurrentTotalPriceActionTypes.SET_STAY_CURRENT_TOTAL_PRICE,
    payload: stayCurrentTotalPrice,
  };
}


export type stayCurrentTotalPriceAction = SetStayCurrentTotalPriceAction;

import { Dialog, Transition } from "@headlessui/react";
import { useCustomSearchParams } from "context/SearchParamsContext";
import { FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  // Link,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { PathName } from "routers/types";
import { getBetaAccess } from "services/api";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import { isMobile } from "react-device-detect";
import { useWindowDimensions } from "hooks/useWindowDimensions";

interface Props {
  href?: PathName;
}

const ButtonSubmit: FC<Props> = ({ href = "/listing-stay-map" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    windowDimensions: { width },
  } = useWindowDimensions();
  const travelingFrom = useSelector((state: any) => state.search.travelingFrom);
  const travelingTo = useSelector((state: any) => state.search.travelingTo);
  const travelingDate = useSelector((state: any) => state.search.dates);
  const travelingGuests = useSelector((state: any) => state.search.guests);
  const [isBetaModalOpen, setBetaModalOpen] = useState(false);
  const [isMobileModalOpen, setMobileModalOpen] = useState(false);
  const [code, setCode] = useState<any>("");
  const [accessSuccess, setAccessSuccess] = useState<null | boolean>(null);
  const [notAvailable, setNotAvailable] = useState<null | boolean>(false);
  const { getStringParams } = useCustomSearchParams();

  const submitCode = (code: string) => {
    getBetaAccess(code)
      .then((res) => {
        if (res.status === "OK") {
          setBetaModalOpen(false);
          setAccessSuccess(true);
          navigate({
            pathname: `/services`,
            search: getStringParams(),
          });
        } else if (res.status === "NOT AVAILABLE") {
          setNotAvailable(true);
          setAccessSuccess(false);
        } else {
          setNotAvailable(false);
          setAccessSuccess(false);
        }
      })
      .catch(() => {
        setNotAvailable(false);
        setAccessSuccess(false);
      });
  };
  const hasAlreadyAccess = () => {
    // accessSuccess ? navigate({
    //   pathname: `/services`, search: getStringParams()
    // }) : setBetaModalOpen(true);
    console.log(travelingFrom, travelingTo, travelingDate, travelingGuests);
    if (
      travelingFrom === null ||
      travelingTo === null ||
      travelingDate.startDate === null ||
      travelingDate.endDate === null
    ) {
      console.log("has errors");
    }
    // navigate({
    //   pathname: `/services`,
    //   search: getStringParams(),
    // });
  };
  const renderModalBeta = () => {
    return (
      <Transition appear show={isBetaModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => setBetaModalOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block h-96 w-136">
                <div
                  className="inline-flex  flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl
                 bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full"
                >
                  <div className="relative flex-shrink-0 px-4 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 light:text-gray-900 dark:text-orange-600"
                      id="headlessui-dialog-title-70"
                    >
                      beta version
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={() => setBetaModalOpen(false)} />
                    </span>
                  </div>
                  <div className="px-6 py-6 h-full overflow-auto text-neutral-700 dark:text-neutral-200">
                    {accessSuccess === null && (
                      <div>
                        <h3 className="text-2xl font-bold leading-10">
                          Enter your beta access code
                        </h3>
                        <p className="leading-10 pb-4">
                          At this time, beta testers are upon invitation
                        </p>
                        <Input
                          type="password"
                          placeholder=""
                          className="w-1/2 h-16 text-xl"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </div>
                    )}
                    {accessSuccess === false && !notAvailable && (
                      <div>
                        <i className="la la-lock text-4xl"></i>
                        <h3 className="text-2xl font-bold leading-10">
                          Let's try that again
                        </h3>
                        <p className="pb-4">
                          The code you entered was incorrect.
                        </p>
                      </div>
                    )}
                    {accessSuccess === false && notAvailable && (
                      <div>
                        <i className="la la-lock text-4xl"></i>
                        <h3 className="text-2xl font-bold leading-10">
                          MIT GSW 2023 beta testers
                        </h3>
                        <p className="pb-4">
                          Respecting the pitches of other finalists, the access
                          is disabled until the end of the session.
                        </p>
                      </div>
                    )}
                  </div>
                  <hr></hr>
                  <div className="px-6 py-3 flex justify-end">
                    {accessSuccess === null && (
                      <ButtonPrimary onClick={() => submitCode(code)}>
                        Continue
                      </ButtonPrimary>
                    )}
                    {accessSuccess === false && (
                      <ButtonPrimary
                        onClick={() => {
                          setCode("");
                          setAccessSuccess(null);
                        }}
                      >
                        Try again
                      </ButtonPrimary>
                    )}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const navigateToServices = () =>
    navigate({
      pathname: `/services`,
      search: getStringParams(),
    });

  const handleSearchAccess = () => {
    if (!isMobile && width >= 1048) {
      navigateToServices();
    } else {
      setMobileModalOpen(true);
    }
  };

  const renderMobileVersionModal = () => {
    return (
      <Transition appear show={isMobileModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={() => setMobileModalOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block h-96 w-136">
                <div
                  className="inline-flex  flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl
                 bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full"
                >
                  <div className="relative flex-shrink-0 px-4 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 light:text-gray-900 dark:text-orange-600"
                      id="headlessui-dialog-title-70"
                    >
                      apologies
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={() => setMobileModalOpen(false)} />
                    </span>
                  </div>
                  <div className="px-6 py-6 h-full overflow-auto text-neutral-700 dark:text-neutral-200 flex flex-col justify-center">
                    <div className="">
                      <p className="leading-8 pb-4 text-2xl">
                        The available information is extensive, and accessing it
                        on a computer will provide a more efficient and
                        comprehensive experience.
                      </p>
                    </div>
                  </div>
                  <hr></hr>
                  <div className="px-6 py-3 flex justify-end">
                    <ButtonPrimary onClick={() => setMobileModalOpen(false)}>
                      Close
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <>
      <button
        // onClick={handleSearchAccess}
        type="submit"
        className="h-14 md:h-16 w-full md:w-16 rounded-full bg-white
         text-black dark:bg-[#F47D20] hover:bg-[#2097F4]
         hover:dark:bg-[#2097F4] flex items-center justify-center 
          focus:outline-none mr-3"
      >
        <span className="mr-3 md:hidden">Search</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
      {renderModalBeta()}
    </>
  );
};

export default ButtonSubmit;




import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react'
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonThird from 'shared/Button/ButtonThird';

type MultiCheckBoxValueType = {
    selected: boolean;
    value: string
}

export type MultiCheckBoxProp = {
    title: string;
    valueOptions: string[]
    onApplyClick: (selectedValues: string[]) => void;
    onClearFilters: () => void;
    className?: string;
}

const removeDuplicates = (arr: string[]) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
}

const MultiCheckBox = ({ valueOptions, title, onApplyClick, onClearFilters, className = "" }: MultiCheckBoxProp) => {


    const [values, setValues] = useState<MultiCheckBoxValueType[]>(removeDuplicates(valueOptions).map(val => ({ selected: true, value: val })))

    const onChange = (item: MultiCheckBoxValueType) => {

        setValues(prevValues => prevValues.map(itm =>
            itm.value === item.value ? { ...itm, selected: !itm.selected } : itm
        ));
    }


    return (<Popover className="relative">
        {({ open, close }) => (
            <>
                <Popover.Button
                    className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${open ? "!border-primary-500 " : ""
                        }`}
                >
                    <span>{title}</span>
                    <i className="las la-angle-down ml-2"></i>
                </Popover.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                >
                    <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0">
                        <div className={`overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700 ${className}`}>
                            <div className="relative flex flex-col px-5 py-6 space-y-5">
                                {

                                    values
                                        .map((item, index) => (
                                            <div key={`transporation_type ${index}`} className="">
                                                <div className={`flex text-sm sm:text-base`}>
                                                    <input
                                                        type="checkbox"
                                                        className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary rounded border-neutral-500
                                                         bg-white dark:bg-neutral-700  dark:checked:bg-primary-500 focus:ring-primary-500"
                                                        checked={item.selected}
                                                        onChange={() => onChange(item)}
                                                    />
                                                    <label
                                                        className="ml-3.5 flex flex-col flex-1 justify-center"
                                                    >
                                                        <span className="text-neutral-900 dark:text-neutral-100">
                                                            {item.value}
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        ))}
                            </div>
                            <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                                <ButtonThird

                                    onClick={() => {
                                        onClearFilters();
                                        setValues(prev => prev.map(val => ({ selected: true, value: val.value })))
                                    }}
                                    sizeClass="px-4 py-2 sm:px-5"
                                >
                                    Reset
                                </ButtonThird>
                                <ButtonPrimary
                                    onClick={() => {
                                        close();
                                        onApplyClick(values.filter(val => val.selected).map(val => val.value))
                                    }}
                                    sizeClass="px-4 py-2 sm:px-5"
                                >
                                    Apply
                                </ButtonPrimary>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </>
        )}
    </Popover>)
}

export default MultiCheckBox
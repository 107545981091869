export interface SignInPopoverState {
  SignInPopover: boolean;
}

const initState = {
    SignInPopover: false,
}

const signInPopoverReducer = (state=initState, action: { type: string; payload: any; storeKey: string }) => {
  switch (action.type) {
    case 'SHOW':
      return {
        ...state,
        SignInPopover: true,
      };
    case 'HIDE':
      return {
        ...state,
        SignInPopover: false,
      };
    default:
      return state;
  }
}
export default signInPopoverReducer;
import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { FC } from "react";
import ButtonSubmit from "./ButtonSubmit";
import { PathName } from "routers/types";
import CheckboxAsRadio from "shared/Checkbox/CheckboxAsRadio";
import { useSelector, useDispatch } from "react-redux";
import { setStay } from "../../store/actions/stay";
import { setStayCurrentPrice } from "../../store/actions/stayCurrentPrice";
import { setStayCurrentCharges } from "../../store/actions/stayCurrentCharges";
import { setStayCurrentNON } from "../../store/actions/stayCurrentNON";
import { setStayCurrentTotalPrice } from "../../store/actions/stayCurrentTotalPrice";
import { setStayCurrentTotalPriceWithCharges } from "../../store/actions/stayCurrentTotalPriceWithCharges";
import { setStayCancellationTypes } from "../../store/actions/stayCancellationTypes";

export interface CancellationInputProps {
  onChange?: (date: moment.Moment | null) => void;
  defaultFocus?: boolean;
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: PathName;
  hasButtonSubmit?: boolean;
  isDisabled?: boolean;
  setSelectedCancelation: React.Dispatch<React.SetStateAction<string>>;
  data?: any[];
}

const CancellationInput: FC<CancellationInputProps> = ({
  onChange,
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
  isDisabled = false,
  defaultFocus = false,
  setSelectedCancelation,
  data = [],
}) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [focusedInput, setFocusedInput] = useState(defaultFocus);

  const [selectedValue, setSelectedValue] = useState("");

  const stay = useSelector((state: any) => state.stay.stay);
  const stayCancellationTypes = useSelector(
    (state: any) => state.stayCancellationTypes.stayCancellationTypes
  );

  const stayMealTypes = useSelector(
    (state: any) => state.stayMealTypes.stayMealTypes
  );

  const ClientSideCancellationTypes: { [index: number]: string } = {
    0: "Non refundable",
    2: "Flexible",
    100: "N/A",
  };

  let cancellationTypes: any = [];

  const webhotelierAvailabilityString = (value: string) => {
    if (value === "") {
      return 0;
    } else if (value === "no availability") {
      return 100;
    } else if (value === undefined) {
      return 100;
    } else if (value.length > 5) {
      return 2;
    } else {
      return 100;
    }
  };

  const ratehawkAvailabilityString = (value: string) => {
    if (value === "") {
      return 0;
    } else if (value === null) {
      return 0;
    } else if (value === "no availability") {
      return 100;
    } else if (value === undefined) {
      return 100;
    } else if (value.length > 5) {
      return 2;
    } else {
      return 100;
    }
  };

  if (stay && !stay[0].no_availability) {
    const getCancellationLabel = (value: string, src: any) => {
      if (src === "wh") {
        return webhotelierAvailabilityString(value);
      }
      if (src === "rh") {
        return ratehawkAvailabilityString(value);
      }
      return 0;
    };

    stay.forEach((stayDetails: any) => {
      const index = getCancellationLabel(
        stayDetails.cancellation_expiry,
        stayDetails.src
      );

      const name = ClientSideCancellationTypes[index];

      // Check if the name already exists in boardTypes
      const isUnique = !cancellationTypes.some(
        (cancellationType: any) => cancellationType.name === name
      );

      if (isUnique) {
        cancellationTypes.push({
          id: stayDetails.id,
          name: name,
          description: "",
          checked: false,
          cancellationId: stayDetails.cancellation_expiry,
        });
      }
    });

    if (cancellationTypes.length > 0) {
      cancellationTypes[0].checked = true;
    } else {
      cancellationTypes.push({
        id: 0,
        name: "Strict",
        boardId: 0,
        description: "",
        checked: true,
        cancellationId: "",
      });
    }
    if (!selectedValue) {
      setSelectedValue(cancellationTypes[0].name);
      setSelectedCancelation(cancellationTypes[0].name)
      //  const checkedObject = stay.find((item: any) => item.checked);
      //  // Get the price attribute value of the checked object
      //  const checkedObjectPrice = checkedObject.price;
      //  dispatch(setStayCurrentPrice(checkedObjectPrice));
      dispatch(setStayCancellationTypes(cancellationTypes));
    }
  } else {
    cancellationTypes.push({
      id: 0,
      name: "Strict",
      boardId: 0,
      description: "",
      checked: true,
      cancellationId: "no availability",
    });
  }


  const handleChange = (value: string) => {
    setSelectedValue(value);
    setSelectedCancelation(value)
    // Get the index of the selected value
    const selectedIndex = cancellationTypes.findIndex(
      (item: any) => item.name === value
    );

    // Create a new array with updated checked property
    const updatedCancellationTypes = cancellationTypes.map(
      (item: any, index: number) =>
        index === selectedIndex
          ? { ...item, checked: true }
          : { ...item, checked: false }
    );


    dispatch(setStayCancellationTypes(updatedCancellationTypes));

    const selectedBoardId = stayMealTypes.find(
      (item: any) => item.checked
    )?.boardId;
    const selectedCancellationId = updatedCancellationTypes[selectedIndex]?.cancellationId;
    

    const updatedStay = stay.map((item: any) => {
      if (selectedCancellationId === null) {
        // Find the lowest price where item.cancellation_expiry is null and item.board === selectedBoardId
        const lowestPrice = stay.reduce(
          (lowest: number | null, stayItem: any) => {
            if (
              stayItem.board === selectedBoardId &&
              stayItem.cancellation_expiry === null &&
              (lowest === null || parseFloat(stayItem.price) < lowest)
            ) {
              return parseFloat(stayItem.price);
            }
            return lowest;
          },
          null
        );

        if (parseFloat(item.price) === lowestPrice) {
          return { ...item, checked: true };
        } else {
          return { ...item, checked: false };
        }
      } else if (typeof selectedCancellationId === "string") {
        
        // Find the lowest price where item.board === selectedBoardId and cancellation_expiry matches selectedCancellationId
        const lowestPrice = stay.reduce(
          (lowest: number | null, stayItem: any) => {
            if (
              stayItem.board === selectedBoardId &&
              stayItem.cancellation_expiry === selectedCancellationId &&
              (lowest === null || parseFloat(stayItem.price) < lowest)
            ) {
              return parseFloat(stayItem.price);
            }
            return lowest;
          },
          null
        );
        if (parseFloat(item.price) === lowestPrice) {
          return { ...item, checked: true };
        } else {
          return { ...item, checked: false };
        }
      } else {
        // No match with the given conditions, uncheck all stays
       
        return { ...item, checked: false };
      }
    });

    dispatch(setStay(updatedStay));

    // Get the checked object from the updatedStay array
    const checkedObject = updatedStay.find((item: any) => item.checked);

    // Get the relevant attributes from the checked object
    const checkedObjectPrice = checkedObject.price;
    const checkedObjectCharges = checkedObject.charges;
    const checkedObjectNON = checkedObject?.numberOfNights;
    const checkedObjectTotalPrice = checkedObject?.totalPrice;
    const checkedObjectTotalPriceWithCharges =
      checkedObject?.totalPriceWithCharges;

    // Update the state with the retrieved values
    dispatch(setStayCurrentPrice(checkedObjectPrice));
    dispatch(setStayCurrentCharges(checkedObjectCharges));
    dispatch(setStayCurrentNON(checkedObjectNON));
    dispatch(setStayCurrentTotalPrice(checkedObjectTotalPrice));
    dispatch(
      setStayCurrentTotalPriceWithCharges(checkedObjectTotalPriceWithCharges)
    );
  };

  useEffect(() => {
    setFocusedInput(defaultFocus);
  }, [defaultFocus, dispatch]);

  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none cursor-pointer ${open ? "nc-hero-field-focused" : ""
              }`}
          >
            <Popover.Button
              className={`flex-1 flex text-left items-center ${fieldClassName} space-x-3 `}
              onClick={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400">
                <div className="text-4xl">
                  <i className="la la-clipboard-list"></i>
                </div>
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {selectedValue || "Strict"}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  Cancellation
                </span>
              </div>
            </Popover.Button>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit href={buttonSubmitHref} />
              </div>
            )}
          </div>
          {!isDisabled && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 w-max max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
                <div className="overflow-hidden">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {stayCancellationTypes.map((item: any) => (
                      <div key={item.id} className="">
                        <CheckboxAsRadio
                          name={item.name}
                          label={item.name}
                          value={String(item.id)}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={() => handleChange(item.name)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          )}
        </>
      )}
    </Popover>
  );
};

export default CancellationInput;

import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
// import convertNumbThousand from "utils/convertNumbThousand";

export interface CardCategory5Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategory5: FC<CardCategory5Props> = ({
  className = "",
  taxonomy,
}) => {
  const {
    // count, 
    name,
    href = "/",
    thumbnail,
    date = ""
  } = taxonomy;
  return (
    <a
      href={href}
      target="_blank"
      className={`nc-CardCategory5 flex flex-col ${className}`}
      data-nc-id="CardCategory5"
    >
      <div
        className={`flex-shrink-0 relative w-2/3 aspect-w-4 aspect-h-2 h-0 rounded-2xl overflow-hidden group`}
      >
        <NcImage
          src={thumbnail}
          className="object-contain w-full h-full rounded-2xl"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-4 px-3 truncate">
        <h2
          className={`text-sm text-neutral-900 dark:text-neutral-100 font-body truncate italic`}
        >
          {name}
        </h2>
        <span
          className={`block mt-2 text-sm text-neutral-6000 dark:text-neutral-400 font-semibold`}
        >
          {date}
        </span>
      </div>
    </a>
  );
};

export default CardCategory5;

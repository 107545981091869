import { MovesActionTypes, MovesAction } from "./../actions/moves";

export interface MovesState {
  moves: any[];
}

const initialState: MovesState = {
  moves: [],
};

const movesReducer = (state = initialState, action: MovesAction) => {
  switch (action.type) {
    case MovesActionTypes.SET_MOVES:
      return {
        ...state,
        moves: action.payload,
      };
    default:
      return state;
  }
};

export default movesReducer;

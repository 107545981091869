import __accreditations from "./jsons/__accreditations.json";
import { DEMO_CATEGORIES } from "./taxonomies";
import { AccreditationsDataType } from "./types";
// import { DEMO_AUTHORS } from "./authors";


const ACCREDITATIONS = __accreditations.map((post): AccreditationsDataType => {

  const categories = post.categoriesId.map(
    (id) => DEMO_CATEGORIES.filter((taxonomy) => taxonomy.id === id)[0]
  );

  return {
    ...post,
    type: "accreditation",
    image: post.featuredImage,
    categories: [categories[0]],
  } as AccreditationsDataType;
});

export { ACCREDITATIONS };

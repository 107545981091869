import Hotjar from "@hotjar/browser";
import { HOTJAR_SITE_ID, HOTJAR_VERSION } from "config/environment";
import { createContext, useContext, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

type HotJarProviderProps = {
  children: ReactNode;
};

type HotJarParamsContextType = {};

const HotJarParamsContext = createContext<HotJarParamsContextType | undefined>(
  undefined
);

export const HotJarProvider: React.FC<HotJarProviderProps> = ({ children }) => {
  useEffect(() => {
    Hotjar.init(Number(HOTJAR_SITE_ID), Number(HOTJAR_VERSION));
  }, []);

  return (
    <HotJarParamsContext.Provider value={{}}>
      {children}
    </HotJarParamsContext.Provider>
  );
};

export const useHotJarContext = (): HotJarParamsContextType => {
  const context = useContext(HotJarParamsContext);
  if (!context) {
    throw new Error("useHotJarContext must be used within a HotJarProvider");
  }
  return context;
};

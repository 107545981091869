import {HotelActionTypes, HotelAction} from './../actions/hotels'


export interface HotelState {
  hotels: any[];
}

const initialState: HotelState = {
  hotels: [],
};

const hotelReducer = (state = initialState, action: HotelAction) => {
  switch (action.type) {
    case HotelActionTypes.ADD_HOTEL:
      return {
        ...state,
        hotels: [...state.hotels, action.payload],
      };
    case HotelActionTypes.REMOVE_HOTEL:
      return {
        ...state,
        hotels: state.hotels.filter((hotel) => hotel.id !== action.payload.id),
      };
    case HotelActionTypes.UPDATE_HOTEL:
      const updatedHotels = state.hotels.map((hotel) => {
        if (hotel.id === action.payload.id) {
          return {
            ...hotel,
            ...action.payload.updates,
          };
        } else {
          return hotel;
        }
      });
      return {
        ...state,
        hotels: updatedHotels,
      };
    case HotelActionTypes.SET_HOTELS:
      return {
        ...state,
        hotels: action.payload,
      };
    default:
      return state;
  }
};

export default hotelReducer;
import { OffersByDate } from "containers/HowToMoveAroundPage/custom/TransportationPerDateTimeSlotsInput";
import { IOfferCheckOptions } from "containers/HowToMoveAroundPage/custom/TrasportationDetailPage";
import { ITransportation } from "services/models";
import {
  TransportationAction,
  TransportationActionType,
} from "store/actions/transportation";

export interface TransportationState {
  transportations: ITransportation[];
  onOffOffers: IOfferCheckOptions[];
  offersByDate: OffersByDate;
}

const initialState: TransportationState = {
  transportations: [],
  offersByDate: {},
  onOffOffers: [],
};

const transportationReducer = (
  state = initialState,
  action: TransportationAction
) => {
  switch (action.type) {
    case TransportationActionType.SET_TRANSPORTATIONS:
      return {
        ...state,
        transportations: action.payload,
      };
    case TransportationActionType.SET_ON_OFF_OFFERS:
      return {
        ...state,
        onOffOffers: action.payload,
      };
    case TransportationActionType.SET_BY_DATE_OFFERS:
      return {
        ...state,
        offersByDate: action.payload,
      };
    default:
      return state;
  }
};

export default transportationReducer;

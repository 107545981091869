export const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3001";
export const GOOGLE_MAPS_KEY =
  process.env.GOOGLE_MAPS_KEY || "AIzaSyCO6hL_x5W8tnWJQn0ELwIpisT4ITU_laM";

export const REACT_APP_SITE_KEY =
  process.env.REACT_APP_SITE_KEY || "6LdF1V4pAAAAAJLyr1xkDY_A03tUhq3r6a-F2w3H";

export const SENDGRID_API_KEY =
  process.env.SENDGRID_API_KEY ||
  "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";

export const HOTJAR_SITE_ID = process.env.HOTJAR_SITE_ID || "3919375";
export const HOTJAR_VERSION = process.env.HOTJAR_VERSION || "6";

export const HOMEPAGE = process.env.HOMEPAGE || "http://localhost:3000/";

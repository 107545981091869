import { FC } from "react";
import PolicyItem from "components/PrivacyPolicyListing/PolicyItem";
import Seo from "components/Seo/Seo";

const texts = [
  {
    title: "ACCESSIBLE TRAVEL HUB",
    text: "orbito travel is an online one-stop travel platform for users with reduced mobility, who will be able to plan and book their trip online with one credit card transaction.",
    icon: "question-circle",
  },
  {
    title: "MOBILITY-INCLUSIVE ADVENTURES",
    text: "It provides mobility-challenged travellers to have online access to all the components of their trip i.e. transportation, accommodation, activities, transfers and other services, offering for each component all the online available alternatives with price transparency. We ensure that all suggestions are aligned to travellers&rsquo; mobility needs. Thus, the booking flow is smooth and streamlined in every aspect providing a unique user experience, under one credit card transaction.",
    icon: "question-circle",
  },
  {
    title: "MISSION",
    text: "Our mission is to enable an accessible and actively inclusive travel community, in which travelers with reduced mobility have equal opportunities to participate in a socially sustainable tourism sector.",
    icon: "question-circle",
  },
  {
    title: "PASSION",
    text: "Our passion is to make a world and especially travel more accessible, by breaking ground in inclusive engagement of travellers, travel vendors, destination authorities and local communities.",
    icon: "question-circle",
  },
  {
    title: "RESPONSIBILITY",
    text: "Travel and tourism can be considered basic human rights as they can improve substantially the quality of life and in hindsight create better living conditions for all parties involved.<br /> orbito travel offering only accessible options for travellers in wheelchairs aims in involving more and more travellers to take a firm step out of their safe space at home and feel secure and free to roam the world.",
    icon: "question-circle",
  },
  {
    title: "IMPACT",
    text: "In partnership with academic institutions and governmental organisations, we will prepare training material, addressed to our travel vendor partners, which will enhance their interaction to mobility-challenged travelers, in oder to promote the diversity, equity and inclusion of the latter in the tourism market.",
    icon: "question-circle",
  },
  {
    title: "COMMITMENT",
    text: "5% of our profit will be given to donate wheelchairs to individuals, who cannot afford it and to provide financial aid to individuals in a wheelchair, who cannot afford to travel, by covering part of the travel cost.",
    icon: "question-circle",
  },
];

const AboutPage: FC<any> = () => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative`}
      data-nc-id="PageAbout"
    >
      <Seo
        name="About Page | Orbito Travel"
        description="Discover our commitment to inclusive travel at Orbito Travel. Join our mission for equal opportunities in tourism. Learn how we empower travelers with reduced mobility. Start exploring with us today!"
        title="About Page | Orbito Travel"
        type="page"
      />
      <div className="container py-16 space-y-16 lg:space-y-28">
        <div className="w-screen max-w-full">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 mb-8">
            About
          </h2>
          <div className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            <PolicyItem texts={texts} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;

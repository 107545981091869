import { Popover, Transition } from "@headlessui/react";
import { Fragment, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SignIn from "components/Header/SignIn";
import { Authenticator, ThemeProvider } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { AuthFormThemeLight, AuthFormThemeDark } from "config/amplifyAuthTheme";
import { useSelector, useDispatch } from "react-redux";

import {
  showSignInPopover,
  hideSignInPopover,
} from "store/actions/signInPopover";

export default function SignInDropdown() {
  const signInPopoverState = (state: any) => state.signInPopover;
  const signInPopoverStateStatus =
    useSelector(signInPopoverState).SignInPopover;

  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;

  const authState = (state: any) => state.auth;
  const auth = useSelector(authState);

  const dispatch = useDispatch();

  function customColors(cssVar: string) {
    return `rgb(var(${cssVar}))`;
  }

  function handleSignInPopoverStateChange() {
    return signInPopoverStateStatus === true
      ? dispatch(hideSignInPopover())
      : dispatch(showSignInPopover());
  }

  useEffect(() => {
    const style = document.createElement("style");

    style.textContent = `
      .amplify-label {
        color: ${customColors("--c-secondary-400")};
      }
      .amplify-tabs-item {
        color: ${customColors("--c-secondary-400")};
      }
      .amplify-heading {
        color: ${customColors("--c-secondary-400")};
      }

      input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [auth]);

  return (
    <div className="SignInDropdown">
      <Popover className="relative">
        <>
          <Popover.Button
            className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            {/* <ButtonPrimary onClick={() => handleSignInPopoverStateChange()}>
              Sign up
            </ButtonPrimary> */}
          </Popover.Button>
          <Transition
            show={signInPopoverStateStatus}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <div>
              <Popover.Panel className="absolute z-10 w-screen max-w-fit px-4 mt-4 sm:right-0 sm:px-0 ml-50">
                <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5">
                  {/* <div className="relative grid gap-6 bg-white dark:bg-neutral-800"> */}
                  <ThemeProvider
                    theme={isDarkMode ? AuthFormThemeDark : AuthFormThemeLight}
                  >
                    <Authenticator>
                      {({ signOut, user }) => (
                        <div>{<SignIn user={user} />}</div>
                      )}
                    </Authenticator>
                  </ThemeProvider>
                  {/* </div> */}
                </div>
              </Popover.Panel>
            </div>
          </Transition>
        </>
      </Popover>
    </div>
  );
}

import { Popover } from "@headlessui/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addGuests } from "store/actions/search";
import { Guests } from "store/reducers/search";

const MainIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="nc-icon-field"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18 9v3m0 0v3m0-3h3m-3 0h-3m-2-5a4 4 0 11-8 0 4 4 0 018 0zM3 20a6 6 0 0112 0v1H3v-1z"
    />
  </svg>
);

const AdultsIcon = () => (
  <svg viewBox="0 0 16 16" fill="currentColor" height="1.5rem" width="1.5rem">
    <path
      fillRule="evenodd"
      d="M10.5 5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm.061 3.073a4 4 0 10-5.123 0 6.004 6.004 0 00-3.431 5.142.75.75 0 001.498.07 4.5 4.5 0 018.99 0 .75.75 0 101.498-.07 6.005 6.005 0 00-3.432-5.142z"
    />
  </svg>
);

const WheelchairIcon = () => (
  <svg viewBox="0 0 24 24" fill="currentColor" height="1.7rem" width="1.7rem">
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M8 10.341v2.194A3.999 3.999 0 1013.465 18h2.193A6.002 6.002 0 014 16a6.003 6.003 0 014-5.659zM12 17a3 3 0 01-3-3v-4c0-1.044.534-1.964 1.343-2.501a3 3 0 113.314.002A2.99 2.99 0 0115 10v4.999l1.434.001a2 2 0 011.626.836l.089.135 2.708 4.515-1.714 1.028L16.433 17 15 16.999 12 17zm0-8a1 1 0 00-1 1v4a1 1 0 001 1h.999L13 10a1 1 0 00-1-1zm0-5a1 1 0 100 2 1 1 0 000-2z" />
  </svg>
);

const GuestsInput = () => {
  const dispatch = useDispatch();
  const travelingGuests:Guests = useSelector((state: any) => state.search.guests);

  const guestsInputRef = useRef<HTMLButtonElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [adultsValue, setAdultsValue] = useState<number>( 0);
  const [wheelchairValue, setWheelchairValue] = useState<number>( 1);

  const value = adultsValue + wheelchairValue;
  const placeHolder = value > 1 ? "Travelers" : "Traveler";
  const isDisabled = value >= 2;

  // if (value) {
  //   dispatch(
  //     addGuests({
  //       guestAdults: adultsValue,
  //       guestWheelchair: wheelchairValue,
  //     })
  //   );
  // }

  useEffect(() => {
    const onFocus = () => {
      setIsFocused(true);
    };

    const onBlur = () => {
      setIsFocused(false);
    };

    const inputElement = guestsInputRef.current;

    inputElement?.addEventListener("focus", onFocus);
    inputElement?.addEventListener("blur", onBlur);

    // Cleanup event listeners
    return () => {
      inputElement?.removeEventListener("focus", onFocus);
      inputElement?.removeEventListener("blur", onBlur);
    };
  }, []);

  return (
    <Popover className={`relative h-full w-full rounded-full`}>
      <Popover.Button
        onClick={() => setIsFocused((prev) => !prev)}
        ref={guestsInputRef}
        className={`focus:outline-none focus:ring-0  rounded-full h-full w-full flex 
        gap-x-3 flex-row justify-center items-center text-sm shadow-md pl-7 ${
          isFocused ? "bg-[#fca15bc2] dark:bg-neutral-600" : ""
        }`}
      >
        <div>
          <MainIcon />
        </div>
        <div className="flex flex-col items-start ">
          <label id="guests">{placeHolder}</label>
          <input
            type="number"
            required
            placeholder={placeHolder}
            className={`m-0 p-0 bg-transparent dark:bg-transparent border-none text-base
               text-white font-bold  placeholder:text-white focus:outline-none focus:ring-0 ${
                 value ? "" : "text-transparent"
               } `}
            value={value}
            id="guests"
          />
        </div>
      </Popover.Button>

      <Popover.Panel
        className="mt-6 absolute left-[10%] flex flex-col
      justify-center items-center
       w-[165px] h-28 z-10 bg-white dark:bg-neutral-800 text-black dark:text-white shadow-md gap-4 rounded-3xl" 
      >
        <div className="flex flex-row gap-3">
          <div>
            <WheelchairIcon />
          </div>
          <button
            type="button"
            onClick={() => {
              setWheelchairValue((prev) => prev - 1);
              dispatch(
                addGuests({
                  guestAdults: adultsValue,
                  guestWheelchair: wheelchairValue - 1,
                })
              );
            }}
            disabled={wheelchairValue <= 1}
            className="border border-secondary-500 rounded-full px-2"
          >
            -
          </button>
          <div className="w-4  text-center">{wheelchairValue}</div>
          <button
            type="button"
            disabled={isDisabled}
            onClick={() => {
              setWheelchairValue((prev) => prev + 1);
              dispatch(
                addGuests({
                  guestAdults: adultsValue,
                  guestWheelchair: wheelchairValue + 1,
                })
              );
            }}
            className="border border-secondary-500 rounded-full px-2"
          >
            +
          </button>
        </div>
        <div className="flex flex-row gap-3 ml-1">
          <div>
            <AdultsIcon />
          </div>
          <button
            type="button"
            disabled={adultsValue <= 0}
            onClick={() => {
              setAdultsValue((prev) => prev - 1);
              dispatch(
                addGuests({
                  guestAdults: adultsValue - 1,
                  guestWheelchair: wheelchairValue,
                })
              );
            }}
            className="border border-secondary-500 rounded-full px-2"
          >
            -
          </button>
          <div className="w-4 text-center">{adultsValue}</div>
          <button
            type="button"
            onClick={() => {
              setAdultsValue((prev) => prev + 1);
              dispatch(
                addGuests({
                  guestAdults: adultsValue + 1,
                  guestWheelchair: wheelchairValue,
                })
              );
            }}
            disabled={isDisabled}
            className="border border-secondary-500 rounded-full px-2"
          >
            +
          </button>
        </div>
      </Popover.Panel>
    </Popover>
  );
};

export default GuestsInput;

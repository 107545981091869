import { OffersByDate } from "containers/HowToMoveAroundPage/custom/TransportationPerDateTimeSlotsInput";
import { IOfferCheckOptions } from "containers/HowToMoveAroundPage/custom/TrasportationDetailPage";
import { ITransportation } from "services/models";

export enum TransportationActionType {
  SET_TRANSPORTATIONS = "SET_TRANSPORTATIONS",
  SET_ON_OFF_OFFERS = "SET_ON_OFF_OFFERS",
  SET_BY_DATE_OFFERS = "SET_BY_DATE_OFFERS",
}

export interface SetTransportationsAction {
  type: TransportationActionType.SET_TRANSPORTATIONS;
  payload: ITransportation[];
}

export interface SetTransportationsOnOffOffersAction {
  type: TransportationActionType.SET_ON_OFF_OFFERS;
  payload: IOfferCheckOptions[];
}

export interface SetTransportationsByDateOffersAction {
  type: TransportationActionType.SET_BY_DATE_OFFERS;
  payload: OffersByDate;
}

export function setTransportations(
  payload: ITransportation[]
): SetTransportationsAction {
  return {
    type: TransportationActionType.SET_TRANSPORTATIONS,
    payload,
  };
}

export function setOnOffOffers(
  payload: IOfferCheckOptions[]
): SetTransportationsOnOffOffersAction {
  return {
    type: TransportationActionType.SET_ON_OFF_OFFERS,
    payload,
  };
}

export function setOffersByDate(
  payload: OffersByDate
): SetTransportationsByDateOffersAction {
  return {
    type: TransportationActionType.SET_BY_DATE_OFFERS,
    payload,
  };
}

export type TransportationAction =
  | SetTransportationsAction
  | SetTransportationsOnOffOffersAction
  | SetTransportationsByDateOffersAction;

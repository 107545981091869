import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { PostDataType } from "data/types";
import { Link } from "react-router-dom";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import SocialsShare from "shared/SocialsShare/SocialsShare";
import { DEMO_POSTS } from "data/posts";
import { BlogPost } from "services/models";
import moment from "moment";
import Avatar from "shared/Avatar/Avatar";

export interface Card12Props {
  className?: string;
  post?: PostDataType | BlogPost;
}

const Card12: FC<Card12Props> = ({
  className = "h-full",
  post = DEMO_POSTS[0],
}) => {
  if (post.postType === "blogpost") {
    const { title, author, createdAt, descr, image, slug, postType } = post;
    const size = "normal";
    const createdFormattedDate = moment(createdAt).format("DD/MM/YYYY");
    return (
      <div
        className={`nc-Card12 group relative flex flex-col  ${className}`}
        data-nc-id="Card12"
      >
        <Link
          //  "href": "/blog/lenovo-smarter-devices",
          to={`/blog/${slug}`}
          className="block flex-shrink-0 flex-grow relative w-full h-0 aspect-w-4 aspect-h-3 rounded-3xl overflow-hidden"
        >
          <NcImage
            containerClassName="absolute inset-0 rounded-3xl "
            className="object-contain h-full w-full rounded-3xl d"
            src={image}
            alt={title}
          />
          <span>
            <PostTypeFeaturedIcon
              className="absolute bottom-2 left-2"
              postType={"standard"}
              wrapSize="w-8 h-8"
              iconSize="w-4 h-4"
            />
          </span>
        </Link>

        {/* <SocialsShare className="absolute hidden md:grid gap-[5px] right-4 top-4 opacity-0 z-[-1] group-hover:z-10 group-hover:opacity-100 transition-all duration-300" /> */}

        <div className=" mt-8 pr-10 flex flex-col">
          <h2
            className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 transition-colors text-lg sm:text-2xl`}
          >
            <Link to={`/blog/${slug}`} className="line-clamp-2" title={title}>
              {title}
            </Link>
          </h2>
          <span className="hidden sm:block mt-4 text-neutral-500 dark:text-neutral-400">
            <span className="line-clamp-2"> {descr}</span>
          </span>
          <div
            className={`nc-PostCardMeta mt-2 inline-flex items-center fledx-wrap text-neutral-800 dark:text-neutral-200 ${
              size === "normal" ? "text-sm" : "text-base"
            } ${className}`}
            data-nc-id="PostCardMeta"
          >
            <span className="block  text-neutral-6000 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11"
              />{" "}
              {author}
            </span>

            <>
              <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
                ·
              </span>
              <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                {createdFormattedDate || ""}
              </span>
            </>
          </div>
        </div>
      </div>
    );
  } else {
    const { title, href, featuredImage, desc, postType } = post;

    return (
      <div
        className={`nc-Card12 group relative flex flex-col ${className}`}
        data-nc-id="Card12"
      >
        <Link
          to={href}
          className="block flex-shrink-0 flex-grow relative w-full h-0 aspect-w-4 aspect-h-3 rounded-3xl overflow-hidden"
        >
          <NcImage
            containerClassName="absolute inset-0"
            className="w-full h-full"
            src={featuredImage}
            alt={title}
          />
          <span>
            <PostTypeFeaturedIcon
              className="absolute bottom-2 left-2"
              postType={postType}
              wrapSize="w-8 h-8"
              iconSize="w-4 h-4"
            />
          </span>
        </Link>

        <SocialsShare className="absolute hidden md:grid gap-[5px] right-4 top-4 opacity-0 z-[-1] group-hover:z-10 group-hover:opacity-100 transition-all duration-300" />

        <div className=" mt-8 pr-10 flex flex-col">
          <h2
            className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 transition-colors text-lg sm:text-2xl`}
          >
            <Link to={href} className="line-clamp-2" title={title}>
              {title}
            </Link>
          </h2>
          <span className="hidden sm:block mt-4 text-neutral-500 dark:text-neutral-400">
            <span className="line-clamp-2"> {desc}</span>
          </span>
          <PostCardMeta className="mt-5" meta={post} />
        </div>
      </div>
    );
  }
};

export default Card12;

import { LocalStorageAdapter } from "LocalStorage/adapter/LocalStorageAdapter";
import { Order } from "containers/CheckOutPage/CheckOutPage";
import {
  DataDetailType,
  HowToGetThereCart,
  StayDetailType,
  WhatToDoCart,
  WhereToEatCart,
} from "data/types";
export interface FlightInfo {
  dTime: string;
  aTime: string;
  dString: string;
  aString: string;
  flyFrom: string;
  flyTo: string;
  airlineCode: string;
  airlineName: string;
  airlineLogo: string;
  duration: string;
}
export type HowToGetThereCartItem = {
  cart_type: "HOW_TO_GET_THERE";
  _id: string;
  value: {
    price: number;
    routeDestStructure: FlightInfo;
    routeRetStructure: FlightInfo;
  };
  offerCode:string;
  searchGuid:string;
  flightClass:string;
  termsAndConditions: {
    cancelationPolicy: string;
    notes: string;
  };
};

export type WhereToStayCartItem = {
  cart_type: "WHERE_TO_STAY";
  _id: string;
  inputs: {
    meals: string;
    cancelation: string;
    guests: number;
  };
  value: StayDetailType;
  cash_tax?:{value:number;
    currency:string;
    title:string;
  }
};

export type WhatToDoCartItem = {
  cart_type: "WHAT_TO_DO";
  _id: string;
  inputs: {
    timeslot: string;
    date: string;
    guests: number;
    options: {
      title: string;
      price?: number;
    }[];
  };
  value: WhatToDoCart;
};

export type WhereToEatCartItem = {
  cart_type: "WHERE_TO_EAT";
  _id: string;
  inputs: {
    timeslot: string;
    date: string;
    seating: string;
    guests: number;
  };
  value: WhereToEatCart;
};

export interface HowToMoveAroundCartItem {
  cart_type: "HOW_TO_MOVE_AROUND";
  _id: string;
  name: string;
  type: string;
  selectedOffers: {
    date?: string | "FIRST_DAY" | "LAST_DAY";
    price: number;
    type: string;
    quantity: number;
  }[];
  // maxOccupancy: number;
  // maxPeopleOnWheelchair: number;
  cardImg: string;
  termsAndConditions: {
    cancelationPolicy: string;
    notes: string;
  };
}

export interface WhatsExtraCartItem {
  cart_type: "WHATS_EXTRA";
  _id: string;
  name: string;
  type: string;
  selectedOffers: {
    date?: string | "FIRST_DAY" | "LAST_DAY";
    price: number;
    type: string;
    quantity: number;
  }[];
  cardImg: string;
  termsAndConditions: {
    cancelationPolicy: string;
    notes: string;
  };
}

export type CartItemType =
  | HowToGetThereCartItem
  | WhereToStayCartItem
  | WhatToDoCartItem
  | WhereToEatCartItem
  | HowToMoveAroundCartItem
  | WhatsExtraCartItem;

export type CartType = {
  howToGetThere: HowToGetThereCartItem | null;
  whereToStay: WhereToStayCartItem | null;
  whatToDo: WhatToDoCartItem[];
  whereToEat: WhereToEatCartItem[];
  howToMoveAround: HowToMoveAroundCartItem[];
  whatsExtra: WhatsExtraCartItem[];
  order: Order | null;
};

const initState: CartType = {
  howToGetThere: LocalStorageAdapter.get<HowToGetThereCartItem | null>(
    "howToGetThere",
    () => null
  ),
  whereToStay: LocalStorageAdapter.get<WhereToStayCartItem | null>(
    "whereToStay",
    () => null
  ),
  whatToDo: LocalStorageAdapter.get<WhatToDoCartItem[]>("whatToDo", () => []),
  whereToEat: LocalStorageAdapter.get<WhereToEatCartItem[]>(
    "whereToEat",
    () => []
  ),
  howToMoveAround: LocalStorageAdapter.get<HowToMoveAroundCartItem[]>(
    "howToMoveAround",
    () => []
  ),
  whatsExtra: LocalStorageAdapter.get<WhatsExtraCartItem[]>(
    "whatsExtra",
    () => []
  ),
  order: null,
};
const cartReducer = (
  state = initState,
  action: { type: string; payload: any; storeKey: string }
) => {
  switch (action.type) {
    case "ADD_HOW_TO_GET_THERE": {
      LocalStorageAdapter.set<HowToGetThereCartItem>("howToGetThere", {
        value: action.payload,
      });
      return { ...state, howToGetThere: action.payload };
    }

    case "REMOVE_HOW_TO_GET_THERE": {
      LocalStorageAdapter.set<null>("howToGetThere", { value: null });
      return { ...state, howToGetThere: null };
    }

    case "ADD_WHERE_TO_STAY": {
      LocalStorageAdapter.set<WhereToStayCartItem>("whereToStay", {
        value: action.payload,
      });
      return { ...state, whereToStay: action.payload };
    }

    case "REMOVE_WHERE_TO_STAY": {
      LocalStorageAdapter.set<null>("whereToStay", { value: null });
      return { ...state, whereToStay: null };
    }
    case "ADD_WHAT_TO_DO": {
      LocalStorageAdapter.set<WhatToDoCartItem[]>("whatToDo", {
        value: [...state.whatToDo, action.payload],
      });
      return {
        ...state,
        whatToDo: [...state.whatToDo, action.payload],
      };
    }
    case "REMOVE_WHAT_TO_DO": {
      LocalStorageAdapter.set<WhatToDoCartItem[]>("whatToDo", {
        value: state.whatToDo.filter(
          (item: any) => item._id !== action.payload
        ),
      });
      return {
        ...state,
        whatToDo: state.whatToDo.filter(
          (item: any) => item._id !== action.payload
        ),
      };
    }
    case "ADD_WHAT_TO_EAT": {
      LocalStorageAdapter.set<WhereToEatCartItem[]>("whereToEat", {
        value: [...state.whereToEat, action.payload],
      });
      return {
        ...state,
        whereToEat: [...state.whereToEat, action.payload],
      };
    }
    case "REMOVE_WHAT_TO_EAT": {
      LocalStorageAdapter.set<WhereToEatCartItem[]>("whereToEat", {
        value: state.whereToEat.filter(
          (item: any) => item._id !== action.payload
        ),
      });
      return {
        ...state,
        whereToEat: state.whereToEat.filter(
          (item: any) => item._id !== action.payload
        ),
      };
    }
    case "ADD_HOW_TO_MOVE": {
      LocalStorageAdapter.set<HowToMoveAroundCartItem[]>("howToMoveAround", {
        value: [...state.howToMoveAround, action.payload],
      });
      return {
        ...state,
        howToMoveAround: [...state.howToMoveAround, action.payload],
      };
    }
    case "REMOVE_HOW_TO_MOVE": {
      LocalStorageAdapter.set<HowToMoveAroundCartItem[]>("howToMoveAround", {
        value: state.howToMoveAround.filter(
          (item: any) => item._id !== action.payload
        ),
      });
      return {
        ...state,
        howToMoveAround: state.howToMoveAround.filter(
          (item: any) => item._id !== action.payload
        ),
      };
    }
    case "ADD_WHATS_EXTRA": {
      LocalStorageAdapter.set<WhatsExtraCartItem[]>("whatsExtra", {
        value: [...state.whatsExtra, action.payload],
      });
      return {
        ...state,
        whatsExtra: [...state.whatsExtra, action.payload],
      };
    }
    case "REMOVE_WHATS_EXTRA": {
      LocalStorageAdapter.set<WhatsExtraCartItem[]>("whatsExtra", {
        value: state.whatsExtra.filter(
          (item: any) => item._id !== action.payload
        ),
      });
      return {
        ...state,
        whatsExtra: state.whatsExtra.filter(
          (item: any) => item._id !== action.payload
        ),
      };
    }
    case "ADD_ORDER":
      return { ...state, order: action.payload };
    // other cases
    case "ADD_TO_CART":
      return {
        ...state,
        [action.storeKey]: [
          ...(state[action.storeKey as keyof typeof state] as []),
          action.payload,
        ],
      };
    case "REMOVE_FROM_CART":
      const currentStateArr: DataDetailType[] = [
        ...(state[action.storeKey as keyof typeof state] as []),
      ];
      const currentStateArrIds: number[] = currentStateArr.map(
        (item) => item.id
      );
      const index = currentStateArrIds.indexOf(action.payload.id);
      if (index > -1) {
        currentStateArr.splice(index, 1);
      }
      return { ...state, [action.storeKey]: [...currentStateArr] };

    case "RESET_CART": {
      localStorage.removeItem("howToGetThere");
      localStorage.removeItem("whereToStay");
      localStorage.removeItem("whereToEat");
      localStorage.removeItem("howToMoveAround");
      localStorage.removeItem("whatToDo");
      localStorage.removeItem("whatsExtra");

      return {
        howToGetThere: null,
        whereToStay: null,
        whatToDo: [],
        whereToEat: [],
        howToMoveAround: [],
        whatsExtra: [],
        order: null,
      } as CartType;
    }
    default:
      return state;
  }
};
export default cartReducer;

import {DoActionTypes, DoAction} from './../actions/do'


export interface DoState {
  do: any[];
}

const initialState: DoState = {
  do: [],
};

const doReducer = (state = initialState, action: DoAction) => {
  switch (action.type) {
    case DoActionTypes.SET_DO:
      return {
        ...state,
        do: action.payload,
      };
    default:
      return state;
  }
};

export default doReducer;
import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { FC } from "react";
import ClearDataButton from "./ClearDataButton";
import ButtonSubmit from "./ButtonSubmit";
import { PlaceGuestsObject } from "components/HeroSearchForm2Mobile/PlaceGuestsInput";
import { PathName } from "routers/types";

export interface GuestsInputProps {
  title?: string;
  guests: PlaceGuestsObject,
  setGuests: React.Dispatch<React.SetStateAction<PlaceGuestsObject>>,
  onChange?: (data: PlaceGuestsObject) => void;
  fieldClassName?: string;
  className?: string;
  buttonSubmitHref?: PathName;
  hasButtonSubmit?: boolean;
  isDisabled?: boolean;
}

const GuestsInput: FC<GuestsInputProps> = ({
  title = "Guests",
  guests,
  onChange,
  setGuests,
  fieldClassName = "[ nc-hero-field-padding ]",
  className = "[ nc-flex-1 ]",
  buttonSubmitHref = "/listing-stay-map",
  hasButtonSubmit = true,
  isDisabled = false,
}) => {
  const { guestAdults, guestWheelchair } = guests;
  const totalGuests = (guestAdults !== undefined && guestWheelchair !== undefined) ? (guestAdults + guestWheelchair) : 1;
  const renderAdultsIcon = () => {
    return (
      // <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
      //   <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
      // </svg>
      <svg
        viewBox="0 0 16 16"
        fill="currentColor"
        height="1.5rem"
        width="1.5rem"
      >
        <path
          fillRule="evenodd"
          d="M10.5 5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0zm.061 3.073a4 4 0 10-5.123 0 6.004 6.004 0 00-3.431 5.142.75.75 0 001.498.07 4.5 4.5 0 018.99 0 .75.75 0 101.498-.07 6.005 6.005 0 00-3.432-5.142z"
        />
      </svg>
    );
  };

  const renderWheelchairIcon = () => {
    return (
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        height="1.7rem"
        width="1.7rem"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M8 10.341v2.194A3.999 3.999 0 1013.465 18h2.193A6.002 6.002 0 014 16a6.003 6.003 0 014-5.659zM12 17a3 3 0 01-3-3v-4c0-1.044.534-1.964 1.343-2.501a3 3 0 113.314.002A2.99 2.99 0 0115 10v4.999l1.434.001a2 2 0 011.626.836l.089.135 2.708 4.515-1.714 1.028L16.433 17 15 16.999 12 17zm0-8a1 1 0 00-1 1v4a1 1 0 001 1h.999L13 10a1 1 0 00-1-1zm0-5a1 1 0 100 2 1 1 0 000-2z" />
      </svg>
    );
  };



  return (
    <Popover className={`flex relative ${className}`}>
      {({ open }) => (
        <>
          <div
            className={`flex-1 flex items-center focus:outline-none cursor-pointer ${open ? "nc-hero-field-focused" : ""
              }`}
          >
            <Popover.Button
              className={`flex-1 flex text-left items-center ${fieldClassName} space-x-3 `}
              onClick={() => document.querySelector("html")?.click()}
            >
              <div className="text-neutral-300 dark:text-neutral-400 text-4xl">
                <i className="la la-user-plus"></i>
              </div>
              <div className="flex-grow">
                <span className="block xl:text-lg font-semibold">
                  {totalGuests}
                </span>
                <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                  {title}
                </span>
              </div>
            </Popover.Button>

            <div className="relative">
              {!!totalGuests && open && !isDisabled && (
                <ClearDataButton
                  onClick={() => {
                    setGuests({
                      guestAdults: 0,
                      guestChildren: 0,
                      guestInfants: 0,
                      guestWheelchair: 0
                    })
                  }}
                />
              )}
            </div>

            {/* BUTTON SUBMIT OF FORM */}
            {hasButtonSubmit && (
              <div className="pr-2 xl:pr-4">
                <ButtonSubmit href={buttonSubmitHref} />
              </div>
            )}
          </div>
          {!isDisabled && (
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 w-full max-w-sm bg-white 
              dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-5 rounded-3xl shadow-xl flex flex-col">
                <NcInputNumber
                  className="w-full"
                  defaultValue={guestWheelchair}
                  onChange={(value) =>
                    setGuests(prev => ({ ...prev, guestWheelchair: value }))
                  }
                  min={1}
                  max={2 - (guests.guestAdults || 0)}
                  icon={renderWheelchairIcon()}
                // desc="Ages 2–12"
                />
                <NcInputNumber
                  className="w-full"
                  defaultValue={guestAdults}
                  onChange={(value) => setGuests(prev => ({ ...prev, guestAdults: value }))}
                  min={0}
                  max={2 - (guests.guestWheelchair || 0)}
                  icon={renderAdultsIcon()}
                // desc="Ages 13 or above"
                />
              </Popover.Panel>
            </Transition>
          )}
        </>
      )}
    </Popover>
  );
};

export default GuestsInput;

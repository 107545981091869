export interface DarkModeState {
  isDarkMode: boolean;
  isLightMode: boolean;
}

const initState = {
    isDarkMode: null,
    isLightMode: null,
}

const isDarkModeReducer = (state=initState, action: { type: string; payload: any; storeKey: string }) => {
  switch (action.type) {
    case 'DARK':
      return {
        ...state,
        isDarkMode: true,
        isLightMode: false,
      };
    case 'LIGHT':
      return {
        ...state,
        isDarkMode: false,
        isLightMode: true,
      };
    default:
      return state;
  }
}
export default isDarkModeReducer;
import { FC } from "react";
import FlightCard from "components/FlightCard/FlightCard";
import { useSelector } from "react-redux";
import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import { TextBlock } from "react-placeholder/lib/placeholders";

export interface SectionGridFilterCardProps {
  className?: string;
  isEmpty?: any;
  flights?: any;
  isLoading?: any;
  airlines?: any;
  travelAirports?: any;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  // isEmpty,
  flights,
  isLoading,
  airlines,
  travelAirports,
}) => {
  const selectedFlightId = useSelector(
    (state: any) => state.cart.howToGetThere?._id
  );

  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;

  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";

  const textRowsPlaceholder = (
    <div className="text-3-placeholder">
      <TextBlock color={placeholderColor} rows={5} />
    </div>
  );

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {flights.length > 0 && (
        <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
          {flights.map((item: any) => (
            <ReactPlaceholder
              key={item.id}
              ready={!isLoading}
              customPlaceholder={textRowsPlaceholder}
              showLoadingAnimation
            >
              <FlightCard
                key={item.id}
                data={item}
                airlines={airlines}
                travelAirports={travelAirports}
                isOnCart={selectedFlightId === item.id}
                isLoading={isLoading}
              />
            </ReactPlaceholder>
          ))}
        </div>
      )}

      {flights.length === 0 && (
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={textRowsPlaceholder}
          showLoadingAnimation
        >
          <div className="grid md:grid-cols-1 gap-6 lg:grid-cols-1 xl:gap-8 mb-4">
            <div className="flex flex-row p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
              <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                Sorry, no direct flights to displayed for your convenience.
              </span>
            </div>
          </div>
        </ReactPlaceholder>
      )}
    </div>
  );
};

export default SectionGridFilterCard;

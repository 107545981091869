import { DateRage } from "components/HeroSearchForm/StaySearchForm"
import { CityInfo } from "services/api"


export const addTravelingFrom = (item: CityInfo) => {
    return {
        type: 'ADD_TRAVELING_FROM',
        payload: item
    }
}

export const addTravelingTo = (item: CityInfo) => {
    return {
        type: 'ADD_TRAVELING_TO',
        payload: item
    }
}

export const addDate = (date: DateRage) => {
    return {
        type: 'ADD_DATE',
        payload: date,
    }
}

export const addGuests = (guests: any) => {
    return {
        type: 'ADD_GUESTS',
        payload: guests,
    }
}

export const resetSearch = () => {
    return {
        type: 'RESET_SEARCH',
    }
}
import { FC } from "react";
import { Fragment, useState } from "react";
import { Listbox, Popover, Switch, Transition } from "@headlessui/react";
import { euro } from "utils/currency";

import { IOffer, ITransportation } from "services/models";
import { useSelector } from "react-redux";
import { Moment } from "moment";
import moment from "moment";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { IOfferCheckOptions } from "./WhatsExtraDetailPage";
import OffersListBox from "./OffersListBox";

export type OffersByDate = {
  [date: string]: IOfferCheckOptions[];
};

type ServicePerDateSlotsInputProps = {
  offers: OffersByDate;
  handleChange: (date: string, offer: IOffer | null) => void;
  handleFocus?: () => void;
};

const ServicePerDateSlotsInput: FC<ServicePerDateSlotsInputProps> = ({
  offers,
  handleChange,
  handleFocus,
}) => {
  const handleClick = () => {
    document.querySelector("html")?.click();
    handleFocus && handleFocus();
  };
  return (
    <div className="flex flex-1">
      <Popover className={`flex relative h-full`}>
        {({ open, close }) => (
          <>
            <div
              className={`flex-1 flex items-center focus:outline-none cursor-pointer ${
                open ? "nc-hero-field-focused" : ""
              }`}
            >
              <Popover.Button
                className={`flex-1 flex text-left items-center p-4 w-[179px] space-x-3 `}
                onClick={handleClick}
              >
                <div className="text-neutral-300 dark:text-neutral-400">
                  <div className="text-4xl">
                    <i className="las la-check-circle"></i>
                  </div>
                </div>
                <div className="flex-grow">
                  <span className="block xl:text-lg font-semibold">
                    Service
                  </span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    Options
                  </span>
                </div>
              </Popover.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                className="absolute right-0 z-10 w-[350px] max-w-sm bg-white dark:bg-neutral-800 
              top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl h-112 overflow-y-scroll disable-scroll"
              >
                <div className="">
                  <div className=" flex flex-col justify-center px-5 py-6 space-y-3">
                    {Object.entries(offers).map(([date, offers]) => (
                      <div key={date} className="flex flex-row">
                        <div className="flex-1 flex items-center py-3 pr-2">
                          {moment(date).format("DD/MM/YYYY")}
                        </div>
                        <div className="flex-1">
                          <OffersListBox
                            offers={offers}
                            handleChange={(selectedOffer) =>
                              handleChange(date, selectedOffer)
                            }
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ServicePerDateSlotsInput;

/* eslint-disable eqeqeq */
import { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import GoogleMapReact from "google-map-react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import { useParams } from "react-router-dom";
import { StayDetailType } from "data/types";
import Stars from "components/Stars/Stars";
import TripadvisorStars from "components/Stars/TripadvisorStars";
import { useDispatch, useSelector } from "react-redux";
import { addWhereToStay, removeWhereToStay } from "store/actions/cart";
import { euro } from "utils/currency";
import CancellationInput from "components/HeroSearchForm/CancellationInput";
import ExperiencesMealInput from "components/HeroSearchForm/ExperiencesMealInput";
import { getHotelData, retrieveHotels } from "services/api";
import { setStay } from "./../../../store/actions/stay";
import sanitizeHtml from "sanitize-html";
import { set, toInteger } from "lodash";
import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
// import { addToCart, removeFromCart } from "store/actions/cart";
import {
  TextBlock,
  RectShape,
  RoundShape,
} from "react-placeholder/lib/placeholders";
import { GOOGLE_MAPS_KEY } from "config/environment";
import TermsAndConditionsPage from "containers/StaticPages/TermsAndConditionsPage";
import TermsAndConditionsSection from "components/CancelationPolicy/TermsAndConditionsSection";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import MinusIcon from "svg/MinusIcon";
import PlusIcon from "svg/PlusIcon";
import { setHotels } from "store/actions/hotels";
import DetailsImageGallery from "components/DetailsImageGallery/DetailsImageGallery";
import AddToCartModal from "components/AddToCartModal/AddToCartModal";

export interface WhereToStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}
interface TotalCashTaxes {
  value: number;
  currency: string;
  title: string;
}

const WhereToStayDetailPage: FC<WhereToStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const { isMobile } = useWindowDimensions();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  const startDate = useSelector((state: any) => state.search.dates.startDate);
  const endDate = useSelector((state: any) => state.search.dates.endDate);
  const guestsCount = useSelector(
    (state: any) => state.search.guests.guestAdults
  );
  const guestWheelchairCount = useSelector(
    (state: any) => state.search.guests.guestWheelchair
  );
  const stay = useSelector((state: any) => state.stay.stay);
  const currentUserState = (state: any) => state.auth;
  const auth = useSelector(currentUserState);

  const stayCurrentPrice = useSelector(
    (state: any) => state.stayCurrentPrice.stayCurrentPrice
  );
  const stayCurrentCharges = useSelector(
    (state: any) => state.stayCurrentCharges.stayCurrentCharges
  );

  const stayCurrentTotalPrice = useSelector(
    (state: any) => state.stayCurrentTotalPrice.stayCurrentTotalPrice
  );
  const stayCurrentTotalPriceWithCharges = useSelector(
    (state: any) =>
      state.stayCurrentTotalPriceWithCharges.stayCurrentTotalPriceWithCharges
  );
  const travelDates: any = {
    startDate: startDate,
    endDate: endDate,
  };

  const [selectedMeals, setSelectedMeals] = useState<string>("No meals");
  const [selectedCancelation, setSelectedCancelation] =
    useState<string>("Non refundable");
  const destination = useSelector((state: any) => state.search.travelingTo);

  const rawStartDate = travelDates.startDate;
  const rawEndDate = travelDates.endDate;
  const parsedStartDate = new Date(rawStartDate);
  const parsedEndDate = new Date(rawEndDate);
  const timeDifference = parsedEndDate.getTime() - parsedStartDate.getTime();
  const numberOfNights = Math.ceil(timeDifference / (1000 * 3600 * 24));

  const [showAddToCartModal, setShowAddToCartModal] = useState(false);
  // const [hotel, setHotel] = useState<StayDetailType>();

  let params = useParams();
  const selectedStayId = useSelector(
    (state: any) => state.cart.whereToStay?._id
  );
  const hotels = useSelector((state: any) => state.hotels.hotels);

  const [hotel, setHotel] = useState<StayDetailType | null>(null);

  useEffect(() => {
    const fetchHotelData = async () => {
      try {
        if (!hotels || hotels.length === 0) {
          const data = await retrieveHotels(destination, travelDates, {
            guestAdults: guestsCount,
            guestWheelchair: guestWheelchairCount,
          });
          const { hotels } = data;
          const foundedHotel = hotels.find(
            (tempHotel: any) => tempHotel._id === params.id
          );
          if (foundedHotel) {
            setHotel(foundedHotel);
            dispatch(setStay(foundedHotel.results));
            setIsLoading(false);
            setIsDataLoading(false);
          }
          dispatch(setHotels(hotels));
        } else {
          const foundedHotel = hotels.find(
            (tempHotel: any) => tempHotel._id === params.id
          );
          if (foundedHotel) {
            setHotel(foundedHotel);
            dispatch(setStay(foundedHotel.results));
            setIsLoading(false);
            setIsDataLoading(false);
          }
        }
      } catch (error) {
        console.error("Error fetching hotel data:", error);
        // Handle errors appropriately, such as displaying an error message to the user
        setIsLoading(false);
        setIsDataLoading(false);
      }
    };

    fetchHotelData();
  }, [
    hotels,
    destination,
    travelDates,
    guestsCount,
    guestWheelchairCount,
    params.id,
    dispatch,
  ]);

  const { rating, results } = hotel || {};
  console.log("Rating", rating, hotel);

  const {
    _id,
    // _id,
    type,
    name,
    stars,
    price,
    // address,
    fullAddress,
    ecoFriendly,
    pricePer,
    href,
    lhref,
    overview,
    propertyFeatures,
    roomFeatures,
    detailsImgs,
    map,
    checkIn,
    checkOut,
    notes,
    whatsAround,
    thingsToKnow,
    room_surface,
    client_room_name,
    cash_taxes,
    isAvailable,
  } = hotel || {};

  const isOnCart = selectedStayId === _id;

  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  let [isOpenModalVirtualRoom, setIsOpenModalVirtualRoom] = useState(false);
  const [totalCashTaxesValue, setTotalCashTaxesValue] = useState(0);
  const [totalTaxesValue, setTotalTaxesValue] = useState(0);
  const [totalCashTaxesTitle, setTotalCashTaxesTitle] = useState("");
  const [totalCashTaxesCurrency, setTotalCashTaxesCurrency] = useState("");
  const useSmoothScroll = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scrollPercentage =
          (window.pageYOffset / window.innerHeight) * 100;
        setIsScrolled(scrollPercentage > 65);

        // Scroll to a specific element or position
        const targetElement = document.getElementById("targetElementId");
        targetElement?.scrollIntoView({
          behavior: "smooth",
        });
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return isScrolled;
  };

  useEffect(() => {
    let cash_tax = calculateCashTaxes();
    setTotalCashTaxesValue(cash_tax.value);
    setTotalCashTaxesCurrency(cash_tax.currency);
    setTotalCashTaxesTitle(cash_tax.title);
    setTotalTaxesValue(cash_tax.value + stayCurrentCharges);
  }, [hotel]);

  const isScrolled = useSmoothScroll();

  // placeholders...
  // let placeholderColor = isDarkMode ? "#CCCCCC" : "#E0E0E0";
  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";
  const ratePlaceholder = (
    <div className="rate-placeholder">
      <RectShape style={{ width: 150, height: 30 }} color={placeholderColor} />
    </div>
  );
  const pricePlaceholder = (
    <div className="price-placeholder">
      <TextBlock color={placeholderColor} rows={2} />
    </div>
  );

  const btnPlaceholder = (
    <div className="Btn-placeholder">
      <RoundShape style={{ width: 360, height: 50 }} color={placeholderColor} />
    </div>
  );

  const sideboxesPlaceholder = (
    <div className="sidebars-placeholder">
      <RoundShape style={{ width: 375, height: 80 }} color={placeholderColor} />
    </div>
  );

  const galleryPlaceholder = (
    <div className="gallery-placeholder">
      <RectShape
        style={{ width: 1000, height: 590 }}
        color={placeholderColor}
      />
    </div>
  );

  const subGalleryPlaceholder = (
    <div className="subGallery-placeholder">
      <RectShape style={{ width: 500, height: 290 }} color={placeholderColor} />
    </div>
  );

  const mapPlaceholder = (
    <div className="map-placeholder">
      <RectShape style={{ width: 725, height: 400 }} color={placeholderColor} />
    </div>
  );

  const showAllPhotosPlaceholder = (
    <div className="showAllPhotos-placeholder">
      <RectShape style={{ width: 50, height: 20 }} color={placeholderColor} />
    </div>
  );

  const textOneRowPlaceholder = (
    <div className="price-placeholder">
      <TextBlock color={placeholderColor} rows={1} />
    </div>
  );

  const textTwoRowsPlaceholder = (
    <div className="text-2-placeholder">
      <TextBlock color={placeholderColor} rows={2} />
    </div>
  );

  const textThreeRowsPlaceholder = (
    <div className="text-3-placeholder">
      <TextBlock color={placeholderColor} rows={3} />
    </div>
  );

  const textFourRowsPlaceholder = (
    <div className="text-4-placeholder">
      <TextBlock color={placeholderColor} rows={4} />
    </div>
  );

  const textFiveRowsPlaceholder = (
    <div className="text-5-placeholder">
      <TextBlock color={placeholderColor} rows={5} />
    </div>
  );

  const textTenRowsPlaceholder = (
    <div className="text-10-placeholder">
      <TextBlock color={placeholderColor} rows={10} />
    </div>
  );

  const textTwentyRowsPlaceholder = (
    <div className="text-20-placeholder">
      <TextBlock color={placeholderColor} rows={20} />
    </div>
  );

  const calculateCashTaxes = () => {
    let totalValue = 0;
    const titlesSet = new Set<string>();
    let currency = "";

    for (const tax of hotel?.cash_taxes || []) {
      const taxStartDate = new Date(tax.from_date);
      const taxEndDate = new Date(tax.to_date);
      for (let d = 0; d < numberOfNights; d++) {
        const currentDate = new Date(parsedStartDate);
        currentDate.setDate(currentDate.getDate() + d);
        if (currentDate >= taxStartDate && currentDate <= taxEndDate) {
          totalValue += tax.value;
          titlesSet.add(tax.title);
          if (!currency) {
            currency = tax.currency;
          }
        }
      }
    }

    const titlesArray = Array.from(titlesSet);
    const title =
      titlesArray.length > 1
        ? titlesArray.slice(0, -1).join(", ") +
          " and " +
          titlesArray[titlesArray.length - 1]
        : titlesArray[0] || "";

    return {
      value: totalValue,
      currency: currency,
      title: title,
    };
  };

  const getCancellationPolicy = () => {
    console.log(stayCurrentTotalPriceWithCharges);
    let checkedObject = null;
    if (stay) {
      checkedObject = stay.find(
        (item: any) => item.totalPrice === stayCurrentTotalPriceWithCharges
      );
    }

    if (checkedObject && checkedObject.cancellation_policy) {
      const sanitizedHtml = sanitizeHtml(checkedObject.cancellation_policy);
      return sanitizedHtml;
    } else {
      return "";
    }
  };

  const addToCart = () => {
    if (isOnCart) {
      dispatch(removeWhereToStay());
    } else {
      if (hotel) {
        var stayCashTaxes: TotalCashTaxes = calculateCashTaxes();
        console.log(stayCashTaxes, "staycashatx");
        dispatch(
          addWhereToStay({
            _id: hotel._id!,
            cart_type: "WHERE_TO_STAY",
            inputs: {
              meals: selectedMeals,
              cancelation: selectedCancelation,
              guests: (guestsCount || 0) + (guestWheelchairCount || 0),
            },
            value: hotel,
            cash_tax: stayCashTaxes,
          })
        );
        if (localStorage.getItem("disableNotification") !== "true") {
          setShowAddToCartModal(true);
        }
      }
    }
  };

  // const currentPage = window.location.pathname.split("/")[1];
  // const selectedIds: number[] = useSelector((state: any) =>
  //   state.cart[StateNames[currentPage as keyof typeof StateNames]].map(
  //     (item: any) => item.id
  //   )
  // );

  const roomSurfaceInfo = (surfaceInSquareMeters: number) => {
    let surfaceInSquareFeet = surfaceInSquareMeters * 10.7639;
    return `${surfaceInSquareMeters} sq.m. / ${surfaceInSquareFeet.toFixed(
      0
    )} sq.ft.`;
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function closeModalVirtualRoom() {
    setIsOpenModalVirtualRoom(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  function openModalVirtualRoom() {
    setIsOpenModalVirtualRoom(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  // info
  const renderSection1 = () => {
    return (
      // <div className="listingSection__wrap !space-y-6">
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}

        <span>
          <div className="flex justify-between items-center">
            <ReactPlaceholder
              ready={!isDataLoading}
              customPlaceholder={textOneRowPlaceholder}
              showLoadingAnimation
            >
              <div className="flex flex-row">
                <span className="mr-1">{type} </span>
                {!!stars && type === "Hotel" && <Stars stars={stars} />}
              </div>
            </ReactPlaceholder>
            <div className="flex items-center justify-end">
              <ReactPlaceholder
                ready={!isLoading}
                customPlaceholder={ratePlaceholder}
                showLoadingAnimation
              >
                <div>
                  {rating && (
                    <div className="flex items-center justify-end">
                      <div className="bg-[#35e0a1] rounded-full h-8 w-8 flex items-center justify-center mr-4">
                        <i className="lab la-tripadvisor text-black text-2xl"></i>
                      </div>
                      <div>
                        {rating ? (
                          <TripadvisorStars
                            stars={rating}
                            key={`hotel-stars`}
                          />
                        ) : null}
                      </div>
                    </div>
                  )}
                </div>{" "}
              </ReactPlaceholder>
            </div>
          </div>
        </span>

        {/* 2 */}
        <ReactPlaceholder
          ready={!isDataLoading}
          customPlaceholder={textThreeRowsPlaceholder}
          showLoadingAnimation
        >
          <div className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {name} <br />
            {room_surface && client_room_name && (
              <div className="text-lg font-normal">
                <div className="flex justify-between items-center w-full">
                  <span className="text-lg">
                    {client_room_name} &nbsp;
                    <span className="text-base italic">
                      ({roomSurfaceInfo(toInteger(room_surface))})
                    </span>
                  </span>{" "}
                </div>
              </div>
            )}
          </div>
        </ReactPlaceholder>
        {/* 3 */}
        {/* {room_surface && client_room_name && (
          <div className="">
            <div className="flex justify-between items-center w-full">
              <span className="text-lg">
                {client_room_name} &nbsp;
                <span className="text-base italic">
                  ({roomSurfaceInfo(toInteger(room_surface))})
                </span>
              </span>{" "}
            </div>
          </div>
        )} */}
        <ReactPlaceholder
          ready={!isDataLoading}
          customPlaceholder={textOneRowPlaceholder}
          showLoadingAnimation
        >
          <div className="flex items-center space-x-4">
            <span className="flex-grow">
              <i className="las la-map-marker-alt"></i>
              <span className="ml-1">{fullAddress}</span>
            </span>
            {ecoFriendly && ecoFriendly == true ? (
              <span className="text-eco-friendly-green font-bold text-3xl">
                <i className="las la-leaf"></i>
              </span>
            ) : null}
          </div>
        </ReactPlaceholder>
      </div>
    );
  };

  // overview
  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Overview</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {overview && (
          <>
            <ReactPlaceholder
              ready={!isDataLoading}
              customPlaceholder={textTenRowsPlaceholder}
              showLoadingAnimation
            >
              <div className="text-neutral-6000 dark:text-neutral-300 prose">
                <p
                  className="list-disc"
                  dangerouslySetInnerHTML={{ __html: overview }}
                />
              </div>
            </ReactPlaceholder>
          </>
        )}
      </div>
    );
  };

  const renderPropertyFeatures = () => {
    const { firstSection, secondSection } = propertyFeatures || {};
    return (
      <div className="listingSection__wrap mt-5">
        <h2 className="text-2xl font-semibold">Property Features</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {Array.isArray(firstSection) && firstSection.length > 0 ? (
          <>
            <ReactPlaceholder
              ready={!isDataLoading}
              customPlaceholder={textFiveRowsPlaceholder}
              showLoadingAnimation
            >
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
                {firstSection &&
                  firstSection
                    .filter((_, i) => i < 50)
                    .map((item, index) => (
                      <div
                        key={`${item.name}-${index}`}
                        className="flex items-center space-x-3"
                      >
                        <i className={`text-3xl las ${item.icon}`}></i>
                        <span className=" ">{item.name}</span>
                      </div>
                    ))}
              </div>
            </ReactPlaceholder>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          </>
        ) : null}
        {Array.isArray(secondSection) && secondSection.length > 0 ? (
          <ReactPlaceholder
            ready={!isDataLoading}
            customPlaceholder={textFiveRowsPlaceholder}
            showLoadingAnimation
          >
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
              {secondSection &&
                secondSection
                  .filter((_, i) => i < 50)
                  .map((item, index) => (
                    <div
                      key={`${item.name}-${index}`}
                      className="flex items-center space-x-3"
                    >
                      <i className={`text-3xl las ${item.icon}`}></i>
                      <span className=" ">{item.name}</span>
                    </div>
                  ))}
            </div>
          </ReactPlaceholder>
        ) : null}
      </div>
    );
  };

  const renderRoomFeatures = () => {
    const { firstSection, secondSection } = roomFeatures || {};
    return (
      <div className="listingSection__wrap mt-5">
        <h2 className="text-2xl font-semibold">Accessible Room Features</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {Array.isArray(firstSection) && firstSection.length > 0 ? (
          <>
            <ReactPlaceholder
              ready={!isDataLoading}
              customPlaceholder={textFiveRowsPlaceholder}
              showLoadingAnimation
            >
              <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
                {firstSection &&
                  firstSection
                    .filter((_, i) => i < 50)
                    .map((item, index) => (
                      <div
                        key={`${item.name}-${index}`}
                        className="flex items-center space-x-3"
                      >
                        <i className={`text-3xl las ${item.icon}`}></i>
                        <span className=" ">{item.name}</span>
                      </div>
                    ))}
              </div>
            </ReactPlaceholder>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
          </>
        ) : null}
        {Array.isArray(secondSection) && secondSection.length > 0 ? (
          <ReactPlaceholder
            ready={!isDataLoading}
            customPlaceholder={textFiveRowsPlaceholder}
            showLoadingAnimation
          >
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
              {secondSection &&
                secondSection
                  .filter((_, i) => i < 50)
                  .map((item, index) => (
                    <div
                      key={`${item.name}-${index}`}
                      className="flex items-center space-x-3"
                    >
                      <i className={`text-3xl las ${item.icon}`}></i>
                      <span className=" ">{item.name}</span>
                    </div>
                  ))}
            </div>
          </ReactPlaceholder>
        ) : null}
      </div>
    );
  };

  // accessibilityFeatures
  const renderSection3 = () => {
    return (
      <>
        <div>
          {propertyFeatures &&
          propertyFeatures?.firstSection?.length > 0 &&
          propertyFeatures?.secondSection?.length > 0
            ? renderPropertyFeatures()
            : null}
          {roomFeatures &&
          roomFeatures?.firstSection?.length > 0 &&
          roomFeatures?.secondSection?.length > 0
            ? renderRoomFeatures()
            : null}
        </div>
      </>
    );
  };

  // map
  const renderSection7 = () => {
    if (typeof map === "undefined") {
      return false;
    }

    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <ReactPlaceholder
            ready={!isDataLoading}
            customPlaceholder={textOneRowPlaceholder}
            showLoadingAnimation
          >
            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
              {/* {address} - {city}, {country} */}
              {fullAddress}
            </span>
          </ReactPlaceholder>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <ReactPlaceholder
            ready={!isDataLoading}
            customPlaceholder={mapPlaceholder}
            showLoadingAnimation
          >
            <div className="rounded-xl overflow-hidden">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: GOOGLE_MAPS_KEY,
                }}
                yesIWantToUseGoogleMapApiInternals
                defaultZoom={15}
                defaultCenter={{
                  lat: map.lat,
                  lng: map.lng,
                }}
              >
                <LocationMarker lat={map.lat} lng={map.lng} />
              </GoogleMapReact>
            </div>
          </ReactPlaceholder>
        </div>
      </div>
    );
  };

  const termsAndConditions = () => {
    return;
  };

  // terms and conditions | notes | etc
  const renderSection8 = () => {
    return (
      <TermsAndConditionsSection
        isLoading={isLoading}
        cancelationPolicy={getCancellationPolicy()}
        check={{
          in: checkIn,
          out: checkOut,
        }}
        notes={notes}
        thingsToKnow={thingsToKnow}
      />
    );
  };

  // whatsAround
  const renderSection9 = () => {
    const regex = /([\d.]+)\s*km/;

    const parseStringToNumber = (str: string) => {
      const number = str.match(regex);
      if (number && number.length > 1) {
        return parseFloat(number[1]);
      }
    };
    let whatsAroundTemp = whatsAround;
    whatsAroundTemp = whatsAroundTemp?.map((item) => ({
      ...item,
      number: parseStringToNumber(item.distance),
    }));
    whatsAroundTemp = whatsAroundTemp?.sort(
      (a, b) => (a?.number || 0) - (b?.number || 0)
    );

    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">What's around</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            All distances are calculated with Google Maps.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <ReactPlaceholder
            ready={!isDataLoading}
            customPlaceholder={textTwentyRowsPlaceholder}
            showLoadingAnimation
          >
            <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
              {whatsAroundTemp &&
                whatsAroundTemp
                  .filter((_item: any, i: number) => i < 1212)
                  .map((item: any, i: number) => (
                    <div key={`whatsAround-${i}`}>
                      <div
                        className={
                          i % 2 === 0
                            ? "p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg"
                            : "p-4  flex justify-between items-center space-x-4 rounded-lg"
                        }
                      >
                        <span>
                          {" "}
                          <i
                            className={`pr-3 mb-1 text-2xl las ${item.icon}`}
                          ></i>{" "}
                          {item.name}
                        </span>
                        <span>{item.distance}</span>
                      </div>
                    </div>
                  ))}
            </div>
          </ReactPlaceholder>
        </div>
      </div>
    );
  };

  const renderMotalVirtualRoom = () => {
    return (
      <Transition appear show={isOpenModalVirtualRoom} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-4 h-screen w-full">
                <div className="inline-flex  flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-6 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      {" "}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalVirtualRoom} />
                    </span>
                  </div>
                  <div className="h-full overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <iframe
                      data-hj-allow-iframe
                      title="VR_Rom"
                      src={auth.isAuthenticated ? lhref : href}
                      className={"w-full h-full"}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={pricePlaceholder}
          showLoadingAnimation
        >
          <div className="flex justify-between">
            <div>
              {price && results && !results[0]?.no_availability ? (
                <span>
                  {!stayCurrentPrice ? (
                    <span className="text-3xl font-semibold">
                      No Availability
                    </span>
                  ) : (
                    <span className="text-3xl font-semibold">
                      {/* {euro.format(stayCurrentPrice)} */}
                    </span>
                  )}
                </span>
              ) : (
                <span className="text-3xl font-semibold">No Availability</span>
              )}
            </div>
          </div>
        </ReactPlaceholder>

        {/* ROOM Virtual Tour */}
        {!!href && (
          <ButtonPrimary
            className="text-2xl virtual-tour"
            onClick={() => openModalVirtualRoom()}
          >
            <i className={`las la-photo-video text-4xl pr-2`}></i>
            Virtual Tour
          </ButtonPrimary>
        )}
        {renderMotalVirtualRoom()}

        {/* SIDE BOXES */}
        {results && !results[0]?.no_availability && (
          <ReactPlaceholder
            ready={!isLoading}
            customPlaceholder={sideboxesPlaceholder}
            showLoadingAnimation
          >
            {renderSideBoxes()}
          </ReactPlaceholder>
        )}

        {/* SUM */}
        {results && !results[0]?.no_availability && (
          <ReactPlaceholder
            ready={!isLoading}
            customPlaceholder={textFourRowsPlaceholder}
            showLoadingAnimation
          >
            {stayCurrentTotalPrice > 0 ? (
              <div className="flex flex-col space-y-4">
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>
                    {" "}
                    €
                    {stayCurrentPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    x {numberOfNights} nights
                  </span>
                  <span>
                    €
                    {stayCurrentTotalPrice.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
                <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                  <span>Taxes and other charges</span>
                  {totalCashTaxesValue !== 0 ? (
                    <span>
                      {totalCashTaxesCurrency === "USD" ? "$" : "€"}{" "}
                      {totalCashTaxesValue.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  ) : (
                    <span>€0.00</span>
                  )}
                </div>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex justify-between font-semibold text-[18px]">
                  <span>Total</span>
                  <span>
                    €
                    {stayCurrentTotalPriceWithCharges.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
                  </span>
                </div>
              </div>
            ) : null}
          </ReactPlaceholder>
        )}

        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={btnPlaceholder}
          showLoadingAnimation
        >
          <AddToCartModal
            title={hotel?.name}
            showModal={showAddToCartModal}
            onClose={() => setShowAddToCartModal(false)}
          />{" "}
          {results && !results[0]?.no_availability && (
            <ButtonPrimary
              disabled={(results && results[0]?.no_availability) || isLoading}
              onClick={() => addToCart()}
              className={`
              h-[83px]
              ring-0
             
            ${isOnCart ? "custom-green-button-cart" : ""}`}
            >
              <p className="flex space-x-2 justify-center items-center">
                {isOnCart ? (
                  <>
                    <span> Added To cart</span>
                  </>
                ) : (
                  <>
                    <span>Add to cart</span>
                  </>
                )}
              </p>
            </ButtonPrimary>
          )}
          {results &&
            !results[0]?.no_availability &&
            totalCashTaxesValue !== 0 && (
              <div className="mt-1 hotel-cash-tax">
                <span className="text-sm">
                  Please note that the amount of{" "}
                  {totalCashTaxesCurrency === "USD" ? "$" : "€"}{" "}
                  {totalCashTaxesValue.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  for the{" "}
                  {totalCashTaxesTitle !== ""
                    ? totalCashTaxesTitle
                    : "climate resilient tax"}{" "}
                  should be paid on-site at the hotel by credit/debit card or
                  cash.
                </span>
              </div>
            )}
        </ReactPlaceholder>
      </div>
    );
  };

  const renderSideBoxes = () => {
    return (
      <div>
        {hotel && (
          <form
            className="flex flex-col sm:flex-row border divide-y sm:divide-y-0 sm:divide-x divide-neutral-200
           dark:divide-neutral-700 border-neutral-200 dark:border-neutral-700 rounded-3xl items-center "
          >
            <div className="flex-1 ">
              <ExperiencesMealInput
                anchorDirection={"right"}
                fieldClassName="p-4"
                className="h-full"
                setSelectedMeals={setSelectedMeals}
                data={stay}
              />
            </div>
            <div className="flex-1 listingExperiencesDetailPage__GuestsInput w-36 ">
              <CancellationInput
                fieldClassName="p-4 h-2"
                hasButtonSubmit={false}
                data={stay}
                setSelectedCancelation={setSelectedCancelation}
              />
            </div>
          </form>
        )}
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <div>
          <div>
            {" "}
            <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
              {/* <DetailsImageGallery images={detailsImgs?.map((img:any)=> img.url) || []}/> */}
              <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
                {detailsImgs && detailsImgs.length > 1 ? (
                  <>
                    <div
                      className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                      onClick={() => handleOpenModal(0)}
                    >
                      <ReactPlaceholder
                        ready={!isDataLoading}
                        customPlaceholder={galleryPlaceholder}
                        showLoadingAnimation
                      >
                        <NcImage
                          containerClassName="absolute inset-0"
                          className="object-cover w-full h-full rounded-md sm:rounded-xl"
                          src={
                            detailsImgs && detailsImgs.length > 0
                              ? detailsImgs[0].url
                              : ""
                          }
                        />
                      </ReactPlaceholder>
                    </div>
                    {detailsImgs
                      ?.filter((_item: any, i: number) => i >= 1 && i < 5)
                      .map((item: any, index: number) => (
                        <div
                          key={`imgBox-${index}`}
                          className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                            index >= 3 ? "hidden sm:block" : ""
                          }`}
                        >
                          <ReactPlaceholder
                            ready={!isDataLoading}
                            customPlaceholder={subGalleryPlaceholder}
                            showLoadingAnimation
                          >
                            <NcImage
                              key={`imgContainer-${index}`}
                              containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                              className="object-cover w-full h-full rounded-md sm:rounded-xl "
                              src={item.url || ""}
                            />
                          </ReactPlaceholder>

                          {/* OVERLAY */}
                          <div
                            className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                            onClick={() => handleOpenModal(index + 1)}
                          />
                        </div>
                      ))}
                  </>
                ) : (
                  <div
                    className="col-span-4 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                    onClick={() => handleOpenModal(0)}
                  >
                    <ReactPlaceholder
                      ready={!isDataLoading}
                      customPlaceholder={galleryPlaceholder}
                      showLoadingAnimation
                    >
                      <NcImage
                        containerClassName="absolute inset-0"
                        className="object-cover w-full h-full rounded-md sm:rounded-xl"
                        src={
                          detailsImgs && detailsImgs.length > 0
                            ? detailsImgs[0].url
                            : ""
                        }
                      />
                    </ReactPlaceholder>
                  </div>
                )}

                <div
                  className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                  onClick={() => handleOpenModal(0)}
                >
                  <ReactPlaceholder
                    ready={!isDataLoading}
                    customPlaceholder={showAllPhotosPlaceholder}
                    showLoadingAnimation
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>

                    <span className="ml-2 text-neutral-800 text-sm font-medium">
                      Show all photos
                    </span>
                  </ReactPlaceholder>
                </div>
              </div>
            </header>
            {/* MODAL PHOTOS */}
            <ModalPhotos
              imgs={detailsImgs || []}
              isOpen={isOpen}
              onClose={handleCloseModal}
              initFocus={openFocusIndex}
              uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
            />
          </div>
        </div>
      </>

      <div>
        {/* MAIn */}
        <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
          {/* CONTENT */}
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10 z-">
            <div className="space-y-8">
              {/* info */}
              {renderSection1()}

              {/* // overview */}
              {overview && renderSection2()}

              {/* // accessibilityFeatures */}
              {renderSection3()}

              {/* map */}
              {map && map.lat && renderSection7()}

              {/* terms and conditions | notes | etc */}
              {renderSection8()}

              {/* whatsAround */}
              {whatsAround && renderSection9()}
            </div>

            <div className="pt-2"></div>
          </div>

          <div className="md:block md:flex-grow md:mt-0">
            <div className={"sticky smooth-scroll top-48 pb-12 "}>
              <div>{renderSidebar()}</div>
            </div>
          </div>
        </main>
      </div>

      {/* OTHER SECTION */}
    </div>
  );
};

export default WhereToStayDetailPage;

export enum PlaceActionTypes {
  ADD_PLACE = 'ADD_PLACE',
  REMOVE_PLACE = 'REMOVE_PLACE',
  UPDATE_PLACE = 'UPDATE_PLACE',
  SET_PLACES = 'SET_PLACES',
}

export interface SetPlacesAction {
  type: PlaceActionTypes.SET_PLACES;
  payload: any; 
}

export interface AddPlaceAction {
  type: PlaceActionTypes.ADD_PLACE;
  payload: any;
}

export interface RemovePlaceAction {
  type: PlaceActionTypes.REMOVE_PLACE;
  payload: any;
}

export interface UpdatePlaceAction {
  type: PlaceActionTypes.UPDATE_PLACE;
  payload: any;
}

export function addPlace(payload: any): AddPlaceAction {
  return {
    type: PlaceActionTypes.ADD_PLACE,
    payload,
  };
}

export function removePlace(payload: any): RemovePlaceAction {
  return {
    type: PlaceActionTypes.REMOVE_PLACE,
    payload,
  };
}

export function updatePlace(payload: any): UpdatePlaceAction {
  return {
    type: PlaceActionTypes.UPDATE_PLACE,
    payload,
  };
}

export function setPlaces(places: any): SetPlacesAction {
  return {
    type: PlaceActionTypes.SET_PLACES,
    payload: places,
  };
}


export type PlaceAction = AddPlaceAction | RemovePlaceAction | UpdatePlaceAction | SetPlacesAction;

import { BrowserRouter, Routes, Route } from "react-router-dom";
// import {BrowserRouter, Routes, Route } from "react-router-dom";

import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import PageContact from "containers/PageContact/PageContact";
import SiteHeader from "containers/SiteHeader";
// import useWindowSize from "hooks/useWindowResize";

// import PageSignUp from "containers/PageSignUp/PageSignUp";
// import PageLogin from "containers/PageLogin/PageLogin";

//orbito pages
import ResultsLandingPage from "containers/ResultsLandingPage/ResultsLandingPage";
import HowToGetTherePage from "containers/HowToGetTherePage/HowToGetTherePage";
import WhereToStayPage from "containers/WhereToStayPage/WhereToStayPage";
import WhereToStayDetailPage from "containers/WhereToStayPage/WhereToStayDetailPage/WhereToStayDetailPage";
import WhatToDoPage from "containers/WhatToDoPage/WhatToDoPage";
import DetailPage from "containers/DetailPage/DetailPage";
import WhereToEatPage from "containers/WhereToEatPage/WhereToEatPage";
import RestaurantDetailPage from "containers/DetailPage/RestaurantDetailPage";
import HowToMoveAroundPage from "containers/HowToMoveAroundPage/HowToMoveAroundPage";
import WhatsExtraPage from "containers/WhatsExtraPage/WhatsExtraPage";
import TermsAndConditionsPage from "containers/StaticPages/TermsAndConditionsPage";
import AboutPage from "containers/StaticPages/AboutPage";
import AccreditationPage from "containers/StaticPages/AccreditationPage";
import PrivacyPolicyPage from "containers/StaticPages/PrivacyPolicyPage";
// import ContactPage from "containers/StaticPages/ContactPage";
import FaqPage from "containers/StaticPages/FaqPage";
import TransportationDetailPage from "containers/HowToMoveAroundPage/custom/TrasportationDetailPage";
import WhatsExtraDetailPage from "containers/WhatsExtraPage/custom/WhatsExtraDetailPage";
import ShoppingCart from "components/ShoppingCart/ShoppingCart";
import { Switch } from "@headlessui/react";
import ShoppingCart2 from "components/ShoppingCart/ShoppingCart2";
import TermsOfUsePage from "containers/StaticPages/TermsOfUsePage";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/services", exact: true, component: ResultsLandingPage },
  { path: "/how-to-get-there", exact: true, component: HowToGetTherePage },
  // { path: "/where-to-stay", exact: true, component: WhereToStayPage },
  { path: "/what-to-do", exact: true, component: WhatToDoPage },
  { path: "/where-to-eat-and-drink", exact: true, component: WhereToEatPage },
  { path: "/how-to-move-around", exact: true, component: HowToMoveAroundPage },
  { path: "/what-is-extra", exact: true, component: WhatsExtraPage },

  // {
  //   path: "/where-to-stay-detail/:id",
  //   exact: true,
  //   component: WhereToStayDetailPage,
  // },
  { path: "/what-to-do-detail/:id", exact: true, component: DetailPage },
  {
    path: "/where-to-eat-and-drink-detail/:id",
    exact: true,
    component: RestaurantDetailPage,
  },
  {
    path: "/how-to-move-around-detail/:id",
    exact: true,
    component: TransportationDetailPage,
  },
  {
    path: "/what-is-extra-detail/:id",
    exact: true,
    component: WhatsExtraDetailPage,
  },
  { path: "/checkout", component: CheckOutPage },
  { path: "/cart", component: ShoppingCart2 },
  { path: "/pay-done", component: PayPage },
  { path: "/#", exact: true, component: PageHome },
  {
    path: "/terms-of-use",
    exact: true,
    component: TermsOfUsePage,
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    component: TermsAndConditionsPage,
  },
  { path: "/about", exact: true, component: AboutPage },
  { path: "/accreditations", exact: true, component: AccreditationPage },
  { path: "/privacy-policy", exact: true, component: PrivacyPolicyPage },
  { path: "/contact", exact: true, component: PageContact },
  { path: "/faq", exact: true, component: FaqPage },
];

const MyRoutes = () => {
  // const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  return (
    <Routes>
      {pages.map(({ component, path, data, exact }) => {
        const Component = component;
        return <Route key={path} element={<Component />} path={path} />;
      })}

      <Route path="where-to-stay">
        <Route index element={<WhereToStayPage />} />
        <Route path=":id" element={<WhereToStayDetailPage />} />
      </Route>
      <Route path="blog">
        <Route index element={<BlogPage />} />
        <Route path=":slug" element={<BlogSingle />} />
      </Route>
      <Route element={<Page404 />} />
    </Routes>
  );
};

export default MyRoutes;

import { FC } from "react";
import { ACCREDITATIONS } from "data/accreditations";
import { AccreditationsDataType } from "data/types";
import Card4 from "containers/BlogPage/Card4";

// THIS IS DEMO FOR MAIN DEMO
// OTHER DEMO WILL PASS PROPS
const postsDemo: AccreditationsDataType[] = ACCREDITATIONS;
//
export interface SectionLatestPostsProps {
  posts?: AccreditationsDataType[];
  className?: string;
  postCardName?: "card4";
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  posts = postsDemo,
  postCardName = "card4",
  className = "",
}) => {
  const renderCard = (post: AccreditationsDataType) => {
    switch (postCardName) {
      case "card4":
        return <Card4 key={post.id} className="" post={post} />;

      default:
        return null;
    }
  };

  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full">
          <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100 mb-8">
            Accreditations, certificates, memberships
          </h2>
          <div className={`grid gap-6 md:gap-8 grid-cols-1`}>
            {posts.map((post) => renderCard(post))}
          </div>
          <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center"></div>
        </div>
      </div>
    </div>
  );
};

export default SectionLatestPosts;

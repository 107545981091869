import {StayCurrentPriceActionTypes, stayCurrentPriceAction} from './../actions/stayCurrentPrice'


export interface StayCurrentPriceState {
  stayCurrentPrice: any[];
}

const initialState: StayCurrentPriceState = {
  stayCurrentPrice: [],
};

const stayCurrentPriceReducer = (state = initialState, action: stayCurrentPriceAction) => {
  switch (action.type) {
    case StayCurrentPriceActionTypes.SET_STAY_CURRENT_PRICE:
      return {
        ...state,
        stayCurrentPrice: action.payload,
      };
    default:
      return state;
  }
};

export default stayCurrentPriceReducer;
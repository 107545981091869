import {StayCurrentTotalPriceWithChargesActionTypes, stayCurrentTotalPriceWithChargesAction} from './../actions/stayCurrentTotalPriceWithCharges'


export interface StayCurrentTotalPriceWithChargesState {
  stayCurrentTotalPriceWithCharges: any[];
}

const initialState: StayCurrentTotalPriceWithChargesState = {
  stayCurrentTotalPriceWithCharges: [],
};

const stayCurrentTotalPriceWithChargesReducer = (state = initialState, action: stayCurrentTotalPriceWithChargesAction) => {
  switch (action.type) {
    case StayCurrentTotalPriceWithChargesActionTypes.SET_STAY_CURRENT_TOTAL_PRICE_WITH_CHARGES:
      return {
        ...state,
        stayCurrentTotalPriceWithCharges: action.payload,
      };
    default:
      return state;
  }
};

export default stayCurrentTotalPriceWithChargesReducer;
import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonPrimaryProps extends ButtonProps {
  form?: string
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  className = "",
  form,
  ...args
}) => {
  return (
    <Button
      form={form}
      className={`disabled:bg-opacity-70 bg-[#F47D20] hover:bg-[#4995ED] text-neutral-50  ${className}`}
      {...args}
    />
  );
};

export default ButtonPrimary;

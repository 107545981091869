
export interface User {
  signInUserSession: {
    accessToken: {
      jwtToken: string;
    },
    refreshToken: {
      token: string;
    },
  },
  username: string;
}


export interface AuthState {
  isAuthenticated: boolean;
  user: User | null;
}

const initState = {
    isAuthenticated: false,
     user: null,
}
const authReducer = (state=initState, action: { type: string; payload: any; storeKey: string }) => {
  switch (action.type) {
    case 'SET_USER':
      const { username, signInUserSession } = action.payload;
      const plainUser = { username, signInUserSession };
      return {
        ...state,
        isAuthenticated: true,
        user: JSON.stringify(plainUser),
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
}
export default authReducer;
import React, { MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import logoImg from "images/orbito/logos/new/orbito_travel_logo_web.png";
import logoLightImg from "images/orbito/logos/new/dark.png";
// import logoLightImg from "images/orbito/logos/orbito_travel_logo_web.png";
// import LogoSvgLight from "./LogoSvgLight";
// import LogoSvg from "./LogoSvg";
import { useDispatch, useSelector } from "react-redux";
import { resetCart } from "store/actions/cart";
import { resetSearch } from "store/actions/search";
import { HOMEPAGE } from "config/environment";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
}) => {
  const dispatch = useDispatch();
  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  let logo;
  if (isDarkMode) {
    logo = logoImg;
  } else {
    logo = logoImg;
  }

  return (
    <a
      href={`http://${window.location.host}/`}
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      {/* <LogoSvgLight />
      <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {logo ? (
        <img
          draggable="false"
          className={`block max-h-24 ${logo ? "dark:hidden" : ""}`}
          src={logo}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {logoLightImg && (
        <img
          draggable="false"
          className="hidden max-h-24 dark:block"
          src={logoLightImg}
          alt="Logo-Light"
        />
      )}
    </a>
  );
};

export default Logo;

import { API_URL } from "config/environment";
import { BlogPost } from "./models";

export const findAllPosts =  (): Promise<BlogPost[]> => {
  return fetch(API_URL + "/v1/posts", {
    method: "GET",
  }).then((res) => res.json());
};

export const findPost =  (slug: string): Promise<BlogPost> => {
  return fetch(API_URL + `/v1/posts/${slug}`, {
    method: "GET",
  }).then((res) => res.json());
};

import React, { Fragment, useEffect } from 'react'
import { IOfferCheckOptions } from './WhatsExtraDetailPage'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid'
import { off } from 'process'
import { euro } from 'utils/currency'

type OffersListBoxProp = {
    offers: IOfferCheckOptions[]
    handleChange: (selectedOffer: IOfferCheckOptions | null) => void;
}

const OffersListBox = ({ offers, handleChange }: OffersListBoxProp) => {
    const [selectedOffer, setSelectedOffer] = React.useState<IOfferCheckOptions | null>(null);

    useEffect(() => {
        setSelectedOffer(offers.find(off => off.isEnabled) || null)
    }, [offers])

    return (
        <Listbox value={selectedOffer} onChange={(offer) => {
            const selOffer = offer ? { isEnabled: true, price: offer!.price, type: offer!.type } : null;
            setSelectedOffer(selOffer)
            handleChange(selOffer)
            return selOffer;
        }}>
            <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 
                pr-10 text-left shadow-md focus:outline-none focus-visible:ring-2
                 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                    <span className="block truncate">{selectedOffer ? selectedOffer.type : 'None'}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                        />
                    </span>
                </Listbox.Button>
                <Transition
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="relative z-20 mt-1  w-full overflow-auto rounded-md
                     bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                        {/* None option */}
                        <Listbox.Option
                            key="none"
                            className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary-100 text-primary-900' : 'text-gray-900'
                                }`
                            }
                            value={null}
                        >
                            {({ selected }) => (
                                <>
                                    <span
                                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                    >
                                        None
                                    </span>
                                    {selected ? (
                                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                        </span>
                                    ) : null}
                                </>
                            )}
                        </Listbox.Option>

                        {offers.map((offer, offerIndex) => (
                            <Listbox.Option
                                key={offerIndex}
                                className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primary-100 text-primary-900' : 'text-gray-900'
                                    }`
                                }
                                value={offer}
                            >
                                {({ selected }) => (
                                    <>
                                        <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                }`}
                                        >
                                            <span className='mr-1'>{offer.type}</span> -  <span>{euro.format(offer.price)}</span>
                                        </span>
                                        {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        ) : null}
                                    </>
                                )}
                            </Listbox.Option>
                        ))}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}

export default OffersListBox
export enum StayCurrentNONActionTypes {
  SET_STAY_CURRENT_NON = 'SET_STAY_CURRENT_NON',
}

export interface SetStayCurrentNONAction {
  type: StayCurrentNONActionTypes.SET_STAY_CURRENT_NON;
  payload: any; 
}


export function setStayCurrentNON(stayCurrentNON: any): SetStayCurrentNONAction {
  return {
    type: StayCurrentNONActionTypes.SET_STAY_CURRENT_NON,
    payload: stayCurrentNON,
  };
}


export type stayCurrentNONAction = SetStayCurrentNONAction;

import moment from "moment";

type LocalStorageType<T> = {
  value: T;
};

export namespace LocalStorageAdapter {
  export function set<T>(key: string, value: LocalStorageType<T>) {
    if (typeof localStorage !== "undefined") {
      if (value) {
        localStorage.setItem(key, JSON.stringify(value));
      }
    }
  }

  export function clearItem(key: string) {
    localStorage.removeItem(key);
  }

  export function get<T>(
    key: string,
    defaultValueFunction: () => T ,
    parseFunction?: (value: any) => T
  ) {
    let value = localStorage.getItem(key);
    if (value) {
      const parsedValue: LocalStorageType<T> = JSON.parse(value);

      if (parseFunction) {
        return parseFunction(parsedValue.value);
      }

      return parsedValue.value;
    }
    return defaultValueFunction();
  }
}

import React, { useEffect, useRef, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CityInfo, getCitiesByName } from "services/api";
import { addTravelingFrom } from "store/actions/search";
import { useDispatch, useSelector } from "react-redux";

interface TravelFromInputProps {
  className?: string;
  onBlur?: () => void;
  error?: string;
}

export default function TravelFromInput({
  className,
  onBlur,
  error,
}: TravelFromInputProps) {
  const dispatch = useDispatch();
  const travelingFrom: CityInfo = useSelector(
    (state: any) => state.search.travelingFrom
  );

  const [locations, setLocations] = useState<CityInfo[]>([]);
  const [selected, setSelected] = useState<CityInfo | string | null>(null);
  const [query, setQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const onFocus = () => {
      setIsFocused(true);
    };

    const onBlurEvent = () => {
      setIsFocused(false);
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener("focus", onFocus);
      inputElement.addEventListener("blur", onBlurEvent);
    }

    // Cleanup event listeners
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("focus", onFocus);
        inputElement.removeEventListener("blur", onBlurEvent);
      }
    };
  }, []);

  useEffect(() => {
    getCitiesByName("")
      .then((res) => {
        setLocations(res);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (query) {
      getCitiesByName(query)
        .then((res) => {
          setLocations(res);
        })
        .catch((error) => console.log(error));
    } else {
      getCitiesByName("")
        .then((res) => {
          setLocations(res);
        })
        .catch((error) => console.log(error));
    }
  }, [query]);

  const sortedLocations = locations.sort((firstLocation, secondLocation) => {
    if (firstLocation.country < secondLocation.country) {
      return -1;
    }
    if (firstLocation.country > secondLocation.country) {
      return 1;
    }
    return 0;
  });

  return (
    <div className={`relative h-full rounded-full ${className}`}>
      <Combobox
        value={selected}
        onChange={(value: CityInfo | string) => {
          if (typeof value === "string") {
            localStorage.setItem("no_flight", "true");
            setSelected("No Flight");
            setQuery("");
          } else {
            setSelected(value);
            dispatch(addTravelingFrom(value));
            localStorage.setItem("no_flight", "true");
          }
        }}
        name="departingFrom"
        nullable
      >
        <div className="relative w-full h-full cursor-default overflow-hidden rounded-full text-left shadow-md sm:text-sm flex flex-col justify-center">
          {selected && (
            <Combobox.Label className="mt-5 flex items-center bg-transparent border-none pl-12 text-sm">
              Departing from
            </Combobox.Label>
          )}
          <Combobox.Button className="absolute inset-y-0 left-0 mx-4 flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-7 h-7"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </Combobox.Button>
          <div className="absolute inset-y-0 left-0 mx-2 flex items-center pr-2"></div>

          <Combobox.Input
            ref={inputRef}
            autoComplete={"off"}
            className={`flex-1 ${
              selected ? "mb-4" : ""
            } w-full h-full border-none border-transparent bg-transparent dark:bg-transparent pl-12 text-base leading-5 text-white font-bold placeholder:text-white focus:outline-none focus:ring-0 ${className}`}
            displayValue={(location: CityInfo | string) => {
              if (typeof location === "string") {
                return location;
              } else if (location) {
                return `${location.city} (${location.IATA})`;
              }
              return "";
            }}
            placeholder={
              selected === "No Flight"
                ? "No Flight"
                : sortedLocations.length === 0 && query !== ""
                ? query
                : "Departing from"
            }
            onChange={(event) => {
              setQuery(event.target.value.replace("(", "").replace(")", ""));
            }}
            onFocus={onBlur}
          />

          <Combobox.Button className="absolute inset-y-0 right-0 mx-2 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-white"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Combobox.Options
            static
            hold={false}
            className="z-50 absolute mt-6 max-h-60 w-120 overflow-auto rounded-3xl bg-white dark:bg-neutral-800 py-1 text-md shadow-lg ring-1 ring-black/5 focus:outline-none"
          >
            <Combobox.Option
              key="no_flight"
              className={({ active }) =>
                `relative cursor-default select-none py-2 pl-10 pr-4 text-neutral-700 dark:text-neutral-200 ${
                  active ? "bg-[#f47d20] text-white dark:bg-neutral-600" : ""
                }`
              }
              value="I don't want to book a flight"
            >
              {({ active }) => (
                <span
                  className={`block truncate font-medium ${
                    active ? "font-semibold" : "font-normal"
                  }`}
                >
                  I don't want to book a flight
                </span>
              )}
            </Combobox.Option>

            {sortedLocations.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none px-4 py-6 text-gray-700 dark:text-white">
                We couldn't find {query}. Please select a city from the
                available options.
              </div>
            ) : (
              sortedLocations.map((location, i) => {
                if (location.city === "" || location.city === " ") {
                  return;
                } else {
                  return (
                    <Combobox.Option
                      key={`${location.IATA} -${i}`}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-10 pr-4 text-neutral-700 dark:text-neutral-200 ${
                          location.disabled ? "" : "hover:text-white"
                        } ${
                          active
                            ? "bg-[#f47d20] text-white dark:bg-neutral-600"
                            : ""
                        }`
                      }
                      value={location}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate font-medium ${
                              selected ? "font-semibold" : "font-normal"
                            }`}
                          >
                            {location.city}, {location.country} ({location.IATA}
                            )
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-[#f47d20]"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  );
                }
              })
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
      {error && <div className="validation-message">{error}</div>}
    </div>
  );
}

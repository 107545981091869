const initState = {
    hotels: [],
    hotelsLoading: true,
    whatToDo: [],
    whatToDoLoading: true,
    whereToEat: [],
    whereToEatLoading: true,
    howToMoveAround: [],
    howToMoveAroundLoading: true,
    whatsExtra: [],
    whatsExtraLoading: true
}
const dataReducer = (state=initState, action: { type: string; payload: any }) => {
    switch(action.type){
        case 'ADD_HOTELS_DATA':
            return {...state, hotels: action.payload, hotelsLoading: false};
        case 'LOADING_HOTELS_DATA':
            return {...state, hotelsLoading: true};
        case 'ADD_WHAT_TO_DO_DATA':
            return {...state, whatToDo: action.payload, whatToDoLoading: false};
        case 'LOADING_WHAT_TO_DO_DATA':
            return {...state, whatToDoLoading: true};
        case 'ADD_WHERE_TO_EAT_DATA':
            return {...state, whereToEat: action.payload, whereToEatLoading: false};
        case 'LOADING_WHERE_TO_EAT_DATA':
            return {...state, whereToEatLoading: true};
        case 'ADD_HOW_TO_MOVE_AROUND_DATA':
            return {...state, howToMoveAround: action.payload, howToMoveAroundLoading: false};
        case 'LOADING_HOW_TO_MOVE_AROUND_DATA':
            return {...state, howToMoveAroundLoading: true};
        case 'ADD_WHATS_EXTRA_DATA':
            return {...state, whatsExtra: action.payload, whatsExtraLoading: false};
        case 'LOADING_WHATS_EXTRA_DATA':
            return {...state, whatsExtraLoading: true};
        default:
        return state;
    }
}
export default dataReducer;
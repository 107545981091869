export const COUNTRIES: {
    mobilePrefixCode: string;
    name: string;
  }[] = [
    { mobilePrefixCode: "1", name: "United States" },
    { mobilePrefixCode: "93", name: "Afghanistan" },
    { mobilePrefixCode: "358", name: "Aland Islands" },
    { mobilePrefixCode: "355", name: "Albania" },
    { mobilePrefixCode: "213", name: "Algeria" },
    { mobilePrefixCode: "1684", name: "American Samoa" },
    { mobilePrefixCode: "376", name: "Andorra" },
    { mobilePrefixCode: "244", name: "Angola" },
    { mobilePrefixCode: "1264", name: "Anguilla" },
    { mobilePrefixCode: "672", name: "Antarctica" },
    { mobilePrefixCode: "1268", name: "Antigua and Barbuda" },
    { mobilePrefixCode: "54", name: "Argentina" },
    { mobilePrefixCode: "374", name: "Armenia" },
    { mobilePrefixCode: "297", name: "Aruba" },
    { mobilePrefixCode: "61", name: "Australia" },
    { mobilePrefixCode: "43", name: "Austria" },
    { mobilePrefixCode: "994", name: "Azerbaijan" },
    { mobilePrefixCode: "1242", name: "Bahamas" },
    { mobilePrefixCode: "973", name: "Bahrain" },
    { mobilePrefixCode: "880", name: "Bangladesh" },
    { mobilePrefixCode: "1246", name: "Barbados" },
    { mobilePrefixCode: "375", name: "Belarus" },
    { mobilePrefixCode: "32", name: "Belgium" },
    { mobilePrefixCode: "501", name: "Belize" },
    { mobilePrefixCode: "229", name: "Benin" },
    { mobilePrefixCode: "1441", name: "Bermuda" },
    { mobilePrefixCode: "975", name: "Bhutan" },
    { mobilePrefixCode: "591", name: "Bolivia" },
    { mobilePrefixCode: "599", name: "Bonaire, Sint Eustatius and Saba" },
    { mobilePrefixCode: "387", name: "Bosnia and Herzegovina" },
    { mobilePrefixCode: "267", name: "Botswana" },
    { mobilePrefixCode: "55", name: "Bouvet Island" },
    { mobilePrefixCode: "55", name: "Brazil" },
    { mobilePrefixCode: "246", name: "British Indian Ocean Territory" },
    { mobilePrefixCode: "673", name: "Brunei Darussalam" },
    { mobilePrefixCode: "359", name: "Bulgaria" },
    { mobilePrefixCode: "226", name: "Burkina Faso" },
    { mobilePrefixCode: "257", name: "Burundi" },
    { mobilePrefixCode: "855", name: "Cambodia" },
    { mobilePrefixCode: "237", name: "Cameroon" },
    { mobilePrefixCode: "1", name: "Canada" },
    { mobilePrefixCode: "238", name: "Cape Verde" },
    { mobilePrefixCode: "1345", name: "Cayman Islands" },
    { mobilePrefixCode: "236", name: "Central African Republic" },
    { mobilePrefixCode: "235", name: "Chad" },
    { mobilePrefixCode: "56", name: "Chile" },
    { mobilePrefixCode: "86", name: "China" },
    { mobilePrefixCode: "61", name: "Christmas Island" },
    { mobilePrefixCode: "672", name: "Cocos (Keeling) Islands" },
    { mobilePrefixCode: "57", name: "Colombia" },
    { mobilePrefixCode: "269", name: "Comoros" },
    { mobilePrefixCode: "242", name: "Congo" },
    { mobilePrefixCode: "242", name: "Congo, Democratic Republic of the Congo" },
    { mobilePrefixCode: "682", name: "Cook Islands" },
    { mobilePrefixCode: "506", name: "Costa Rica" },
    { mobilePrefixCode: "225", name: "Cote D'Ivoire" },
    { mobilePrefixCode: "385", name: "Croatia" },
    { mobilePrefixCode: "53", name: "Cuba" },
    { mobilePrefixCode: "599", name: "Curacao" },
    { mobilePrefixCode: "357", name: "Cyprus" },
    { mobilePrefixCode: "420", name: "Czech Republic" },
    { mobilePrefixCode: "45", name: "Denmark" },
    { mobilePrefixCode: "253", name: "Djibouti" },
    { mobilePrefixCode: "1767", name: "Dominica" },
    { mobilePrefixCode: "1809", name: "Dominican Republic" },
    { mobilePrefixCode: "593", name: "Ecuador" },
    { mobilePrefixCode: "20", name: "Egypt" },
    { mobilePrefixCode: "503", name: "El Salvador" },
    { mobilePrefixCode: "240", name: "Equatorial Guinea" },
    { mobilePrefixCode: "291", name: "Eritrea" },
    { mobilePrefixCode: "372", name: "Estonia" },
    { mobilePrefixCode: "251", name: "Ethiopia" },
    { mobilePrefixCode: "500", name: "Falkland Islands (Malvinas)" },
    { mobilePrefixCode: "298", name: "Faroe Islands" },
    { mobilePrefixCode: "679", name: "Fiji" },
    { mobilePrefixCode: "358", name: "Finland" },
    { mobilePrefixCode: "33", name: "France" },
    { mobilePrefixCode: "594", name: "French Guiana" },
    { mobilePrefixCode: "689", name: "French Polynesia" },
    { mobilePrefixCode: "262", name: "French Southern Territories" },
    { mobilePrefixCode: "241", name: "Gabon" },
    { mobilePrefixCode: "220", name: "Gambia" },
    { mobilePrefixCode: "995", name: "Georgia" },
    { mobilePrefixCode: "49", name: "Germany" },
    { mobilePrefixCode: "233", name: "Ghana" },
    { mobilePrefixCode: "350", name: "Gibraltar" },
    { mobilePrefixCode: "30", name: "Greece" },
    { mobilePrefixCode: "299", name: "Greenland" },
    { mobilePrefixCode: "1473", name: "Grenada" },
    { mobilePrefixCode: "590", name: "Guadeloupe" },
    { mobilePrefixCode: "1671", name: "Guam" },
    { mobilePrefixCode: "502", name: "Guatemala" },
    { mobilePrefixCode: "44", name: "Guernsey" },
    { mobilePrefixCode: "224", name: "Guinea" },
    { mobilePrefixCode: "245", name: "Guinea-Bissau" },
    { mobilePrefixCode: "592", name: "Guyana" },
    { mobilePrefixCode: "509", name: "Haiti" },
    { mobilePrefixCode: "39", name: "Holy See (Vatican City State)" },
    { mobilePrefixCode: "504", name: "Honduras" },
    { mobilePrefixCode: "852", name: "Hong Kong" },
    { mobilePrefixCode: "36", name: "Hungary" },
    { mobilePrefixCode: "354", name: "Iceland" },
    { mobilePrefixCode: "91", name: "India" },
    { mobilePrefixCode: "62", name: "Indonesia" },
    { mobilePrefixCode: "98", name: "Iran, Islamic Republic of" },
    { mobilePrefixCode: "964", name: "Iraq" },
    { mobilePrefixCode: "353", name: "Ireland" },
    { mobilePrefixCode: "44", name: "Isle of Man" },
    { mobilePrefixCode: "972", name: "Israel" },
    { mobilePrefixCode: "39", name: "Italy" },
    { mobilePrefixCode: "1876", name: "Jamaica" },
    { mobilePrefixCode: "81", name: "Japan" },
    { mobilePrefixCode: "44", name: "Jersey" },
    { mobilePrefixCode: "962", name: "Jordan" },
    { mobilePrefixCode: "7", name: "Kazakhstan" },
    { mobilePrefixCode: "254", name: "Kenya" },
    { mobilePrefixCode: "686", name: "Kiribati" },
    { mobilePrefixCode: "850", name: "Korea, Democratic People's Republic of" },
    { mobilePrefixCode: "82", name: "Korea, Republic of" },
    { mobilePrefixCode: "383", name: "Kosovo" },
    { mobilePrefixCode: "965", name: "Kuwait" },
    { mobilePrefixCode: "996", name: "Kyrgyzstan" },
    { mobilePrefixCode: "856", name: "Lao People's Democratic Republic" },
    { mobilePrefixCode: "371", name: "Latvia" },
    { mobilePrefixCode: "961", name: "Lebanon" },
    { mobilePrefixCode: "266", name: "Lesotho" },
    { mobilePrefixCode: "231", name: "Liberia" },
    { mobilePrefixCode: "218", name: "Libyan Arab Jamahiriya" },
    { mobilePrefixCode: "423", name: "Liechtenstein" },
    { mobilePrefixCode: "370", name: "Lithuania" },
    { mobilePrefixCode: "352", name: "Luxembourg" },
    { mobilePrefixCode: "853", name: "Macao" },
    { mobilePrefixCode: "389", name: "Macedonia, the Former Yugoslav Republic of" },
    { mobilePrefixCode: "261", name: "Madagascar" },
    { mobilePrefixCode: "265", name: "Malawi" },
    { mobilePrefixCode: "60", name: "Malaysia" },
    { mobilePrefixCode: "960", name: "Maldives" },
    { mobilePrefixCode: "223", name: "Mali" },
    { mobilePrefixCode: "356", name: "Malta" },
    { mobilePrefixCode: "692", name: "Marshall Islands" },
    { mobilePrefixCode: "596", name: "Martinique" },
    { mobilePrefixCode: "222", name: "Mauritania" },
    { mobilePrefixCode: "230", name: "Mauritius" },
    { mobilePrefixCode: "262", name: "Mayotte" },
    { mobilePrefixCode: "52", name: "Mexico" },
    { mobilePrefixCode: "691", name: "Micronesia, Federated States of" },
    { mobilePrefixCode: "373", name: "Moldova, Republic of" },
    { mobilePrefixCode: "377", name: "Monaco" },
    { mobilePrefixCode: "976", name: "Mongolia" },
    { mobilePrefixCode: "382", name: "Montenegro" },
    { mobilePrefixCode: "1664", name: "Montserrat" },
    { mobilePrefixCode: "212", name: "Morocco" },
    { mobilePrefixCode: "258", name: "Mozambique" },
    { mobilePrefixCode: "95", name: "Myanmar" },
    { mobilePrefixCode: "264", name: "Namibia" },
    { mobilePrefixCode: "674", name: "Nauru" },
    { mobilePrefixCode: "977", name: "Nepal" },
    { mobilePrefixCode: "31", name: "Netherlands" },
    { mobilePrefixCode: "599", name: "Netherlands Antilles" },
    { mobilePrefixCode: "687", name: "New Caledonia" },
    { mobilePrefixCode: "64", name: "New Zealand" },
    { mobilePrefixCode: "505", name: "Nicaragua" },
    { mobilePrefixCode: "227", name: "Niger" },
    { mobilePrefixCode: "234", name: "Nigeria" },
    { mobilePrefixCode: "683", name: "Niue" },
    { mobilePrefixCode: "672", name: "Norfolk Island" },
    { mobilePrefixCode: "1670", name: "Northern Mariana Islands" },
    { mobilePrefixCode: "47", name: "Norway" },
    { mobilePrefixCode: "968", name: "Oman" },
    { mobilePrefixCode: "92", name: "Pakistan" },
    { mobilePrefixCode: "680", name: "Palau" },
    { mobilePrefixCode: "970", name: "Palestinian Territory, Occupied" },
    { mobilePrefixCode: "507", name: "Panama" },
    { mobilePrefixCode: "675", name: "Papua New Guinea" },
    { mobilePrefixCode: "595", name: "Paraguay" },
    { mobilePrefixCode: "51", name: "Peru" },
    { mobilePrefixCode: "63", name: "Philippines" },
    { mobilePrefixCode: "64", name: "Pitcairn" },
    { mobilePrefixCode: "48", name: "Poland" },
    { mobilePrefixCode: "351", name: "Portugal" },
    { mobilePrefixCode: "1787", name: "Puerto Rico" },
    { mobilePrefixCode: "974", name: "Qatar" },
    { mobilePrefixCode: "262", name: "Reunion" },
    { mobilePrefixCode: "40", name: "Romania" },
    { mobilePrefixCode: "7", name: "Russian Federation" },
    { mobilePrefixCode: "250", name: "Rwanda" },
    { mobilePrefixCode: "590", name: "Saint Barthelemy" },
    { mobilePrefixCode: "290", name: "Saint Helena" },
    { mobilePrefixCode: "1869", name: "Saint Kitts and Nevis" },
    { mobilePrefixCode: "1758", name: "Saint Lucia" },
    { mobilePrefixCode: "590", name: "Saint Martin" },
    { mobilePrefixCode: "508", name: "Saint Pierre and Miquelon" },
    { mobilePrefixCode: "1784", name: "Saint Vincent and the Grenadines" },
    { mobilePrefixCode: "684", name: "Samoa" },
    { mobilePrefixCode: "378", name: "San Marino" },
    { mobilePrefixCode: "239", name: "Sao Tome and Principe" },
    { mobilePrefixCode: "966", name: "Saudi Arabia" },
    { mobilePrefixCode: "221", name: "Senegal" },
    { mobilePrefixCode: "381", name: "Serbia" },
    { mobilePrefixCode: "381", name: "Serbia and Montenegro" },
    { mobilePrefixCode: "248", name: "Seychelles" },
    { mobilePrefixCode: "232", name: "Sierra Leone" },
    { mobilePrefixCode: "65", name: "Singapore" },
    { mobilePrefixCode: "599", name: "Sint Maarten" },
    { mobilePrefixCode: "421", name: "Slovakia" },
    { mobilePrefixCode: "386", name: "Slovenia" },
    { mobilePrefixCode: "677", name: "Solomon Islands" },
    { mobilePrefixCode: "252", name: "Somalia" },
    { mobilePrefixCode: "27", name: "South Africa" },
    { mobilePrefixCode: "500", name: "South Georgia and the South Sandwich Islands" },
    { mobilePrefixCode: "211", name: "South Sudan" },
    { mobilePrefixCode: "34", name: "Spain" },
    { mobilePrefixCode: "94", name: "Sri Lanka" },
    { mobilePrefixCode: "249", name: "Sudan" },
    { mobilePrefixCode: "597", name: "Suriname" },
    { mobilePrefixCode: "47", name: "Svalbard and Jan Mayen" },
    { mobilePrefixCode: "268", name: "Swaziland" },
    { mobilePrefixCode: "46", name: "Sweden" },
    { mobilePrefixCode: "41", name: "Switzerland" },
    { mobilePrefixCode: "963", name: "Syrian Arab Republic" },
    { mobilePrefixCode: "886", name: "Taiwan, Province of China" },
    { mobilePrefixCode: "992", name: "Tajikistan" },
    { mobilePrefixCode: "255", name: "Tanzania, United Republic of" },
    { mobilePrefixCode: "66", name: "Thailand" },
    { mobilePrefixCode: "670", name: "Timor-Leste" },
    { mobilePrefixCode: "228", name: "Togo" },
    { mobilePrefixCode: "690", name: "Tokelau" },
    { mobilePrefixCode: "676", name: "Tonga" },
    { mobilePrefixCode: "1868", name: "Trinidad and Tobago" },
    { mobilePrefixCode: "216", name: "Tunisia" },
    { mobilePrefixCode: "90", name: "Turkey" },
    { mobilePrefixCode: "7370", name: "Turkmenistan" },
    { mobilePrefixCode: "1649", name: "Turks and Caicos Islands" },
    { mobilePrefixCode: "688", name: "Tuvalu" },
    { mobilePrefixCode: "256", name: "Uganda" },
    { mobilePrefixCode: "380", name: "Ukraine" },
    { mobilePrefixCode: "971", name: "United Arab Emirates" },
    { mobilePrefixCode: "44", name: "United Kingdom" },
    { mobilePrefixCode: "598", name: "Uruguay" },
    { mobilePrefixCode: "998", name: "Uzbekistan" },
    { mobilePrefixCode: "678", name: "Vanuatu" },
    { mobilePrefixCode: "58", name: "Venezuela" },
    { mobilePrefixCode: "84", name: "Viet Nam" },
    { mobilePrefixCode: "1284", name: "Virgin Islands, British" },
    { mobilePrefixCode: "1340", name: "Virgin Islands, U.s." },
    { mobilePrefixCode: "681", name: "Wallis and Futuna" },
    { mobilePrefixCode: "212", name: "Western Sahara" },
    { mobilePrefixCode: "967", name: "Yemen" },
    { mobilePrefixCode: "260", name: "Zambia" },
    { mobilePrefixCode: "263", name: "Zimbabwe" },
  ];
  

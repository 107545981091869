export enum StayCurrentChargesActionTypes {
  SET_STAY_CURRENT_CHARGES = 'SET_STAY_CURRENT_CHARGES',
}

export interface SetStayCurrentChargesAction {
  type: StayCurrentChargesActionTypes.SET_STAY_CURRENT_CHARGES;
  payload: any; 
}


export function setStayCurrentCharges(stayCurrentCharges: any): SetStayCurrentChargesAction {
  return {
    type: StayCurrentChargesActionTypes.SET_STAY_CURRENT_CHARGES,
    payload: stayCurrentCharges,
  };
}


export type stayCurrentChargesAction = SetStayCurrentChargesAction;

/* eslint-disable eqeqeq */
import { FC, useEffect } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DataDetailType, StayDetailType } from "data/types";
import { Link } from "react-router-dom";
import Stars from "components/Stars/Stars";
import EurosSigns from "components/EurosSigns/EurosSigns";
import { euro } from "utils/currency";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import { toInteger } from "lodash";
import {
  formatDuration,
  getShortDescText,
  getLangShortDescText,
} from "../../../helpers/methods";

import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import { TextBlock, RectShape } from "react-placeholder/lib/placeholders";
import { useSelector } from "react-redux";
import { IExtraService } from "services/models";
import { useCustomSearchParams } from "context/SearchParamsContext";

export interface ExtraServiceCardProp {
  className?: string;
  extraService: IExtraService;
  size?: "default" | "small";
  internalLink: string;
  priceStartingString?: string;
  isOnCart: boolean;
  isLoading?: boolean;
}

const ExtraServiceCard: FC<ExtraServiceCardProp> = ({
  size = "default",
  className = "",
  extraService,
  internalLink,
  priceStartingString = "from",
  isOnCart = false,
  isLoading,
}) => {
  const { getStringParams } = useCustomSearchParams();

  const cheaperOfferPrice = extraService.offers.sort((a, b) => a.price - b.price)[0]

  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;

  if (isLoading === undefined || isLoading === null) {
    isLoading = false;
  }

  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";

  const galleryPlaceholder = (
    <div className="gallery-placeholder">
      <RectShape style={{ width: 320, height: 241 }} color={placeholderColor} />
    </div>
  );

  const textRowsPlaceholder = (
    <div className="text-3-placeholder">
      <TextBlock color={placeholderColor} rows={5} />
    </div>
  );

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={extraService._id}
          className=""
          showDots={extraService.cardImgs.length >1}
          showNextPrev={extraService.cardImgs.length >1}
          ratioClass=""
          galleryImgs={extraService.cardImgs}
          href={internalLink + "/" + extraService._id}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {isOnCart && (
        <SaleOffBadge
            desc="Added to cart"
            className="absolute left-3 top-3 bg-green-500"
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-2" : "p-3 space-y-2"}>
        <div className="flex flex-row">
          <span className="mr-1">{extraService.type} </span>
          {/* <div>
            {!!stars && type === "Hotel" && <Stars stars={stars} key={stars} />}
          </div> */}
        </div>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400"></span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                }`}
            >
              <span className="line-clamp-1">{extraService.name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <div className="flex justify-between items-center w-full">
              <span className="">{extraService.city}, {extraService.country}</span>

            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {
              cheaperOfferPrice && <>from {euro.format(cheaperOfferPrice?.price!)}</>
            }
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                
              </span>
            )}
          </span>
        </div>
      </div>
    );
  };
  let fullLink = null;
  fullLink = internalLink + "/" + extraService._id;
  // if (
  //   internalLink === "/where-to-eat-and-drink-detail" ||
  //   internalLink === "/how-to-move-around-detail" ||
  //   internalLink === "/what-is-extra-detail"
  // ) {
  //   fullLink = internalLink;
  // }

  return (
    <div
      className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      <ReactPlaceholder
        ready={!isLoading}
        customPlaceholder={galleryPlaceholder}
        showLoadingAnimation
      >
        {renderSliderGallery()}
      </ReactPlaceholder>
      {isLoading && <br />}
      <ReactPlaceholder
        ready={!isLoading}
        customPlaceholder={textRowsPlaceholder}
        showLoadingAnimation
      >
        <Link to={{
          pathname: fullLink,
          search: getStringParams()
        }}>{renderContent()}</Link>
      </ReactPlaceholder>
      {isLoading && <br />}
    </div>
  );
};

export default ExtraServiceCard;

import { FC } from "react";
import StayCard from "components/StayCard/StayCard";
import { DataDetailType } from "data/types";
import { useSelector } from "react-redux";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: DataDetailType[];
  isLoading?: boolean;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = [],
  isLoading,
}) => {
  const searchCity = useSelector((state: any) => state.search.travelingTo.city);
  const filterData = data.filter((item) => item.city === searchCity);
  const selectedIds: string[] = useSelector((state: any) =>
    state.cart?.whereToEat.map((item: any) => item._id)
  );

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {/* <Heading2 /> */}

      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div> */}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {filterData.map((stay) => (
          <StayCard
            key={stay._id!}
            data={stay}
            internalLink="/where-to-eat-and-drink-detail"
            priceStartingString=""
            isOnCart={selectedIds.includes(stay._id!)}
            isLoading={isLoading}
          />
        ))}
      </div>

      {filterData.length === 0 && (
        <div className="grid md:grid-cols-1 gap-6 lg:grid-cols-1 xl:gap-8 mb-4">
          <div className="flex flex-row p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              Sorry, no restaurants to displayed for your convenience.
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionGridFilterCard;

// const { REACT_APP_API_URL } = process.env
import { API_URL } from "config/environment";

export const getHotelsJson = (): Promise<any> => {
  return fetch(API_URL + "/v1/json/hotels", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getWhatToDoJson = (): Promise<any> => {
  return fetch(API_URL + "/v1/json/what-to-do", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getWhereToEatJson = (): Promise<any> => {
  return fetch(API_URL + "/v1/json/where-to-eat", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getHowToMoveJson = (): Promise<any> => {
  return fetch(API_URL + "/v1/json/how-to-move", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getWhatsExtraJson = (): Promise<any> => {
  return fetch(API_URL + "/v1/json/whats-extra", {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((res) => {
    return res.json();
  });
};


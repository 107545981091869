import { FC, FormEvent, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import NcImage from "shared/NcImage/NcImage";
import Input from "shared/Input/Input";
import { saveNewEmail } from "services/newsletterService";
import first from "images/OT_homepage_newsletter.jpg";

interface Notification {
  message: string;
  type: "SUCCESS" | "ERROR" | "NOT_EMAIL";
}

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const [email, setEmail] = useState<string>("");
  const [notification, setNotification] = useState<Notification | null>(null);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    const isValidEmail = validateEmail(email);

    if (!isValidEmail) {
      setNotification({ message: "The email is not valid", type: "NOT_EMAIL" });
      return;
    }

    try {
      const response: Response = await saveNewEmail({ email });
      const message =
        response.status === 200 ? "Thank you for the subscription." : "Thank you for the subscription.";
      setNotification({ message, type: response.status === 200 ? "SUCCESS" : "ERROR" });
      setEmail("");
    } catch (error) {
      console.error("An error occurred:", error);
      setNotification({ message: "Thank you for the subscription.", type: "ERROR" });
    }
  };

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Our newsletter</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Become a travel insider. Join our community and get exclusive content.
        </span>
        <form className="mt-10 relative max-w-lg" onSubmit={handleSubmit}>
          <Input
            onChange={(ev) => {
              setNotification(null);
              setEmail(ev.target.value);
            }}
            value={email}
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            className="h-16"
            rounded="rounded-full"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[10px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
        {notification && (
          <div className="">
            <p className="mt-1 mx-1 px-4 py-2 text-white bg-[#F47D20] rounded-3xl font-thin text-sm">
              {notification.message}
            </p>
          </div>
        )}
      </div>
      <div className="flex-grow">
        <NcImage className="rounded-3xl" src={first} />
      </div>
    </div>
  );
};

export default SectionSubscribe2;

import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import { FC, useEffect, useMemo } from "react";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";
import { Link } from "react-router-dom";
import { useCustomSearchParams } from "context/SearchParamsContext";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: any[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
  showDots?: boolean;
  showNextPrev?: boolean;
}

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-4 aspect-h-3",
  uniqueID = "uniqueID",
  href = "/listing-stay-detail",
  showDots = true,
  showNextPrev = true,
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();
  const { getStringParams } = useCustomSearchParams();
  let MY_GLIDEJS = useMemo(() => {
    return new Glide(`.${UNIQUE_CLASS}`, {
      perView: 1,
      gap: 0,
      keyboard: false,
    });
  }, [UNIQUE_CLASS]);

  useEffect(() => {
    setTimeout(() => {
      MY_GLIDEJS.mount();
    }, 10);
  }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const renderDots = () => {
    return (
      <div
        className="cursor-pointer glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
        data-glide-el="controls[nav]"
      >
        {galleryImgs?.map((_, i) => (
          <button
            className="cursor-pointer glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
            key={i}
            data-glide-dir={`=${i}`}
          />
        ))}
      </div>
    );
  };

  const renderSliderGallery = () => {
    return (
      <div className={`${UNIQUE_CLASS} cursor-pointer relative group overflow-hidden max-h-[160px]`}>
        <div className="glide__track cursor-pointer" data-glide-el="track">
          <ul className="glide__slides cursor-pointer">
            {galleryImgs?.map((item, index) => (
              <li key={index} className="glide__slide cursor-pointer">
                <Link to={{
                  pathname: href, search: getStringParams()
                }} className={`block ${ratioClass}`}>
                  <NcImage src={item.url} />
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {showDots && renderDots()}
        {showNextPrev && (
       
            <Link to={{
                  pathname: href, search: getStringParams()
                }} className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
            <NextPrev
              className="w-full justify-between"
              btnClassName="w-8 h-8"
            />
            </Link>
       
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-GallerySlider ${className} max-h-[160px]`}
      data-nc-id="GallerySlider"
    >
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySlider;

export interface EcoFriendlyState {
  isEcoFriendly: boolean;
}

const initState = {
    isEcoFriendly: false,
}

const isEcoFriendlyReducer = (state=initState, action: { type: string; payload: any; storeKey: string }) => {
  switch (action.type) {
    case 'YES':
      return {
        ...state,
        isEcoFriendly: true,
      };
    case 'NO':
      return {
        ...state,
        isEcoFriendly: false,
      };
    default:
      return state;
  }
}
export default isEcoFriendlyReducer;
export enum StayActionTypes {
  SET_STAY = 'SET_STAY',
}

export interface SetStayAction {
  type: StayActionTypes.SET_STAY;
  payload: any; 
}


export function setStay(stay: any): SetStayAction {
  return {
    type: StayActionTypes.SET_STAY,
    payload: stay,
  };
}


export type StayAction = SetStayAction;

import {StayCurrentTotalPriceActionTypes, stayCurrentTotalPriceAction} from './../actions/stayCurrentTotalPrice'


export interface StayCurrentTotalPriceState {
  stayCurrentTotalPrice: any[];
}

const initialState: StayCurrentTotalPriceState = {
  stayCurrentTotalPrice: [],
};

const stayCurrentTotalPriceReducer = (state = initialState, action: stayCurrentTotalPriceAction) => {
  switch (action.type) {
    case StayCurrentTotalPriceActionTypes.SET_STAY_CURRENT_TOTAL_PRICE:
      return {
        ...state,
        stayCurrentTotalPrice: action.payload,
      };
    default:
      return state;
  }
};

export default stayCurrentTotalPriceReducer;
export enum StayCurrentTimeslotsActionTypes {
  SET_STAY_CURRENT_TIMESLOTS = 'SET_STAY_CURRENT_TIMESLOTS',
}

export interface SetStayCurrentTimeslotsAction {
  type: StayCurrentTimeslotsActionTypes.SET_STAY_CURRENT_TIMESLOTS;
  payload: any; 
}


export function setStayCurrentTimeslots(stayCurrentTimeslots: string[]): SetStayCurrentTimeslotsAction {
  return {
    type: StayCurrentTimeslotsActionTypes.SET_STAY_CURRENT_TIMESLOTS,
    payload: stayCurrentTimeslots,
  };
}


export type stayCurrentTimeslotsAction = SetStayCurrentTimeslotsAction;

import { getHotelsJson, getHowToMoveJson, getWhatsExtraJson, getWhatToDoJson, getWhereToEatJson } from "services/jsonFetchService"

export const fetchHotels: any = () => async (dispatch: any, getState: any) => {
        if(!getState().data.hotels.length) {
            dispatch(dataFetchHotels())
            const response = await getHotelsJson();
            dispatch(dataFetchHotelsSuccess(response));
        }
}

export const fetchWhatToDo: any = () => async (dispatch: any, getState: any) => {
    if(!getState().data.whatToDo.length) {
        dispatch(dataFetchWhatToDo())
        const response = await getWhatToDoJson();
        dispatch(dataFetchWhatToDoSuccess(response));
    }
}

export const fetchWhereToEat: any = () => async (dispatch: any, getState: any) => {
    if(!getState().data.whereToEat.length) {
        dispatch(dataFetchWhereToEat())
        const response = await getWhereToEatJson();
        dispatch(dataFetchWhereToEatSuccess(response));
    }
}

export const fetchHowToMoveAround: any = () => async (dispatch: any, getState: any) => {
    if(!getState().data.howToMoveAround.length) {
        dispatch(dataFetchHowToMoveAround())
        const response = await getHowToMoveJson();
        dispatch(dataFetchHowToMoveAroundSuccess(response));
    }
}

export const fetchWhatsExtra: any = () => async (dispatch: any, getState: any) => {
    if(!getState().data.whatsExtra.length) {
        dispatch(dataFetchWhatsExtra())
        const response = await getWhatsExtraJson();
        dispatch(dataFetchWhatsExtraSuccess(response));
    }
}

export const dataFetchHotelsSuccess = (items: any) => {
    return {
        type: 'ADD_HOTELS_DATA',
        payload: items,
    }
}

export const dataFetchHotels = () => {
    return {
        type: 'LOADING_HOTELS_DATA',
    }
}

export const dataFetchWhatToDoSuccess = (items: any) => {
    return {
        type: 'ADD_WHAT_TO_DO_DATA',
        payload: items,
    }
}

export const dataFetchWhatToDo = () => {
    return {
        type: 'LOADING_WHAT_TO_DO_DATA',
    }
}

export const dataFetchWhereToEatSuccess = (items: any) => {
    return {
        type: 'ADD_WHERE_TO_EAT_DATA',
        payload: items,
    }
}

export const dataFetchWhereToEat = () => {
    return {
        type: 'LOADING_WHERE_TO_EAT_DATA',
    }
}

export const dataFetchHowToMoveAroundSuccess = (items: any) => {
    return {
        type: 'ADD_HOW_TO_MOVE_AROUND_DATA',
        payload: items,
    }
}

export const dataFetchHowToMoveAround = () => {
    return {
        type: 'LOADING_HOW_TO_MOVE_AROUND_DATA',
    }
}

export const dataFetchWhatsExtraSuccess = (items: any) => {
    return {
        type: 'ADD_WHATS_EXTRA_DATA',
        payload: items,
    }
}

export const dataFetchWhatsExtra = () => {
    return {
        type: 'LOADING_WHATS_EXTRA_DATA',
    }
}

import { FC } from "react";
import rightImgPng from "images/OT_homepage_what-makes-us-different.jpg";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "",
  rightImg = rightImgPng,
  type = "type2",
}) => {
  return (
    <div
      className={`nc-SectionOurFeatures relative flex flex-col items-center ${
        type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
      } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow ">
        <NcImage className="rounded-3xl" src={rightImg} />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-2/5 ${
          type === "type1" ? "lg:pl-16" : "lg:pr-16"
        }`}
      >
        <h2 className="font-semibold text-4xl mt-5 w-128">
          What makes us different
        </h2>
        <span
          className="mt-2 md:mt-3 font-normal block 
        text-base sm:text-xl text-neutral-500 dark:text-neutral-400"
        >
          Details define the experience.
        </span>
        <ul className="space-y-10 mt-16">
          <li className="space-y-4">
            {/* <Badge name="Advertising" /> */}
            <span className="block text-xl font-semibold">
              Accessibility chain
            </span>
            <p className="block mt-5 text-neutral-500 dark:text-neutral-400">
              <span className="font-bold text-black">From A to Z, let us handle it all :</span>
              <br /> air-tickets, hotels, museum visits, wineries, transfers,
              wheelchair insurance and so much more!
            </p>
          </li>
          <li className="space-y-4">
            {/* <Badge color="green" name="Exposure " /> */}
            <span className="block text-xl font-semibold">Valid options</span>
            <p className="block mt-5 text-neutral-500 dark:text-neutral-400">
              <span className="font-bold text-black"> Thumbs-up :</span>
              <br /> our team visits each place to evaluate wheelchair
              accessibility, ramps, restrooms, and more, sharing detailed
              information for seamless travel.
            </p>
          </li>
          <li className="space-y-4">
            {/* <Badge color="red" name="Secure" /> */}
            <span className="block text-xl font-semibold">Virtual tours</span>
            <p className="block mt-5 text-neutral-500 dark:text-neutral-400">
              <span className="font-bold text-black">See it, feel it, go for it :</span>
              <br /> our Virtual Tours bring the listed locations to life,
              helping you choose the perfect fit for you.
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;

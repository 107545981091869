import {StayCurrentChargesActionTypes, stayCurrentChargesAction} from './../actions/stayCurrentCharges'


export interface StayCurrentChargesState {
  stayCurrentCharges: any[];
}

const initialState: StayCurrentChargesState = {
  stayCurrentCharges: [],
};

const stayCurrentChargesReducer = (state = initialState, action: stayCurrentChargesAction) => {
  switch (action.type) {
    case StayCurrentChargesActionTypes.SET_STAY_CURRENT_CHARGES:
      return {
        ...state,
        stayCurrentCharges: action.payload,
      };
    default:
      return state;
  }
};

export default stayCurrentChargesReducer;
export enum EcoFriendlyActionTypes {
  YES = 'YES',
  NO = 'NO',
}

export interface setEcoFriendlyAction {
  type: EcoFriendlyActionTypes.YES;
}

export interface unsetEcoFriendlyAction {
  type: EcoFriendlyActionTypes.NO;
}

export function setEcoFriendly(): setEcoFriendlyAction {
  return {
    type: EcoFriendlyActionTypes.YES,
  };
}

export function unsetEcoFriendly(): unsetEcoFriendlyAction {
  return {
    type: EcoFriendlyActionTypes.NO,
  };
}

export type EcoFriendlyAction = setEcoFriendlyAction | unsetEcoFriendlyAction;

import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { FC, Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-placeholder/lib/reactPlaceholder.css";
import SectionGridHasMap from "./SectionGridHasMap";
import ReactPlaceholder from "react-placeholder";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { getAllTransportations } from "services/api";
import { ITransportation } from "services/models";
import TransportationCard from "./custom/TransportationCard";
import { RoundShape } from "react-placeholder/lib/placeholders";
import { Popover, Transition } from "@headlessui/react";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Checkbox from "./custom/Checkbox";
import MultiCheckBox from "./custom/MultiCheckBox";
import convertNumbThousand from "utils/convertNumbThousand";
import Slider from "rc-slider";
import CheckboxAsRadio from "shared/Checkbox/CheckboxAsRadio";
import React from "react";
import { TransportationState } from "store/reducers/transportations";
import { setTransportations } from "store/actions/transportation";
import Seo from "components/Seo/Seo";

export interface HowToMoveAroundPageProps {
  className?: string;
}

const HowToMoveAroundPage: FC<HowToMoveAroundPageProps> = ({
  className = "",
}) => {
  const dispatch = useDispatch();

  const destination = useSelector((state: any) => state.search.travelingTo);
  const destinationString = destination?.city + ", " + destination?.country;

  const selectedIds: any = useSelector((state: any) =>
    (state.cart.howToMoveAround ?? []).map((item: any) => item._id)
  );
  // const darkModeState = (state: any) => state.darkMode;
  // const isDarkMode = useSelector(darkModeState).isDarkMode;
  // const startDate = useSelector((state: any) => state.search.dates.startDate);
  // const endDate = useSelector((state: any) => state.search.dates.endDate);
  // const travelDates: any = {
  //   startDate: startDate,
  //   endDate: endDate,
  // };

  const moves = useSelector((state: any) => state.moves.moves);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialTransportation, setInitialTransportations] = useState<
    ITransportation[]
  >([]);
  const transportations: ITransportation[] = useSelector(
    (state: any) => state.transportation.transportations
  );
  useEffect(() => {
    const findAllTransportations = async () => {
      setIsLoading((prev) => true);
      let data = await getAllTransportations();
      data = data.filter((transportation) => {
        return (
          transportation.isActive &&
          transportation.city === destination?.city &&
          transportation.country === destination?.country &&
          transportation.offers.length > 0
        );
      });
      const sortedPrices = data
        .flatMap((i) => i.offers)
        .map((i) => i.price)
        .sort((a, b) => a - b);
      setMinPrice(sortedPrices[0]);
      setMaxPrice(sortedPrices[sortedPrices.length - 1]);
      setRangePrices([sortedPrices[0], sortedPrices[sortedPrices.length - 1]]);
      setInitialTransportations((prev) => data);
      dispatch(setTransportations(data));
      setIsLoading((prev) => false);
    };

    findAllTransportations();
  }, [dispatch]);

  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(100);
  const [rangePrices, setRangePrices] = useState<number[]>([
    minPrice,
    maxPrice,
  ]);

  const updateRangePrice = (numbers: any[]) => {
    setRangePrices(numbers);
  };

  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;

  // placeholders...
  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";
  const filterPlaceholder = (
    <div className="Btn-placeholder">
      <RoundShape style={{ width: 150, height: 40 }} color={placeholderColor} />
    </div>
  );

  const [selectedSort, setSelectedSort] = useState("alpha");
  const [typeOfSorts, setTypeOfSorts] = useState([
    {
      id: "sort-1",
      name: "Alphabetical",
      description: "",
      sort: "alpha",
      checked: true,
    },
    {
      id: "sort-2",
      name: "Price in ascending order",
      description: "",
      sort: "price_asc",
      checked: false,
    },
  ]);
  const handleSortChange = (value: string) => {
    setSelectedSort(value);

    // Get the index of the selected value
    const selectedIndex = typeOfSorts.findIndex((item) => item.sort === value);

    // Set the checked property of the selected value to true
    typeOfSorts[selectedIndex].checked = true;

    // Set the checked property of all other values to false
    for (let i = 0; i < typeOfSorts.length; i++) {
      if (i !== selectedIndex) {
        typeOfSorts[i].checked = false;
      }
    }

    setTypeOfSorts(typeOfSorts);
  };
  const renderTabsTypeOfSort = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Sort By</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 right-1 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfSorts.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <CheckboxAsRadio
                          name={item.name}
                          label={item.name}
                          value={String(item.sort)}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={() => handleSortChange(item.sort)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        handleSortChange("alpha");
                        dispatch(
                          setTransportations(
                            transportations
                              .slice()
                              .sort((a, b) => a.name.localeCompare(b.name))
                          )
                        );
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset All
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        const sorting = typeOfSorts.find(
                          (item) => item.sort === selectedSort
                        );
                        let shortedTransportations: ITransportation[];
                        if (sorting) {
                          switch (sorting.id) {
                            case "sort-1":
                              {
                                shortedTransportations = transportations
                                  .slice()
                                  .sort((a, b) => a.name.localeCompare(b.name));
                              }
                              break;
                            case "sort-2":
                              {
                                shortedTransportations = transportations
                                  .slice()
                                  .sort((a, b) => {
                                    let minPriceA = Math.min(
                                      ...a.offers.map((offer) => offer.price)
                                    );
                                    let minPriceB = Math.min(
                                      ...b.offers.map((offer) => offer.price)
                                    );
                                    return minPriceA - minPriceB;
                                  });
                              }
                              break;
                            case "sort-3":
                              {
                                shortedTransportations = transportations
                                  .slice()
                                  .sort((a, b) => a.type.localeCompare(b.type));
                              }
                              break;
                            default: {
                              shortedTransportations = transportations
                                .slice()
                                .sort((a, b) => a.name.localeCompare(b.name));
                            }
                          }
                          dispatch(setTransportations(shortedTransportations));
                        }
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRange = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center min-w-[160px] px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>
                {`€${convertNumbThousand(
                  rangePrices[0]
                )} - €${convertNumbThousand(rangePrices[1])}`}{" "}
              </span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Price Range</span>
                      <Slider
                        range
                        className="text-red-400"
                        min={minPrice}
                        max={maxPrice}
                        value={[rangePrices[0], rangePrices[1]]}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={(e) => updateRangePrice(e as number[])}
                      />
                    </div>

                    <div className="flex justify-between">
                      <div className="text-left">
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md text-left top-3">
                          <span className="text-neutral-500 sm:text-sm">
                            €{convertNumbThousand(rangePrices[0])}
                          </span>
                          <input
                            type="text"
                            disabled
                            name="minPrice"
                            id="minPrice"
                            className="opacity-0 pl-7 pr-3 sm:text-sm text-neutral-900 border-none font-bold"
                            value={rangePrices[0]}
                          />
                        </div>
                      </div>

                      <div className="text-right">
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md text-right top-3">
                          <span className="text-neutral-500 sm:text-sm">
                            €{convertNumbThousand(rangePrices[1])}
                          </span>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="opacity-0 pl-7 pr-3 sm:text-sm text-neutral-900 border-none font-bold"
                            value={rangePrices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        setRangePrices((prev) => [minPrice, maxPrice]);
                        setTransportations(initialTransportation);
                        // updateRangePrice(rangePrices as number[]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        dispatch(
                          setTransportations(
                            initialTransportation.filter((transportation) => {
                              const isPriceWithinRange =
                                transportation.offers.some((offer) => {
                                  const price = offer.price;
                                  return (
                                    price >= rangePrices[0] &&
                                    price <= rangePrices[1]
                                  );
                                });
                              console.log(isPriceWithinRange);
                              return isPriceWithinRange;
                            })
                          )
                        );
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="HowToMoveAroundPage"
    >
      <Seo
        name="Transportations | Orbito Travel"
        description="Experience convenient and accessible transportation options with Orbito Travel. Explore our range of inclusive services tailored to meet the needs of all travelers. Start your journey with hassle-free travel today!"
        title="Transportations | Orbito Travel"
        type="page"
      />
      <BgGlassmorphism />

      <div className="container relative overflow-hidden ">
        {/* SECTION HERO */}
        {/* <SectionHeroArchivePage
          currentPage="Cars"
          currentTab="Cars"
          title="How to move in"
          subTitle={destinationString}
          rightImage={
            "https://orbito.s3.eu-central-1.amazonaws.com/images/wizard/05.png"
          }
          className="pt-10 lg:pt-16 "
        /> */}

        <div className="grid md:grid-cols-1 gap-6 lg:grid-cols-1 xl:gap-8 mb-4">
          <div className="flex flex-row p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <i
              style={{ margin: "auto 20px" }}
              className="las la-exclamation-circle text-3xl"
            ></i>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              Please note that you can select different types of transfers for
              each day of your trip, depending on your needs. Feel free to
              contact the ortbito travel team, in order to accomodate any other
              transfer request you might have.
            </span>
          </div>
        </div>

        <div>
          <div>
            <div>
              <div className="lg:space-x-4 pb-8 flex justify-between">
                <div className="hidden lg:flex space-x-4">
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    <MultiCheckBox
                      title="Type of service"
                      valueOptions={initialTransportation.map(
                        (transportation) => transportation.type
                      )}
                      onApplyClick={(values) =>
                        dispatch(
                          setTransportations(
                            initialTransportation.filter((transportation) =>
                              values.includes(transportation.type)
                            )
                          )
                        )
                      }
                      onClearFilters={() =>
                        dispatch(setTransportations(initialTransportation))
                      }
                    />
                  </ReactPlaceholder>
                  {/* <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    <MultiCheckBox title="Languages"
                      valueOptions={initialTransportation.flatMap(transportation => transportation.languages)}
                      onApplyClick={(values) => dispatch(setTransportations(initialTransportation.filter(transportation => transportation.languages.some(language => values.includes(language)))))}
                      onClearFilters={() => dispatch(setTransportations(initialTransportation))} />
                  </ReactPlaceholder> */}
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    <MultiCheckBox
                      className="w-[36rem]"
                      title="Type of mobility aid"
                      valueOptions={initialTransportation.flatMap(
                        (transportation) => transportation.typeOfWheelChairs
                      )}
                      onApplyClick={(values) =>
                        dispatch(
                          setTransportations(
                            initialTransportation.filter((transportation) => {
                              const transWheels =
                                transportation.typeOfWheelChairs;
                              return values.some((value) =>
                                transWheels.includes(value)
                              );
                            })
                          )
                        )
                      }
                      onClearFilters={() =>
                        dispatch(setTransportations(initialTransportation))
                      }
                    />
                  </ReactPlaceholder>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsPriceRange()}
                  </ReactPlaceholder>
                </div>
                <div>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfSort()}
                  </ReactPlaceholder>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`nc-SectionGridFilterCard pb-24 lg:pb-28 min-h-[500px]`}
          data-nc-id="SectionGridFilterCard"
        >
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {transportations.length > 0 &&
              transportations.map((transportation: ITransportation) => (
                <TransportationCard
                  key={transportation._id}
                  transportation={transportation}
                  internalLink="/how-to-move-around-detail"
                  isOnCart={selectedIds.includes(transportation._id)}
                  isLoading={isLoading}
                />
              ))}
          </div>
          {transportations.length === 0 && (
            <div className="grid md:grid-cols-1 gap-6 lg:grid-cols-1 xl:gap-8 mb-4">
              <div className="flex flex-row p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                  Sorry, no properties to displayed for your convenience.
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HowToMoveAroundPage;

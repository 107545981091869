import NavigationItem, { NavItemType } from "./NavigationItem";
import { NAVIGATION_TABS } from "data/navigation";
import { useLocation } from "react-router";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useNavigate } from "react-router-dom";
import { useCustomSearchParams } from "context/SearchParamsContext";

function Navigation({ isDemo }: { isDemo: boolean }) {
  const location = useLocation();
  const navigate = useNavigate();
  const cartPage = location.pathname === "/cart";
  const { getStringParams } = useCustomSearchParams();
  if (
    isDemo ||
    location.pathname === "/" ||
    // location.pathname.startsWith("/how-to-get-there") ||
    // location.pathname.startsWith("/where-to-stay-detail") ||
    // location.pathname.startsWith("/what-to-do-detail") ||
    // location.pathname.startsWith("/where-to-eat-and-drink-detail") ||
    // location.pathname.startsWith("/how-to-move-around-detail") ||
    // location.pathname.startsWith("/what-is-extra-detail") ||
    location.pathname.startsWith("/pay-done") ||
    location.pathname.startsWith("/about") ||
    location.pathname.startsWith("/accreditations") ||
    location.pathname.startsWith("/privacy-policy") ||
    location.pathname.startsWith("/terms-and-conditions") ||
    location.pathname.startsWith("/terms-of-use") ||
    location.pathname.startsWith("/contact") ||
    location.pathname.startsWith("/faq") ||
    location.pathname.startsWith("/blog")
  ) {
    return null;
  }

  const nextSection = () => {
    const currentLocation = location.pathname;
    if (currentLocation.startsWith("/what-is-extra")) {
      navigate({ pathname: "/cart", search: getStringParams() });
    }

    NAVIGATION_TABS.forEach((item: NavItemType, index) => {
      if (
        item.href.startsWith(currentLocation) ||
        (item.subHref && currentLocation.includes(item.subHref))
      ) {
        navigate({
          pathname: NAVIGATION_TABS[index + 1].href,
          search: getStringParams(),
        });
      }
    });
  };

  const showNavigation = () => {
    const currentLocation = location.pathname;

    if (currentLocation.includes("/services")) {
      return false;
    } else if (
      localStorage.getItem("no_flight") === "true" &&
      currentLocation.includes("/where-to-stay") &&
      !currentLocation.includes("/where-to-stay/")
    ) {
      return false;
    }
    return true;
  };

  const previousSection = () => {
    const currentLocation = location.pathname;

    if (currentLocation.includes("/cart")) {
      navigate(-1);
    }

    NAVIGATION_TABS.forEach((item: NavItemType, index) => {
      if (item.subHref) {
        if (currentLocation.includes(item.subHref)) {
          navigate({
            pathname: NAVIGATION_TABS[index].href,
            search: getStringParams(),
          });
        }
      }
      if (item.href === currentLocation && index > 0) {
        navigate({
          pathname: NAVIGATION_TABS[index - 1].href,
          search: getStringParams(),
        });
      }
    });
  };

  return (
    <>
      <div className="flex flex-col justify-end items-center">
        <div className="">
          <ButtonPrimary
            className={`${!showNavigation() ? "invisible" : null} bg-[#EF8733]`}
            onClick={() => previousSection()}
          >
            <i className="las la-arrow-left text-2xl"></i>
            {/* Previous Section */}
          </ButtonPrimary>
        </div>
        {!showNavigation() ? null : (
          <p className="italic text-[#676767]">back</p>
        )}
      </div>

      <ul
        className="first-step nc-Navigation flex flex-row items-center gap-4
      justify-between lg:flex lg:flex-wrap lg:items-center relative "
      >
        {NAVIGATION_TABS.map((item, i) => {
          if (i === 0 && localStorage.getItem("no_flight") === "true") {
            return;
          } else {
            return <NavigationItem key={item.id} menuItem={item} />;
          }
        })}
      </ul>

      <div className="flex flex-col justify-between items-center">
        <ButtonPrimary
          className={`${cartPage ? "invisible" : null} bg-[#EF8733]`}
          onClick={() => nextSection()}
        >
          {/* Next Section */}
          <i className="las la-arrow-right text-2xl"></i>
        </ButtonPrimary>
        {cartPage ? null : <p className="italic text-[#676767]">next</p>}
      </div>
    </>
  );
}

export default Navigation;

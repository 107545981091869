import { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NcImage from "shared/NcImage/NcImage";
import ModalPhotos from "./ModalPhotos";
import MobileFooterSticky from "./MobileFooterSticky";
import { useParams } from "react-router-dom";
import { DataDetailType } from "data/types";
import {
  getAllExtraServices,
  getAllTransportations,
  getMoveData,
} from "services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  addWhatsExtra,
  removeFromCart,
  removeWhatsExtra,
} from "store/actions/cart";
import { euro } from "utils/currency";

import moment, { Moment } from "moment";

import "react-placeholder/lib/reactPlaceholder.css";

import ReactPlaceholder from "react-placeholder";
import {
  TextBlock,
  RectShape,
  RoundShape,
} from "react-placeholder/lib/placeholders";
import { IExtraService, IOffer } from "services/models";
import PlaceGuestsInput from "components/HeroSearchForm/PlaceGuestsInput";
import { OffersByDate } from "./ServicePerDateSlotsInput";
import ServiceSlotsInput from "./ServiceSlotsInput";
import ServicePerDateSlotsInput from "./ServicePerDateSlotsInput";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { WhatsExtraCartItem } from "store/reducers/cart";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import TermsAndConditionsSection from "components/CancelationPolicy/TermsAndConditionsSection";
import { PlaceGuestsObject } from "components/HeroSearchForm2Mobile/PlaceGuestsInput";
import { formatDate } from "helpers/methods";
import isPricePerPerson from "utils/isPricePerPerson";
import MinusIcon from "svg/MinusIcon";
import PlusIcon from "svg/PlusIcon";
import AddToCartModal from "components/AddToCartModal/AddToCartModal";
import Validation from "components/Validation/Validation";

const getDatesInRange = (startDate: Moment, endDate: Moment) => {
  let dateArray = [];
  let currentDate = moment(startDate);

  while (currentDate <= endDate) {
    dateArray.push(currentDate.format("l"));
    currentDate.add(1, "days");
  }

  return dateArray;
};

export interface WhatsExtraDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
  data?: DataDetailType[];
}

export type IOfferCheckOptions = IOffer & {
  isEnabled: boolean;
};
// TODO Check if the tranpsortation exists , and if not 404
const WhatsExtraDetailPage: FC<WhatsExtraDetailPageProps> = ({
  className = "",
  isPreviewMode,
  // data = [],
}) => {
  let params = useParams();
  const dispatch = useDispatch();
  const [extraService, setExtraService] = useState<IExtraService>();
  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  const TravelDates = useSelector((state: any) => state.search.dates);
  const [isLoading, setIsLoading] = useState(true);
  const currentUserState = (state: any) => state.auth;
  const auth = useSelector(currentUserState);
  const [isOpenModalVirtualRoom, setIsOpenModalVirtualRoom] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);
  const [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const travelers = useSelector((state: any) => state.search.guests);
  const guestsCount = travelers.guestAdults;
  const guestWheelChairCount = travelers.guestWheelchair;
  const [onOffOffers, setOnOffOffers] = useState<IOfferCheckOptions[]>();
  const [offersByDate, setOffersByDate] = useState<OffersByDate>({});
  const selectedIds: string[] = useSelector((state: any) =>
    (state.cart.whatsExtra ?? []).map((item: any) => item._id)
  );
  const [extraServiceCount, setExtraServiceCount] = useState<PlaceGuestsObject>(
    {
      guestAdults: guestsCount !== null ? guestsCount : 0,
      guestChildren: 0,
      guestInfants: 0,
      guestWheelchair: guestWheelChairCount !== null ? guestWheelChairCount : 0,
    }
  );
  const totalExtraServiceCount =
    (extraServiceCount?.guestAdults || 0) +
    (extraServiceCount?.guestWheelchair || 0);
  const [errors, setErrors] = useState<any>({});
  const [showAddToCartModal, setShowAddToCartModal] = useState(false);
  useEffect(() => {
    let isCancelled = false;
    const findExtraService = async () => {
      setIsLoading((prev) => true);
      let extraServices = await getAllExtraServices();

      const extaServ = extraServices.find(
        (extraService) => extraService._id === params.id
      );
      const offers = extaServ!.offers.map((offer) => ({
        ...offer,
        isEnabled: false,
      }));
      const savedOnOffStringService = localStorage.getItem(extaServ!._id);
      if (extaServ?.isOnOffService) {
        setOnOffOffers((prev) => offers);
        // if (!savedOnOffStringService) {
        //   localStorage.setItem(extaServ._id, JSON.stringify(offers))
        //   setOnOffOffers((prev) => offers);
        // } else {
        //   setOnOffOffers((prev) => JSON.parse(savedOnOffStringService));
        // }
      } else {
        const dates = getDatesInRange(
          TravelDates.startDate,
          TravelDates.endDate
        );
        const initialData: OffersByDate = {};
        dates.forEach((date) => {
          initialData[date] = offers!; // Initialize with an empty array
        });
        // if (!savedOnOffStringService) {
        //   localStorage.setItem(extaServ!._id, JSON.stringify(initialData))
        //   setOffersByDate((prev) => initialData);
        // } else {
        //   setOffersByDate((prev) => JSON.parse(savedOnOffStringService));
        // }
        setOffersByDate((prev) => initialData);
      }
      setExtraService((prev) => extaServ!);
      setIsLoading((prev) => false);
    };
    (async () => {
      if (!isCancelled) {
        await findExtraService();
      }
    })();

    return () => {
      isCancelled = true;
    };
  }, []);

  // fect(() => {
  //   if (extraService) {
  //     const storedTransportationOffers = localStorage.getItem(extraService._id)
  //     if (extraService?.isOnOffService) {
  //       localStorage.setItem(extraService._id, JSON.stringify(onOffOffers))
  //     } else {
  //       localStorage.setItem(extraService._id, JSON.stringify(offersByDate))
  //     }useEf
  //   }
  // }, [onOffOffers, offersByDate])

  const useSmoothScroll = () => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        const scrollPercentage =
          (window.pageYOffset / window.innerHeight) * 100;
        setIsScrolled(scrollPercentage > 65);

        // Scroll to a specific element or position
        const targetElement = document.getElementById("targetElementId");
        targetElement?.scrollIntoView({
          behavior: "smooth",
        });
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return isScrolled;
  };

  const handleFocusSelect = () => {
    var tempErrors = { ...errors };

    delete tempErrors["extras"];
    setErrors(tempErrors);
  };

  const isScrolled = useSmoothScroll();

  // const isOnCart = selectedIds.includes(id);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  function closeModalVirtualRoom() {
    setIsOpenModalVirtualRoom(false);
  }

  function openModalVirtualRoom() {
    setIsOpenModalVirtualRoom(true);
  }

  const handleCloseModal = () => setIsOpen(false);

  // placeholders...
  // let placeholderColor = isDarkMode ? "#CCCCCC" : "#E0E0E0";
  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";
  const pricePlaceholder = (
    <div className="price-placeholder">
      <TextBlock color={placeholderColor} rows={2} />
    </div>
  );

  const btnPlaceholder = (
    <div className="Btn-placeholder">
      <RoundShape style={{ width: 380, height: 50 }} color={placeholderColor} />
    </div>
  );

  const sideboxesPlaceholder = (
    <div className="sidebars-placeholder">
      <RoundShape
        style={{ width: 378, height: 178 }}
        color={placeholderColor}
      />
    </div>
  );

  const textFourRowsPlaceholder = (
    <div className="text-4-placeholder">
      <TextBlock color={placeholderColor} rows={4} />
    </div>
  );

  const galleryPlaceholder = (
    <div className="gallery-placeholder">
      <RectShape
        style={{ width: 1000, height: 590 }}
        color={placeholderColor}
      />
    </div>
  );

  const subGalleryPlaceholder = (
    <div className="subGallery-placeholder">
      <RectShape style={{ width: 500, height: 290 }} color={placeholderColor} />
    </div>
  );

  const mapPlaceholder = (
    <div className="map-placeholder">
      <RectShape style={{ width: 725, height: 400 }} color={placeholderColor} />
    </div>
  );

  const showAllPhotosPlaceholder = (
    <div className="showAllPhotos-placeholder">
      <RectShape style={{ width: 50, height: 20 }} color={placeholderColor} />
    </div>
  );

  const textOneRowPlaceholder = (
    <div className="price-placeholder">
      <TextBlock color={placeholderColor} rows={1} />
    </div>
  );

  const textTwoRowsPlaceholder = (
    <div className="text-2-placeholder">
      <TextBlock color={placeholderColor} rows={2} />
    </div>
  );

  const textFiveRowsPlaceholder = (
    <div className="text-5-placeholder">
      <TextBlock color={placeholderColor} rows={5} />
    </div>
  );

  const textTenRowsPlaceholder = (
    <div className="text-10-placeholder">
      <TextBlock color={placeholderColor} rows={10} />
    </div>
  );

  const ratePlaceholder = (
    <div className="rate-placeholder">
      <RectShape style={{ width: 150, height: 30 }} color={placeholderColor} />
    </div>
  );

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <ReactPlaceholder
            ready={!isLoading}
            customPlaceholder={textOneRowPlaceholder}
            showLoadingAnimation
          >
            <div className="flex flex-row">
              <span className="mr-1">{extraService?.type} </span>
            </div>
          </ReactPlaceholder>
        </div>
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={textTwoRowsPlaceholder}
          showLoadingAnimation
        >
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
            {extraService?.name}
          </h2>
        </ReactPlaceholder>
        <div className="flex items-center space-x-4">
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1">
              {" "}
              {extraService?.city}, {extraService?.country}{" "}
            </span>
          </span>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Overview</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={textTenRowsPlaceholder}
          showLoadingAnimation
        >
          <div className="text-neutral-6000 dark:text-neutral-300 prose">
            <div
              dangerouslySetInnerHTML={{ __html: extraService!.overview! }}
            />
          </div>
        </ReactPlaceholder>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Accessibility Features </h2>
          <br />
          <ReactPlaceholder
            ready={!isLoading}
            customPlaceholder={textTenRowsPlaceholder}
            showLoadingAnimation
          >
            <div
              className="text-neutral-6000 dark:text-neutral-300"
              dangerouslySetInnerHTML={{
                __html: extraService!.accessibilityFeatures,
              }}
            />
          </ReactPlaceholder>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={textFiveRowsPlaceholder}
          showLoadingAnimation
        >
          <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
            {extraService?.accessibilityIcons &&
              extraService?.accessibilityIcons
                ?.filter((_, i) => i < 12)
                .map((item) => (
                  <div key={item.name} className="flex items-center space-x-3">
                    <i className={`text-3xl las ${item.icon}`}></i>
                    <span className=" ">{item.name}</span>
                  </div>
                ))}
          </div>
        </ReactPlaceholder>

        {/* ----- */}
        {extraService?.accessibilityIcons &&
          extraService?.accessibilityIcons?.length >= 13 && (
            <>
              <div className="w-14 border-b border-neutral-200"></div>
              <div>
                <ButtonSecondary onClick={openModalAmenities}>
                  View more
                </ButtonSecondary>
              </div>
              {renderMotalAmenities()}
            </>
          )}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Accessibility Features
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {extraService?.accessibilityIcons
                      ?.filter((_, i) => i < 1212)
                      .map((item) => (
                        <div
                          key={item.name}
                          className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                        >
                          <i
                            className={`text-4xl text-neutral-6000 las ${item.icon}`}
                          ></i>
                          <span>{item.name}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection8 = () => {
    return (
      <TermsAndConditionsSection
        isLoading={isLoading}
        thingsToKnow={extraService?.thingsToKnow}
        cancelationPolicy={extraService?.cancelationPolicy}
        notes={extraService?.notes}
      />
    );
  };

  const renderMotalVirtualRoom = () => {
    return (
      <Transition appear show={isOpenModalVirtualRoom} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-4 h-screen w-full">
                <div className="inline-flex  flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-6 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      {" "}
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalVirtualRoom} />
                    </span>
                  </div>
                  <div className="h-full overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    <iframe
                      title="VR_Rom"
                      data-hj-allow-iframe
                      src={
                        auth.isAuthenticated
                          ? extraService?.lhref
                          : extraService?.href
                      }
                      className={"w-full h-full"}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSidebar = () => {
    function calculatePricePerPerson(): number {
      let finalPricePerPerson = 0;
      if (extraService) {
        if (extraService.isOnOffService) {
          finalPricePerPerson = onOffOffers
            ? onOffOffers
                ?.filter((off) => off.isEnabled)
                .map((off) => off.price)
                .reduce((acc, current) => acc + current, 0)
            : 0;
        } else {
          Object.values(offersByDate).forEach((offers) => {
            offers.forEach((offer) => {
              if (offer.isEnabled) {
                finalPricePerPerson += offer.price;
              }
            });
          });
        }
      }

      return finalPricePerPerson;
    }

    const isOnCart = selectedIds.includes(extraService!._id) || false;
    const addToCartHandler = () => {
      if (extraService) {
        if (isOnCart) {
          dispatch(removeWhatsExtra(extraService._id!));
        } else {
          if (extraService) {
            let selectedOffers: {
              date?: string | undefined;
              price: number;
              type: string;
              quantity: number;
            }[] = [];

            if (extraService.isOnOffService) {
              selectedOffers =
                onOffOffers
                  ?.filter((offer) => offer.isEnabled)
                  .map((offer) => ({
                    price:
                      offer.price *
                      isPricePerPerson(extraService, totalExtraServiceCount),
                    type: offer.type,
                    quantity: totalExtraServiceCount,
                  })) || [];
            } else {
              for (const [date, offerOptions] of Object.entries(offersByDate)) {
                const selectedOffer = offerOptions.find(
                  (offer) => offer.isEnabled
                );
                if (selectedOffer) {
                  selectedOffers.push({
                    date: formatDate(date),
                    price:
                      selectedOffer.price *
                      isPricePerPerson(extraService, totalExtraServiceCount),
                    type: selectedOffer.type,
                    quantity: totalExtraServiceCount,
                  });
                }
              }
            }
            if (selectedOffers.length === 0) {
              let tempErrors: any = {};
              tempErrors["extras"] = "Please select an option";
              setErrors(tempErrors);
            } else {
              const whatsExtraCheckout: WhatsExtraCartItem = {
                _id: extraService._id,
                cart_type: "WHATS_EXTRA",
                cardImg: extraService?.cardImgs[0]?.url || "",
                name: extraService.name,
                selectedOffers: selectedOffers,
                type: extraService.type,
                termsAndConditions: {
                  cancelationPolicy: extraService.cancelationPolicy,
                  notes: extraService.notes,
                },
              };
              dispatch(addWhatsExtra(whatsExtraCheckout));
              if (localStorage.getItem("disableNotification") !== "true") {
                setShowAddToCartModal(true);
              }
            }
          }
        }
      }
    };

    function calculateTotalGuests(): number {
      return guestsCount + guestWheelChairCount;
    }

    function calculateTotalPrice() {
      return (
        calculatePricePerPerson() *
        isPricePerPerson(extraService, totalExtraServiceCount)
      );
    }

    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* ROOM Virtual Tour */}
        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={sideboxesPlaceholder}
          showLoadingAnimation
        >
          {extraService?.href && (
            <ButtonPrimary
              className="text-2xl virtual-tour"
              onClick={() => openModalVirtualRoom()}
            >
              <i className={`las la-photo-video text-4xl pr-2`}></i>
              Virtual Tour
            </ButtonPrimary>
          )}
          {renderMotalVirtualRoom()}
        </ReactPlaceholder>

        {/* SIDE BOXES */}

        <ReactPlaceholder
          ready={!isLoading}
          customPlaceholder={sideboxesPlaceholder}
          showLoadingAnimation
        >
          <div className="relative">
            <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
              <div className="flex flex-row divide-x divide-neutral-200 dark:divide-neutral-700 !grid-cols-1 sm:!grid-cols-2">
                <PlaceGuestsInput
                  title={extraService?.countParam || "Count"}
                  fieldClassName="p-4 w-1/2"
                  setGuests={setExtraServiceCount}
                  guests={extraServiceCount}
                  hasButtonSubmit={false}
                />
                {extraService?.isOnOffService ? (
                  <ServiceSlotsInput
                    handleFocus={handleFocusSelect}
                    offers={onOffOffers || []}
                    handleChange={(selectedOffer) => {
                      setOnOffOffers((prev) =>
                        prev?.map((offer) => {
                          if (offer.type === selectedOffer.type) {
                            return { ...offer, isEnabled: !offer.isEnabled };
                          }
                          return { ...offer, isEnabled: false };
                        })
                      );
                    }}
                  />
                ) : (
                  <ServicePerDateSlotsInput
                    handleFocus={handleFocusSelect}
                    offers={offersByDate}
                    handleChange={(date, offer) => {
                      setOffersByDate((prev) => {
                        // Check if the specified date exists in the prevState and if it has offers
                        if (prev[date] && Array.isArray(prev[date])) {
                          // Map over the offers on the specified date
                          const updatedOffers = prev[date].map((off) => {
                            if (off.type === offer?.type) {
                              // Return a new object with isEnabled set to true for the matching offer
                              return {
                                ...off,
                                isEnabled: true,
                              };
                            } else {
                              // Return a new object with isEnabled set to false for other offers
                              return {
                                ...off,
                                isEnabled: false,
                              };
                            }
                          });

                          // Return the updated state with the new offers array for the specified date
                          return {
                            ...prev,
                            [date]: updatedOffers,
                          };
                        }

                        // If the date does not exist or does not have offers, return the state as is
                        return prev;
                      });
                    }}
                  />
                )}
              </div>
            </form>
            {errors["extras"] && (
              <Validation
                classNameCustom="absolute left-1/2 -bottom-1/2"
                text={errors["extras"]}
              />
            )}
          </div>
        </ReactPlaceholder>
        {/* // const [onOffOffers, setOnOffOffers] = useState<IOfferCheckOptions[]>()
    // const [offersByDate, setOffersByDate] = useState<OffersByDate>({}); */}

        <div className="mb-4 text-neutral-6000 dark:text-neutral-300">
          <div className="flex justify-between items-center mb-2">
            <span>Subtotal</span>
            <span>
              €
              {calculateTotalPrice().toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
          <div className="flex justify-between items-center mb-2">
            <span>Taxes and other charges</span>
            <span>€0.00</span>
          </div>
          <div className="border-t mt-2 pt-2">
            <div className="flex justify-between items-center font-semibold text-[18px]">
              <span>Total</span>
              <span>
                €
                {calculateTotalPrice().toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
          </div>
        </div>
        <ButtonPrimary
          onClick={() => addToCartHandler()}
          // disabled={calculatePricePerPerson() === 0}
          className={`
          h-[83px]
          ring-0
      
        ${isOnCart ? "custom-green-button-cart" : ""}`}
        >
          <p className="flex space-x-2 justify-center items-center">
            {isOnCart ? (
              <>
                <span> Added To cart</span>
              </>
            ) : (
              <>
                <span>Add to cart</span>
              </>
            )}
          </p>
        </ButtonPrimary>
      </div>
    );
  };

  const renderIncludes = () => {
    return (
      <div className="listingSection__wrap spacer-y-4">
        <div>
          <h2 className="text-2xl font-semibold">Included </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {extraService?.whatIsIncludes
            ?.filter((_item, i) => i < 12)
            .map((item, index) => (
              <div
                key={`includes-${index}`}
                className="flex items-center space-x-3"
              >
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.text}</span>{" "}
                {/* Render the text property of the item */}
              </div>
            ))}
        </div>
      </div>
    );
  };

  const renderNonIncludes = () => {
    return (
      <div className="listingSection__wrap spacer-y-4">
        <div>
          <h2 className="text-2xl font-semibold">Not Included </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {extraService?.whatNotIncludes
            ?.filter((_item, i) => i < 12)
            .map((item, index) => (
              <div
                key={`not-includes-${index}`}
                className="flex items-center space-x-3"
              >
                <i className="las la-minus-circle text-2xl"></i>
                <span>{item.text}</span>{" "}
                {/* Render the text property of the item */}
              </div>
            ))}
        </div>
      </div>
    );
  };

  return extraService ? (
    <div
      className={`ListingMoveDetailPage nc-ListingMoveDetailPage ${className}`}
      data-nc-id="ListingMoveDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <AddToCartModal
          title={extraService ? extraService.name : "Extra Service"}
          showModal={showAddToCartModal}
          onClose={() => setShowAddToCartModal(false)}
        />{" "}
        {extraService.detailsImgs && extraService?.detailsImgs?.length > 1 ? (
          <header className="container 2xl:px-14 rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
              <div
                className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
                onClick={() => handleOpenModal(0)}
              >
                <ReactPlaceholder
                  ready={!isLoading}
                  customPlaceholder={galleryPlaceholder}
                  showLoadingAnimation
                >
                  <NcImage
                    containerClassName="absolute inset-0"
                    className="object-cover w-full h-full rounded-md sm:rounded-xl"
                    src={
                      extraService?.detailsImgs &&
                      extraService?.detailsImgs.length > 0
                        ? extraService?.detailsImgs[0].url
                        : ""
                    }
                  />
                </ReactPlaceholder>
              </div>
              {extraService?.detailsImgs
                ?.filter((_item, i) => i >= 1 && i < 5)
                .map((item, index) => (
                  <div
                    key={item?.url || index} // Use a unique identifier if available
                    className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                      index >= 3 ? "hidden sm:block" : ""
                    }`}
                  >
                    <ReactPlaceholder
                      ready={!isLoading}
                      customPlaceholder={subGalleryPlaceholder}
                      showLoadingAnimation
                    >
                      <NcImage
                        containerClassName=""
                        className="object-cover w-full h-full rounded-md sm:rounded-xl"
                        src={item.url || ""} // Assuming each item has a 'url' property
                      />
                    </ReactPlaceholder>

                    {/* OVERLAY */}
                    <div
                      className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                      onClick={() => handleOpenModal(index + 1)}
                    />
                  </div>
                ))}

              <div
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
                onClick={() => handleOpenModal(0)}
              >
                <ReactPlaceholder
                  ready={!isLoading}
                  customPlaceholder={showAllPhotosPlaceholder}
                  showLoadingAnimation
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                    />
                  </svg>
                  <span className="ml-2 text-neutral-800 text-sm font-medium">
                    Show all photos
                  </span>
                </ReactPlaceholder>
              </div>
            </div>
          </header>
        ) : null}
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={extraService?.detailsImgs || []}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingMoveDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection1()}
          {extraService.overview && extraService.overview.length > 0
            ? renderSection2()
            : null}
          {extraService?.accessibilityFeatures && renderSection3()}
          {renderIncludes()}
          {renderNonIncludes()}
          {/* {renderSection4()} */}
          {/* {renderSectionCheckIndate()} */}
          {/* {renderSection5()} */}
          {/* {renderSection6()} */}
          {/* {renderSection7()} */}
          {extraService?.notes && renderSection8()}
          <div className="pt-8"></div>
        </div>

        {/* SIDEBAR */}
        {/* <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div
            className={`sticky ${isScrolled ? "smooth-scroll top-28 pt-20 pb-12" : ""
              }`}
          >
            {extraService && <div>{renderSidebar()}</div>}
          </div>
        </div> */}
        <div className="md:block md:flex-grow md:mt-0">
          <div className={"sticky smooth-scroll top-48 pb-12 "}>
            <div>{renderSidebar()}</div>
          </div>
        </div>
      </main>

      {/* OTHER SECTION */}
    </div>
  ) : (
    <>Not Found</>
  );
};

export default WhatsExtraDetailPage;

import { CityInfo } from "services/api";

const ArrivingCities: CityInfo[] = [
  {
    city: "Athens",
    country: "Greece",
    IATA: "ATH",
    disabled: false,
  },
  // {
  //   city: "Thessaloniki",
  //   country: "Greece",
  //   IATA: "SKG",
  //   disabled: false,
  // },
  {
    city: "Amsterdam",
    country: "Netherlands",
    IATA: "AMS",
    disabled: true,
  },
  {
    city: "Barcelona",
    country: "Spain",
    IATA: "BCN",
    disabled: true,
  },
  {
    city: "Berlin",
    country: "Germany",
    IATA: "TXL",
    disabled: true,
  },
  {
    city: "Rome",
    country: "Italy",
    IATA: "FCO",
    disabled: true,
  },
  {
    city: "Vienna",
    country: "Austria",
    IATA: "VIE",
    disabled: true,
  },
];
export default ArrivingCities;
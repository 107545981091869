import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import { PostDataType } from "data/types";
import { Link } from "react-router-dom";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import { BlogPost } from "services/models";
import moment from "moment";
import Avatar from "shared/Avatar/Avatar";

export interface Card13Props {
  className?: string;
  post: PostDataType | BlogPost;
}

const Card13: FC<Card13Props> = ({ className = "", post }) => {
  if (post.postType === "blogpost") {
    const { title, author, createdAt, descr, image, slug, postType } = post;
    const createdFormattedDate = moment(createdAt).format("DD/MM/YYYY");
    const size = "normal";
    return (
      <div
        className={`nc-Card13 relative flex ${className}`}
        data-nc-id="Card13"
      >
        <div className="flex flex-col h-full py-2">
          <h2 className={`nc-card-title block font-semibold text-base`}>
            <Link to={`/blog/${slug}`} className="line-clamp-2" title={title}>
              {title}
            </Link>
          </h2>
          <span className="hidden sm:block my-3 text-neutral-500 dark:text-neutral-400 ">
            <span className="line-clamp-2"> {descr}</span>
          </span>
          <span className="mt-4 block sm:hidden text-sm text-neutral-500 ">
            {createdFormattedDate}
          </span>
          <div className="mt-auto hidden sm:block">
            <div
              className={`nc-PostCardMeta inline-flex items-center fledx-wrap text-neutral-800 dark:text-neutral-200 ${
                size === "normal" ? "text-sm" : "text-base"
              } ${className}`}
              data-nc-id="PostCardMeta"
            >
              <span className="block text-neutral-6000 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
                <Avatar
                  containerClassName="flex-shrink-0"
                  sizeClass="w-8 h-8 sm:h-11 sm:w-11"
                />{" "}
                {author}
              </span>

              <>
                <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
                  ·
                </span>
                <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
                  {createdFormattedDate}
                </span>
              </>
            </div>
          </div>
        </div>

        <Link
          to={`/blog/${slug}`}
          className={`block relative h-full flex-shrink-0 w-2/5 sm:w-1/3 ml-3 sm:ml-5`}
        >
          <NcImage
            containerClassName="absolute inset-0 "
            className="object-cover w-full h-full rounded-xl sm:rounded-3xl"
            src={image}
            alt={title}
          />
          <PostTypeFeaturedIcon
            className="absolute bottom-2 left-2"
            postType={"standard"}
            wrapSize="w-8 h-8"
            iconSize="w-4 h-4"
          />
        </Link>
      </div>
    );
  } else {
    const { title, href, desc, featuredImage, date, postType } = post;
    return (
      <div
        className={`nc-Card13 relative flex ${className}`}
        data-nc-id="Card13"
      >
        <div className="flex flex-col h-full py-2">
          <h2 className={`nc-card-title block font-semibold text-base`}>
            <Link to={href} className="line-clamp-2" title={title}>
              {title}
            </Link>
          </h2>
          <span className="hidden sm:block my-3 text-neutral-500 dark:text-neutral-400 ">
            <span className="line-clamp-2"> {desc}</span>
          </span>
          <span className="mt-4 block sm:hidden text-sm text-neutral-500 ">
            {date}
          </span>
          <div className="mt-auto hidden sm:block">
            <PostCardMeta meta={{ ...post }} />
          </div>
        </div>

        <Link
          to={href}
          className={`block relative h-full flex-shrink-0 w-2/5 sm:w-1/3 ml-3 sm:ml-5`}
        >
          <NcImage
            containerClassName="absolute inset-0 "
            className="object-cover w-full h-full rounded-xl sm:rounded-3xl"
            src={featuredImage}
            alt={title}
          />
          <PostTypeFeaturedIcon
            className="absolute bottom-2 left-2"
            postType={postType}
            wrapSize="w-8 h-8"
            iconSize="w-4 h-4"
          />
        </Link>
      </div>
    );
  }
};

export default Card13;

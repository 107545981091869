import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { FC, Fragment, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import CheckboxAsRadio from "shared/Checkbox/CheckboxAsRadio";
import Slider from "rc-slider";
import convertNumbThousand from "utils/convertNumbThousand";
import { setPlaces } from "./../../store/actions/places";
import { getPlacesResults } from "services/api";
import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import {
  // TextBlock,
  // RectShape,
  RoundShape,
} from "react-placeholder/lib/placeholders";
import Seo from "components/Seo/Seo";

export interface WhatToDoPageProps {
  className?: string;
}

const WhatToDoPage: FC<WhatToDoPageProps> = ({ className = "" }) => {
  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  const destination = useSelector((state: any) => state.search.travelingTo);
  const startDate = useSelector((state: any) => state.search.dates.startDate);
  const endDate = useSelector((state: any) => state.search.dates.endDate);
  const travelDates: any = {
    startDate: startDate,
    endDate: endDate,
  };
  const guestsCount = useSelector(
    (state: any) => state.search.guests.guestAdults
  );
  const guestWheelchairCount = useSelector(
    (state: any) => state.search.guests.guestWheelchair
  );
  const destinationString = destination?.city + ", " + destination?.country;

  const dispatch = useDispatch();
  const places = useSelector((state: any) => state.places.places);
  const [isLoading, setIsLoading] = useState(true);

  const [originalResults, setOriginalResults] = useState<any[]>(places);
  const [minPlacePrice, setMinPlacePrice] = useState(0);
  const [maxPlacePrice, setMaxPlacePrice] = useState(0);

  const [typeOfProperties, setTypeOfProperties] = useState<any[]>([]);
  const [typeOfLanguages, setTypeOfLanguages] = useState<any[]>([]);
  const [typeOfDurations, setTypeOfDurations] = useState<any[]>([]);

  const fetchPlaces = async () => {
    // await setIsLoading(true);
    const data = await getPlacesResults(
      destination?.city,
      travelDates,
      guestsCount,
      guestWheelchairCount
    );
    await dispatch(setPlaces(data));
    await setOriginalResults(data);
    const getMinPrice = minPrice();
    await setMinPlacePrice(getMinPrice);
    const getMaxPrice = maxPrice();
    await setMaxPlacePrice(getMaxPrice);
    await setRangePrices([getMinPrice, getMaxPrice]);
    const uniqueTypes = await places.reduce((types: any[], place: any) => {
      if (Array.isArray(place.type_filter)) {
        place.type_filter.forEach((typeName: any) => {
          // Check if typeName.name already exists in the types array
          if (!types.some((type: any) => type.name === typeName.name)) {
            types.push({
              name: typeName.name,
              description: "",
              value: null,
              checked: true,
            });
          }
        });
      } else if (typeof place.type_filter === "string") {
        if (!types.some((type: any) => type.name === place.type_filter)) {
          types.push({
            name: place.type_filter,
            description: "",
            value: null,
            checked: true,
          });
        }
      }
      return types;
    }, []);

    uniqueTypes.sort((a: any, b: any) => a.name.localeCompare(b.name));
    await setTypeOfProperties(uniqueTypes);
    const uniqueLanguages = await places.reduce(
      (languages: any[], place: any) => {
        if (Array.isArray(place.languages)) {
          place.languages.forEach((languageName: any) => {
            if (
              !languages.some(
                (language: any) => language.name === languageName.lang
              )
            ) {
              languages.push({
                name: languageName.lang,
                description: "",
                value: null,
                checked: true,
              });
            }
          });
        } else if (typeof place.languages === "string") {
          if (
            !languages.some(
              (language: any) => language.name === place.languages
            )
          ) {
            languages.push({
              name: place.languages,
              description: "",
              value: null,
              checked: true,
            });
          }
        }
        return languages;
      },
      []
    );

    uniqueLanguages.sort((a: any, b: any) => a.name.localeCompare(b.name));
    await setTypeOfLanguages(uniqueLanguages);

    await setTypeOfDurations([
      {
        id: 1,
        name: "up to 3 hours",
        description: "",
        from: 0,
        to: 180,
        checked: true,
      },
      {
        id: 2,
        name: "3 to 5 hours",
        description: "",
        from: 180,
        to: 300,
        checked: true,
      },
      {
        id: 3,
        name: "5 to 7 hours",
        description: "",
        from: 300,
        to: 420,
        checked: true,
      },
      {
        id: 4,
        name: "more than 7 hours",
        description: "",
        from: 421,
        to: 9999,
        checked: true,
      },
    ]);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchPlaces();
  }, [dispatch, maxPlacePrice, minPlacePrice, isLoading]);

  // ###################################
  // Filters
  const [selectedSort, setSelectedSort] = useState("alpha");

  const [typeOfSorts, setTypeOfSorts] = useState([
    {
      id: "sort-1",
      name: "Alphabetical",
      description: "",
      sort: "alpha",
      checked: true,
    },
    {
      id: "sort-2",
      name: "Price in ascending order",
      description: "",
      sort: "price_asc",
      checked: false,
    },
  ]);

  const maxPrice = () => {
    let maxPrice = Number.MIN_VALUE;
    if (Array.isArray(originalResults)) {
      for (const place of originalResults) {
        if (place.price > maxPrice) {
          maxPrice = place.price;
        }
      }
    }
    return maxPrice;
  };

  const minPrice = () => {
    let minPrice = Number.MAX_VALUE; // Set the initial minimum price to the maximum possible value
    if (Array.isArray(originalResults)) {
      for (const place of originalResults) {
        if (place.price < minPrice) {
          // Check if the current hotel's price is less than the current minimum price
          minPrice = place.price; // Update the minimum price if a lower price is found
        }
      }
    }
    return minPrice;
  };

  const [rangePrices, setRangePrices] = useState([0, maxPlacePrice]);
  // ###################################

  const handleSortChange = (value: string) => {
    setSelectedSort(value);

    // Get the index of the selected value
    const selectedIndex = typeOfSorts.findIndex((item) => item.sort === value);

    // Set the checked property of the selected value to true
    typeOfSorts[selectedIndex].checked = true;

    // Set the checked property of all other values to false
    for (let i = 0; i < typeOfSorts.length; i++) {
      if (i !== selectedIndex) {
        typeOfSorts[i].checked = false;
      }
    }

    setTypeOfSorts(typeOfSorts);
  };

  const updateRangePrice = (numbers: any[]) => {
    setRangePrices(numbers);
  };

  const resetProperties = () => {
    const uniqueTypes = originalResults.reduce((types: any, place: any) => {
      if (Array.isArray(place.type_filter)) {
        place.type_filter.forEach((typeName: { name: string }) => {
          if (!types.find((type: any) => type.name === typeName.name)) {
            types.push({
              name: typeName.name,
              description: "",
              value: null,
              checked: checkIfAtLeastOneTypeChecked() ? false : true,
            });
          }
        });
      } else if (typeof place.type_filter === "string") {
        if (!types.find((type: any) => type.name === place.type_filter)) {
          types.push({
            name: place.type_filter,
            description: "",
            value: null,
            checked: checkIfAtLeastOneTypeChecked() ? false : true,
          });
        }
      }
      return types;
    }, []);
    uniqueTypes.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setTypeOfProperties(uniqueTypes);
  };

  const resetLanguages = () => {
    const uniqueLanguages = originalResults.reduce(
      (languages: any, place: any) => {
        if (Array.isArray(place.languages)) {
          place.languages.forEach((language: { lang: string }) => {
            if (!languages.find((lang: any) => lang.name === language.lang)) {
              languages.push({
                name: language.lang,
                description: "",
                value: null,
                checked: checkIfAtLeastOneLanguageChecked() ? false : true,
              });
            }
          });
        } else if (typeof place.languages === "string") {
          if (!languages.find((lang: any) => lang.name === place.languages)) {
            languages.push({
              name: place.languages,
              description: "",
              value: null,
              checked: checkIfAtLeastOneLanguageChecked() ? false : true,
            });
          }
        }
        return languages;
      },
      []
    );
    uniqueLanguages.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setTypeOfLanguages(uniqueLanguages);
  };

  const resetDurations = () => {
    setTypeOfDurations([
      {
        id: 1,
        name: "up to 3 hours",
        description: "",
        from: 0,
        to: 180,
        checked: checkIfAtLeastOneDurationChecked() ? false : true,
      },
      {
        id: 2,
        name: "3 to 5 hours",
        description: "",
        from: 180,
        to: 300,
        checked: checkIfAtLeastOneDurationChecked() ? false : true,
      },
      {
        id: 3,
        name: "5 to 7 hours",
        description: "",
        from: 300,
        to: 420,
        checked: checkIfAtLeastOneDurationChecked() ? false : true,
      },
      {
        id: 4,
        name: "more than 7 hours",
        description: "",
        from: 421,
        to: 9999,
        checked: checkIfAtLeastOneDurationChecked() ? false : true,
      },
    ]);
  };

  const resetPriceRange = async () => {
    await setMinPlacePrice(minPrice());
    await setMaxPlacePrice(maxPrice());
    await setRangePrices([minPrice(), maxPrice()]);
    await updateRangePrice([minPrice(), maxPrice()]);
    await filterPlaces();
  };

  const resetSortType = async () => {
    const sortedPlaces = places.sort((a: any, b: any) =>
      a.name > b.name ? 1 : -1
    );
    await dispatch(setPlaces(sortedPlaces));
    await setTypeOfSorts([
      {
        id: "sort-1",
        name: "Alphabetical",
        description: "",
        sort: "alpha",
        checked: true,
      },
      {
        id: "sort-2",
        name: "Price in ascending order",
        description: "",
        sort: "price_asc",
        checked: false,
      },
    ]);
    await setSelectedSort("alpha");
    await handleSortChange("alpha");
    await filterPlaces();
  };

  const resetAllFilters = () => {
    resetPlaces();
  };

  const resetPlaces = async () => {
    // await resetProperties();
    // await resetLanguages();
    await resetSortType();
    await setSelectedSort("alpha");
    await handleSortChange("alpha");
    // await checkIfAtLeastOneTypeChecked();
    // await checkIfAtLeastOneLanguageChecked();
    await filterPlaces();
    await fetchPlaces();
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setTypeOfProperties((prevState) => {
      const updatedProperties = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedProperties;
    });
  };

  const handleLanguagesCheckboxChange = (name: string, checked: boolean) => {
    setTypeOfLanguages((prevState) => {
      const updatedLanguages = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedLanguages;
    });
  };

  const handleDurationsCheckboxChange = (name: string, checked: boolean) => {
    setTypeOfDurations((prevState) => {
      const updatedDurations = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedDurations;
    });
  };

  const getSelectedOptions = (
    properties: { name: string; checked: boolean }[]
  ) => {
    return properties.filter((item) => item.checked).map((item) => item.name);
  };

  const getSelectedLanguagesOptions = (
    languages: { name: string; checked: boolean }[]
  ) => {
    return languages.filter((item) => item.checked).map((item) => item.name);
  };

  const checkIfAtLeastOneTypeChecked = () => {
    const hasCheckedObject = typeOfProperties.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfAtLeastOneLanguageChecked = () => {
    const hasCheckedObject = typeOfLanguages.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfAtLeastOneDurationChecked = () => {
    const hasCheckedObject = typeOfDurations.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const updateSelectedProperties = () => {
    const selectedProperties = getSelectedOptions(typeOfProperties);
    const updatedProperties = selectedProperties;
    return updatedProperties;
  };

  const updateSelectedLanguages = () => {
    const selectedLanguages = getSelectedLanguagesOptions(typeOfLanguages);
    const updatedLanguages = selectedLanguages;
    return updatedLanguages;
  };

  useEffect(() => {}, [
    typeOfProperties,
    typeOfLanguages,
    typeOfDurations,
    typeOfSorts,
    rangePrices,
    minPrice,
    maxPrice,
    maxPlacePrice,
    minPlacePrice,
    places,
    isLoading,
    originalResults,
    resetSortType,
  ]);

  const filterPlaces = () => {
    const filteredPlaces = originalResults.filter((place) => {
      const typeFilter = Array.isArray(place.type_filter)
        ? place.type_filter.map((filter: any) => filter.name)
        : [place.type_filter.name];

      const languageFilter = Array.isArray(place.languages)
        ? place.languages.map((language: any) => language.lang)
        : [place.languages.lang];

      const isTypeIncluded = typeFilter.some((typeName: string) => {
        return updateSelectedProperties().includes(typeName);
      });

      const isLanguageIncluded = languageFilter.some((languageName: string) => {
        return updateSelectedLanguages().includes(languageName);
      });
      const isPriceInRange =
        place.price >= rangePrices[0] && place.price <= rangePrices[1];

      // const isDurationInRange = typeOfDurations.some((filter) => {
      //   console.log(`\x1b[31m duration: ${place.duration} \x1b[0m`);
      //   console.log(`\x1b[31m from: ${filter.from} to: ${filter.to} \x1b[0m`);
      //   console.log(`\x1b[31m checked: ${filter.checked} \x1b[0m`);
      //   return place.duration >= filter.from && place.duration <= filter.to;
      // });

      const isDurationInRange = typeOfDurations.some((filter) => {
        return (
          filter.checked &&
          place.duration >= filter.from &&
          place.duration <= filter.to
        );
      });

      return (
        isTypeIncluded &&
        isDurationInRange &&
        isLanguageIncluded &&
        isPriceInRange
      );
    });

    if (selectedSort === "alpha") {
      const sortedPlaces = filteredPlaces.sort((a: any, b: any) =>
        a.name > b.name ? 1 : -1
      );
      dispatch(setPlaces(sortedPlaces));
    } else if (selectedSort === "price_asc") {
      const sortedPlaces = filteredPlaces.sort(
        (a: any, b: any) => a.price - b.price
      );
      dispatch(setPlaces(sortedPlaces));
    } else if (selectedSort === "type") {
      const sortedPlaces = filteredPlaces.sort((a: any, b: any) =>
        a.property_type_filter > b.property_type_filter ? 1 : -1
      );
      dispatch(setPlaces(sortedPlaces));
    }
  };

  const renderTabsTypeOfProperties = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Type of Experience</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfProperties.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={(checked) =>
                            handleCheckboxChange(item.name, checked)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetProperties();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneTypeChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterPlaces();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfLanguages = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Language</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfLanguages.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={(checked) =>
                            handleLanguagesCheckboxChange(item.name, checked)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetLanguages();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneLanguageChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterPlaces();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfDurations = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Duration</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfDurations.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={(checked) =>
                            handleDurationsCheckboxChange(item.name, checked)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetDurations();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneDurationChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterPlaces();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfSort = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Sort By</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 right-1 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfSorts.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <CheckboxAsRadio
                          name={item.name}
                          label={item.name}
                          value={String(item.sort)}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={() => handleSortChange(item.sort)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        resetAllFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset All
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterPlaces();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center min-w-[160px] px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>
                {`€${convertNumbThousand(
                  rangePrices[0]
                )} - €${convertNumbThousand(rangePrices[1])}`}{" "}
              </span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Price per day</span>
                      <Slider
                        range
                        className="text-red-400"
                        min={minPlacePrice}
                        max={maxPlacePrice}
                        value={[rangePrices[0], rangePrices[1]]}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={(e) => updateRangePrice(e as number[])}
                      />
                    </div>

                    <div className="flex justify-between">
                      <div className="text-left">
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md text-left top-3">
                          <span className="text-neutral-500 sm:text-sm">
                            €{convertNumbThousand(rangePrices[0])}
                          </span>
                          <input
                            type="text"
                            disabled
                            name="minPrice"
                            id="minPrice"
                            className="opacity-0 pl-7 pr-3 sm:text-sm text-neutral-900 border-none font-bold"
                            value={rangePrices[0]}
                          />
                        </div>
                      </div>

                      <div className="text-right">
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md text-right top-3">
                          <span className="text-neutral-500 sm:text-sm">
                            €{convertNumbThousand(rangePrices[1])}
                          </span>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="opacity-0 pl-7 pr-3 sm:text-sm text-neutral-900 border-none font-bold"
                            value={rangePrices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        resetPriceRange();
                        // updateRangePrice(rangePrices as number[]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterPlaces();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // placeholders...
  // let placeholderColor = isDarkMode ? "#CCCCCC" : "#E0E0E0";
  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";
  // const ratePlaceholder = (
  //   <div className="rate-placeholder">
  //     <RectShape style={{ width: 150, height: 30 }} color={placeholderColor} />
  //   </div>
  // );
  // const pricePlaceholder = (
  //   <div className="price-placeholder">
  //     <TextBlock color={placeholderColor} rows={2} />
  //   </div>
  // );

  const filterPlaceholder = (
    <div className="Btn-placeholder">
      <RoundShape style={{ width: 150, height: 40 }} color={placeholderColor} />
    </div>
  );

  // const sideboxesPlaceholder = (
  //   <div className="sidebars-placeholder">
  //     <RoundShape style={{ width: 375, height: 80 }} color={placeholderColor} />
  //   </div>
  // );

  // const textOneRowPlaceholder = (
  //   <div className="price-placeholder">
  //     <TextBlock color={placeholderColor} rows={1} />
  //   </div>
  // );

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="WhereToStayPage"
    >
      <Seo
        name="Activities | Orbito Travel"
        description="Embark on exciting and accessible activities with Orbito Travel. Discover our range of inclusive experiences tailored to travelers of all abilities. Start planning your memorable adventures today!"
        title="Activities | Orbito Travel"
        type="page"
      />
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        {/* <SectionHeroArchivePage
          currentPage="Flights"
          currentTab="Flights"
          title="What to do in"
          subTitle={destinationString}
          rightImage={
            "https://orbito.s3.eu-central-1.amazonaws.com/images/wizard/03.png"
          }
          className="pt-10 lg:pt-16 "
        /> */}

        <div className="grid md:grid-cols-1 gap-6 lg:grid-cols-1 xl:gap-8 mb-4">
          <div className="flex flex-row p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <i
              style={{ margin: "auto 20px" }}
              className="las la-exclamation-circle text-3xl"
            ></i>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              Please note that only experiences with accessible facilities are
              displayed. The orbito travel team has performed an on-site
              inspection to each location and produced a Virtual Tour for your
              convenience.
            </span>
          </div>
        </div>

        {/* SECTION */}

        <div>
          <div>
            <div>
              <div className="lg:space-x-4 pb-8 flex justify-between">
                <div className="hidden lg:flex space-x-4">
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfProperties()}
                  </ReactPlaceholder>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfLanguages()}
                  </ReactPlaceholder>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfDurations()}
                  </ReactPlaceholder>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsPriceRage()}
                  </ReactPlaceholder>
                </div>
                <div>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfSort()}
                  </ReactPlaceholder>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <SectionGridFilterCard
              data={places}
              className="pb-24 lg:pb-28 min-h-[500px]"
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatToDoPage;

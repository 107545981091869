import { combineReducers } from "@reduxjs/toolkit";
import cartReducer from "./cart";
import dataReducer from "./data";
import searchReducer from "./search";
import authReducer from "./auth";
import signInPopoverReducer from "./signInPopover";
import darkModeReducer from "./darkMode";
import hotelsReducer from "./hotels";
import placesReducer from "./places";
import stayReducer from "./stay";
import stayCurrentPriceReducer from "./stayCurrentPrice";
import stayCancellationTypeReducer from "./stayCancellationTypes";
import stayMealTypeReducer from "./stayMealTypes";
import ecoFriendlyReducer from "./ecoFriendly";
import stayCurrentChargesReducer from "./stayCurrentCharges";
import stayCurrentNONReducer from "./stayCurrentNON";
import stayCurrentTotalPriceReducer from "./stayCurrentTotalPrice";
import stayCurrentTotalPriceWithChargesReducer from "./stayCurrentTotalPriceWithCharges";
import stayCurrentTimeslotsReducer from "./stayCurrentTimeslots";
import doReducer from "./do";
import restaurantsReducer from "./restaurants";
import movesReducer from "./moves";
import transportationReducer from "./transportations";

const allReducers = combineReducers({
  search: searchReducer,
  cart: cartReducer,
  data: dataReducer,
  auth: authReducer,
  signInPopover: signInPopoverReducer,
  darkMode: darkModeReducer,
  hotels: hotelsReducer,
  places: placesReducer,
  stay: stayReducer,
  ecoFriendly: ecoFriendlyReducer,
  stayCurrentPrice: stayCurrentPriceReducer,
  stayCancellationTypes: stayCancellationTypeReducer,
  stayMealTypes: stayMealTypeReducer,
  stayCurrentCharges: stayCurrentChargesReducer,
  stayCurrentNON: stayCurrentNONReducer,
  stayCurrentTotalPrice: stayCurrentTotalPriceReducer,
  stayCurrentTotalPriceWithCharges: stayCurrentTotalPriceWithChargesReducer,
  do: doReducer,
  restaurants: restaurantsReducer,
  moves: movesReducer,
  stayCurrentTimeslots: stayCurrentTimeslotsReducer,
  transportation:transportationReducer,
});

export default allReducers;

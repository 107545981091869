import { FC } from "react";
import GetThereCard from "components/GetThereCard/GetThereCard";
import { ResultDataType } from "data/types";
import { useSelector } from "react-redux";

export interface SectionGridFilterCardProps {
  className?: string;
  data: ResultDataType[];
  isLoading?: boolean;
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data,
  isLoading,
}) => {
  // const searchCity = useSelector(
  //   (state: any) => state.search.travelingTo?.city
  // );
  const selectedStayId = useSelector(
    (state: any) => state.cart.whereToStay?.id
  );

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {/* <Heading2 /> */}
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.map((stay: any) => (
          <GetThereCard
            key={stay.id}
            data={stay}
            internalLink="/how-to-get-there"
            isOnCart={selectedStayId === stay.id}
            isLoading={isLoading}
          />
        ))}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;

import { FC } from "react";

interface FaqItemProps {
  className?: string;
  texts: {
    text: any;
    title: string;
    icon: string;
  }[];
}

const FaqItem: FC<FaqItemProps> = ({ texts }) => {
  return (
    <div>
      {texts.map((text, index) => (
        <div key={index} className="listingSection__wrap mb-8">
          {/* HEADING */}
          <h2 className="text-2xl font-semibold">{text.title}</h2>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

          {/* ICON & TEXT */}
          <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
            <div className={`nc-FaqItem flex space-x-4`} data-nc-id="FaqItem">
              <div className="pt-0.5">
                <i className={`la la-${text.icon} text-4xl`}></i>
              </div>
              <div className="flex-grow">
                <div className="flex justify-between space-x-3">
                  <div className="flex flex-col">
                    <div className="text-sm font-semibold"></div>
                    <span className="text-sm text-neutral-500 dark:text-neutral-400 mt-0.5"></span>
                  </div>
                  <div className="flex text-yellow-500"></div>
                </div>
                <span
                  className="block mt-3 text-neutral-6000 dark:text-neutral-300 "
                  dangerouslySetInnerHTML={{ __html: text.text }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FaqItem;

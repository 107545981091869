import Logo from "shared/Logo/Logo";
import FooterImage from "images/footer.png";
import StripeLogo from "images/orbito/stripe/stripe.png";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";
import { Link } from "react-router-dom";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "/about", label: "About" },
      { href: "/accreditations", label: "Accreditations" },
      { href: "/faq", label: "F.A.Q." },
    ],
  },
  {
    id: "3",
    title: "Getting started",
    menus: [
      { href: "/blog", label: "Blog" },
      { href: "/contact", label: "Contact Us" },
    ],
  },
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "/cookies", label: "Cookies Settings" },
      { href: "/privacy-policy", label: "Privacy Policy" },
      { href: "/terms-of-use", label: "Terms of use" },
    ],
  },
];

const currentYear = new Date().getFullYear();

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div
        key={index}
        className="text-sm flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start"
      >
        {/* <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2> */}
        <br />
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => {
            if (item.href === "/cookies") {
              return <li key={index}>
                <button
                  key={index}
                  className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                  type="button"
                  onClick={(ev) => {
                    ev.preventDefault();
                    const button =
                      document.querySelector<HTMLButtonElement>(
                        ".cky-btn-revisit"
                      );
                    button?.click();
                  }}
                >
                  {item.label}
                </button>
              </li>;
            }else{
              return (
                <li key={index}>
                  <Link
                    key={index}
                    className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                    to={item.href}
                  >
                    {item.label}
                  </Link>
                </li>
              );
            }
           
          })}
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="nc-Footer pt-20 pb-8 lg:pt-14 lg:pb-12 border-t border-neutral-200 dark:border-neutral-700">
        <div className="container flex flex-col md:flex-row space-y-9 md:space-y-0 space-x-2">
          <div className="md:mx-0 flex flex-row items-center justify-center md:flex-col md:items-start gap-5">
            <div className="">
              <Logo />
            </div>
            <div className="">
              <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
            </div>
          </div>
          <div className="flex-1 flex flex-row justify-between md:items-end gap-5 py-3">
            {widgetMenus.map((item: any, index: number) => {
              return (
                <div className="mx-auto">
                  {renderWidgetMenuItem(item, index)}
                </div>
              );
            })}
          </div>
          <div className="flex-1 flex flex-row justify-around gap-5">
            <div className="flex-1 flex flex-row justify-end items-end md:flex-col gap-5 md:gap-0">
              <img
                className="w-1/3 md:w-5/12 aspect-w-1 md:aspect-w-7"
                draggable="false"
                src={FooterImage}
                alt="Orbito Travel IATA EU"
              />
              <img
                className="w-1/3 md:w-5/12 aspect-w-1 md:aspect-w-7"
                draggable="false"
                src={StripeLogo}
                alt="stripe paymenet gateway"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center text-sm py-2 px-2 mx-auto">
        © {currentYear} orbito travel private company | made with love and
        respect in Athens, Greece
      </div>
    </>
  );
};

export default Footer;

import React, { useState, useContext, useEffect } from "react";

type AddToCartModalInputProps = {
  showModal: boolean;
  onClose: () => void;
  title?: string;
};

const AddToCartModal: React.FC<AddToCartModalInputProps> = ({
  showModal,
  onClose,
  title,
}) => {
  const [disableNotification, setDisableNotification] = useState(false);

  const handleCheckboxChange = () => {
    setDisableNotification(!disableNotification);
    localStorage.setItem("disableNotification", String(!disableNotification));
  };

  const handleCloseModal = () => {
    onClose();
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-start justify-end z-50 container">
      <div
        className="rounded-lg p-8 shadow-md drop-shadow-md   bg-[#4995ED]"
        style={{ marginTop: 150 }}
      >
        <span
          className="absolute top-2 right-2 text-white py-2 px-4 cursor-pointer"
          onClick={handleCloseModal}
        >
          Χ
        </span>

        <h2 className="text-2xl font-bold mb-4 text-white">
          {title ? title : "Item"} added to Cart
        </h2>
        <p className="mb-4 text-white">
          Explore even more services by clicking the remaining category icons.
        </p>
        <p className="mb-4 text-white">
          Ready to check out? Just click the shopping cart icon.{" "}
        </p>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="disableNotification"
            checked={disableNotification}
            onChange={handleCheckboxChange}
            className="mr-2"
          />
          <label htmlFor="disableNotification" className="text-white">
            Don't show this message again
          </label>
        </div>
      </div>
    </div>
  );
};

export default AddToCartModal;

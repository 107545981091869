import image1 from "images/home/main_1.jpg";
import image2 from "images/home/main_2.jpg";
import image3 from "images/home/main_3.jpg";
import image4 from "images/home/main_4.jpg";
import image5 from "images/home/main_5.jpg";
import NcImage from "shared/NcImage/NcImage";

type Image = {
  src: string;
  alt: string;
  height: string;
};

const contain1 = "w-[128px] h-[230px]"
const contain2 = "w-[143px] h-[280px]"
const contain3 = "w-[167px] h-[326px]"

const images: Image[] = [
  {
    src: image1,
    alt: "image1",
    height: contain1,
  },
  {
    src: image2,
    alt: "image2",
    height: contain2,
  },
  {
    src: image3,
    alt: "image3",
    height: contain3,
  },
  {
    src: image4,
    alt: "image5",
    height: contain2,
  },
  {
    src: image5,
    alt: "image5",
    height: contain1,
  },
];

const HorizontalImages = () => {
  return (
    <div className="relative flex items-center gap-3">
      {images.map((img) => (
        <div className={`flex ${img.height} `} key={img.alt}>
          <img
            key={img.alt}
            src={img.src}
            alt={img.alt}
            className="object-fill h-full w-full rounded-3xl"
          />
        </div>
      ))}
    </div>
  );
};

export default HorizontalImages;

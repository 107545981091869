export enum HotelActionTypes {
  ADD_HOTEL = 'ADD_HOTEL',
  REMOVE_HOTEL = 'REMOVE_HOTEL',
  UPDATE_HOTEL = 'UPDATE_HOTEL',
  SET_HOTELS = 'SET_HOTELS',
}

export interface SetHotelsAction {
  type: HotelActionTypes.SET_HOTELS;
  payload: any; 
}

export interface AddHotelAction {
  type: HotelActionTypes.ADD_HOTEL;
  payload: any;
}

export interface RemoveHotelAction {
  type: HotelActionTypes.REMOVE_HOTEL;
  payload: any;
}

export interface UpdateHotelAction {
  type: HotelActionTypes.UPDATE_HOTEL;
  payload: any;
}

export function addHotel(payload: any): AddHotelAction {
  return {
    type: HotelActionTypes.ADD_HOTEL,
    payload,
  };
}

export function removeHotel(payload: any): RemoveHotelAction {
  return {
    type: HotelActionTypes.REMOVE_HOTEL,
    payload,
  };
}

export function updateHotel(payload: any): UpdateHotelAction {
  return {
    type: HotelActionTypes.UPDATE_HOTEL,
    payload,
  };
}

export function setHotels(hotels: any): SetHotelsAction {
  return {
    type: HotelActionTypes.SET_HOTELS,
    payload: hotels,
  };

}


export type HotelAction = AddHotelAction | RemoveHotelAction | UpdateHotelAction | SetHotelsAction;

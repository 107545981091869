export enum StayCancellationTypesActionTypes {
  SET_STAY_CANCELLATION_TYPES = 'SET_STAY_CANCELLATION_TYPES',
}

export interface SetStayCancellationTypesAction {
  type: StayCancellationTypesActionTypes.SET_STAY_CANCELLATION_TYPES;
  payload: any; 
}


export function setStayCancellationTypes(stayCancellationTypes: any): SetStayCancellationTypesAction {
  return {
    type: StayCancellationTypesActionTypes.SET_STAY_CANCELLATION_TYPES,
    payload: stayCancellationTypes,
  };
}


export type stayCancellationTypesAction = SetStayCancellationTypesAction;

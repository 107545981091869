import {StayCurrentNONActionTypes, stayCurrentNONAction} from './../actions/stayCurrentNON'


export interface StayCurrentNONState {
  stayCurrentNON: any[];
}

const initialState: StayCurrentNONState = {
  stayCurrentNON: [],
};

const stayCurrentNONReducer = (state = initialState, action: stayCurrentNONAction) => {
  switch (action.type) {
    case StayCurrentNONActionTypes.SET_STAY_CURRENT_NON:
      return {
        ...state,
        stayCurrentNON: action.payload,
      };
    default:
      return state;
  }
};

export default stayCurrentNONReducer;
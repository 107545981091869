import React from "react";

interface ValidationProps {
  text: string;
  classNameCustom?: string;
}

const Validation: React.FC<ValidationProps> = ({
  text,
  classNameCustom = "",
}) => {
  return (
    <div
      className={
        "h-max w-max bg-white border rounded-lg p-2  ml-2 " + classNameCustom
      }
    >
      <div className=" bg-white absolute top-0 left-5 transform  -translate-y-13 ">
        <div className="bg-white w-2 h-2 transform rotate-45 -translate-y-1 border border-r-0 border-b-0"></div>
      </div>
      <div className="flex items-center gap-x-2">
        <div className="bg-orange-500 w-6 h-6 rounded text-black flex items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm0-2a6 6 0 100-12 6 6 0 000 12zm0-9a1 1 0 011 1v4a1 1 0 01-2 0V8a1 1 0 011-1zm0-2a1 1 0 110-2 1 1 0 010 2z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <p className="text-black">{text}</p>
      </div>
    </div>
  );
};

export default Validation;

import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { FC, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { useSelector } from "react-redux";
import { ResultDataType } from "data/types";
import { getFlightsNoData } from "services/api";
import AirlineImage from "images/orbito/airline/planes_on.png";
import TrainImage from "images/orbito/train/trains_off.png";
import FerryImage from "images/orbito/ferry/ferries_off.png";
import Seo from "components/Seo/Seo";
import { useTour } from "@reactour/tour";
import { useCustomSearchParams } from "context/SearchParamsContext";
export interface ResultsLandingPageProps {
  className?: string;
}

const ResultsLandingPage: FC<ResultsLandingPageProps> = ({
  className = "",
}) => {
  const { setIsOpen } = useTour();
 const {data} = useCustomSearchParams();
  const departure = data()?.travelingFrom;
  const destination = data()?.travelingTo;
  const destinationString = destination?.city + ", " + destination?.country;
  const departureString = departure?.city + ", " + departure?.country;
  const [isLoading, setIsLoading] = useState(true);
  const [DATA, setDATA] = useState<ResultDataType[]>([]);
  const startDate =data()?.dates.startDate;
  const endDate = data()?.dates.endDate;
  const destination_iata = departure?.IATA

  const arrival_iata =  destination?.IATA
  const travelDates: any = {
    startDate: startDate,
    endDate: endDate,
  };

  const showOptions = async () => {
    const flightsNoData = await getFlightsNoData(
      destination_iata || "",
      arrival_iata || "",
      travelDates
    );
    let flightsNo = flightsNoData.rows;
    let airlineCardText = `${flightsNo} airlines were found, offering non-stop accessible air tickets from ${departureString} to ${destinationString}`;
    await setDATA([
      {
        id: 1,
        name: "Airline tickets",
        desc: airlineCardText,
        cardImgs: [
          {
            url: AirlineImage,
          },
        ],
        detailsImgs: [
          {
            url: AirlineImage,
          },
        ],
        disabled: flightsNo === 0,
      },
      {
        id: 2,
        name: "Train tickets",
        desc: `No train operating companies were found, offering accessible train tickets from ${departureString} to ${destinationString}`,
        cardImgs: [
          {
            url: TrainImage,
          },
        ],
        detailsImgs: [
          {
            url: TrainImage,
          },
        ],
        disabled: true,
      },
      {
        id: 3,
        name: "Ferry tickets",
        desc: `No ferry companies were found, offering accessible ferry tickets from ${departureString} to ${destinationString}`,
        cardImgs: [
          {
            url: FerryImage,
          },
        ],
        detailsImgs: [
          {
            url: FerryImage,
          },
        ],
        disabled: true,
      },
    ]);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    showOptions();
    const tourIsClosed = Boolean(localStorage.getItem("tourIsClosed"));
    if (!tourIsClosed) {
      setIsOpen(true);
    }
  }, [isLoading]);

  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
      data-nc-id="ResultsLandingPage"
    >
      <Seo
        name="Services | Orbito Travel"
        description="Discover the comprehensive services offered by Orbito Travel for accessible and inclusive travel experiences. Explore our range of accommodations, transportation options, and specialized assistance. Start planning your barrier-free journey with us today!"
        title="Services | Orbito Travel"
        type="page"
      />
      <BgGlassmorphism />

      <div className="container relative">
        {/* SECTION HERO */}
        {/* <SectionHeroArchivePage
          currentPage="Flights"
          currentTab="Flights"
          title="How to get to"
          subTitle={destinationString}
          rightImage={
            "https://orbito.s3.eu-central-1.amazonaws.com/images/wizard/01.png"
          }
          listingType={
            <>
              <i className="text-2xl las la-plane-departure"></i>
              <span className="ml-2.5">1599 flights</span>
            </>
          }
          className="pt-10 lg:pt-16 "
        /> */}

        {/* SECTION */}
        <SectionGridFilterCard
          data={DATA}
          className="pb-24 lg:pb-28 min-h-[500px]"
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default ResultsLandingPage;

import { Outlet } from "react-router-dom";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { FC, Fragment, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { useSelector, useDispatch } from "react-redux";
import { StayDetailType } from "data/types";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import { toInteger } from "lodash";
import convertNumbThousand from "utils/convertNumbThousand";
import CheckboxAsRadio from "shared/Checkbox/CheckboxAsRadio";
import { setHotels } from "./../../store/actions/hotels";
import {
  unsetEcoFriendly,
  setEcoFriendly,
} from "./../../store/actions/ecoFriendly";
import { getHotelsResults, retrieveHotels } from "services/api";
import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import {
  // TextBlock,
  // RectShape,
  RoundShape,
} from "react-placeholder/lib/placeholders";
import Seo from "components/Seo/Seo";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTour } from "@reactour/tour";

export interface WhereToStayPageProps {
  className?: string;
}

type Property = {
  name: string;
  description: string;
  value: any;
  checked: boolean;
};

const WhereToStayPage: FC<WhereToStayPageProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const hotels = useSelector((state: any) => state.hotels.hotels);
  const [originalResults, setOriginalResults] = useState<StayDetailType[]>([]);
  const { setIsOpen } = useTour();
  const [minHotelPrice, setMinHotelPrice] = useState<number>(1);
  const [maxHotelPrice, setMaxHotelPrice] = useState<number>(200);
  const [minHotelSurface, setMinHotelSurface] = useState<number>(1);
  const [maxHotelSurface, setMaxHotelSurface] = useState<number>(200);
  const ecoFriendly = useSelector(
    (state: any) => state.ecoFriendly.isEcoFriendly
  );
  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  const destination = useSelector((state: any) => state.search.travelingTo);
  const startDate = useSelector((state: any) => state.search.dates.startDate);
  const endDate = useSelector((state: any) => state.search.dates.endDate);

  const guestsCount = useSelector(
    (state: any) => state.search.guests.guestAdults
  );
  const guestWheelchairCount = useSelector(
    (state: any) => state.search.guests.guestWheelchair
  );
  const travelDates: any = {
    startDate: startDate,
    endDate: endDate,
  };

  const [isLoading, setIsLoading] = useState(true);

  const [typeOfProperties, setTypeOfProperties] = useState<Property[]>([]);

  const [typeOfEco, setTypeOfEco] = useState<any[]>([
    {
      id: "eco-1",
      name: "Display only eco friendly",
      description: "",
      sort: 1,
      checked: false,
    },
    {
      id: "eco-2",
      name: "Display all",
      description: "",
      sort: 0,
      checked: true,
    },
  ]);

  const initialPaginatedResultsCount = 8;
  const nextPaginatedResultsCount = 4;
  const [paginatedHotels, setPaginatedHotels] = useState<{
    items: StayDetailType[];
    hasNext: boolean;
  }>(() => {
    const totalHotelsCount = hotels.length;
    const hasNext = totalHotelsCount > initialPaginatedResultsCount;
    if (hasNext) {
      return {
        items: hotels.slice(0, initialPaginatedResultsCount),
        hasNext,
      };
    }
    return {
      items: hotels,
      hasNext: false,
    };
  });
  useEffect(() => {
    const tourIsClosed = Boolean(localStorage.getItem("tourIsClosed"));
    if (!tourIsClosed) {
      setIsOpen(true);
    }
  }, [isLoading]);

  const paginateHotels = () => {
    setTimeout(() => {
      const totalHotelsCount = hotels.length;
      console.log("totalHotelsCount", totalHotelsCount);
      const totalPaginatedItemsCount = paginatedHotels.items.length;
      console.log("totalPaginatedItemsCount", totalPaginatedItemsCount);
      const hasNext =
        totalHotelsCount > totalPaginatedItemsCount + nextPaginatedResultsCount;
      console.log("hasNext", hasNext);
      if (hasNext) {
        setPaginatedHotels({
          items: hotels.slice(
            0,
            totalPaginatedItemsCount + nextPaginatedResultsCount
          ),
          hasNext,
        });
      }
      setPaginatedHotels({
        items: hotels,
        hasNext: false,
      });
    }, 1500);
  };

  function removeDuplicates(arr: string[]) {
    return Array.from(new Set(arr));
  }

  // Define a function that fetches the hotels and dispatches them to the state
  const fetchHotels = async () => {
    let data;
    if (hotels && hotels.length > 0) {
      data = { hotels: hotels };
    } else {
      console.log("Refetch hotels");
      data = await retrieveHotels(destination, travelDates, {
        guestAdults: guestsCount,
        guestWheelchair: guestWheelchairCount,
      });
      console.log(data);
    }

    let filteredHotels = null;
    // eslint-disable-next-line eqeqeq
    if (ecoFriendly == "1") {
      filteredHotels = data.hotels.filter(
        // eslint-disable-next-line eqeqeq
        (hotel: any) => hotel.ecoFriendly == ecoFriendly
      );
    } else {
      filteredHotels = data.hotels;
    }
    const tempHotels: any[] = [...data.hotels];
    dispatch(setHotels(filteredHotels));
    setOriginalResults(data.hotels);
    const sortedByPriceResults = tempHotels.sort((a: any, b: any) =>
      a.price > b.price ? 1 : -1
    );
    const getMinPrice = sortedByPriceResults[0]?.price || 0;
    const getMaxPrice =
      sortedByPriceResults[sortedByPriceResults.length - 1]?.price || 200;
    const sortedByRoomSurfaceResults = tempHotels.sort((a: any, b: any) =>
      a.room_surface > b.room_surface ? 1 : -1
    );
    const getMinSurface = sortedByRoomSurfaceResults[0]?.room_surface || 0;
    const getMaxSurface =
      sortedByRoomSurfaceResults[sortedByRoomSurfaceResults.length - 1]
        ?.room_surface || 200;
    setMinHotelPrice(getMinPrice);
    setMaxHotelPrice(getMaxPrice);
    setMinHotelSurface((prev) => getMinSurface);
    setMaxHotelSurface((prev) => getMaxSurface);
    setRangePrices((prev) => [getMinPrice, getMaxPrice]);
    setRangeSurfaces((prev) => [getMinSurface, getMaxSurface]);
    const typesOfProp: string[] = data.hotels.map(
      (hotel: any) => hotel.property_type_filter
    );
    const uniqueTypes = removeDuplicates(typesOfProp);
    uniqueTypes.sort();
    setTypeOfProperties(
      uniqueTypes.map((tp) => ({
        name: tp,
        description: "",
        value: null,
        checked: true,
      }))
    );
    dispatch(unsetEcoFriendly());
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  useEffect(() => {
    fetchHotels();
  }, []);

  // ###################################
  // Filters
  const [selectedSort, setSelectedSort] = useState("alpha");

  const [typeOfSorts, setTypeOfSorts] = useState([
    {
      id: "sort-1",
      name: "Alphabetical",
      description: "",
      sort: "alpha",
      checked: true,
    },
    {
      id: "sort-2",
      name: "Price in ascending order",
      description: "",
      sort: "price_asc",
      checked: false,
    },
    {
      id: "sort-3",
      name: "Type of property",
      description: "",
      sort: "type",
      checked: false,
    },
  ]);

  const [rangePrices, setRangePrices] = useState([0, 100]);
  const [rangeSurfaces, setRangeSurfaces] = useState([
    minHotelSurface,
    maxHotelSurface,
  ]);
  // ###################################

  const handleSortChange = (value: string) => {
    setSelectedSort(value);

    // Get the index of the selected value
    const selectedIndex = typeOfSorts.findIndex((item) => item.sort === value);

    // Set the checked property of the selected value to true
    typeOfSorts[selectedIndex].checked = true;

    // Set the checked property of all other values to false
    for (let i = 0; i < typeOfSorts.length; i++) {
      if (i !== selectedIndex) {
        typeOfSorts[i].checked = false;
      }
    }

    setTypeOfSorts(typeOfSorts);
  };

  const handleEcoChange = (value: string) => {
    // eslint-disable-next-line eqeqeq
    if (value == "1") {
      dispatch(setEcoFriendly());
    } else {
      dispatch(unsetEcoFriendly());
    }
    // Get the index of the selected value
    const selectedIndex = typeOfEco.findIndex((item) => item.sort === value);

    // Set the checked property of the selected value to true
    typeOfEco[selectedIndex].checked = true;

    // Set the checked property of all other values to false
    for (let i = 0; i < typeOfEco.length; i++) {
      if (i !== selectedIndex) {
        typeOfEco[i].checked = false;
      }
    }

    setTypeOfEco(typeOfEco);
  };

  const updateRangePrice = (numbers: any[]) => {
    setRangePrices((prev) => numbers);
  };

  const updateRangeSurface = (numbers: any[]) => {
    setRangeSurfaces((prev) => numbers);
  };

  const resetProperties = () => {
    const uniqueTypes = originalResults.reduce((types: any, hotel: any) => {
      if (
        !types.find((type: any) => type.name === hotel.property_type_filter)
      ) {
        types.push({
          name: hotel.property_type_filter,
          description: "",
          value: null,
          checked: checkIfAtLeastOneTypeChecked() ? false : true,
        });
      }
      return types;
    }, []);
    uniqueTypes.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setTypeOfProperties(uniqueTypes);
  };

  const hardResetProperties = () => {
    const uniqueTypes = originalResults.reduce((types: any, hotel: any) => {
      if (
        !types.find((type: any) => type.name === hotel.property_type_filter)
      ) {
        types.push({
          name: hotel.property_type_filter,
          description: "",
          value: null,
          checked: true,
        });
      }
      return types;
    }, []);
    uniqueTypes.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setTypeOfProperties(uniqueTypes);
  };

  const resetPriceRange = async () => {
    const sortedByPriceResults = Array.from(
      originalResults.sort((a: any, b: any) => (a.price > b.price ? 1 : -1))
    );
    const getMinPrice = sortedByPriceResults[0]?.price || 0;
    const getMaxPrice =
      sortedByPriceResults[sortedByPriceResults.length - 1]?.price || 200;
    await setMinHotelPrice(getMinPrice);
    await setMaxHotelPrice(getMaxPrice);
    await setRangePrices([getMinPrice, getMaxPrice]);
    await updateRangePrice([getMinPrice, getMaxPrice]);
    await filterHotels();
  };

  const resetSurfaceRange = async () => {
    const sortedByRoomSurfaceResults = Array.from(
      originalResults.sort((a: any, b: any) =>
        a.room_surface > b.room_surface ? 1 : -1
      )
    );
    const getMinSurface = sortedByRoomSurfaceResults[0]?.room_surface || 0;
    const getMaxSurface =
      sortedByRoomSurfaceResults[sortedByRoomSurfaceResults.length - 1]
        ?.room_surface || 200;
    await setMinHotelSurface(getMinSurface);
    await setMaxHotelSurface(getMaxSurface);
    await setRangeSurfaces([getMinSurface, getMaxSurface]);
    await updateRangeSurface([getMinSurface, getMaxSurface]);
    await filterHotels();
  };

  const resetSortType = async () => {
    // await setSelectedSort("alpha");
    // await setTypeOfSorts([
    //   {
    //     id: "sort-1",
    //     name: "Alphabetical",
    //     description: "",
    //     sort: "alpha",
    //     checked: true,
    //   },
    //   {
    //     id: "sort-2",
    //     name: "Price in ascending order",
    //     description: "",
    //     sort: "price_asc",
    //     checked: false,
    //   },
    //   {
    //     id: "sort-3",
    //     name: "Type of property",
    //     description: "",
    //     sort: "type",
    //     checked: false,
    //   },
    // ]);

    const sortedHotels = hotels.sort((a: any, b: any) =>
      a.name > b.name ? 1 : -1
    );
    await dispatch(setHotels(sortedHotels));
    await setTypeOfSorts([
      {
        id: "sort-1",
        name: "Alphabetical",
        description: "",
        sort: "alpha",
        checked: true,
      },
      {
        id: "sort-2",
        name: "Price in ascending order",
        description: "",
        sort: "price_asc",
        checked: false,
      },
      {
        id: "sort-3",
        name: "Type of property",
        description: "",
        sort: "type",
        checked: false,
      },
    ]);
    await setSelectedSort("alpha");
    await handleSortChange("alpha");
    await filterHotels();
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setTypeOfProperties((prevState) => {
      const updatedProperties = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedProperties;
    });
  };

  const getSelectedOptions = (
    properties: { name: string; checked: boolean }[]
  ) => {
    return properties.filter((item) => item.checked).map((item) => item.name);
  };

  const checkIfAtLeastOneTypeChecked = () => {
    const hasCheckedObject = typeOfProperties.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const updateSelectedProperties = () => {
    const selectedProperties = getSelectedOptions(typeOfProperties);
    const updatedProperties = selectedProperties;
    return updatedProperties;
  };

  const roomSurfaceInfo = (surfaceInSquareMeters: number) => {
    let surfaceInSquareFeet = surfaceInSquareMeters * 10.7639;
    return (
      <p>
        <span style={{ fontWeight: "bold", color: "grey" }}>
          {surfaceInSquareMeters}
        </span>{" "}
        sq.m. /{" "}
        <span style={{ fontWeight: "bold", color: "grey" }}>
          {surfaceInSquareFeet.toFixed(0)}
        </span>{" "}
        sq.ft.
      </p>
    );
  };

  const filterHotels = () => {
    let filteredHotels = null;
    // eslint-disable-next-line eqeqeq
    if (ecoFriendly == "1") {
      filteredHotels = originalResults.filter(
        (hotel) =>
          updateSelectedProperties().includes(
            hotel.property_type_filter.toString()
          ) &&
          hotel.price >= rangePrices[0] &&
          hotel.price <= rangePrices[1] &&
          toInteger(hotel.room_surface) >= rangeSurfaces[0] &&
          toInteger(hotel.room_surface) <= rangeSurfaces[1] &&
          // eslint-disable-next-line eqeqeq
          hotel.ecoFriendly == ecoFriendly
      );
    } else {
      filteredHotels = originalResults.filter(
        (hotel) =>
          updateSelectedProperties().includes(
            hotel.property_type_filter.toString()
          ) &&
          hotel.price >= rangePrices[0] &&
          hotel.price <= rangePrices[1] &&
          toInteger(hotel.room_surface) >= rangeSurfaces[0] &&
          toInteger(hotel.room_surface) <= rangeSurfaces[1]
      );
    }

    if (selectedSort === "alpha") {
      const sortedHotels = filteredHotels.sort((a: any, b: any) =>
        a.name > b.name ? 1 : -1
      );
      dispatch(setHotels(sortedHotels));
    } else if (selectedSort === "price_asc") {
      const sortedHotels = filteredHotels.sort(
        (a: any, b: any) => a.price - b.price
      );
      dispatch(setHotels(sortedHotels));
    } else if (selectedSort === "type") {
      const sortedHotels = filteredHotels.sort((a: any, b: any) =>
        a.property_type_filter > b.property_type_filter ? 1 : -1
      );
      dispatch(setHotels(sortedHotels));
    }
  };

  // placeholders...
  // let placeholderColor = isDarkMode ? "#CCCCCC" : "#E0E0E0";
  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";
  // const ratePlaceholder = (
  //   <div className="rate-placeholder">
  //     <RectShape style={{ width: 150, height: 30 }} color={placeholderColor} />
  //   </div>
  // );
  // const pricePlaceholder = (
  //   <div className="price-placeholder">
  //     <TextBlock color={placeholderColor} rows={2} />
  //   </div>
  // );

  const filterPlaceholder = (
    <div className="Btn-placeholder">
      <RoundShape style={{ width: 150, height: 40 }} color={placeholderColor} />
    </div>
  );

  // const sideboxesPlaceholder = (
  //   <div className="sidebars-placeholder">
  //     <RoundShape style={{ width: 375, height: 80 }} color={placeholderColor} />
  //   </div>
  // );

  // const textOneRowPlaceholder = (
  //   <div className="price-placeholder">
  //     <TextBlock color={placeholderColor} rows={1} />
  //   </div>
  // );

  const renderTabsTypeOfProperties = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Type of Property</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfProperties.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={(checked) =>
                            handleCheckboxChange(item.name, checked)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetProperties();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneTypeChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterHotels();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsPriceRange = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center min-w-[160px] px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>
                {`€${convertNumbThousand(
                  rangePrices[0]
                )} - €${convertNumbThousand(rangePrices[1])}`}{" "}
              </span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Price per day</span>
                      <Slider
                        range
                        className="text-red-400"
                        min={minHotelPrice}
                        max={maxHotelPrice}
                        value={[rangePrices[0], rangePrices[1]]}
                        defaultValue={[rangePrices[0], rangePrices[1]]}
                        allowCross={false}
                        onChange={(e) => updateRangePrice(e as number[])}
                      />
                    </div>

                    <div className="flex justify-between">
                      <div className="text-left">
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative rounded-md text-left top-3">
                          <span className="text-neutral-500 sm:text-sm">
                            €{convertNumbThousand(rangePrices[0])}
                          </span>
                          <input
                            type="text"
                            disabled
                            name="minPrice"
                            id="minPrice"
                            className="opacity-0 pl-7 pr-3 sm:text-sm text-neutral-900 border-none font-bold"
                            value={rangePrices[0]}
                          />
                        </div>
                      </div>

                      <div className="text-right">
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md text-right top-3">
                          <span className="text-neutral-500 sm:text-sm">
                            €{convertNumbThousand(rangePrices[1])}
                          </span>
                          <input
                            type="text"
                            disabled
                            name="maxPrice"
                            id="maxPrice"
                            className="opacity-0 pl-7 pr-3 sm:text-sm text-neutral-900 border-none font-bold"
                            value={rangePrices[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        resetPriceRange();
                        // updateRangePrice(rangePrices as number[]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterHotels();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsSurfaceRange = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center min-w-[185px] px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>
                {`${convertNumbThousand(
                  rangeSurfaces[0]
                )} sq.m. - ${convertNumbThousand(rangeSurfaces[1])} sq.m.`}{" "}
              </span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Surface Range</span>
                      <Slider
                        range
                        className="text-red-400"
                        min={minHotelSurface}
                        max={maxHotelSurface}
                        value={[rangeSurfaces[0], rangeSurfaces[1]]}
                        defaultValue={[rangeSurfaces[0], rangeSurfaces[1]]}
                        allowCross={false}
                        onChange={(e) => updateRangeSurface(e as number[])}
                      />
                    </div>

                    <div className="flex justify-between">
                      <div className="text-left">
                        <label
                          htmlFor="minSurface"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min surface
                        </label>
                        <div className="mt-1 relative rounded-md text-left top-3">
                          <span className="text-neutral-500 sm:text-sm">
                            {roomSurfaceInfo(rangeSurfaces[0])}
                          </span>
                          <input
                            type="text"
                            disabled
                            name="minSurface"
                            id="minSurface"
                            className="opacity-0 pl-7 pr-3 sm:text-sm text-neutral-900 border-none font-bold"
                            value={rangeSurfaces[0]}
                          />
                        </div>
                      </div>

                      <div className="text-right">
                        <label
                          htmlFor="maxSurface"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max surface
                        </label>
                        <div className="mt-1 relative rounded-md text-right top-3">
                          <span className="text-neutral-500 sm:text-sm">
                            {roomSurfaceInfo(rangeSurfaces[1])}
                          </span>
                          <input
                            type="text"
                            disabled
                            name="maxSurface"
                            id="maxSurface"
                            className="opacity-0 pl-7 pr-3 sm:text-sm text-neutral-900 border-none font-bold"
                            value={rangeSurfaces[1]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        resetSurfaceRange();
                        // updateRangeSurface(rangeSurfaces as number[]);
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterHotels();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfEco = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Eco Friendly</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 right-1 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfEco.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <CheckboxAsRadio
                          name={item.name}
                          label={item.name}
                          value={String(item.sort)}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={() => handleEcoChange(item.sort)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterHotels();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfSort = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Sort By</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 right-1 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfSorts.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <CheckboxAsRadio
                          name={item.name}
                          label={item.name}
                          value={String(item.sort)}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={() => handleSortChange(item.sort)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        resetSortType();
                        dispatch(unsetEcoFriendly());
                        setSelectedSort("alpha");
                        handleSortChange("alpha");
                        hardResetProperties();
                        filterHotels();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset All
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        filterHotels();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const availableHotels = hotels.filter((hotel: any) => hotel.isAvailable);
  const unavailableHotels = hotels.filter((hotel: any) => !hotel.isAvailable);
  const sortedHotels = [...availableHotels, ...unavailableHotels];
  return (
    <>
      <div
        className={`nc-ListingStayPage relative overflow-hidden ${className}`}
        data-nc-id="WhereToStayPage"
      >
        <Seo
          name="Hotels | Orbito Travel"
          description="Explore accessible accommodations with Orbito Travel. Discover our curated selection of hotels catering to travelers with diverse needs. Start your journey towards inclusive and comfortable stays with us today!"
          title="Hotels | Orbito Travel"
          type="page"
        />

        <BgGlassmorphism />

        <div className="container relative overflow-hidden">
          {/* SECTION HERO */}
          {/* <SectionHeroArchivePage
          currentPage="Flights"
          currentTab="Flights"
          title="Where to stay in"
          subTitle={destinationString}
          rightImage={
            "https://orbito.s3.eu-central-1.amazonaws.com/images/wizard/02.png"
          }
          className="pt-10 lg:pt-16"
        /> */}

          <div className="grid md:grid-cols-1 gap-6 lg:grid-cols-1 xl:gap-8 mb-4">
            <div className="flex flex-row p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
              <i
                style={{ margin: "auto 20px" }}
                className="las la-exclamation-circle text-3xl"
              ></i>
              <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
                Please note that only hotels with accessible rooms are
                displayed. The orbito travel team has performed an on-site
                inspection to each hotel and produced a Virtual Tour for your
                convenience.
              </span>
            </div>
          </div>

          {/* SECTION */}
          <div>
            <div>
              <div>
                <div className="lg:space-x-4 pb-8 flex justify-between">
                  <div className="hidden lg:flex space-x-4">
                    <ReactPlaceholder
                      ready={!isLoading}
                      customPlaceholder={filterPlaceholder}
                      showLoadingAnimation
                    >
                      {renderTabsTypeOfProperties()}
                    </ReactPlaceholder>
                    <ReactPlaceholder
                      ready={!isLoading}
                      customPlaceholder={filterPlaceholder}
                      showLoadingAnimation
                    >
                      {renderTabsPriceRange()}
                    </ReactPlaceholder>
                    <ReactPlaceholder
                      ready={
                        !isLoading &&
                        minHotelSurface !== 0 &&
                        maxHotelSurface !== 0
                      }
                      customPlaceholder={filterPlaceholder}
                      showLoadingAnimation
                    >
                      {renderTabsSurfaceRange()}
                    </ReactPlaceholder>
                    <ReactPlaceholder
                      ready={!isLoading}
                      customPlaceholder={filterPlaceholder}
                      showLoadingAnimation
                    >
                      {renderTabsTypeOfEco()}
                    </ReactPlaceholder>
                  </div>
                  <div>
                    <ReactPlaceholder
                      ready={!isLoading}
                      customPlaceholder={filterPlaceholder}
                      showLoadingAnimation
                    >
                      {renderTabsTypeOfSort()}
                    </ReactPlaceholder>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              {/* <InfiniteScroll
              dataLength={paginatedHotels.items.length}
              next={paginateHotels}
              hasMore={paginatedHotels.hasNext}
              loader={<h4>Loading...</h4>}
            > */}
              <SectionGridFilterCard
                data={sortedHotels}
                className="pb-24 lg:pb-28 min-h-[500px]"
                isLoading={isLoading}
              />
              {/* </InfiniteScroll> */}
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default WhereToStayPage;

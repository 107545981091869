import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import { FC, Fragment, useEffect, useState } from "react";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { useDispatch, useSelector } from "react-redux";
import { getRestaurantsResults } from "services/api";
import { setRestaurants } from "../../store/actions/restaurants";
import { Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import Checkbox from "shared/Checkbox/Checkbox";
import CheckboxAsRadio from "shared/Checkbox/CheckboxAsRadio";
import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import { RoundShape } from "react-placeholder/lib/placeholders";
import { toInteger } from "lodash";
import Seo from "components/Seo/Seo";

// const DATA: DataDetailType[] = whereToEatStatic;

export interface WhereToEatProps {
  className?: string;
}

const WhereToEatPage: FC<WhereToEatProps> = ({ className = "" }) => {
  const dispatch = useDispatch();

  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  const destination = useSelector((state: any) => state.search.travelingTo);
  const startDate = useSelector((state: any) => state.search.dates.startDate);
  const endDate = useSelector((state: any) => state.search.dates.endDate);
  const travelDates: any = {
    startDate: startDate,
    endDate: endDate,
  };
  const guestsCount = useSelector(
    (state: any) => state.search.guests.guestAdults
  );
  const guestWheelchairCount = useSelector(
    (state: any) => state.search.guests.guestWheelchair
  );
  const destinationString = destination?.city + ", " + destination?.country;

  const restaurants = useSelector(
    (state: any) => state.restaurants.restaurants
  );
  const [isLoading, setIsLoading] = useState(true);
  const [originalResults, setOriginalResults] = useState<any[]>(restaurants);
  const [minPlacePrice, setMinPlacePrice] = useState(0);
  const [maxPlacePrice, setMaxPlacePrice] = useState(0);
  const [typeOfProperties, setTypeOfProperties] = useState<any[]>([]);
  const [typeOfCuisines, setTypeOfCuisines] = useState<any[]>([]);
  const [typeOfDietaries, setTypeOfDietaries] = useState<any[]>([]);
  const [typeOfPriceRanges, setTypeOfPriceRanges] = useState<any[]>([]);
  const [selectedSeating, setSelectedSeating] = useState(3);
  const [typeOfSeating, setTypeOfSeating] = useState<any[]>([
    {
      name: "Indoor",
      description: "",
      value: 1,
      checked: false,
    },
    {
      name: "Outdoor",
      description: "",
      value: 2,
      checked: false,
    },
    {
      name: "Indoor & Outdoor",
      description: "",
      value: 3,
      checked: true,
    },
  ]);

  const maxPrice = () => {
    let maxPrice = Number.MIN_VALUE;
    if (Array.isArray(originalResults)) {
      for (const place of originalResults) {
        if (place.price > maxPrice) {
          maxPrice = place.price;
        }
      }
    }
    return maxPrice;
  };

  const minPrice = () => {
    let minPrice = Number.MAX_VALUE; // Set the initial minimum price to the maximum possible value
    if (Array.isArray(originalResults)) {
      for (const place of originalResults) {
        if (place.price < minPrice) {
          // Check if the current hotel's price is less than the current minimum price
          minPrice = place.price; // Update the minimum price if a lower price is found
        }
      }
    }
    return minPrice;
  };

  const [rangePrices, setRangePrices] = useState([
    minPlacePrice,
    maxPlacePrice,
  ]);

  // const currentUserState = (state: any) => state.auth;
  // const auth = useSelector(currentUserState);

  const fetchRestaurants = async () => {
    // await setIsLoading(true);
    const data = await getRestaurantsResults(
      destination?.city,
      travelDates,
      guestsCount,
      guestWheelchairCount
    );
    await dispatch(setRestaurants(data));
    await setOriginalResults(data);
    const getMinPrice = minPrice();
    await setMinPlacePrice(getMinPrice);
    const getMaxPrice = maxPrice();
    await setMaxPlacePrice(getMaxPrice);
    await setRangePrices([getMinPrice, getMaxPrice]);
    const uniqueTypes = await restaurants.reduce((types: any, place: any) => {
      if (Array.isArray(place.type_filter)) {
        place.type_filter.forEach((typeName: any) => {
          if (!types.some((type: any) => type.name === typeName.name)) {
            types.push({
              name: typeName.name,
              description: "",
              value: null,
              checked: true,
            });
          }
        });
      } else if (typeof place.type_filter === "string") {
        if (!types.some((type: any) => type.name === place.type_filter.name)) {
          types.push({
            name: place.type_filter.name,
            description: "",
            value: null,
            checked: true,
          });
        }
      }
      return types;
    }, []);
    uniqueTypes.sort((a: any, b: any) => a.name.localeCompare(b.name));
    await setTypeOfProperties(uniqueTypes);

    const uniqueCuisines = await restaurants.reduce(
      (cuisines: any, place: any) => {
        if (Array.isArray(place.cuisines)) {
          place.cuisines.forEach((cuisine: any) => {
            if (
              !cuisines.some(
                (cuisineObj: any) => cuisineObj.name === cuisine.text
              )
            ) {
              cuisines.push({
                name: cuisine.text,
                description: "",
                value: null,
                checked: true,
              });
            }
          });
        } else if (typeof place.cuisines === "string") {
          if (
            !cuisines.some(
              (cuisineObj: any) => cuisineObj.name === place.cuisines
            )
          ) {
            cuisines.push({
              name: place.cuisines,
              description: "",
              value: null,
              checked: true,
            });
          }
        }
        return cuisines;
      },
      []
    );
    uniqueCuisines.sort((a: any, b: any) => a.name.localeCompare(b.name));
    await setTypeOfCuisines(uniqueCuisines);

    const uniqueDietaries = await restaurants.reduce(
      (dietaries: any, place: any) => {
        if (Array.isArray(place.dietaries)) {
          place.dietaries.forEach((dietary: any) => {
            if (
              !dietaries.some(
                (dietaryObj: any) => dietaryObj.name === dietary.text
              )
            ) {
              dietaries.push({
                name: dietary.text,
                description: "",
                value: null,
                checked: true,
              });
            }
          });
        } else if (typeof place.dietaries === "string") {
          if (
            !dietaries.some(
              (dietaryObj: any) => dietaryObj.name === place.dietaries
            )
          ) {
            dietaries.push({
              name: place.dietaries,
              description: "",
              value: null,
              checked: true,
            });
          }
        }
        return dietaries;
      },
      []
    );
    uniqueDietaries.sort((a: any, b: any) => a.name.localeCompare(b.name));
    await setTypeOfDietaries(uniqueDietaries);

    await setTypeOfPriceRanges([
      {
        id: 1,
        name: "up to 15 Euros per person",
        description: "",
        from: 0,
        to: 15,
        checked: true,
      },
      {
        id: 2,
        name: "15 to 25 Euros per person",
        description: "",
        from: 15.1,
        to: 25,
        checked: true,
      },
      {
        id: 3,
        name: "25 to 35 Euros per person",
        description: "",
        from: 25.1,
        to: 35,
        checked: true,
      },
      {
        id: 4,
        name: "35 to 50 Euros per person",
        description: "",
        from: 35.1,
        to: 50,
        checked: true,
      },
      {
        id: 5,
        name: "more than 50 Euros per person",
        description: "",
        from: 50.1,
        to: 9999,
        checked: true,
      },
    ]);
    const uniqueSeatings = [
      {
        name: "Indoor",
        description: "",
        value: 1,
        checked: false,
      },
      {
        name: "Outdoor",
        description: "",
        value: 2,
        checked: false,
      },
      {
        name: "Indoor & Outdoor",
        description: "",
        value: 3,
        checked: true,
      },
    ];
    await setTypeOfSeating(uniqueSeatings);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  useEffect(() => {
    fetchRestaurants();
  }, [dispatch, maxPlacePrice, minPlacePrice, isLoading]);

  const [selectedSort, setSelectedSort] = useState("alpha");

  const [typeOfSorts, setTypeOfSorts] = useState([
    {
      id: "sort-1",
      name: "Alphabetical",
      description: "",
      sort: "alpha",
      checked: true,
    },
    {
      id: "sort-2",
      name: "Price in ascending order",
      description: "",
      sort: "price_asc",
      checked: false,
    },
  ]);

  const handleSortChange = (value: string) => {
    setSelectedSort(value);

    // Get the index of the selected value
    const selectedIndex = typeOfSorts.findIndex((item) => item.sort === value);

    // Set the checked property of the selected value to true
    typeOfSorts[selectedIndex].checked = true;

    // Set the checked property of all other values to false
    for (let i = 0; i < typeOfSorts.length; i++) {
      if (i !== selectedIndex) {
        typeOfSorts[i].checked = false;
      }
    }

    setTypeOfSorts(typeOfSorts);
  };

  // const updateRangePrice = (numbers: any[]) => {
  //   setRangePrices(numbers);
  // };

  const resetProperties = () => {
    const uniqueTypes = originalResults.reduce((types: any, place: any) => {
      if (Array.isArray(place.type_filter)) {
        place.type_filter.forEach((typeName: any) => {
          if (!types.find((type: any) => type.name === typeName.name)) {
            types.push({
              name: typeName.name,
              description: "",
              value: null,
              checked: checkIfAtLeastOneTypeChecked() ? false : true,
            });
          }
        });
      } else if (typeof place.type_filter === "string") {
        if (!types.find((type: any) => type.name === place.type_filter.name)) {
          types.push({
            name: place.type_filter.name,
            description: "",
            value: null,
            checked: checkIfAtLeastOneTypeChecked() ? false : true,
          });
        }
      }
      return types;
    }, []);
    uniqueTypes.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setTypeOfProperties(uniqueTypes);
  };

  const resetCuisines = () => {
    const uniqueCuisines = originalResults.reduce((cuisines, place) => {
      // Check if "cuisines" is an array of objects
      if (Array.isArray(place.cuisines)) {
        place.cuisines.forEach((cuisineObj: any) => {
          const cuisineName = cuisineObj.text; // Access cuisine name from the new structure
          // Check if the cuisine is not already added to the uniqueCuisines array
          if (!cuisines.find((cuisine: any) => cuisine.name === cuisineName)) {
            cuisines.push({
              name: cuisineName,
              description: "",
              value: null,
              checked: checkIfAtLeastOneCuisineChecked() ? false : true,
            });
          }
        });
      }
      // Check if "cuisines" is a string
      else if (typeof place.cuisines === "string") {
        const cuisineName = place.cuisines; // Access cuisine name from the old structure
        // Check if the cuisine is not already added to the uniqueCuisines array
        if (!cuisines.find((cuisine: any) => cuisine.name === cuisineName)) {
          cuisines.push({
            name: cuisineName,
            description: "",
            value: null,
            checked: checkIfAtLeastOneCuisineChecked() ? false : true,
          });
        }
      }
      return cuisines;
    }, []);

    uniqueCuisines.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setTypeOfCuisines(uniqueCuisines);
  };

  const resetDietaries = () => {
    const uniqueDietaries = originalResults.reduce((dietaries, place) => {
      // Check if "dietaries" is an array of objects
      if (Array.isArray(place.dietaries)) {
        place.dietaries.forEach((dietaryObj: any) => {
          const dietaryName = dietaryObj.text; // Access dietary name from the new structure
          // Check if the dietary is not already added to the uniqueDietaries array
          if (!dietaries.find((dietary: any) => dietary.name === dietaryName)) {
            dietaries.push({
              name: dietaryName,
              description: "",
              value: null,
              checked: checkIfAtLeastOneDietaryChecked() ? false : true,
            });
          }
        });
      }
      // Check if "dietaries" is a string
      else if (typeof place.dietaries === "string") {
        const dietaryName = place.dietaries; // Access dietary name from the old structure
        // Check if the dietary is not already added to the uniqueDietaries array
        if (!dietaries.find((dietary: any) => dietary.name === dietaryName)) {
          dietaries.push({
            name: dietaryName,
            description: "",
            value: null,
            checked: checkIfAtLeastOneDietaryChecked() ? false : true,
          });
        }
      }
      return dietaries;
    }, []);

    uniqueDietaries.sort((a: any, b: any) => a.name.localeCompare(b.name));
    setTypeOfDietaries(uniqueDietaries);
  };

  const resetPriceRanges = () => {
    setTypeOfPriceRanges([
      {
        id: 1,
        name: "up to 15 Euros per person",
        description: "",
        from: 0,
        to: 15,
        checked: checkIfAtLeastOnePriceRangesChecked() ? false : true,
      },
      {
        id: 2,
        name: "15 to 25 Euros per person",
        description: "",
        from: 15.1,
        to: 25,
        checked: checkIfAtLeastOnePriceRangesChecked() ? false : true,
      },
      {
        id: 3,
        name: "25 to 35 Euros per person",
        description: "",
        from: 25.1,
        to: 35,
        checked: checkIfAtLeastOnePriceRangesChecked() ? false : true,
      },
      {
        id: 4,
        name: "35 to 50 Euros per person",
        description: "",
        from: 35.1,
        to: 50,
        checked: checkIfAtLeastOnePriceRangesChecked() ? false : true,
      },
      {
        id: 5,
        name: "more than 50 Euros per person",
        description: "",
        from: 50.1,
        to: 9999,
        checked: checkIfAtLeastOnePriceRangesChecked() ? false : true,
      },
    ]);
  };

  const resetSortType = async () => {
    await fetchRestaurants();
    await applyFilter();
  };

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setTypeOfProperties((prevState) => {
      const updatedProperties = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedProperties;
    });
  };

  const handleCheckboxCuisineChange = (name: string, checked: boolean) => {
    setTypeOfCuisines((prevState) => {
      const updatedCuisines = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedCuisines;
    });
  };

  const handleCheckboxDietaryChange = (name: string, checked: boolean) => {
    setTypeOfDietaries((prevState) => {
      const updatedDietaries = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedDietaries;
    });
  };

  const handlePriceRangeCheckboxChange = (name: string, checked: boolean) => {
    setTypeOfPriceRanges((prevState) => {
      const updatedPriceRange = prevState.map((item) =>
        item.name === name ? { ...item, checked } : item
      );
      return updatedPriceRange;
    });
  };

  const getSelectedOptions = (
    properties: { name: string; checked: boolean }[]
  ) => {
    return properties.filter((item) => item.checked).map((item) => item.name);
  };

  const getSelectedCuisinesOptions = (
    cuisines: { name: string; checked: boolean }[]
  ) => {
    return cuisines.filter((item) => item.checked).map((item) => item.name);
  };

  const getSelectedDietariesOptions = (
    cuisines: { name: string; checked: boolean }[]
  ) => {
    return cuisines.filter((item) => item.checked).map((item) => item.name);
  };

  // const getSelectedPriceRangesOptions = (
  //   cuisines: { name: string; checked: boolean }[]
  // ) => {
  //   return cuisines.filter((item) => item.checked).map((item) => item.name);
  // };

  const checkIfAtLeastOneTypeChecked = () => {
    const hasCheckedObject = typeOfProperties.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfAtLeastOneCuisineChecked = () => {
    const hasCheckedObject = typeOfCuisines.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfAtLeastOneDietaryChecked = () => {
    const hasCheckedObject = typeOfDietaries.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const checkIfAtLeastOnePriceRangesChecked = () => {
    const hasCheckedObject = typeOfPriceRanges.every(
      (obj) => obj.checked === true
    );

    if (hasCheckedObject) {
      return true;
    } else {
      return false;
    }
  };

  const updateSelectedProperties = () => {
    const selectedProperties = getSelectedOptions(typeOfProperties);
    const updatedProperties = selectedProperties;
    return updatedProperties;
  };

  const updateSelectedCuisines = () => {
    const selectedCuisines = getSelectedCuisinesOptions(typeOfCuisines);
    const updatedCuisines = selectedCuisines;
    return updatedCuisines;
  };

  const updateSelectedDietaries = () => {
    const selectedDietaries = getSelectedDietariesOptions(typeOfDietaries);
    const updatedDietaries = selectedDietaries;
    return updatedDietaries;
  };

  // const updateSelectedPriceRanges = () => {
  //   const selectedDietaries = getSelectedPriceRangesOptions(typeOfPriceRanges);
  //   const updatedDietaries = selectedDietaries;
  //   return updatedDietaries;
  // };

  const handleSeatingChange = (value: number) => {
    setSelectedSeating(value);
    // Get the index of the selected value
    const selectedIndex = typeOfSeating.findIndex(
      (item) => parseInt(item.value) === value
    );

    // Set the checked property of the selected value to true
    typeOfSeating[selectedIndex].checked = true;

    // Set the checked property of all other values to false
    for (let i = 0; i < typeOfSeating.length; i++) {
      if (i !== selectedIndex) {
        typeOfSeating[i].checked = false;
      }
    }

    setTypeOfSeating(typeOfSeating);
  };

  useEffect(() => {}, [
    typeOfProperties,
    typeOfCuisines,
    typeOfDietaries,
    typeOfPriceRanges,
    typeOfSeating,
    typeOfSorts,
    rangePrices,
    minPrice,
    maxPrice,
    maxPlacePrice,
    minPlacePrice,
    restaurants,
    isLoading,
    originalResults,
    resetSortType,
  ]);

  const filterRestaurants = (selectedSeatingParam: number) => {
    // Function to check if a place meets the indoorOutdoorOptions criteria
    const isIndoorOutdoorIncluded = (place: any, selectedSeatingParam: any) => {
      // If selectedSeatingParam is 3, return true for all options (1, 2, and 3)
      if (selectedSeatingParam === 3) {
        return true;
      }
      if (selectedSeatingParam === 2 && place.indoorOutdoorOptions === 3) {
        return true;
      }
      if (selectedSeatingParam === 1 && place.indoorOutdoorOptions === 3) {
        return true;
      }
      // Otherwise, check if the place's indoorOutdoorOptions matches the selectedSeatingParam
      return place.indoorOutdoorOptions === selectedSeatingParam;
    };

    const filteredPlaces = originalResults.filter((place) => {
      const typeFilter = Array.isArray(place.type_filter)
        ? place.type_filter
        : [place.type_filter];

      const isTypeIncluded = typeFilter.some((typeName: any) => {
        if (typeof typeName.name === "string") {
          return updateSelectedProperties().includes(typeName.name);
        }
        return false;
      });

      const cuisineFilter = Array.isArray(place.cuisines)
        ? place.cuisines
        : [place.cuisine];

      const isCuisineIncluded = cuisineFilter.some((cuisineName: any) => {
        if (typeof cuisineName.text === "string") {
          return updateSelectedCuisines().includes(cuisineName.text);
        }
        return false;
      });

      const dietaryFilter = Array.isArray(place.dietaries)
        ? place.dietaries
        : [place.dietaries];

      const isDietaryIncluded = dietaryFilter.some((dietaryName: any) => {
        if (typeof dietaryName.text === "string") {
          return updateSelectedDietaries().includes(dietaryName.text);
        }
        return false;
      });

      const isPriceRangeInRange = typeOfPriceRanges.some((filter) => {
        return (
          filter.checked &&
          place.priceRangePerPerson >= filter.from &&
          place.priceRangePerPerson <= filter.to
        );
      });

      // updateSelectedPriceRanges();

      // Additional condition for indoorOutdoorOptions
      const isIndoorOutdoorIncludedCon = isIndoorOutdoorIncluded(
        place,
        selectedSeatingParam
      );

      return (
        isTypeIncluded &&
        isCuisineIncluded &&
        isDietaryIncluded &&
        isPriceRangeInRange &&
        isIndoorOutdoorIncludedCon
      );
    });

    if (selectedSort === "alpha") {
      const sortedPlaces = filteredPlaces.sort((a: any, b: any) =>
        a.name > b.name ? 1 : -1
      );
      dispatch(setRestaurants(sortedPlaces));
      return sortedPlaces;
    } else if (selectedSort === "price_asc") {
      const sortedPlaces = filteredPlaces.sort(
        (a: any, b: any) => a.stars - b.stars
      );
      dispatch(setRestaurants(sortedPlaces));
      return sortedPlaces;
    } else if (selectedSort === "type") {
      const sortedPlaces = filteredPlaces.sort((a: any, b: any) =>
        a.property_type_filter > b.property_type_filter ? 1 : -1
      );
      dispatch(setRestaurants(sortedPlaces));
      return sortedPlaces;
    }
  };

  const applyFilter = () => {
    const filteredRestaurants = filterRestaurants(selectedSeating);
    dispatch(setRestaurants(filteredRestaurants)); // Update the restaurants state with filtered data
  };

  const renderTabsTypeOfSeating = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Seating</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 right-1 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfSeating &&
                      typeOfSeating.map((item, index) => (
                        <div key={`placeSeating-${index}`} className="">
                          <CheckboxAsRadio
                            name={item.name}
                            label={item.name}
                            value={toInteger(item.value)}
                            subLabel={item.description}
                            checked={item.checked}
                            onChange={() => handleSeatingChange(item.value)}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        applyFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfProperties = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Type</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfProperties.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={(checked) =>
                            handleCheckboxChange(item.name, checked)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetProperties();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneTypeChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        applyFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfCuisines = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Cuisine</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[600px] px-4 mt-3 left-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <div className="grid grid-cols-3 gap-4">
                      {typeOfCuisines.map((item, index) => (
                        <div key={`${item.name}-${index}`} className="">
                          <Checkbox
                            name={item.name}
                            label={item.name}
                            subLabel={item.description}
                            checked={item.checked}
                            onChange={(checked) =>
                              handleCheckboxCuisineChange(item.name, checked)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetCuisines();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneCuisineChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        applyFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfDietaries = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Dietary</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[600px] px-4 mt-3 left-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    <div className="grid grid-cols-3 gap-4">
                      {typeOfDietaries.map((item, index) => (
                        <div key={`${item.name}-${index}`} className="">
                          <Checkbox
                            name={item.name}
                            label={item.name}
                            subLabel={item.description}
                            checked={item.checked}
                            onChange={(checked) =>
                              handleCheckboxDietaryChange(item.name, checked)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetDietaries();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOneDietaryChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        applyFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfPriceRanges = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Price Range</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfPriceRanges.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <Checkbox
                          name={item.name}
                          label={item.name}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={(checked) =>
                            handlePriceRangeCheckboxChange(item.name, checked)
                          }
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        // close();
                        resetPriceRanges();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      {checkIfAtLeastOnePriceRangesChecked()
                        ? "Unselect all"
                        : "Select all"}
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        applyFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderTabsTypeOfSort = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border border-neutral-300 dark:border-neutral-700 focus:outline-none ${
                open ? "!border-primary-500 " : ""
              }`}
            >
              <span>Sort By</span>
              <i className="las la-angle-down ml-2"></i>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-sm px-4 mt-3 right-1 sm:px-0 lg:max-w-[300px]">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {typeOfSorts.map((item, index) => (
                      <div key={`${item.name}-${index}`} className="">
                        <CheckboxAsRadio
                          name={item.name}
                          label={item.name}
                          value={String(item.sort)}
                          subLabel={item.description}
                          checked={item.checked}
                          onChange={() => handleSortChange(item.sort)}
                        />
                      </div>
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        // resetSortType();
                        // setSelectedSort("alpha");
                        // handleSortChange("alpha");
                        fetchRestaurants();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Reset All
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        close();
                        applyFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";
  const filterPlaceholder = (
    <div className="Btn-placeholder">
      <RoundShape style={{ width: 150, height: 40 }} color={placeholderColor} />
    </div>
  );

  return (
    <div
      className={`nc-ListingStayPage relative overflow-hidden ${className}`}
      data-nc-id="WhereToStayPage"
    >
      <Seo
        name="Restaurants | Orbito Travel"
        description="Indulge in delicious and accessible dining experiences with Orbito Travel. Explore our curated selection of restaurants offering inclusive menus and facilities. Start savoring memorable meals with us today!"
        title="Restaurants | Orbito Travel"
        type="page"
      />
      <BgGlassmorphism />

      <div className="container relative overflow-hidden">
        {/* SECTION HERO */}
        {/* <SectionHeroArchivePage
          currentPage="Flights"
          currentTab="Flights"
          title="Where to eat in"
          subTitle={destinationString}
          rightImage={
            "https://orbito.s3.eu-central-1.amazonaws.com/images/wizard/04.png"
          }
          className="pt-10 lg:pt-16 "
        /> */}

        <div className="grid md:grid-cols-1 gap-6 lg:grid-cols-1 xl:gap-8 mb-4">
          <div className="flex flex-row p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
            <i
              style={{ margin: "auto 20px" }}
              className="las la-exclamation-circle text-3xl"
            ></i>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              Please note that only restaurants with accessible facilities are
              displayed. The orbito travel team has performed an on-site
              inspection to each location and produced a Virtual Tour for your
              convenience.
            </span>
          </div>
        </div>

        {/* SECTION */}
        <div>
          <div>
            <div>
              <div className="lg:space-x-4 pb-8 flex justify-between">
                <div className="hidden lg:flex space-x-4">
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfProperties()}
                  </ReactPlaceholder>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfCuisines()}
                  </ReactPlaceholder>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfPriceRanges()}
                  </ReactPlaceholder>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfDietaries()}
                  </ReactPlaceholder>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfSeating()}
                  </ReactPlaceholder>
                </div>
                <div>
                  <ReactPlaceholder
                    ready={!isLoading}
                    customPlaceholder={filterPlaceholder}
                    showLoadingAnimation
                  >
                    {renderTabsTypeOfSort()}
                  </ReactPlaceholder>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <SectionGridFilterCard
              data={restaurants}
              className="pb-24 lg:pb-28 min-h-[500px]"
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhereToEatPage;

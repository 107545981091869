import { AnchorDirectionShape } from "react-dates";
import { FC } from "react";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { PathName } from "routers/types";
import CheckboxAsRadio from "shared/Checkbox/CheckboxAsRadio";
import {
  // useSelector, 
  useDispatch
} from "react-redux";
import { useParams } from "react-router-dom";

export interface PlaceTimeSlotsInputProps {
  onChange?: (date: moment.Moment | null) => void;
  defaultFocus?: boolean;
  fieldClassName?: string;
  onFocusChange?: (focused: boolean) => void;
  className?: string;
  anchorDirection?: AnchorDirectionShape;
  isDisabled?: boolean;
  hasButtonSubmit?: boolean;
  buttonSubmitHref?: PathName;
  setSelectedTimeSlot: React.Dispatch<React.SetStateAction<string>>;
  data?: any;
}

const PlaceTimeSlotsInput: FC<PlaceTimeSlotsInputProps> = ({
  hasButtonSubmit = true,
  buttonSubmitHref = "/listing-stay-map",
  isDisabled = false,
  onChange,
  defaultFocus = false,
  onFocusChange,
  anchorDirection,
  className = "",
  setSelectedTimeSlot,
  fieldClassName = "[ nc-hero-field-padding ]",
  data = [{ slot: "" }],
}) => {
  const dispatch = useDispatch();
  let params = useParams();

  const [selectedValue, setSelectedValue] = useState("");
  const [timeSlots, setTimeSlots] = useState<any[]>([]);

  // const places = useSelector((state: any) => state.places.places);
  const placeAvailableTimeSlots = data;

  useEffect(() => {
    // Run the code only on component mount
    if (placeAvailableTimeSlots) {
      const initialTimeSlots = placeAvailableTimeSlots.map(
        (slot: any, index: number) => ({
          id: index,
          name: slot.slot,
          description: "",
          checked: index === 0, // Set the first slot as checked initially
        })
      );
      setSelectedValue(initialTimeSlots[0].name);
      initialTimeSlots[0].checked = true;
      setSelectedTimeSlot(initialTimeSlots[0].name)
      setTimeSlots(initialTimeSlots);
    }
  }, [dispatch, params, placeAvailableTimeSlots]);

  // useEffect(() => {}, [dispatch]);

  const handleChange = (value: string) => {
    const updatedSlots = timeSlots.map((slot: any) => ({
      ...slot,
      checked: slot.name === value,
    }));
    setSelectedValue(value);
    setSelectedTimeSlot((prev) => value)
    setTimeSlots(updatedSlots);
  };

  return (
    <div className="flex flex-1">
      <Popover className={`flex relative ${className}`}>
        {({ open, close }) => (
          <>
            <div
              className={`flex-1 flex items-center focus:outline-none cursor-pointer ${open ? "nc-hero-field-focused" : ""
                }`}
            >
              <Popover.Button
                className={`flex-1 flex text-left items-center ${fieldClassName} space-x-3 `}
                onClick={() => document.querySelector("html")?.click()}
              >
                <div className="text-neutral-300 dark:text-neutral-400">
                  <div className="text-4xl">
                    <i className="la la-user-clock"></i>
                  </div>
                </div>
                <div className="flex-grow">
                  <span className="block xl:text-lg font-semibold">
                    {selectedValue || "--:--"}
                  </span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    Time slot
                  </span>
                </div>
              </Popover.Button>
            </div>
            {!isDisabled && (
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <Popover.Panel className="absolute right-0 z-10 w-max max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
                  <div className="overflow-hidden">
                    <div className="relative flex flex-col px-5 py-6 space-y-5">
                      {timeSlots.map((item: any) => (
                        <div key={item.id} className="">
                          <CheckboxAsRadio
                            name={item.name}
                            label={item.name}
                            value={String(item.id)}
                            subLabel={item.description}
                            checked={item.checked}
                            onChange={() => handleChange(item.name)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            )}
          </>
        )}
      </Popover>
    </div>
  );
};

export default PlaceTimeSlotsInput;

import { FC, useEffect, useState } from "react";
import { DEMO_POSTS } from "data/posts";
import { Helmet } from "react-helmet";
import SectionAds from "./SectionAds";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionMagazine5 from "./SectionMagazine5";
import SectionLatestPosts from "./SectionLatestPosts";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { findAllPosts } from "services/postsService";
import { BlogPost } from "services/models";
import moment from "moment";
import Card12 from "./Card12";
import Card13 from "./Card13";
import Loading from "components/Loading/Loading";

// DEMO DATA
const POSTS = DEMO_POSTS;

// DEMO POST FOR MAGAZINE SECTION
const MAGAZINE1_POSTS = POSTS.filter((_: any, i: number) => i >= 0 && i < 8);
//
type BlogPageProps = {
  helmet?: boolean;
};
const BlogPage: FC<BlogPageProps> = ({ helmet = true }) => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const postCount = posts.length - 1 || 0;
  useEffect(() => {
    findAllPosts().then((posts) => {
      setPosts((prev) =>
        posts.map((tpost) => ({ ...tpost, postType: "blogpost" }))
      );
      setLoading(false);
    });
  }, []);

  return (
    <div className="nc-BlogPage overflow-hidden relative">
      {helmet && (
        <Helmet>
          <title>Blog || Orbito Travel</title>
        </Helmet>
      )}
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      {/* ======== ALL SECTIONS ======== */}
      {/* ======= START CONTAINER ============= */}
      {loading ? (
        <Loading />
      ) : (
        <div className={`${helmet ? "container " : ""} relative`}>
          {/* === SECTION 1 === */}
          <div className="pt-12 pb-16 lg:pb-28">
            <div className="nc-SectionMagazine5">
              <div className=" mb-10 md:mb-12">
                <h2 className="font-semibold text-4xl mt-5">Our Blog</h2>
                <span
                  className="mt-2 md:mt-3 font-normal block 
        text-base sm:text-xl text-neutral-500 dark:text-neutral-400"
                >
                  {postCount} {postCount > 1 ? "posts" : "post"}
                </span>
              </div>
              <div className="grid lg:grid-cols-2 gap-6 md:gap-8">
                {posts[0] && <Card12 post={posts[0]} />}
                <div className="grid gap-6 md:gap-8">
                  {posts
                    .filter((_, i) => i < 4 && i > 0)
                    .map((item, index) => (
                      <Card13 key={index} post={item} />
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* === SECTION 1 === */}
          {/* <SectionAds /> */}

          {/* === SECTION 8 === */}
          {/* <SectionLatestPosts className="py-16 lg:py-28" /> */}

          {/* === SECTION 1 === */}
          {/* <SectionSubscribe2 className="pb-16 lg:pb-28" /> */}
        </div>
      )}
    </div>
  );
};

export default BlogPage;

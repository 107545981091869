import { FC } from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { euro } from "utils/currency";

import { IOfferCheckOptions } from "./WhatsExtraDetailPage";

type ServiceSlotsInputProps = {
  offers: IOfferCheckOptions[];
  handleChange: (offer: IOfferCheckOptions) => void;
  handleFocus?: () => void;
};

const ServiceSlotsInput: FC<ServiceSlotsInputProps> = ({
  offers,
  handleChange,
  handleFocus,
}) => {
  const handleClick = () => {
    document.querySelector("html")?.click();
    handleFocus && handleFocus();
  };
  return (
    <div className="flex flex-1">
      <Popover className={`flex relative h-full`}>
        {({ open, close }) => (
          <>
            <div
              className={`flex-1 flex items-center focus:outline-none cursor-pointer ${
                open ? "nc-hero-field-focused" : ""
              }`}
            >
              <Popover.Button
                className={`flex-1 flex text-left items-center p-4 w-[179px] space-x-3 `}
                onClick={handleClick}
              >
                <div className="text-neutral-300 dark:text-neutral-400">
                  <div className="text-4xl">
                    <i className="las la-check-circle"></i>
                  </div>
                </div>
                <div className="flex-grow">
                  <span className="block xl:text-lg font-semibold">
                    Service
                  </span>
                  <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
                    Options
                  </span>
                </div>
              </Popover.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute right-0 z-10 w-[350px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
                <div className="overflow-hidden">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {offers.map((offer, index: number) => (
                      <div
                        key={`offer-${offer.type}-${index}`}
                        className="flex gap-3 justify-between w-full"
                      >
                        <div className="flex gap-3 justify-between w-full">
                          <h4 className="flex-1">{offer.type}</h4>
                          <p className="">{euro.format(offer.price)}</p>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            className="focus:ring-action-primary h-6 w-6 text-primary-500 border-primary 
                                rounded border-neutral-500 bg-white dark:bg-neutral-700  
                                dark:checked:bg-primary-500 focus:ring-primary-500"
                            checked={offer.isEnabled}
                            onChange={() => handleChange(offer)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default ServiceSlotsInput;

import {PlaceActionTypes, PlaceAction} from './../actions/places'


export interface PlaceState {
  places: any[];
}

const initialState: PlaceState = {
  places: [],
};

const placeReducer = (state = initialState, action: PlaceAction) => {
  switch (action.type) {
    case PlaceActionTypes.ADD_PLACE:
      return {
        ...state,
        places: [...state.places, action.payload],
      };
    case PlaceActionTypes.REMOVE_PLACE:
      return {
        ...state,
        places: state.places.filter((place) => place.id !== action.payload.id),
      };
    case PlaceActionTypes.UPDATE_PLACE:
      const updatedPlaces = state.places.map((place) => {
        if (place.id === action.payload.id) {
          return {
            ...place,
            ...action.payload.updates,
          };
        } else {
          return place;
        }
      });
      return {
        ...state,
        places: updatedPlaces,
      };
    case PlaceActionTypes.SET_PLACES:
      return {
        ...state,
        places: action.payload,
      };
    default:
      return state;
  }
};

export default placeReducer;
import { SessionStorageAdapter } from "LocalStorage/adapter/SessionStorageAdapter";
import moment, { Moment } from "moment";
import { UrlParamAdapter } from "utils/getFromUrl";

export type TravelingFrom = {
  city: string;
  country: string;
  IATA: string;
};

export type TravelingTo = {
  city: string;
  country: string;
  IATA: string;
  disabled: boolean;
};

export type Dates = {
  startDate: Moment | null;
  endDate: Moment | null;
};
export type Guests = {
  guestAdults: number;
  guestWheelchair: number;
};

export type SearchType = {
  travelingFrom: TravelingFrom | null;
  travelingTo: TravelingTo | null;
  dates: Dates;
  guests: Guests;
};

const getSearchParam = (value: string) => {

  try {
    const outValue = new URLSearchParams(window.location.search).get(value);
    console.log(outValue);
    if (!outValue) return null;
    return JSON.parse(outValue);
  } catch (error) {
    console.log(`Error in the ${value}`);
  }
};

const getDates = () => {
  const tempDates = getSearchParam("dates");
  if (tempDates) {
    const { startDate, endDate } = tempDates;
    return {
      startDate: startDate ? moment(startDate) : null, // ensure conversion to JavaScript Date
      endDate: endDate ? moment(endDate) : null,
    };
  }
  return {
    startDate: null,
    endDate: null,
  };
};

const initState = {
  travelingFrom: getSearchParam("travelingFrom"),
  travelingTo: getSearchParam("travelingTo"),
  dates: getDates(),
  guests: getSearchParam("guests") || {
    guestAdults: 0,
    guestWheelchair: 1,
  },
};

const searchReducer = (
  state = initState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case "ADD_TRAVELING_FROM": {
      return { ...state, travelingFrom: action.payload };
    }
    case "ADD_TRAVELING_TO": {
      return { ...state, travelingTo: action.payload };
    }
    case "ADD_DATE": {
      return { ...state, dates: { ...action.payload } };
    }
    case "ADD_GUESTS": {
      return { ...state, guests: { ...action.payload } };
    }
    case "RESET_SEARCH": {
      return {
        travelingFrom: null,
        travelingTo: null,
        dates: { startDate: null, endDate: null },
        guests: {
          guestAdults: 0,
          guestWheelchair: 1,
        },
      } as SearchType;
    }
    default:
      return state;
  }
};
export default searchReducer;

/* eslint-disable eqeqeq */
import { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DataDetailType, StayDetailType } from "data/types";
import { Link } from "react-router-dom";
import Stars from "components/Stars/Stars";
import EurosSigns from "components/EurosSigns/EurosSigns";
import { euro } from "utils/currency";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import { toInteger } from "lodash";
import {
  formatDuration,
  getShortDescText,
  getLangShortDescText,
} from "../../helpers/methods";

import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import { TextBlock, RectShape } from "react-placeholder/lib/placeholders";
import { useSelector } from "react-redux";
import { useCustomSearchParams } from "context/SearchParamsContext";

export interface StayCardProps {
  className?: string;
  data: StayDetailType | DataDetailType;
  size?: "default" | "small";
  internalLink: string;
  priceStartingString?: string;
  isOnCart: boolean;
  isLoading?: boolean;
  cardType?: "OTHER" | "HOTELS";
}

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data,
  internalLink,
  priceStartingString = "",
  isOnCart = false,
  isLoading,
  cardType = "OTHER",
}) => {
  const {
    _id,
    type,
    name,
    stars,
    fullAddress,
    ecoFriendly,
    price,
    cardImgs,
    pricePer,
    client_room_name,
    room_surface,
    duration,
    languages,
    priceRangePerPerson,
    cuisines,
    indoorOutdoorOptions,
    isAvailable,
    results = [],
  } = data;

  const { getStringParams } = useCustomSearchParams();

  const roomSurfaceInfo = (surfaceInSquareMeters: number) => {
    let surfaceInSquareFeet = surfaceInSquareMeters * 10.7639;
    return `${surfaceInSquareMeters} sq.m. / ${surfaceInSquareFeet.toFixed(
      0
    )} sq.ft.`;
  };

  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;

  if (isLoading === undefined || isLoading === null) {
    isLoading = false;
  }

  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";

  const galleryPlaceholder = (
    <div className="gallery-placeholder">
      <RectShape style={{ width: 320, height: 241 }} color={placeholderColor} />
    </div>
  );

  const textRowsPlaceholder = (
    <div className="text-3-placeholder">
      <TextBlock color={placeholderColor} rows={5} />
    </div>
  );

  const renderAvalaibility = () => {
    if (cardType === "HOTELS") {
      if (isAvailable) {
        const cheapResult = results[0];
        return (
          <span className="text-base font-semibold">
            {priceStartingString} {euro.format(cheapResult.price)}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                per night
              </span>
            )}
          </span>
        );
      } else {
        return (
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-bold">
            not available
          </span>
        );
      }
    }

    return (
      <span className="text-base font-semibold">
        {priceStartingString} {euro.format(price)}
        {` `}
        {size === "default" && (
          <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            {pricePer}
          </span>
        )}
      </span>
    );
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${_id}`}
          ratioClass="aspect-w-4 aspect-h-3"
          className={`cursor-pointer ${className}`}
          galleryImgs={cardImgs}
          href={internalLink + "/" + _id}
          showDots={cardImgs.length > 1}
          showNextPrev={cardImgs.length > 1}
        />
        <Link
          to={{
            pathname: internalLink + "/" + _id,
            search: getStringParams(),
          }}
          className="absolute inset-0"
          style={{ pointerEvents: 'none' }}
        />
        {isOnCart && (
          <SaleOffBadge
            desc="Added to cart"
            className="absolute left-3 top-3 bg-green-500"
          />
        )}
      </div>
    );
  };
  

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-2" : "p-3 space-y-2"}>
        <div className="flex flex-row">
          <span className="mr-1">{type} </span>
          <div>
            {!!stars && type === "Hotel" && <Stars stars={stars} key={stars} />}
          </div>
        </div>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400"></span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <div className="flex justify-between items-center w-full">
              <span className="">{fullAddress}</span>
              {ecoFriendly && ecoFriendly == true ? (
                <span className="text-eco-friendly-green font-bold text-2xl">
                  <i className="las la-leaf"></i>
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>

        {duration && languages && (
          <div className="space-y-1">
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center">
                <i className="las la-clock text-lg text-neutral-500 dark:text-neutral-400"></i>
                <span className="ml-2 text-sm text-neutral-500 dark:text-neutral-400">
                  {formatDuration(toInteger(duration))}
                </span>
              </div>
            </div>
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center">
                <i className="las la-language text-lg text-neutral-500 dark:text-neutral-400"></i>
                <span className="ml-2 text-sm text-neutral-500 dark:text-neutral-400">
                  {getLangShortDescText(languages)}
                </span>
              </div>
            </div>
          </div>
        )}
        {room_surface && client_room_name && (
          <div>
            <div className="flex justify-between items-center w-full  text-neutral-500 dark:text-neutral-400">
              <span className="">{client_room_name}</span>
              {/* <span className=""> {roomSurfaceInfo(toInteger(room_surface))}</span> */}
            </div>
            <div className="flex justify-between items-center w-full text-sm italic  text-neutral-500 dark:text-neutral-400">
              <span className="">
                {roomSurfaceInfo(toInteger(room_surface))}
              </span>
            </div>
          </div>
        )}
        <div className="flex justify-between items-center">
          {priceRangePerPerson ? (
            <div>
              <div className="space-y-1">
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-center">
                    <i className="las la-utensils text-lg text-neutral-500 dark:text-neutral-400"></i>
                    {cuisines && cuisines.length > 0 && (
                      <span className="ml-2 text-sm text-neutral-500 dark:text-neutral-400">
                        {getShortDescText(cuisines)}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center w-full">
                  <div className="flex items-center">
                    <i className="las la-chair text-lg text-neutral-500 dark:text-neutral-400"></i>
                    {indoorOutdoorOptions && (
                      <span className="ml-2 pt-2 text-sm text-neutral-500 dark:text-neutral-400">
                        {indoorOutdoorOptions === 3
                          ? "Indoor & Outdoor"
                          : indoorOutdoorOptions === 1
                          ? "Indoor"
                          : "Outdoor"}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  {stars && <EurosSigns className="pt-2" EurosSigns={stars} />}
                </div>
              </div>
            </div>
          ) : (
            renderAvalaibility()
          )}
        </div>
      </div>
    );
  };

  let fullLink = internalLink + "/" + _id;

  return (
    <div
      className={`nc-StayCard cursor-pointer group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      <ReactPlaceholder
        ready={!isLoading}
        customPlaceholder={galleryPlaceholder}
        showLoadingAnimation
      >
        {renderSliderGallery()}
      </ReactPlaceholder>
      {isLoading && <br />}
      <ReactPlaceholder
        ready={!isLoading}
        customPlaceholder={textRowsPlaceholder}
        showLoadingAnimation
      >
        <Link
          to={{
            pathname: fullLink,
            search: getStringParams(),
          }}
        >
          {renderContent()}
        </Link>
      </ReactPlaceholder>
      {isLoading && <br />}
    </div>
  );
};

export default StayCard;

import { Fragment, useEffect, useRef, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  CityInfo,
  apiController,
  getCitiesByName,
  getArrivingCityByName,
} from "services/api";
import { lib } from "crypto-js";
import ArrivingCities from "constants/ArrivingToCities";
import { addTravelingTo } from "store/actions/search";
import { useDispatch, useSelector } from "react-redux";

export default function ArrivingToInput() {
  const dispatch = useDispatch();
  const travelingTo = useSelector((state: any) => state.search.travelingTo);
  const [locations, setLocations] = useState<CityInfo[]>([]);
  const [selected, setSelected] = useState<CityInfo | null>();
  const [query, setQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const onFocus = () => {
      setIsFocused(true);
    };

    const onBlur = () => {
      setIsFocused(false);
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener("focus", onFocus);
      inputElement.addEventListener("blur", onBlur);
    }

    // Cleanup event listeners
    return () => {
      if (inputElement) {
        inputElement.removeEventListener("focus", onFocus);
        inputElement.removeEventListener("blur", onBlur);
      }
    };
  }, []);

  useEffect(() => {
    console.log(travelingTo);
    getArrivingCityByName("", "")
      .then((res) => {
        setLocations(res);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (query) {
      getArrivingCityByName("", query)
        .then((res) => {
          setLocations(res);
        })
        .catch((error) => console.log(error));
    } else {
      getArrivingCityByName("", "")
        .then((res) => {
          setLocations(res);
        })
        .catch((error) => console.log(error));
    }
  }, [query]);

  const filteredLocations = locations.filter(
    (city) =>
      city.city
        .toLowerCase()
        .trimStart()
        .includes(query.toLowerCase().trimStart()) ||
      city.country
        .toLowerCase()
        .trimStart()
        .includes(query.toLowerCase().trimStart())
  );

  return (
    <Combobox
      value={selected}
      onChange={(value: CityInfo) => {
        setSelected(value);
        dispatch(addTravelingTo(value));
      }}
      defaultValue={ArrivingCities[0]}
      name="departingFrom"
      nullable
    >
      <div className="relative h-full rounded-full">
        <div
          className={`relative w-full h-full cursor-default overflow-hidden 
        rounded-full  text-left shadow-md 
         sm:text-sm ${
           isFocused ? "bg-[#fca15bc2] dark:bg-neutral-600" : ""
         }  flex flex-col justify-center`}
        >
          {selected && (
            <Combobox.Label
              className="mt-5
          flex items-center bg-transparent 
          border-none pl-12  text-sm 
             "
            >
              Arriving to
            </Combobox.Label>
          )}
          <Combobox.Button className="absolute h-full inset-y-0 left-0 mx-4 flex items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className=" w-7 h-7"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </Combobox.Button>
          <div className="absolute inset-y-0 left-0 mx-2 flex items-center pr-2"></div>

          <Combobox.Input
            ref={inputRef}
            autoComplete="off"
            required
            className={`flex-1 ${
              selected ? "mb-4" : ""
            }  w-full h-full bg-transparent dark:bg-transparent border-none pl-12  text-base leading-5
             text-white font-bold  placeholder:text-white focus:outline-none focus:ring-0 `}
            displayValue={(location: CityInfo) => {
              if (location) {
                return `${location.city} (${location.IATA})`;
              }
              return "";
            }}
            placeholder="Arriving to"
            onChange={(event) => setQuery(event.target.value)}
          />

          <Combobox.Button className="absolute h-full inset-y-0 right-0 mx-2 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-5 w-5 text-white"
              aria-hidden="true"
            />
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          <Combobox.Options
            className="z-50 absolute mt-6 max-h-60 w-120 overflow-auto bg-white dark:tex dark:bg-neutral-800 py-1
           text-md shadow-lg ring-1 ring-black/5 focus:outline-none rounded-3xl"
          >
            {filteredLocations.length === 0 && query !== "" ? (
              <div className="relative cursor-default select-none px-4 py-6 text-gray-700 dark:text-white ">
                We haven't expanded to {query} yet, but we're always growing!
                Check one of our current destiantions.
              </div>
            ) : (
              filteredLocations.map((location, i) => (
                <Combobox.Option
                  key={`${location.IATA} -${i}`}
                  className={({ active, disabled }) =>
                    `relative select-none py-2 pl-10 pr-4 text-neutral-700  dark:text-neutral-200 hover:text-white ${
                      active
                        ? "bg-[#f47d20] text-white dark:bg-neutral-600"
                        : ""
                    } ${
                      disabled
                        ? "hover:bg-[#f47d20] hover:dark:bg-neutral-600 text-black  dark:text-neutral-200 "
                        : ""
                    }`
                  }
                  disabled={location.hasFreeText}
                  value={location}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`block truncate font-medium   ${
                          selected ? "font-semibold" : "font-normal"
                        }`}
                      >
                        {location.city}, {location.country} ({location.IATA}){" "}
                        {""}
                        {location.hasFreeText ? location.freeText : ""}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3  ${
                            active ? "text-white" : "text-[#f47d20]"
                          }`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </div>
    </Combobox>
  );
}

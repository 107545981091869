import moment from "moment";

export function formatDuration(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  if (hours === 0) {
    return `${remainingMinutes}m`;
  } else if (remainingMinutes === 0) {
    return `${hours}h`;
  } else {
    return `${hours}h ${remainingMinutes}m`;
  }
}

export function getLangShortDescText(languages: { lang: string }[]): string {
  const languageNames = languages.map((language) => language.lang);
  const text = languageNames.join(", ");

  if (text.length > 30) {
    return text.substr(0, 30) + "...";
  } else {
    return text;
  }
}

export function getLangLongDescText(languages: { lang: string }[]): string {
  const languageNames = languages.map((language) => language.lang);
  return languageNames.join(", ");
}

export function getShortDescText(data: { text: string }[]): string {
  const dataNames = data.map((data) => data.text);
  const text = dataNames.join(", ");

  if (text.length > 30) {
    return text.substr(0, 30) + "...";
  } else {
    return text;
  }
}

export function getLongDescText(data: { text: string }[]): string {
  const dataNames = data.map((pieceOfData) => pieceOfData.text);
  return dataNames.join(", ");
}

export function formatDate(strDate: string) {
  const formattedDate = moment(strDate, "M/D/YYYY").format("D MMM YYYY");
  return formattedDate !== "Invalid date" ? formattedDate : strDate;
}

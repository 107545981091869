import { FC, useEffect } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { ResultDataType } from "data/types";
import { Link } from "react-router-dom";
// import Stars from "components/Stars/Stars";
// import EurosSigns from "components/EurosSigns/EurosSigns";
// import { euro } from "utils/currency";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
// import { toInteger } from "lodash";
// import { formatDuration, getShortDescText } from "../../helpers/methods";

import "react-placeholder/lib/reactPlaceholder.css";
import ReactPlaceholder from "react-placeholder";
import { TextBlock, RectShape } from "react-placeholder/lib/placeholders";
import { useSelector } from "react-redux";
import { useCustomSearchParams } from "context/SearchParamsContext";

export interface GetThereCardProps {
  className?: string;
  data: ResultDataType;
  size?: "default" | "small";
  internalLink: string;
  priceStartingString?: string;
  isOnCart: boolean;
  isLoading?: boolean;
}

const GetThereCard: FC<GetThereCardProps> = ({
  size = "default",
  className = "",
  data,
  internalLink,
  priceStartingString = "from",
  isOnCart = false,
  isLoading,
}) => {
  const { id, name, desc, cardImgs, disabled } = data;
  const { getStringParams } = useCustomSearchParams();

  useEffect(() => { }, [data.name]);

  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;

  if (isLoading === undefined || isLoading === null) {
    isLoading = false;
  }

  let placeholderColor = isDarkMode ? "#FFFFCC" : "#E0E0E0";

  const galleryPlaceholder = (
    <div className="gallery-placeholder">
      <RectShape style={{ width: 320, height: 241 }} color={placeholderColor} />
    </div>
  );

  const textRowsPlaceholder = (
    <div className="text-3-placeholder">
      <TextBlock color={placeholderColor} rows={5} />
    </div>
  );

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <div className="flex justify-center items-center h-40">
          <img src={cardImgs[0].url} className="w-24 h-24" />
        </div>
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" /> */}
        {isOnCart && (
          <SaleOffBadge
            desc="Added to cart"
            className="absolute left-3 top-3 bg-green-500"
          />
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-2" : "p-3 space-y-2"}>
        <div className="flex flex-row"></div>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400"></span>
          <div className="flex items-center space-x-2">
            <h2
              className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                }`}
            >
              <span className="line-clamp-1">{name}</span>
            </h2>
          </div>
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            <div className="flex justify-between items-center w-full"></div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>

        {desc && (
          <div>
            <div className="flex justify-between items-center w-full text-neutral-500 dark:text-neutral-400">
              <span className={disabled ? "opacity-70" : ""}>{desc}</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={`nc-GetThereCard second-step cursor-pointer group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className} ${disabled ? "opacity-70 pointer-events-none" : ""
        }`} // Dim content and disable pointer events if disabled is true
      data-nc-id="GetThereCard"
    >
      <ReactPlaceholder
        ready={!isLoading}
        customPlaceholder={galleryPlaceholder}
        showLoadingAnimation
      >
        {renderSliderGallery()}
      </ReactPlaceholder>
      {isLoading && <br />}
      <ReactPlaceholder
        ready={!isLoading}
        customPlaceholder={textRowsPlaceholder}
        showLoadingAnimation
      >
        {/* Conditionally render the link or the content based on disabled */}
        {disabled ? (
          renderContent()
        ) : (
          <Link to={{
            pathname: internalLink, search: getStringParams()
          }} >{renderContent()}</Link>
        )}
      </ReactPlaceholder>
      {isLoading && <br />}
    </div>
  );
};

export default GetThereCard;

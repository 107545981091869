import { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { AccreditationsDataType } from "data/types";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";
import { useSelector } from "react-redux";
import IATAimage from "images/IATA.png";
import GNTOimage from "images/GNTO.png";
import TUVimage from "images/TUV.png";
import TUVdarkImage from "images/TUVdark.png";
import HATTAimage from "images/HATTA.png";
import EUimage from "images/EU.png";

export interface Card4Props {
  className?: string;
  post: AccreditationsDataType;
}

const Card4: FC<Card4Props> = ({ className = "h-full", post }) => {
  const { title, featuredImage, desc, categories } = post;
  const darkModeState = (state: any) => state.darkMode;
  const isDarkMode = useSelector(darkModeState).isDarkMode;
  let logo = null;
  switch (featuredImage) {
    case "IATA":
      logo = IATAimage;
      break;
    case "GNTO":
      logo = GNTOimage;
      break;
    case "TUV":
      if (isDarkMode) {
        logo = TUVdarkImage;
      } else {
        logo = TUVimage;
      }
      break;
    case "HATTA":
      logo = HATTAimage;
      break;
    case "EU":
      logo = EUimage;
      break;
    default:
      logo = IATAimage;
      break;
  }

  return (
    <div
      className={`nc-Card4 relative flex flex-col-reverse sm:flex-row sm:items-center rounded-[40px] group ${className}`}
      data-nc-id="Card4"
    >
      <div
        className={`block flex-shrink-0 sm:w-56 sm:mr-6 rounded-3xl overflow-hidden mb-5 sm:mb-0`}
      >
        <div className={`block relative aspect-ratio aspect-w-16 aspect-h-6`}>
          <NcImage
            src={logo}
            alt={title}
            className={`object-contain object-center w-full h-full`}
          />
          <span>
            <PostTypeFeaturedIcon
              className="absolute left-2 bottom-2"
              postType={undefined}
              wrapSize="w-8 h-8"
              iconSize="w-4 h-4"
            />
          </span>
        </div>
      </div>

      <div className="flex flex-col flex-grow">
        <div className="space-y-5 mb-4">
          <CategoryBadgeList categories={categories} />
          <div>
            <h2
              className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}
            >
              {title}
            </h2>
            <div className="hidden sm:block sm:mt-2">
              <span className="text-neutral-500 dark:text-neutral-400 text-base">
                {desc}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card4;

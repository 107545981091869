import { Stripe } from "@stripe/stripe-js";
import { API_URL } from "config/environment";
import { Order } from "containers/CheckOutPage/CheckOutPage";

type StripeConfig = {
  publishableKey: string;
};

export const getStripeConfig = (): Promise<StripeConfig> => {
  return fetch(API_URL + "/v1/payment/config", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => res.json());
};

type PaymentIntentProps = {
  totalPrice: number;
};

export const createPaymentIntent = (
  PaymentIntentProps: PaymentIntentProps
): Promise<any> => {
  return fetch(API_URL + "/v1/payment/create-payment-intent", {
    method: "POST",
    body: JSON.stringify(PaymentIntentProps),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => res.json());
};

export const createOrder = (order: Order) => {
  return fetch(API_URL + "/v1/payment/create-order", {
    method: "POST",
    body: JSON.stringify(order),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => res.json());
};

export const finalizeOrder = async (
  clientSecret: string,
  paymentIntent: string
): Promise<{ order: Order; paymentMethod: any }> => {
  const response = await fetch(API_URL + "/v1/payment/finalize", {
    method: "POST",
    body: JSON.stringify({
      clientSecret: clientSecret,
      paymentIntent: paymentIntent,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  });

  if (!response.ok) {
    throw new Error("Failed to finalize order");
  }
  return await response.json();
};

export const authenticateCaptchaToken = (token: string) => {
  return fetch(API_URL + "/v1/captcha/authentication", {
    method: "POST",
    body: JSON.stringify({
      token,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => res.json());
};

export enum MovesActionTypes {
  SET_MOVES = "SET_MOVES",
}

export interface SetMovesAction {
  type: MovesActionTypes.SET_MOVES;
  payload: any;
}

export function setMoves(WTeat: any): SetMovesAction {
  return {
    type: MovesActionTypes.SET_MOVES,
    payload: WTeat,
  };
}

export type MovesAction = SetMovesAction;

import {StayMealTypesActionTypes, stayMealTypesAction} from './../actions/stayMealTypes'


export interface StayMealTypesState {
  stayMealTypes: any[];
}

const initialState: StayMealTypesState = {
  stayMealTypes: [],
};

const stayMealTypesReducer = (state = initialState, action: stayMealTypesAction) => {
  switch (action.type) {
    case StayMealTypesActionTypes.SET_STAY_MEAL_TYPES:
      return {
        ...state,
        stayMealTypes: action.payload,
      };
    default:
      return state;
  }
};

export default stayMealTypesReducer;
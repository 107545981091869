import {StayActionTypes, StayAction} from './../actions/stay'


export interface StayState {
  stay: any[];
}

const initialState: StayState = {
  stay: [],
};

const stayReducer = (state = initialState, action: StayAction) => {
  switch (action.type) {
    case StayActionTypes.SET_STAY:
      return {
        ...state,
        stay: action.payload,
      };
    default:
      return state;
  }
};

export default stayReducer;
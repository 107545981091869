import { FC, useState } from "react";
// import { Helmet } from "react-helmet";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { postContactUs } from "services/api";
import Seo from "components/Seo/Seo";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "49 Kolokotroni Street, 105 60, Athens, Greece",
  },
  {
    title: "💌 EMAIL",
    desc: "hello@orbito.travel",
  },
  {
    title: "☎ PHONE",
    desc: "+30 215 215 3103",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [form, setForm] = useState<any>({
    fullName: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const handleChange = (event: any) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onClick = () => {
    setLoading(true);
    postContactUs(form).then((res) => {
      setLoading(false);
      setSent(true);
    });
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Seo
        name="Contact Us | Orbito Travel"
        description="Connect with Orbito Travel for personalized assistance and inquiries. Reach out to our team for support with accessible travel arrangements. Start your journey towards inclusive exploration by contacting us today!"
        title="Contact Us | Orbito Travel"
        type="page"
      />
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
            </div>
            <div>
              {sent ? (
                <div>We have received your message. Thank you</div>
              ) : (
                <form
                  className="grid grid-cols-1 gap-6"
                  action="#"
                  method="post"
                >
                  <label className="block">
                    <Label>Full name</Label>

                    <Input
                      type="text"
                      className="mt-1"
                      name="fullName"
                      value={form.fullName}
                      onChange={handleChange}
                    />
                  </label>
                  <label className="block">
                    <Label>Email address</Label>

                    <Input
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                      className="mt-1"
                    />
                  </label>
                  <label className="block">
                    <Label>Message</Label>

                    <Textarea
                      name="message"
                      value={form.message}
                      onChange={handleChange}
                      className="mt-1"
                      rows={6}
                    />
                  </label>
                  <div>
                    <ButtonPrimary loading={loading} onClick={onClick}>
                      Send Message
                    </ButtonPrimary>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;

export enum StayCurrentTotalPriceWithChargesActionTypes {
  SET_STAY_CURRENT_TOTAL_PRICE_WITH_CHARGES = 'SET_STAY_CURRENT_TOTAL_PRICE_WITH_CHARGES',
}

export interface SetStayCurrentTotalPriceWithChargesAction {
  type: StayCurrentTotalPriceWithChargesActionTypes.SET_STAY_CURRENT_TOTAL_PRICE_WITH_CHARGES;
  payload: any; 
}


export function setStayCurrentTotalPriceWithCharges(stayCurrentTotalPriceWithCharges: any): SetStayCurrentTotalPriceWithChargesAction {
  return {
    type: StayCurrentTotalPriceWithChargesActionTypes.SET_STAY_CURRENT_TOTAL_PRICE_WITH_CHARGES,
    payload: stayCurrentTotalPriceWithCharges,
  };
}


export type stayCurrentTotalPriceWithChargesAction = SetStayCurrentTotalPriceWithChargesAction;

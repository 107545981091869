import moment, { Moment } from "moment";

import { API_URL } from "config/environment";
import { IExtraService, ITransportation,IAboutUsSection,IFaqSection,IQuestion ,IAccreditation} from "./models";
import {
  Dates,
  Guests,
  TravelingFrom,
  TravelingTo,
} from "store/reducers/search";

export interface CityInfo {
  IATA: string;
  city: string;
  country: string;
  disabled?: boolean;
  hasFreeText?:boolean;
  freeText?:string;
}

export let apiController: any;

export const getCitiesByName = (name: string): Promise<CityInfo[]> => {
  apiController = new AbortController();
  return fetch(
    API_URL + "/v1/city/getCitiesByName?name=" + encodeURIComponent(name),
    {
      method: "get",
      signal: apiController.signal,
    }
  ).then((res) => {
    apiController = null;
    return res.json();
  });
};

export const getArrivingCityByName = (startingCityName: string, name: string): Promise<CityInfo[]> => {
   apiController = new AbortController();
  return fetch(
    `${API_URL}/v1/city/getArrivingCityByName?startingCityName=${encodeURIComponent(startingCityName)}&name=${encodeURIComponent(name)}`,
    {
      method: "get",
      signal: apiController.signal,
    }
  ).then((res) => {
    apiController = null;
    return res.json();
  });
};


export const getAirlines = (): Promise<any> => {
  return fetch(API_URL + "/v1/airline/data", {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getBetaAccess = (code: string): Promise<{ status: string }> => {
  return fetch(API_URL + "/v1/users/beta-access/" + code, {
    method: "get",
  }).then((res) => {
    return res.json();
  });
};

export const getFlights = (search: any): Promise<any> => {
  const format = "DD/MM/YYYY";

  const flyFrom = search?.travelingFrom?.IATA;
  const flyTo = search?.travelingTo?.IATA;
  const guests: number =
    search?.guests?.guestAdults + search?.guests?.guestWheelchair;

  const departStart = moment(search?.dates?.startDate).format(format);
  const departEnd = moment(search?.dates?.startDate).format(format);

  const retDepartStart = moment(search?.dates?.endDate).format(format);
  const retDepartEnd = moment(search?.dates?.endDate).format(format);

  const body = {
    flyFrom: 'ALG',
    flyTo: 'LON',
    guests: guests,
    departStart:'05/08/2024',
    departEnd: departEnd,
    retDepartStart: '18/08/2024',
    retDepartEnd: retDepartEnd,
  };

  return fetch(API_URL + "/v1/flight/data", {
    method: "POST",
    body: JSON.stringify(body),

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getHotelDetail = (search: any, id: string): Promise<any> => {
  const format = "YYYY-MM-DD";
  const depart = moment(search?.dates?.startDate).format(format);
  const ret = moment(search?.dates?.endDate).format(format);
  const guests: number =
    search?.guests?.guestAdults + search?.guests?.guestWheelchair;

  const body = {
    checkin: depart,
    checkout: ret,
    residency: "gb",
    language: "en",
    guests: [
      {
        adults: guests,
        children: [],
      },
    ],
    ids: [id],
    currency: "EUR",
  };

  return fetch(API_URL + "/v1/stay/hotelRoomInfo", {
    method: "POST",
    body: JSON.stringify(body),

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getHotelsResults = (
  destinationCity: any[],
  travelDates: any[],
  adults: number,
  guestWheelchair: number
): Promise<any> => {
  return fetch(API_URL + "/v1/stay/hotels/results", {
    method: "POST",
    body: JSON.stringify({
      destinationCity: destinationCity,
      travelDates: travelDates,
      guests: adults,
      guestWheelchair: guestWheelchair,
    }),

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const retrieveHotels = (
  destination: TravelingTo,
  travelDates: { startDate: Moment; endDate: Moment },
  { guestAdults, guestWheelchair }: Guests
): Promise<any> => {
  console.log("Fetching hotels for ",destination,travelDates.endDate.toISOString(),travelDates.startDate.toISOString())
  return fetch(API_URL + "/v1/stay/hotels", {
    method: "POST",
    body: JSON.stringify({
      destinationCity: destination.city,
      travelDates: {
        startDate: travelDates.startDate.format('YYYY-MM-DD'),
        endDate: travelDates.endDate.format('YYYY-MM-DD'),
      },
      guests: guestAdults,
      guestWheelchair: guestWheelchair,
    }),

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getPlacesResults = (
  destinationCity: any[],
  travelDates: any[],
  adults: number,
  guestWheelchair: number
): Promise<any> => {
  return fetch(API_URL + "/v1/json/what-to-do", {
    method: "POST",
    body: JSON.stringify({
      destinationCity: destinationCity,
      travelDates: travelDates,
      guests: adults,
      guestWheelchair: guestWheelchair,
    }),

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getRestaurantsResults = (
  destinationCity: any[],
  travelDates: any[],
  adults: number,
  guestWheelchair: number
): Promise<any> => {
  return fetch(API_URL + "/v1/json/where-to-eat", {
    method: "POST",
    body: JSON.stringify({
      destinationCity: destinationCity,
      travelDates: travelDates,
      guests: adults,
      guestWheelchair: guestWheelchair,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getMovesResults = (
  destinationCity: any[],
  travelDates: any[],
  adults: number,
  guestWheelchair: number
): Promise<any> => {
  return fetch(API_URL + "/v1/json/how-to-move", {
    method: "POST",
    body: JSON.stringify({
      destinationCity: destinationCity,
      travelDates: travelDates,
      guests: adults,
      guestWheelchair: guestWheelchair,
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getFlightsNoData = (
  destination_iata: string,
  arrival_iata: string,
  travelDates: any
): Promise<any> => {
  return fetch(API_URL + "/v1/engine/flights/no", {
    method: "POST",
    body: JSON.stringify({
      destination_iata: destination_iata,
      arrival_iata: arrival_iata,
      travel_from: travelDates.startDate,
      travel_to: travelDates.endDate,
    }),

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
      destination_iata: destination_iata,
      arrival_iata: arrival_iata,
      travel_from: travelDates.startDate,
      travel_to: travelDates.endDate,
    },
  }).then((res) => {
    return res.json();
  });
};

export const getHotelData = (
  hotelId: any,
  travelDates: any,
  guests: number,
  guestWheelchair: number
): Promise<any> => {
  return fetch(API_URL + "/v1/stay/hotel/data", {
    method: "POST",
    body: JSON.stringify({
      hotelId: hotelId,
      travelDates: travelDates,
      guests: guests,
      guestWheelchair: guestWheelchair,
    }),

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getPlaceData = (placeId: any): Promise<any> => {
  console.log(placeId);
  return fetch(API_URL + "/v1/do/place/data", {
    method: "POST",
    body: JSON.stringify({
      placeId: placeId,
    }),

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getRestaurantData = (placeId: any): Promise<any> => {
  return fetch(API_URL + "/v1/eat/place/data", {
    method: "POST",
    body: JSON.stringify({
      placeId: placeId,
    }),

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getMoveData = (placeId: any): Promise<any> => {
  return fetch(API_URL + "/v1/move/place/data", {
    method: "POST",
    body: JSON.stringify({
      placeId: placeId,
    }),

    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const postTripSubmit = (
  search: any,
  contactInfo: any,
  cart: any
): Promise<any> => {
  const body = {
    contactInfo,
    tripInfo: search,
    cart,
  };

  return fetch(API_URL + "/v1/cart/submitTrip", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return res.json();
  });
};

export const postContactUs = (contactInfo: any): Promise<any> => {
  const body = {
    contactInfo,
  };

  return fetch(API_URL + "/v1/cart/contact-us", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getAllTransportations = (): Promise<ITransportation[]> => {
  return fetch(API_URL + "/v1/transportations", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};

export const getAllExtraServices = (): Promise<IExtraService[]> => {
  return fetch(API_URL + "/v1/extra/services", {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};


export const getAllAboutUsSections = (): Promise<IAboutUsSection[]> => {
  return fetch(`${API_URL}/v1/about`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=",
    },
  }).then((res) => {
    return res.json();
  });
};


export const getAllFaqSections = (): Promise<IFaqSection[]> => {
  return fetch(`${API_URL}/v1/faq`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=", // Adjust as necessary
    },
  }).then((res) => {
    return res.json();
  });
};

export const getAllAccreditations = (): Promise<IAccreditation[]> => {
  return fetch(`${API_URL}/v1/accreditations`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        "Basic NDg0MzpmMDZiZjNhYy03MzUwLTRkYjgtYjcxZS1lNzA5YWY5MTIzYjI=", // Adjust as necessary
    },
  }).then((res) => {
    return res.json();
  });
};